@charset "UTF-8";

// -----------------------------------------------
// Title
// -----------------------------------------------
.title {
    font-weight: bold;

    @include sm {
        margin-top: 2rem;
        margin-bottom: 1rem;
        padding-bottom: .6rem;
        font-size: 1.6rem;
    }

    @include mt {
        margin-top: 2rem;
        margin-bottom: 1rem;
        padding-bottom: .7rem;
        font-size: 1.6rem;
    }

    &:first-of-type {

      margin-top: 1rem;

        @include mt {
            margin-top: 1rem;
        }
    }


    &.title--primary {
        border-bottom: solid 1px $color_1;
    }

    &.title--needs {
        border-bottom: solid 1px $color_2;
    }

    &.title--sub {
        margin-bottom: 0;
        padding-bottom: 1rem;

        @include sm {
            font-size: 1.6rem;
        }

        @include mt {
            padding-top: 1rem;
            text-align: center;
            font-size: 1.4rem;
        }
    }

    &.title--sub-border {
        padding-bottom: .5rem;

        @include mt {
              padding-bottom: 1rem;
        }
    }
}

.titles {
    position: relative;

    .title {
        @include mt {
            margin-bottom: 1rem;
        }
    }

    .title__arrows {
        @include sm {
            position: absolute;
            top: 50%;
            right: 0;
            display: inline-block;
            text-decoration: underline;
            transform: translateY(-50%);
        }

        @include mt {
            display: block;
            padding: 1rem;
            text-align: center;
            font-size: 1.2rem;
            color: $base_color;
            background: $color_1;
        }

        .row {
            display: block;
        }

        img {
            max-width: 6px;
        }
    }

    .title__arrow {
        @include sm {
            margin: 0 .5rem 0 1rem;
        }

        @include mt {
            display: none;
        }
    }
}

.title-lower {
    .title-lower__text {
        @include padding(1rem 0, 1rem);

        font-size: 1.4rem;
        font-weight: bold;
        color: $base_color;

        @include mt {
            text-align: center;
        }

        &.title-lower__text-has-breadcrumb {
            @include mt {
                text-align: left;
                font-size: 1.2rem;
            }
        }
    }

    &.title-lower--property {
        background: rgb(14, 107, 175);
        background: linear-gradient(
            83deg,
            rgba(14, 107, 175, 1) 0%,
            rgba(63, 66, 111, 1) 100%
        );
    }

    &.title-lower--needs {
        background: rgb(124, 179, 66);
        background: linear-gradient(
            83deg,
            rgba(124, 179, 66, 1) 0%,
            rgba(10, 112, 17, 1) 100%
        );
    }

    &.title-lower--mypage {
        background: url(../images/title_mypage.jpg) center center / cover
            no-repeat;
    }
}

.title-list {
    font-weight: bold;
    font-size: 1.3rem;

    @include padding-bottom(2rem, 1.5rem);
}

.title-box {
    text-align: center;

    .title-box__text {
        display: block;
        padding: .5rem 0;
        background: $color_1_light;
    }
}

.title-icons {
    @include padding(1.4rem 0, .5rem 1rem 1rem);

    margin-left: auto;

    .title-icon__move {
        max-width: 141px;
    }

    .title-icon {
        & + .title-icon {
            @include padding-left(2rem, 1rem);
        }
    }
}

.title-tab {
    @include mt {
        padding: 0;
    }

    .title-tab__items {
        @include mt {
            align-items: center;
            padding: 0;
        }

        .title-tab__item {
            @include mt {
                padding: 0;
                font-size: 1rem;
            }

            .btn {
              border: none;
              font-weight: bold;
            }


            .btn.btn--basic {
                background: none;
                color: $base_color;
                box-shadow: none;

                @include sm {
                    width: 13rem;
                }

                @include mt {
                    width: 100%;
                    padding: 1rem 0;
                    background: $color_14;
                    font-size: 1rem;
                    font-weight: bold;
                }

                &:hover,
                &.btn--activate {
                    background: $base_color;
                    color: $font_color;
                    opacity: 1;
                }

                .circle.circle--has-num {
                    @include mt {
                        display: none;
                    }
                }
            }
        }
    }
}

.title-mypage {
    .title-mypage__wrap {
        @include padding(.5rem 6.2% .5rem 1.5rem, 1rem 1.5rem);

        font-size: 1.4rem;
        background: $font_color;
        color: $base_color;

        @include sm {
            margin-bottom: 1.5rem;
        }
    }

    .title-mypage__text {
        @include mt {
            font-weight: bold;
        }
    }

    .title-mypage__text-note {
        @include sm {
            font-size: .8rem;
        }

        @include mt {
            padding: 1rem 1rem 0;
        }
    }

    .title-mypage__icon {
        @include sm {
            line-height: 0;
            margin-left: auto;
        }

        img {
            max-width: 30px;
        }
    }
}
