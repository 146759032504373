@charset "UTF-8";

@import 'mixins';

/*-----------------------------------------------
pem
-----------------------------------------------*/
.pem {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, .8);
    opacity: 0;
    transition: opacity .3s;

    .pem__wrap {
        display: table;
        width: 100%;
        height: 100%;

        .pem__body {
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            text-align: center;

            .pem__content {
                position: relative;
                width: 50%;
                margin: 0 auto;

                .pem__close {
                    z-index: 1;
                    position: absolute;
                    top: -2rem;
                    right: 0;
                    font-size: 3rem;
                    line-height: 0;
                    color: #fff;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &.pem--activate {
        display: block;
        opacity: 1;
    }

    &.pem--open {
        overflow-y: hidden;
    }
}

.pemLoading {
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .3s;

    &.pemLoading--activate {
        opacity: 1;
    }

    .pemLoading__wrapper {
        display: table;
        width: 100%;
        height: 100%;

        .pemLoading__body {
            display: table-cell;
            text-align: center;
            vertical-align: middle;

            img {
                max-width: 44px;
            }
        }
    }
}
