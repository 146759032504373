@charset "UTF-8";

// -----------------------------------------------
// mypage
// -----------------------------------------------
// top
.contents--mypage-top {
    .title.title--sub {
        @include mt {
            padding-top: 0;
        }
    }

    .myt-menu {

      &.content {
          @include mt {
              padding: 0;
          }
      }
        .btn {
            font-weight: bold;

            @include mt {
                font-size: 1rem;
                width: 100%;
            }
        }

        .myt-menu__item-mask {
            @include sm {
                @include col-2(.75rem);
            }

            & + .myt-menu__item-mask {
                @include padding-top(1.5rem, 0rem);
            }
        }

        .myt-menu__item-wrap + .myt-menu__item-wrap {
            @include mt {
                padding-top: 0rem;
            }
        }

        .myt-menu__item-col {
            padding: 1.5rem;
            background: $color_11;

            .title--sub {
              margin-top: 0;
            }

            @include sm {
                height: 100%;
            }

            @include mt {
                padding: 1rem;

                h2 {
                  margin: 0;
                }
            }

        }

        .myt-menu__items {
            &.myt-menu__items--vertical {
                .myt-menu__item {

                    .btn.btn--hazime span {
                        padding: 0;
                    }

                    & + .myt-menu__item {
                        @include padding-top(1rem, .5rem);


                    }
                }
            }

            a {
              width: 100%;
            }

            &:not(.myt-menu__items--vertical) {
                @include sm {
                    @include col-7(.5rem);
                }

                @include mt {
                    @include col-3(.5rem);
                }

                .myt-menu__item {
                    @include sm {
                        flex-basis: 20%;
                        max-width: 20%;
                    }
                }
            }
        }

        .myt-menu__item-bottom  {
            .myt-menu__item {
                @include mt {
                    padding: 5px;
                }
            }

        }
    }
}

// menu
.contents--mypage-menu {
    .title-tab__item {
        .btn {
            .btn__wrap {
                @include sm {
                    min-height: 2rem;
                }
            }
        }
        .btn:active,
        .btn:hover  {
            top: 0;
        }
    }
}
// list
.contents--mypage-list {
    .title-tab__items {
        @include mt {
            @include col-4(0rem);
        }
    }

    .title-tab__item {
        @include mt {
            flex-basis: 50%;
            max-width: 25%;
            font-size: .8rem;
        }
    }

    .title-lower .title-lower__text {
        @include mt {
            font-size: 1.3rem;
            padding: 1.5rem 0 1rem 1rem;
        }
    }

    .content {
        padding: .5rem;
    }

    .pager {
        padding-top: 0;
    }

    .list-search__items {
        padding-bottom: 2rem;
    }

    .list-search,
    .list__item {
        @include mt {
            padding-left: .5rem;
            padding-right: .5rem;
        }
    }

    .myli__add {

      margin-bottom: 1rem;

      .btn {
        padding: .5rem;
      }
        @include mt {
            padding: .5rem;
        }

        &.mb-off {
            margin-bottom: 2rem;
        }
    }
}

// sell
.contents--mypage-sell {
    .select-mask {
        @include sm {
            margin: 1rem 0 1rem 1rem;
        }
    }
}

// buy
.contents--mypage-buy {
    .title-tab .title-tab__items .title-tab__item:last-child .btn.btn--basic {
        @include sm {
            width: 16rem;
        }
    }

    .list {
        @include sm {
            padding-top: 1rem;
        }
    }
}

.contents--mypage-buy-menu {
    .title-tab .title-tab__items .title-tab__item {
        max-width: 50%;
    }
}

.contents--mypage-sell-menu {
    .title-tab .title-tab__items .title-tab__item {
        max-width: 50%;
    }
}

.contents--mypage-buy-dl {
}

.contents--mypage-buy-favo {
}

.contents--mypage-buy-needs {
    .list .list__item .list__item-main {
        @include sm {
            min-height: 7rem;
        }
    }
}

.contents--mypage-buy-favo {
}

// buy search
.contents--mypage-buy-search {
    .title-box .title-box__text {
        background: $color_17;
    }

    .property-info__text-area {
        @include mt {
            padding-top: .2rem;
            font-size: 1.6rem;
            font-weight: bold;
        }
    }

    .price-highlight {
        @include mt {
            padding: .4rem 0 0;
            font-size: 1rem;
        }
    }

    .list.list--basic {
        .list__item-main {
            @include mt {
                margin: 0;
            }
        }

        .list__item:not(.list__item--disabled)
        .list__item-main
        .list__item-icons {
            @include mt {
                padding: .75rem .75rem 0;
            }
        }
    }
}

// message list
.contents--mypage-message-list {
    .title-mypage .title-mypage__wrap {
        @include mt {
            background: none;
            color: $font_color;
        }
    }

    .title-icons {
        line-height: 0;

        @include mt {
            padding: 0;
        }
    }

    .title-lower .title-lower__text {
        @include mt {
            font-size: 1.3rem;
            padding: 1rem 1rem;
        }
    }


    .title-tab__items {
        @include mt {
            @include col-3(0rem);
        }
    }

    .title-tab__item {
        @include mt {
            flex-basis: 50%;
            max-width: 33.3333%;
        }
    }

    .search-box {
        .help-block {
            display: none;
        }

        @include padding(1rem 0, 0);

        .container {
            position: relative;

            &:after {
                content: '';
                position: absolute;

                @include sm {
                    bottom: -3.5rem;
                    left: calc(50% - 3rem);
                    border-right: 3rem solid transparent;
                    border-top: 2.5rem solid $base_color;
                    border-left: 3rem solid transparent;
                }

                @include mt {
                    bottom: -2rem;
                    left: calc(50% - 3rem);
                    border-right: 3rem solid transparent;
                    border-top: 2rem solid $base_color;
                    border-left: 3rem solid transparent;
                }
            }
        }
    }

    .myml-searches {
        position: relative;

        @include mt {
            background: $color_11;
        }

        &:after {
            content: '';
            position: absolute;

            @include sm {
                bottom: -1rem;
                left: calc(50% - 1.5rem);
                border-right: 1.5rem solid transparent;
                border-top: 1rem solid $base_color;
                border-left: 1.5rem solid transparent;
            }

            @include mt {
                bottom: -2rem;
                left: calc(50% - 3rem);
                border-right: 3rem solid transparent;
                border-top: 2rem solid $color_11;
                border-left: 3rem solid transparent;
            }
        }
    }

    .myml-search {
        @include padding(2rem 0, 1rem 1.5rem);

        .myml-search__wrap {
        }

        .myml-search__inputs {
            @include sm {
                padding-left: 2rem;
            }
        }

        .myml-search__check-items {
            .myml-search__check-item {
                .myml-search__check-item-wrap {
                    @include mt {
                        @include col-2(.75rem);
                    }
                }

                .myml-search__check-label {
                    @include mt {
                        flex-basis: 50%;
                        max-width: 50%;
                    }

                    + .myml-search__check-label {
                        padding-left: .5rem;
                    }
                }

                .label {
                    @include mt {
                        width: 100%;
                    }
                }

                .btn.btn--icon-only {
                    @include sm {
                        padding-left: 1rem;
                    }
                }

                + .myml-search__check-item {
                    @include sm {
                        padding-left: 2rem;
                    }

                    @include mt {
                        padding-top: 1rem;
                    }
                }
            }
        }

        .myml-search__items {
            @include sm {
                @include col-2(.5rem);
            }

            @include mt {
                @include col-2(.75rem);

                padding-bottom: 1.5rem;
            }

            .myml-search__item {
                line-height: 0;

                .btn {
                    @include mt {
                        width: 100%;
                    }
                }
            }
        }

        .input-search-mask {
            @include sm {
                padding-top: .8rem;
            }

            @include mt {
                margin-top: 1rem;
            }

            .input {
                @include sm {
                    max-width: 630px;
                    background: $color_11;
                }

                @include mt {
                    background: $base_color;
                }
            }
        }

        .submit-area.submit-area--independent {
            @include mt {
                padding: 2rem 0 1rem;
            }

            .btn {
                @include mt {
                    background: $font_color;
                    color: $base_color;
                }
            }
        }
    }

    .myml-content {

        background: $color_11;

        .container {
            padding-top: 1rem;
        }

        .myml__item-num {
        }

        .myml__icons {
            @include mt {
                padding: 0 1.5rem;
            }
        }

        .myml__icon-items {
            .myml__icon-item {
            }

            .myml__icon-item__icon {
            }

            .myml__icon-item__text {
                padding-left: 1rem;
            }
        }

        .list {
            .list__item {
                .property-images {
                    .property-image__wrap .property-image {
                        @include mt {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .list {
        @include sm {
            padding-top: 1rem;
            min-height: 7rem;
        }

        @include mt {
            padding-top: 0rem;
        }

        .list__item {

            .list__item-wrap {
              position: relative;
              background: $base_color;
              @include shadow();
              transition: all .3s;

                @include mt {
                    padding: 0;
                }

                &:hover {
                  background-color:darken($base_color, .5%);
                }
            }

            a{
                border: solid 1px $color_1_light;

                &:hover {
                  border: solid 1px $color_1;
                  box-shadow: 1px 3px 1px rgba(0,0,0,.2);
                }
            }


            &.list--needs {
                a{
                    border: solid 1px $color_2_light;

                    &:hover {
                      border: solid 1px $color_2;
                    }
                }

                .list__item-wrap {
                    &:hover {
                      background-color:darken($base_color, .5%);
                    }

                }

                .list__item-mail-status {
                    background: $color_2_light;
                }

                .property-images.property__main-image--blank
                .property-image__wrap {
                    background: $color_2_light;

                    .property-image__text {
                        color: $color_2;
                    }
                }

                .list__item-time {
                    font-size: .8rem;

                    .day {
                        background: $color_2;
                    }
                }

                .list__item-from-name {

                    .from {
                        background: $color_2;
                    }
                }
            }

            .price-highlight {
                font-weight: normal;
            }
        }

        .list__item-mail-status {
            @include padding(0 .4rem, 0);

            @include sm {
                height: 100%;
                background: $color_1_light;
            }

            @include mt {
                text-align: center;
                justify-content: center;
                background: $color_1_light;
            }

            img {
                @include mt {
                    max-width: 30px;
                    max-height: 30px;
                }
            }
        }

        .list__item-read-status {
            @include sm {
                width: 100%;
            }
        }

        .list__item-read-icons {
            text-align: center;

            @include mt {
                align-self: center;
                flex-basis: 15%;
                max-width: 15%;
            }

            .list__item-read-icon-wrap {
                  height: 100%;

                  @include mt {
                      position: relative;
                      background: $color_11;
                      font-size: .8rem;
                  }
            }

            .list__item-read-icon {
                img {
                    max-width: 26px;
                }

                + .list__item-read-icon {
                    @include mt {
                        // margin-left: -3rem;
                    }
                }
            }
        }

        .list__item-time {
            @include mt {
                align-self: center;
                margin-left: auto;
                padding: .5rem;
                font-size: .8rem;
                text-align: right;
            }
        }

        .list__item-main {
            @include mt {
                margin: 0;
                padding: 0 .5rem .5rem;
            }

            > * {
                padding: 0;
            }
        }

        .list__item-subjects {
            @include sm {
                padding: 1rem 1rem .6rem;
            }

            @include mt {
                padding:0 0 .5rem 0;
            }

            .list__item-time {
                font-size: .8rem;

                .day {
                    text-align: center;
                    font-size: .6rem;
                    background: $color_1;
                    color: $base_color;
                    border-radius: 5px;
                    padding: 2px 5px;
                    margin-right: 5px;
                    min-width: 50px;
                    display: inline-block;
                }
            }

            .list__item-subject {
                line-height: 1.2;
                font-size: 1.2rem;
                font-weight: normal;
            }

            .list__item-from-name {
                font-size: .8rem;
                padding: .2rem 0 .5rem 0;
                margin-bottom: .5rem;
                border-bottom: solid 1px $color_11;

                @include mt {
                  padding: .5rem 0;
                }

                .from {
                    text-align: center;
                    font-size: .6rem;
                    background: $color_1;
                    color: $base_color;
                    border-radius: 5px;
                    padding: 2px 5px;
                    margin-right: 5px;
                    min-width: 50px;
                    display: inline-block;

                    @include mt {
                      display: none;
                    }
                }
            }
        }

        .list__item-info {
            @include mt {
                padding: .5rem 0;
                background: $color_11;
            }

            .property-images {

                .property-image__info {
                    .property-image__icon img {
                        display: none;
                        @include sm {
                            top: -.3rem;
                            width: 1.3rem;
                            padding-right: .2rem;
                        }
                    }

                    .property-image__text {
                        @include sm {
                            padding: .2rem 0;
                            font-size: .8rem;
                        }
                    }
                }
            }

            .list__item-title {
                font-weight: normal;
                font-size: .8rem;

                @include sm {
                    line-height: 1.2;
                    padding-bottom: .5rem;
                }

                @include mt {
                    line-height: 1.2;
                    padding-bottom: .5rem;
                }

                .tag {
                    @include sm {
                        display: none;
                    }
                }
            }

            .list__item-property {
                padding-left: 1rem;
            }

            .list__item-address {
                @include padding-top(.5rem, .75rem);

                font-size: .8rem;
            }
        }

        .list__item-arrow {
            text-align: center;

            .row {
                height: 100%;
            }

            img {
                max-width: 20px;
            }
        }

        &.list--basic
        .property-images.property__main-image--blank
        .property-image__wrap {
            @include sm {
                padding: 2rem 0;
            }
        }
    }
}

// message detail
.contents--mypage-message-detail {
    .breadcrumb,
    .msgd__header {
        background: $color_1_light;
    }

    .select-arrow {
        background: $color_1;
    }

    .input {
        &::placeholder {
            @include sm {
                color: transparent;
            }
        }
    }

    .has_triangle {
        position: relative;

        @include mt {
            margin-top: 3rem;
        }

        &:after {
            content: '';
            position: absolute;
            left: calc(50% - 3rem);
            border-right: 3rem solid transparent;
            border-bottom: 2rem solid $base_color;
            border-left: 3rem solid transparent;

            @include sm {
                top: calc(100% - 2rem);
            }

            @include mt {
                top: calc(100% - 1.6rem);
            }
        }
    }

    .msgd__header {
        @include padding(0 0 3.5rem, 0);

        .list-search {
            padding-top: 0;
        }

        .list-search__items {
            @include mt {
                padding: 1rem;
            }

            .list-search__item {
                background: $base_color;
            }
        }
    }

    .msgd__header-info {
        @include sm {
            padding-left: 2.5rem;
        }

        .title-list {
            @include mt {
                padding: 2rem 1.5rem 1.5rem;
            }
        }

        .property-images .property-image__info .property-image__icon img {
            @include sm {
                max-width: 80%;
            }
        }

        .price-highlight {

            @include sm {
                font-size: 1.4rem;
            }

            @include mt {
                padding: 0 1.5rem;
            }
        }

        .title-box .title-box__text {
            background: $base_color;
        }

        .property-infos.property-infos--vertical {
            @include mt {
                padding: 1.5rem 1.5rem 3rem;
            }

            .property-info + .property-info {
                @include sm {
                    padding-top: .5rem;
                }
            }
        }
    }

    .msgd__body {
        @include sm {
            padding: 2rem 0 1.2rem;
        }

        @include mt {
            padding: 0rem;
        }

        &.content {
            @include mt {
              padding: 0rem;
            }

            .container {
              @include mt {
                padding: .5rem;
              }
            }
        }

        .msgd__names {
            @include mt {
                padding-bottom: .5rem;
            }

            .circle.circle--has-name {
                @include sm {
                    width: 3.8rem;
                    height: 3.8rem;
                    line-height: 3.8rem;
                }

                @include mt {
                    width: 2.5rem;
                    height: 2.5rem;
                    line-height: 2.5rem;
                }
            }
        }

        .msgd__forms {
            @include sm {
                padding: 1.2rem 0;
            }

            .form {
                @include sm {
                    padding-right: 2.5rem;
                }
            }

            .form-basic {
              border-bottom: none;

              .form-file {
                  @include mt {
                      padding: 0;
                  }
              }

              .form-file-items {
                padding: 0;
              }
            }

            .submit-area {
                @include sm {
                }
            }
        }
    }

    .msgd__log {
        background: $color_11;

        @include sm {
            margin-top: 1.8rem;
            padding-top: 3rem;
        }

        .msgd__log-wrap {
            @include padding(0 3.5rem 2rem 2rem,0 1rem 0 1rem);

            .title{
                &.title--sub {
                    @include mt {
                        padding: 1rem 0 0 0;
                    }
                }
              }
            }

        .msgd__log-box {
            &:not(.msgd__log-box--reply) {
                .msgd__log-msg {
                    @include mt {
                        padding-top: 0rem;
                    }

                    &:after {
                        content: '';
                        position: absolute;

                        @include sm {
                            top: 50%;
                            left: 0rem;
                            border-top: 1rem solid transparent;
                            border-right: 1rem solid $base_color;
                            border-bottom: 1rem solid transparent;
                            transform: translateY(-50%);
                        }

                        @include mt {
                            left: -0.5rem;
                            top: 0.5rem;
                            border-top: 1rem solid transparent;
                            border-bottom: 1rem solid transparent;
                            border-right: 1rem solid $base_color;
                            // top: 1px;
                            // left: 3rem;
                            // border-top: .5rem solid transparent;
                            // border-right: .5rem solid transparent;
                            // border-bottom: .5rem solid $base_color;
                            // border-left: .5rem solid $base_color;
                        }
                    }
                }

                .msgd__log-msg-wrap {
                    background: $base_color;
                    border-radius: 12px;

                }
            }

            &.msgd__log-box--reply {
                padding-top: 1.5rem;

                @include mt {
                    padding-top: 0rem;
                    margin-top: 1rem;
                }

                .msgd__log-msg {
                    @include mt {
                        margin-left: 1rem;
                    }
                }

                .msgd__log-msg-wrap {
                    position: relative;
                    background: $color_1;
                    border-radius: 12px;
                    color: $base_color;

                    @include padding-top(0, 0rem);

                    &:after {
                        content: '';
                        position: absolute;

                        @include sm {
                            top: 50%;
                            right: -1rem;
                            border-top: 1rem solid transparent;
                            border-left: 1rem solid $color_1;
                            border-bottom: 1rem solid transparent;
                            transform: translateY(-50%);
                        }

                        @include mt {
                            content: '';
                            position: absolute;
                            right: -0.5rem;
                            top: 0.5rem;
                            border-top: 1rem solid transparent;
                            border-bottom: 1rem solid transparent;
                            border-left: 1rem solid $color_1;
                        }
                    }
                }
            }

            @include mt {
                align-items: start;
                margin-top: 1rem;
            }
        }

        .msgd__log-msg {
            position: relative;

            @include sm {
                padding-left: 1rem;
            }
        }

        .msgd__log-msg-wrap {
            @include sm {
                padding:0;
            }

            @include mt {
                padding: .5rem;
            }
        }

        .msgd__log-dear {
            line-height: 2;

            @include mt {
                padding-left: 1rem;
            }
        }

        .msgd__log-subject {
          padding: 1rem 2rem;
            @include mt {
                padding: 0.5rem 0rem;
            }

            h4 {
                font-weight: bold;
            }
            .msgd__name + .msgd__log-subject-text {
                @include mt {
                    padding-left: 1rem;
                }
            }

            .msgd__log-subject-text {
                @include mt {
                    font-weight: bold;
                }
            }
        }

        .msgd__log-body {
            padding:0 2rem;
            line-height: 140%;
            white-space:pre-wrap;
            word-wrap:break-word;
            @include mt {
                padding: 0;
            }
        }

        .msgd__log-btn {
            .submit-area {
                text-align: right;
                padding: 1rem;

                .download__btn {
                    display: inline;
                }

                @include mt {
                    padding: 0;
                    .btn {
                        margin: 0.5rem 0;
                    }
                }
            }
        }

        .msgd__date {
            padding: .5rem 0 0 ;
            font-size: .8rem;
            text-align: right;
        }

        &:after {
            top: -2rem;
            border-bottom-color: $color_11;

            @include mt {
                top: -1rem;
            }
        }
    }

    &.contents--mypage-message-needs {
        .breadcrumb,
        .msgd__header,
        .msgd__log
        .msgd__log-box:not(.msgd__log-box--reply)
        .form-file__title{
            background: $color_2_light;
        }

        .property-infos .property-info__text.property-info__text--yield {
            color: $color_2;
        }

        .msgd__header {
            .property-images .property-image__info {
                position: static;

                .property-image__icon img {
                    position: static;
                }

                .property-image__bar {
                    background: rgba(124, 179, 66, .2);

                    @include sm {
                        height: 10rem;
                    }

                    @include mt {
                        height: 20rem;
                    }
                }

                .property-image__text {
                    color: $color_2;
                    padding-bottom: 0;

                    @include mt {
                        padding-left: 1rem;
                    }
                }
            }
        }

        .form-file__attention {
            color: $color_2_dark;
            background: $color_2_light;
        }

        .form-file__nos,
        .select-arrow {
            background: $color_2;
        }

        .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap {
            background: $color_2;
            &:after {
                border-left: 1rem solid $color_2;
            }
        }
    }
}

.contents--mypage-buy-dl {
    .list__item-title {
        @include mt {
            padding-right: .5rem;
        }
    }
}

// edit detail
.contents--mypage-edit {

    .content {
      @include mt {
        padding: 0;

        .submit-area {
          padding: 0 1rem;
        }
      }
    }
    .select-search {
        .select {
            border-radius: 0;
        }
    }

    .input.input--short {
        @include sm {
            max-width: 150px;
        }
    }

    .form-has-unit {
        .input {
            @include sm {
                max-width: 80%;
            }

            &.input--short {
                @include sm {
                    max-width: 150px;
                }
            }
        }

        .form-has-unit__unit {
            font-weight: normal;
        }
    }

    .checkbox {
        &:not(:checked) + .btn {
            background: $base_color;
            border: solid 1px;
        }

        &:checked + .btn {
            border: solid 1px transparent;
        }

        + .btn {
            border-radius: 0;

            @include sm {
            }
        }
    }

    .range-wrap {
        @include sm {
            padding-left: 0;
        }
    }

    .title.title--sub {
        @include sm {
            padding-top: 1rem;
            font-size: 1.4rem;
        }
    }

    .title-has-anchor {
        @include sm {
            margin-top: -6rem;
            padding-top: 6rem;
        }

        @include mt {
            margin-top: calc(-1 * (16.4vw + 16vw));
            padding-top: calc(16.4vw + 16vw);
        }
    }

    .myep {
        position: relative;

        @include sm {
            padding-top: 4.5rem;
        }

        @include mt {
            padding-top: 4.2rem;
        }
    }

    .myep-menu {
        z-index: 1;
        position: absolute;
        top: 0;
        width: 100%;

        @include mt {
            left: 0;
        }

        .myep-menu__item {
            position: relative;

            a {
                @include padding(1.5rem, 1rem 0);

                position: relative;
                display: block;
                text-align: center;
                font-weight: bold;

                @include mt {
                    font-size: .8rem;
                }
            }

            &.error_state {
                background: $color_3_light;
                color: $color_3;

                span.ng {
                    display: block;

                    @include mt {
                        display: none;
                    }
                }


            }

            &.no_error_state {
                span.ok {
                    display: block;

                    @include mt {
                        display: none;
                    }
                }

            }

            span {
                position: absolute;
                bottom: 4px;
                left: 0;
                right: 0;
                font-weight: bold;
                text-align: center;
                display: none;

                @include mt {
                    font-size: 0.7rem;
                    line-height: 1.5rem;
                }
            }

            &.myep-menu__item--activate a {
                color: $base_color;

                &:after {
                    @include sm {
                        content: '';
                        position: absolute;
                        bottom: -1rem;
                        left: calc(50% - .5rem);
                        border-right: .5rem solid transparent;
                        border-top: 1rem solid;
                        border-left: .5rem solid transparent;
                    }
                }
            }
        }

        &.myep-menu--fixed {
            position: fixed;

            @include mt {
                top: 14vw;
            }
        }
    }

    .myep__content {
        @include sm {
            border-top: solid 1px;
        }

        .input-box {
          @include mt {
              padding: 1rem;
          }

        }

        & + .title.title--sub {
            @include sm {
                padding-top: 2rem;
            }

            @include mt {
                text-align: center;
                margin-top: 0;
            }
        }

        &.myep__content--attachment {
            .input-box {
                @include padding(1rem 0 1rem 1rem, .5rem);
            }
        }
    }

    .traffic-modal {
        border: none;

        .modal-title {
            font-size: 1rem;
            font-weight: bold;
        }

        .title.title--sub {
            padding: .5rem;
            font-size: 1rem;
            margin:1rem 0 0 0;
            color: $base_color;

            @include mt {
                padding-top: 0;
            }
        }

        .btn {
            width: 100%;
        }
      }

    .submit-area {
        @include padding(2rem 0, 1.5rem 0);
    }

    &.contents--mypage-edit--property {
        input, select, textarea {
            @include mt {

            }
        }

        .select-search {
            .select-arrow {
                background: $color_1;
            }
        }

        .checkbox {
            &:not(:checked) + .btn {
                color: $color_1;
                border-color: $color_1;
            }

            &:checked + .btn {
                background: $color_1;
            }
        }

        .myep-menu {
            .container {
                background: $color_1_light;
            }

            .myep-menu__item {
                a {
                    color: $color_1;
                }

                &.no_error_state {
                    color: $color_1;
                }

                &.myep-menu__item--activate a {
                    background: $color_22;
                    color: $base_color;

                    &:after {
                        @include sm {
                            border-top-color: $color_22;
                        }
                    }
                }

                &.myep-menu__item--disabled {
                    display: none;
                }

                &.error_state {
                    background: $color_3_light;
                    color: $color_3;

                    &.myep-menu__item--activate a {
                        background: $color_3;
                        color: $base_color;

                        &:after {
                            @include sm {
                                border-top-color: $color_3;
                            }
                        }
                    }
                }
            }
        }

        .myep__content {
            border-color: $color_1;
        }

        .input-box .input-box__label {
            color: $color_1;
        }

        .traffic-modal {
            background: $color_1_light;

            .modal-title {
              color: $color_1;
            }

            .title.title--sub {
                color: $color_1;
            }

            .checkbox {
                &:not(:checked) + .btn {
                    color: $color_1;
                    border-color: $base_color;
                }
            }
        }
    }

    &.contents--mypage-edit--needs {
        input, select, textarea {
            @include mt {
                font-size: 16px;
            }
        }

        .select-search {
            .select-arrow {
                background: $color_2;
            }
        }

        .checkbox {

            &:not(:checked) + .btn {
                color: $color_2;
                border-color: $color_2;
            }

            &:checked + .btn {
                background: $color_2;
            }
        }

        .myep-menu {
            .container {
                background: $color_2_light;
            }

            .myep-menu__item {
                a {
                    color: $color_2;
                }

                &.no_error_state {
                    color: $color_2_dark;
                }

                &.myep-menu__item--activate a {
                    background: $color_2;
                    color: $base_color;

                    &:after {
                        @include sm {
                            border-top-color: $color_2;
                        }
                    }
                }

                &.myep-menu__item--disabled {
                    display: none;
                }

                &.error_state {
                    background: $color_3_light;
                    color: $color_3;

                    &.myep-menu__item--activate a {
                        background: $color_3;
                        color: $base_color;

                        &:after {
                            @include sm {
                                border-top-color: $color_3;
                            }
                        }
                    }
                }
            }
        }

        .myep__content {
            border-color: $color_2;
        }

        .input-box .input-box__label {
            color: $color_2;
        }

        .traffic-modal {
            background: $color_2_light;

            .modal-title {
              color: $color_2;
            }

            .title.title--sub {
                color: $color_2;
            }

            .checkbox {
                &:not(:checked) + .btn {
                    color: $color_2;
                    border-color: $base_color;
                }

                &:checked + .btn {
                    background: $color_2;
                }
            }
        }
    }
}


// invite detail
.contents--mypage-invite {
    .content {
        .container {
            @include sm {
                max-width: 800px;
            }

            .invite-list_title {
              font-weight: bold;
              line-height: 120%;
              flex-basis: 20%;
            }

            .invite-list_date {
              font-size: .8rem;
              color: #b2b2b2;

              @include mt {
                width: 100%;
                border-top: solid 1px #b2b2b2;
                margin: .5rem;
                padding: .5rem;
              }
            }

            .invite-list_status {
              display: block;
              width: 100%;

              @include mt {
                padding: 0 1rem .5rem 1rem;
              }
            }


                  .invite-list__item {
                    padding-top: 0;

                    ul {
                      border: solid 1px #fff;
                    }


                    @include mt {
                      padding-top: 1rem;
                    }
                  }
        }
    }

    .contents--mypage-invite-list {

      @include mt {
        padding: .5rem;
      }

      a {
        color: $color_3;
      }



    }

    .invite__progress {
        @include sm {
            padding-bottom: 4rem;
        }

        @include mt {
            padding-bottom: 1.5rem;
        }

        .invite__progress-items {
        }

        .invite__progress-item {
            position: relative;
            text-align: center;
            background: $color_1_light;

            &:not(:first-child) {
                .invite__progress-item-wrap {
                    @include mt {
                        padding-left: 1.5rem;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1.5rem;
                    height: 100%;
                    background: url(../images/login_progress_arrow.png) center
                        center / 100% 100% no-repeat;

                    @include tab {
                        left: -1px;
                    }
                }
            }

        }

        .invite__progress-item-wrap {
            display: block;
            font-weight: bold;

            @include padding(.8rem .8rem .8rem 2rem, 1rem 0);

            @include mt {
                font-size: .8rem;
            }
        }
    }
}

_:lang(x)::-ms-backdrop, .contents--mypage-top .myt-menu .btn {
    width: 100%;
}
