.form-group--checkbox-center {
    .label--checkbox {
        .chckbox-wrap {
            justify-content: center;
            .checkbox-text {
                width: auto;
            }
        }
    }
    .help-block {
        text-align: center;
    }
}