@charset "UTF-8";

// -----------------------------------------------
// home
// -----------------------------------------------

.contents.contents--media {
    .content {
        @include padding(2.3rem 0 4.1rem, 0);
    }

    a:hover {
        opacity: 0.8;
    }

    .title.title--sub {
        @include mt {
            padding-top: 2rem;
        }
    }

    .media__pickup {
        display: block;
        overflow: hidden;

        .tns-horizontal.tns-subpixel > .tns-item {
            @include sm {
                font-size: 1rem !important;
            }
        }

        .media__pickup-items {

            @include sm {
                margin-right: -28px;
            }

            + .title {
                @include sm {
                    padding-top: 4rem;
                }

                @include padding-top(4rem, 2rem);
            }

            &:not(.media__pickup-items--has-img) {
                @include sm {
                    @include col-5(.5rem);
                }

                @include mt {
                    @include col-2(.75rem);
                }
            }

            &.media__pickup-items--has-img {
                .media__pickup-item-wrap {

                    @include sm {

                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;
                    }

                    @include mt {
                        display: flex;
                    }
                }

                .media__pickup-item {

                    width: 100%;

                    @include sm {
                        float: left;
                        width: 33%;
                        padding-right: 16px;
                        display: flex;
                        flex-direction: column;
                        flex-shrink: 1;
                        flex-basis: auto;
                    }
                    + .media__pickup-item {
                        @include mt {
                            padding-top: 1rem;
                        }
                    }
                }
            }

            &.media__pickup-items--absolute {
                // position: absolute;
            }

            .btn {
                width: 100%;
                font-weight: bold;

                @include sm {
                    width: 100%;
                    padding: .8rem;
                }
            }

            .media__pickup-imgs {
                background: $color_11;
                position: relative;

                figure {
                    position: relative;
                    overflow: hidden;
                    width: 100%;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        max-width: initial; /* サイズ指定をリセット！ */
                        width: auto;
                        height: 120%;
                    }
                }


                @include sm {
                    min-height: 14rem;

                    figure {
                        height: 14rem;
                    }
                }

                @include mt {
                    width: 43%;
                    min-height: 4rem;

                    figure {
                        height: 100%;
                    }

                }
            }

            .media__pickup-category {

                margin: 0 0 10px 0;

                @include mt {
                    margin:0 0 2% 0;
                    border: solid 1px $color_1;
                    padding: 2%;
                }

                .media__pickup-category-img {
                    text-align: center;

                    img {
                        width:20px;
                        height:20px;
                    }
                }

                .media__pickup-category-text {
                    a {
                        font-size: 1rem;
                        color: $color_1;
                        font-weight: bold;
                    }
                }

            }

            .media__pickup-item-info {
                @include sm {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;
                }

                @include mt {
                    width: 70%;
                }

                @include padding(0, 0 0 0 1%);

                background: $base_color;
            }

            .media__pickup-item-properties {
                @include sm {
                    min-height: 4.6rem;
                }

                @include mt {
                    font-size: .8rem;
                }
            }

            .media__pickup-item-titles {
                text-align: left;
                width: 100%;

                border-bottom: solid 1px $color_1;

                @include sm {
                    margin: 10px 0;
                    height: 80px;
                }

                margin:0 0 2% 0;
            }

            .media__pickup-item-title {
                width: 100%;
                padding: 0;

                @include sm {
                    font-size: 1.2rem;
                }

                @include mt {
                    font-size: 1rem;
                }
            }

            .media__pickup-profile-item {

                width: 100%;

                .media__pickup-profile-img {
                    text-align: center;

                    img {
                        width:20px;
                        height:20px;
                        object-fit:cover;
                        border-radius:50%;
                    }
                }

                .media__pickup-profile-text {
                    font-size: .8rem;

                    @include mt {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .media__pickup-date-item {

                width: 100%;

                .media__pickup-date-img {
                    text-align: center;

                    img {
                        width:20px;
                        height:20px;
                    }
                }

                .media__pickup-date-text {

                    font-size: .8rem;

                    @include mt {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

        }

    }

    .media__list {
        background: $color_11;

        .media__list-left {
            @include sm {
                padding-right: 36px;
            }
        }


    }

    // 共通リスト
    .media__list-items {

        @include sm {
            margin-right: -16px;
            display: flex;
            flex-wrap: wrap;
        }

        + .title {
            @include sm {
                padding-top: 4rem;
            }

            @include padding-top(4rem, 2rem);
        }

        &:not(.media__list-items--has-img) {
            @include sm {
                @include col-5(.5rem);
            }

            @include mt {
                @include col-2(.75rem);
            }
        }

        &.media__list-items--has-img {
            .media__list-item-wrap {

                @include sm {

                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;
                }

                @include mt {
                    display: flex;
                }
            }

            .media__list-item {

                width: 100%;
                margin-bottom: 16px;

                @include sm {
                    float: left;
                    width: 50%;
                    padding-right: 16px;
                    display: flex;
                    flex-direction: column;
                    flex-shrink: 1;
                    flex-basis: auto;
                }
                + .media__list-item {
                    @include mt {
                        padding-top: 1rem;
                    }
                }
            }
        }

        &.media__list-items--absolute {
            // position: absolute;
        }

        .btn {
            width: 100%;
            font-weight: bold;

            @include sm {
                width: 100%;
                padding: .8rem;
            }
        }

        .media__list-imgs {
            background: $base_color;
            position: relative;

            figure {
                position: relative;
                overflow: hidden;
                width: 100%;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    max-width: initial; /* サイズ指定をリセット！ */
                    width: auto;
                    height: 120%;
                }
            }


            @include sm {
                min-height: 14rem;

                figure {
                    height: 14rem;
                }
            }

            @include mt {
                width: 43%;
                min-height: 4rem;

                figure {
                    height: 100%;
                }

            }
        }

        .media__list-item-info {

            @include padding(0  16px  16px  16px, 0 0 0 1%);
            background: $base_color;

            @include sm {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
            }

            @include mt {
                width: 70%;

            }

        }

        .media__list-item-properties {
            @include sm {
                min-height: 4.6rem;
            }

            @include mt {
                font-size: .8rem;
                min-height: 100px;
            }
        }

        .media__list-item-titles {
            text-align: left;
            width: 100%;

            @include sm {
                margin: 10px 0;
                height: 80px;
            }

            margin:0 0 2% 0;
        }

        .media__list-item-title {
            width: 100%;
            padding: 0;

            @include sm {
                font-size: 1.2rem;
            }

            @include mt {
                font-size: 1rem;
            }
        }


        .media__list-date-item {

            .media__list-category-img {
                text-align: center;

                img {
                    width:20px;
                    height:20px;
                }
            }

            .media__list-category-text {
                font-size: .8rem;
                color: $color_1;
                font-weight: bold;
            }

            .media__list-date-img {
                text-align: center;

                img {
                    width:20px;
                    height:20px;
                }
            }

            .media__list-date-text {

                font-size: .8rem;

                @include mt {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

    }

    .media__detail {

        background: $color_11;


        .media__detail-left {
            @include sm {
                padding-right: 36px;
            }

            @include mt {
                margin-bottom: 2.5%;
                background: $base_color;
                padding: 2.5%;
            }

            .media__detail-item {
                @include padding(16px, 0);
                background: $base_color;

                .media__detail-item-info {

                    .media__detail-item-title {
                        text-align: left;
                        width: 100%;
                        padding: 16px 0;

                        @include sm {
                            font-size: 1.4rem;
                        }

                        @include mt {
                            font-size: 1rem;
                        }
                    }

                    .media__detail-date-item {


                        .media__detail-category-img {
                            text-align: left;

                            img {
                                width:20px;
                                height:20px;
                            }
                        }

                        .media__detail-category-text {
                            font-size: .8rem;
                            color: $color_1;
                            font-weight: bold;
                        }

                        .media__detail-date-img {
                            text-align: center;

                            img {
                                width:20px;
                                height:20px;
                            }
                        }

                        .media__detail-date-text {
                            text-align: right;
                            font-size: .8rem;

                            @include mt {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }

                }

            }

            .media__detail-entry {
                position: relative;
                padding: 20px 0;
                min-height: 300px;

                p {
                    margin-bottom: 16px;
                    line-height: 170%;
                }

                img {
                    margin-bottom: 16px;
                }

                .media__detail-secret {
                    position: absolute;
                    top:0px;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto 5% auto;
                    text-align: center;
                    background: url(../images/dummy/dumy_media_secret.png) center  top repeat-x;

                    .media__detail-secretbox {
                        border: solid 1px $color_1;
                        padding: 5%;
                        margin:10% 5% 5% 5%;
                        background: $base_color;

                        @include mt {
                            margin:20% 5% 5% 5%;
                        }
                    }

                    img {
                        width: 30px;
                    }

                }
            }

            .media__detail-item-btn {
                background: #000;
                width: 100%;

                a {
                    padding: 15px;
                    width: 100%;
                }
            }

        }

        .media__detail-right {
            @include mt {
                margin-bottom: 2.5%;
                padding: 2.5%;
            }
        }

        .social {
            margin: 10px auto;
            height: auto;

            a {
                padding: 13px 15px 12px 15px;
                font-family: Arial;
                font-size: 8px auto;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                color: white;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                max-width: 33.3333%;

            }

            .facebook {
                background-color: #2e4a88;
                box-shadow: 0 4px 0 #1B3D82;
                text-shadow: 0 -1px -1px #1B3D82;

                &:hover {
                    background-color: #354F84;
                }

                &:active {
                    top: 2px;
                    box-shadow: 0 2px 0 #1B3D82;
                }

            }

            .twitter {
                background-color: #008DDE;
                box-shadow: 0 4px 0 #0078BD;

                &:active {
                    top: 2px;
                    box-shadow: 0 2px 0 #0078BD;
                }

                &:hover {
                    background-color: #1397D8;
                }

            }

            .line {
                background-color: #00C300;
                box-shadow: 0 4px 0 #00B300;


                &:active {
                    top: 2px;
                    box-shadow: 0 2px 0 #00B300;
                }

                &:hover {
                    background-color: #00e300;
                }

            }

        }

        .media__detail-profile {

            border-top: solid 1px $color_1;
            padding: 16px 0;
            margin-top: 16px;

                        .media__detail-profile-item {

                            .media__detail-profile-img {
                                text-align: center;

                                img {
                                    width:50px;
                                    height:50px;
                                    object-fit:cover;
                                    border-radius:50%;
                                }
                            }

                            .media__detail-profile-text {
                                font-size: 0.8rem;

                                @include mt {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
        }

        .media__list {

            @include mt {
                padding: 2.5%;
                margin: 2.5% 0;
            }

            h3{
                text-align: center;
                font-size: 22px;
                font-weight: bold;
                margin:36px 0 16px 0;

                @include mt {
                    margin: 2.5% 0;
                }
            }

            .media__list-item {

                width: 100%;

                @include sm {
                    float: left;
                    width: 33.333%;
                    margin-bottom: 16px;
                    padding-right: 16px;
                    display: flex;
                    flex-direction: column;
                    flex-shrink: 1;
                    flex-basis: auto;
                }
                + .media__list-item {
                    @include mt {
                        padding-top: 1rem;
                    }
                }

                @include mt {
                    margin: 0;
                    padding: 0;
                }

                .media__list-item-title {
                    font-size: 14px;
                }
            }


        }


    }
    .media__ranking {
        padding: 20px 0;
        background: $color_11;

        h3{
            font-weight: bold;
            text-align: center;
            font-size: 1.2rem;
            margin-bottom: 10px;
        }

        .tab {
            background: $base_color;
            margin-bottom: 10px;

            li {
                padding: 10px;
                text-align: center;

                &.active,
                &:hover {
                    background: $color_1;
                    color: $base_color;
                }
            }
        }

        .media__ranking-items {

            &:not(.media__ranking-items--has-img) {
                @include col-2(.75rem);
            }

            &.media__ranking-items--has-img {
                .media__ranking-item-wrap {
                    display: flex;
                }

                .media__ranking-item {

                    width: 100%;

                    + .media__ranking-item {
                        padding-top: 5px;
                    }
                }
            }

            .media__ranking-no {
                position: relative;
                width: 10%;
                min-height: 4rem;
                background: $base_color;

                span {
                    position: absolute;
                    top: 45%;
                    text-align: center;
                    width: 100%;

                    @include mt {
                        top: 37%;
                    }
                }
            }

            .media__ranking-imgs {
                position: relative;
                width: 33%;
                min-height: 4rem;

                @include mt {
                    width: 24%;
                }


                figure {
                    position: relative;
                    overflow: hidden;
                    height: 100%;
                    min-height: 4rem;
                    background: $color_1;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        max-width: initial; /* サイズ指定をリセット！ */
                        width: auto;
                        height: 120%;
                    }
                }
            }

            .media__ranking-item-info {
                @include padding(2%,2.5%);
                background: $base_color;
                width: 57%;

                @include mt {
                    width: 70%;

                }
            }

            .media__ranking-item-properties {
                min-height: 80px;

                @include mt {
                    font-size: .8rem;
                }
            }

            .media__ranking-item-title {
                text-align: left;
                width: 100%;
                font-size: 13px;
                margin:0 0 2% 0;
            }

        }

    }

    .media__push {
        padding: 20px 0;
        background: $color_11;

        h3{
            font-weight: bold;
            text-align: center;
            font-size: 1.2rem;
            margin-bottom: 10px;
        }

        .tab {
            background: $base_color;
            margin-bottom: 10px;

            li {
                padding: 10px;
                text-align: center;

                &.active,
                &:hover {
                    background: $color_1;
                    color: $base_color;
                }
            }
        }

        .media__push-items {

            &:not(.media__push-items--has-img) {
                @include col-2(.75rem);
            }

            &.media__push-items--has-img {
                .media__push-item-wrap {
                    display: flex;
                }

                .media__push-item {
                    width: 100%;

                    + .media__push-item {
                        padding-top: 5px;
                    }
                }
            }

            .media__push-imgs {
                position: relative;
                width: 33%;
                min-height: 4rem;

                @include mt {
                    width: 24%;
                }

                figure {
                    position: relative;
                    overflow: hidden;
                    height: 100%;
                    min-height: 4rem;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        max-width: initial; /* サイズ指定をリセット！ */
                        width: auto;
                        height: 120%;
                    }
                }
            }

            .media__push-item-info {
                @include padding(2%,2.5%);
                background: $base_color;
                width: 67%;

                @include mt {
                    width: 76%;

                }
            }

            .media__push-item-properties {
                min-height: 80px;

                @include mt {
                    font-size: .8rem;
                }
            }

            .media__push-item-title {
                text-align: left;
                width: 100%;
                font-size: 13px;
                margin:0 0 2% 0;
            }

        }

    }
}
