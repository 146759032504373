//-------------------------*/
// +VARIABLES              */
//-------------------------*/

// GRID

// Default margin between elements
$fx-grid-gutter: 16;

// Default breakpoints for s, m and l columns
$fx-grid-breakpoints: (
    s: 769,
    m: 960,
    l: 1200
);

// DON'T MODIFY BELOW!
//-------------------------
$fx-column-sizes: 1, 2, 3, 4, 5, 8, 10, 12, 15, 16, 20, 25, 30, 33, 35, 37, 40,
    45, 50, 55, 60, 65, 66, 70, 75, 80, 85, 87, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100;
$fx-just-position: (
    start: flex-start,
    center: center,
    end: flex-end,
    baseline: baseline,
    between: space-between,
    around: space-around,
    evenly: space-evenly,
    right: right,
    left: left,
    stretch: stretch
);
$fx-self-position: (
    start: flex-start,
    center: center,
    end: flex-end,
    baseline: baseline,
    stretch: stretch
);
$fx-items-position: (
    start: flex-start,
    center: center,
    end: flex-end,
    baseline: baseline,
    stretch: stretch
);
