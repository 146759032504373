@charset "UTF-8";

// -----------------------------------------------
// detail
// -----------------------------------------------
.contents.contents--detail {
    .property__main {
        padding: 0;

        .property__main-titles {
            width: 100%;

            @include sm {
                margin-bottom: 1.5rem;
            }

            .tag {
                @include padding(.75rem 1.5rem, .5rem .75rem);

                @include sm {
                    font-size: 1rem;
                }
            }

            .property__main-title {
                @include sm {
                    padding-bottom: 0;
                }

                .tag {
                    @include sm {
                        display: none;
                    }
                }
            }
        }


        .property__main-title {
            font-weight: bold;

            @include sm {
                line-height: 1.2;
                padding-bottom: 1.5rem;
                font-size: 1.6rem;
            }

            @include mt {
                order: 2;
                padding: 1rem 1.5rem;
                font-size: 1.4rem;
                background: $base_color;

                img {
                    width: 75%;
                }
            }

            br {
                @include mt {
                    display: none;
                }
            }
        }

        .property__main-prices {
            background: $color_9;

            @include padding(1rem, 1rem);

            .property__main-types {
                .type {
                    background: $base_color;
                    width: 4rem;
                    height: 4rem;
                }
            }

            .title-box .title-box__text {
                background: rgba(255, 255, 255, .74);
            }

            .property__main-menus__item + .property__main-menus__item {
                @include sm {
                    padding-top: 0;
                }
            }

            + .property-infos {
                @include padding(1rem 0 0, 1rem);
            }
        }

        .property__main-menus__item + .property__main-menus__item {
            padding-top: 1rem;
        }

        .property__main-menus {
            @include sm {
                padding-left: 2rem;
            }

            @include mt {
                order: 3;
            }

            .property__main-menus__item-text {
                padding-left: 1rem;

                &.property__main-menus__item-text--yield {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $color_1;
                }
            }

            .property__main-btns {
                @include sm {
                    @include col-2(.5rem);

                    padding-bottom: 1.5rem;
                }

                @include mt {
                    padding: .5rem;
                    margin: 0;
                    background: $base_color;
                }
            }

            .property__main-btns-wrap {
                @include sm {
                    @include col-2(.5rem);
                }

                @include mt {
                    @include col-2(.25rem);
                }
            }
        }

        .property__main-name {
            font-size: 1.6rem;
            font-weight: bold;

            @include mt {
                display: none;
            }

            & + .property-infos {
                @include padding(1rem 0, 1rem 1.5rem);
            }
        }

        .property__main-needs {
            position: relative;

            .property__main-prices {
                @include sm {
                    padding: 3rem 1rem 4rem;
                }

                @include mt {
                    padding: 3rem 1.5rem;
                }
            }

            .property__main-image__bar {
                padding-bottom: 1rem;
            }

            .property__main-image__text {
                padding-left: 1rem;
                font-size: 1.4rem;
            }

            .property__main-menus__item-text {
                padding-left: 1rem;

                @include sm {
                    width: calc(100% - 7.2rem);
                }

                @include mt {
                    flex-basis: 50%;
                    max-width: 50%;
                }
            }

            .property__main-menus__item-yield {
                font-size: 1.4rem;
                font-weight: bold;
                color: $color_2;
            }

            .property__main-menus__item-more {
                padding-left: 1rem;
            }

            .property__main-image__btn {
                position: absolute;

                @include sm {
                    bottom: 1rem;
                    right: 1rem;
                }

                @include mt {
                    right: 1rem;
                    bottom: 1rem;
                }
            }
        }
    }

    .property__tables {
        @include mt {
            padding-top: 0;
        }

        @include sm {
            padding-top: 4rem;
        }

        .title.title--sub {
            @include mt {
                padding-top: 0;
                text-align: left;
            }
        }

        .property__tables-contents {
            @include sm {
                @include col-2(1rem);
            }

            .property__tables-content + .property__tables-content {
                @include mt {
                    padding-top: 2rem;
                }
            }
        }

        .property__tables-remarks {
            line-height: 2;
            padding-top: 1rem;
        }
    }

    .property__file {
        @include padding(1rem 0, 1.5rem 1.5rem);

        background: $color_1;
        color: $base_color;

        .property__file-texts {
            text-align: center;

            @include sm {
                font-size: 1.4rem;
            }

            .row > * {
                @include mt {
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }

        .property__file-img {
            img {
                max-width: 35px;

                @include mt {
                  display: none;
                }
            }
        }

        .property__file-text {
            @include sm {
                padding-left: 1.5rem;
            }

            @include mt {
                padding-top: 1rem;
            }
        }

        .property__file-btns {
            @include sm {
                @include col-2(1rem);
            }
        }

        .property__file-btn {

            @include sm {
                padding: 0 0 0 1rem;
            }

            @include mt {
                padding-top: 1.5rem;
            }
        }

        .property__file-size {
            @include mt {
                padding: 1rem 0 1rem;
                text-align: center;
            }

            &.property__file-size-guest {
                font-size: 1rem;
            }
        }

        .property__file-guest-text {
            padding: 1.5rem 0;
            font-size: 1rem;
        }
    }

    .property__inquiry {
        @include padding(1rem 0, 1rem);

        background: $color_9;

        .property__inquiry-title {
            color: $color_1;
            font-weight: bold;

            .property__inquiry-title_no {
              background: $color_1;
              border-radius: 5px;
              padding: 2px 10px;
              color: $base_color;
              font-size: .8rem;
              margin-left: 1rem;
            }

        }

        .property__inquiry-wrap {
            @include sm {
                @include col-2(.5rem);
            }
        }

        .property__inquiry-name {
            line-height: 1.3;
            padding: 0 0 .5rem 0;
            font-size: 1.4rem;
            font-weight: bold;
        }

        .property__inquiry-location-title {
            padding: .5rem 0;
            background-color:darken($color_1_light, 10%);
            text-align: center;

            @include sm {
                width: 7.2rem;
            }

            @include mt {
                flex-basis: 20%;
                max-width: 20%;
            }
        }

        .property__inquiry-location-texts {
            @include sm {
                flex-basis: calc(100% - 7.2rem);
                max-width: calc(100% - 7.2rem);
            }

            @include mt {
                flex-basis: 80%;
                max-width: 80%;
            }
        }

        .property__inquiry-location-text {
            padding-left: 1rem;
        }

        .property__inquiry-location-btn {
            padding-left: 1rem;
            text-align: right;
        }

        .property__inquiry-btn {
            @include padding-top(1.5rem, 1.5rem);

            @include sm {
                max-width: 314px;
                margin-left: auto;
            }
        }
    }

    .property__status {
        background: $font_color;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        background: rgba(60, 60, 71, .87);
        animation: fade-in-bottom 2s;

        .property__status-items {
        }

        .property__status-item + .property__status-item {
            @include padding-left(.5rem, .75rem);
        }

        .property__status-img {
            line-height: 0;

            img {
                max-width: 30px;
            }
        }

        .property__status-text {
            @include padding(0 2rem 0 1rem,0 1rem 0 0);

            font-weight: bold;
            color: $base_color;
        }
    }

    &.contents--property {
        .property__main {

            .property__main-types {
                @include mt {
                    width: 100%;
                    text-align: center;
                    padding-top: 1rem;
                }
            }

            .price-highlight {
                @include sm {
                    padding-bottom: 0;
                }
            }

            .property-infos {
                @include sm {
                    padding-top: 1rem;
                }
            }

            .property__main-price {
                @include sm {
                    padding-left: 1rem;
                }

                @include mt {
                    width: 100%;
                    text-align: center;
                    padding-top: 1rem;
                }
            }

            .type {
                border-color: $color_1;
                color: $color_1;
            }
        }
    }

    &.contents--needs {
        .property__main {
            .property__main-types {
                text-align: center;

                @include mt {
                    width: 100%;
                    padding-top: 1rem;
                }
            }

            .type {
                border-color: $color_2;
                color: $color_2;

                & + .type {
                    margin-left: .5rem;
                }
            }

            .price-highlight {
                @include padding-top(1.5rem, 1.5rem);
            }
        }

        .title-box .title-box__text {
            background: $color_2_light;
        }

        .property__main-prices,
        .property__info,
        .property__inquiry {
            background: $color_2_light;
        }

        .property__inquiry-location-title {
            background-color:darken($color_2_light, 10%);
        }

        .property-infos .property-info__text.property-info__text--yield,
        .property__inquiry-title {
            color: $color_2;

            .property__inquiry-title_no {
              background: $color_2;
            }
        }
    }

    // property
    .property-images {
        @include mt {
            order: 1;
        }

        .property-image__wrap {
            position: relative;
            height: 20rem;
            width: 100%;
            background: $color_11;
        }

        .property-image  {
              position: relative;
              overflow: hidden;
              width: 100%;

              @include mt {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  padding-right: 0rem;
                  padding-left: 0rem;
              }

                  img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    max-width: initial; /* サイズ指定をリセット！ */
                    width: auto;
                    height: 100%;

                    @include mt {
                        height: 20rem;
                    }

                  }

                  @include sm {
                      height: 20rem;
                  }

              }

          }
}
