@charset "UTF-8";

// -----------------------------------------------
// pre
// -----------------------------------------------

.mb-on {
  @include sm {
    display: none;
  }
}

.mb-off {
  @include mt {
    display: none;
  }
}

.wrapper {
  padding: 0;
}


.pre__header {
  @include padding(1rem,.5rem);

  .header__logo {
    text-align: center;

    img {
      max-width: 160px;

    }
  }
}


.contents.contents--pre {
  .content {
    @include padding(2.3rem 0 4.1rem, 1.5rem 1.5rem 3.5rem);
  }

  .title.title--sub {
    @include mt {
      padding-top: 2rem;
    }
  }

  .title {
    font-weight: bold;
    text-align: center;

    img {
      width: auto;
    }

    @include mt {
      margin:0;
      padding:1rem 0;
    }


  }

  .pre__visual {
    position: relative;
    background:#25a9e7 url(../images/pre/main_bg.png) bottom center no-repeat;
    background-size: cover;
    height: 600px;
    width: 100%;

    @include padding(8.7rem 0 5.2rem, 3.7rem 0 0.5rem);

    @include tab {
      height: 450px;
      padding-top: 2rem;
    }

    @include mb {
      height: 300px;
    }





    .pre__logo {
      position: absolute;
      top:60px;
      z-index: 1;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      img {
        max-width: 750px;
      }

      @include mt {
        top:1.4rem;

        img {
          max-width: 80%;
        }
      }

    }

    .pre__fukidasi {
      z-index: 2;
      position: absolute;
      bottom:-200px;
      width: 700px;
      margin: 0 auto;
      position: relative;

      @include tab {
        bottom:-180px;
        width: 70%;
      }

      @include mb {
        bottom:-80px;
        width: 80%;
      }

      img {
        width: 130px;

        @include tab {
          width: 100px;
        }

        @include mb {
          width: 80px;
        }
      }

      .fukidasi1 {
        position: absolute;
        top: -3rem;
        left: -3rem;

        @include mt {
          left: -1rem;
        }
      }

      .fukidasi2 {
        position: absolute;
        top: 0rem;
        right: -2rem;
      }

      .fukidasi3 {
        position: absolute;
        top: 17rem;
        right: -3rem;

        @include mt {
          top: 7rem;
          right: -1rem;
        }
      }
    }

    .pre__main {
      position: absolute;
      bottom:-150px;
      z-index: 1;
      margin: 0 auto;
      position: relative;
      width: 800px;

      @include tab {
        bottom:-150px;
        width: 80%;
      }

      @include mb {
        bottom:-80px;
        width: 90%;
      }

      img {
        max-width: 80%;
      }


    }
  }

  .pre__banner {
    @include padding(8rem 0 4.1rem, 3rem 1.5rem 1.5rem 1.5rem);
    margin: 0 auto;
    text-align: center;
    width: 700px;

    @include mt {
      width: 100%;
    }

    a {
      display: block;
      margin-top: 3rem;

      img {
        width: 400px;

        @include mt {
          width: 100%;
        }

        &:hover {
          animation: hoverShake 0.15s linear 3;
          opacity: 0.8;
        }

      }
    }
  }

  .pre__about {
    @include padding(4.1rem 0 4.1rem, 1.5rem);

    p {
      text-align: center;
      line-height: 2rem;

        @include mt {
          margin: 2rem 0;
          line-height: 1.7rem;

        }
    }

  }
  .pre__trouble {
    @include padding(4.1rem 0 4.1rem, 1.5rem 0);
    background:#d5e6f2;
    width: 100%;
    position: relative;

    @include mt {

      .title {
        width: 80%;
        margin: auto;
      }
    }

    &:before {
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform:translateX(-50%);
      content: '';
      height: 0;
      width: 0;
      z-index: 1;
      border-style: solid;
      border-width: 59px 350px 0 350px;
      border-color: #d5e6f2 transparent transparent transparent;

      @include mt {
        bottom: -30px;
        border-width: 30px 80px 0 80px;
      }
    }

    .container {
      .pre__comment {
        position: relative;
        text-align: center;

        img {
          width: 650px;
          margin: 20px;

          @include mt {
            width: 90%;
            margin: 10px 0;
          }
        }

        .human1 {
          position: absolute;
          top: 0rem;
          left: 0rem;
          width: 120px;
        }

        .human2 {
          position: absolute;
          top: 8rem;
          right: 0rem;
          width: 120px;
        }
      }
    }
  }

  .pre__point {
    background:#25a9e7 url(../images/pre/main_bg.png) bottom center no-repeat;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;

    @include padding(0 0 5.2rem,1.5rem);

    &:before {
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform:translateX(-50%);
      content: '';
      height: 0;
      width: 0;
      border-style: solid;
      border-width: 59px 350px 0 350px;
      border-color: #228dc7 transparent transparent transparent;

      @include mt {
        bottom: -30px;
        border-width: 30px 80px 0 80px;
      }

    }

    .row {
      margin-bottom: 60px;

      @include mt {
        margin-bottom: 2rem;
      }
    }

    .main {
      z-index: 1;
      position: relative;

      @include mt {
        margin-bottom: 1rem;
      }

      .fukidasi1 {
        z-index: 4;
        position: absolute;
        top:-40px;
        left: 280px;
        width: 120px;
        animation: purupuru 3s ease-in-out 1s infinite normal;

        @include mt {
          left: inherit;
          right: 20px;
        }
      }

    }

    .items-center-xs {

      @include col-3(1rem);

      @include mt {
        .col-100-xs {
          padding: 1rem;
        }

      }
    }

  }

  @keyframes hoverShake {
    0% {transform: skew(0deg,0deg);}
    25% {transform: skew(1deg, 1deg);}
    75% {transform: skew(-1deg, -1deg);}
    100% {transform: skew(0deg,0deg);}
  }

  @keyframes purupuru {
    0% {transform: skew(0deg,0deg);}
    5% {transform: skew(1deg, 1deg);}
    10% {transform: skew(-1deg, -1deg);}
    15% {transform: skew(0deg,0deg);}
    100% {transform: skew(0deg,0deg);}
  }
}


.pre__nav {
  @include mt {
    ul {
      padding-top: 20px;
    }
    li {
      border-top: solid 1px $color_6;
      width: 100%;
      padding: 5px 0;
      text-align: center;
    }
  }
}

.footer__pagetop {
  z-index: 5;
}
