@charset "UTF-8";

// -----------------------------------------------
// Button
// -----------------------------------------------
.btn {
    @include shadow();

    display: inline-block;
    background: $base_color;
    border: solid 1px $base_color;
    text-align: center;
    min-height: 2.6rem;

    &:hover {
        background-color:darken($base_color, 10%);
    }

    @include mt {
        padding: .5rem;
        font-size: 1rem;
        min-width: 80px;
    }

    &.btn--basic {
        @include sm {
            width: 10rem;
            padding: .5rem;
        }
    }

    &.btn--block {
        display: block;
        width: 100%;
    }

    &.btn--mb-horizontal {
        @include mt {
            display: block;
            width: 100%;
            padding: .5rem 0;
        }
    }

    &.btn--primary {
        background: $color_1;
        border: solid 1px $color_1_dark;
        color: $base_color;

        &:hover {
            background-color:darken($color_1, 10%);
        }
    }

    &.btn--hazime {
        background: $color_23;
        border: solid 1px $color_23_dark;
        color: $base_color;

        &:hover {
            background-color:darken($color_23, 10%);
        }

        span {
            border: solid 1px $base_color;
            padding: .3rem .6rem;
            margin-left: .7rem;
        }
    }


    &.btn--needs {
        background: $color_2;
        border: solid 1px $color_2_dark;
        color: $base_color;

        &:hover {
            background-color:darken($color_2, 10%);
        }
    }

    &.btn--negative {
        background: $color_1;
        border: solid 1px $color_1_dark;
        color: $base_color;

        &:hover {
            background-color:darken($color_1, 10%);
        }
    }

    &.btn--gray {
        background: $color_11;
        border: solid 1px $color_11;
        box-shadow: none;

        &:hover {
            background-color:darken($color_11, 10%);
        }
    }

    &.btn--dark {
        background: $font_color;
        border: solid 1px $color_21;
        color: $base_color;

        &:hover {
            background-color:darken($font_color, 10%);
        }
    }

    &.btn--primary-color {
        color: $color_1;

        &:hover {
            color: $base_color;
            background-color:$color_1_dark;
        }
    }

    &.btn--needs-color {
        color: $color_2;

        &:hover {
            color: $base_color;
            background-color:$color_2_dark;
        }
    }

    &.btn--primary-negative {
        color: $color_3;
    }

    &.btn--primary-border {
        border: solid 1px $color_1;
    }

    &.btn--needs-border {
        border: solid 1px $color_2;
    }

    &.btn--basic-border {
        border: solid 1px $color_8;
    }

    &.btn--negative-border {
        border: solid 1px $color_3;
    }

    &.btn--light-border {
        border-top: solid 1px $color_8;
        border-left: solid 1px $color_8;
    }

    &.btn--disabled {
        background: $color_11;
        //border: solid 1px $color_19;
        box-shadow: none;
        color: $color_19;

        &:hover {
            //background-color:darken($color_11, 10%);
        }
    }

    &.btn--has-icon-horizontal {
        width: 100%;
        line-height: 0px;

        .btn__icon {

            img {
                max-width: 30px;

                + img {
                    display: none;
                }
            }
        }

        .btn__text {
            padding-left: .5rem;
            font-weight: bold;
        }
    }

    &.btn--has-icon-vertical {
        .btn__wrap {
            @include sm {
                text-align: center;
            }

            @include mt {
                font-size: 1.1rem;
            }
        }

        .btn__icon {
            line-height: 0;

            img {
                max-width: 66px;
            }
        }

        .btn__address {
            @include sm {
                padding: 1.1rem 0 0;
            }
        }

        .btn__detail {
            line-height: 1.3;

            @include sm {
                padding-top: .8rem;
            }
        }
    }

    &.btn--has-icon-menu {
        @include padding(1.2rem .8rem, .5rem);

        @include sm {
            width: auto;
            min-height: 10rem;
        }

        @include md {
            min-height: inherit;
        }

        @include mt {
            width: 100%;
            height: 100%;
        }

        .btn__icon {
            @include sm {
                min-height: 3rem;
                margin-top: 1rem;
            }

            @include mt {
                margin: 1rem 0;
            }

            img {
                max-width: 44px;
            }
        }

        .btn__detail {
            font-weight: normal;
            font-size: .8rem;
        }
    }

    &.btn--icon-only {
        line-height: 0;
        padding: 0;
        background: none;
        box-shadow: none;

        img {
            display: inline-block;

            + img {
                display: none;
            }
        }
    }

    &.btn--checkbox {
        @include mt {
            width: 100%;
        }

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn--map {
        width: auto;
        color: $color_3;
        min-height: 1rem;

        @include sm {
            padding: .5rem;
            font-size: .8rem;
        }

        @include mt {
            padding: .5rem;
            font-size: .6rem;
            min-width: auto;
        }
    }

    &.btn--has-num {
        .btn__text {
            @include sm {
                padding-left: 2rem;
            }
        }

        .btn__num {
            margin-left: auto;
        }
    }

    &.btn--more {
        @include sm {
            width: 37%;
            padding: .7rem;
        }
    }

    &.btn--activate {
        &.circle.circle--has-icon,
        &.btn--has-icon-horizontal .btn__icon,
        &.btn--icon-only {
            img {
                display: none;

                + img {
                    display: inline-block;
                }
            }
        }

        &.btn--favo {
            color: $color_3;
        }
    }

    &.btn--use-area {
        line-height: 1.2;
        color: $color_1;
        width: 100px;
        height: 100px;
        background: #000;
        display: block;

        @include sm {
            height: 6rem;
        }

        @include mt {
            height: 7rem;
        }

        .btn--use-area__title {
            @include margin-bottom(0,0);
            @include padding(0 .5rem, 0 .75rem);
            display: block;
            line-height: 1.5;
            background: $color_1_light;
            border-radius: 4px;
            color: $color_1;


            &:hover {
                background-color:darken($color_1_light, 10%);
            }
        }

        &.btn--use-area--no-title {
            display: flex;
            justify-content: center;
            align-items: center;

            @include mt {
                justify-content: center;
            }
        }

        &.btn--use-area--2nd .btn--use-area__title {
            color: $color_2;
            background: $color_2_light;
        }
    }
}

.btn-wrap {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
