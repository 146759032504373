@charset "UTF-8";

// -----------------------------------------------
// all
// -----------------------------------------------
html {
    height: 100%;
    min-height: 100%;

    @include sm {
        @include fluid-type(font-size, 769px, 960px, 8px, 14px);
    }

    @include mt {
        @include fluid-type(font-size, 320px, 768px, 12px, 26px);
    }
}

body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    line-height: 1.5;
    background: $color_11;
    color: $font_color;
    @include fontfamily-meiryo();

    // @include sm {
    //     background: url(../images/design/login/pc_registration_step3.png) center
    //         top / cover no-repeat #fff;
    // }

    // @include md {
    //     background: url(../images/design/login/pc_registration_step3.png) center
    //         top / auto no-repeat #fff;
    // }

    // @include mt {
    //     background: url(../images/design/login/mb_registration_step3-bank.png)
    //         center top / contain no-repeat #fff;
    // }
}

main {
    background: $base_color;
  }

a {
    color: $link_color;
    text-decoration: none;
}

a,
button,
.btn {
  transition: all .3s;
  position: relative;
  top: 0;

    &:hover {
        cursor: pointer;
    }

    &:active {
        top: 1px;
    }
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0;
    vertical-align: text-bottom;
}

figure,
picture {
    line-height: 0;
}

picture {
    display: block;
}

figcaption {
    list-style: 1.5;
}

// -----------------------------------------------
// generic
// -----------------------------------------------
// block
.dsn {
    display: none !important;
}

// clearfix
.clear {
    clear: both;
}

.cf {
    display: block;

    &::after {
        clear: both;
        visibility: hidden;
        display: block;
        height: 0;
        content: '';
    }
}

// display
.mb-on {
    @include sm {
        display: none;
    }
}

.mb-off {
    @include mt {
        display: none;
    }
}

// text-aline
.tac {
    text-align: center;
}

.tar {
    text-align: right;
}

.tal {
    text-align: left;
}

// font
.shingo {
    @include fontfamily-shingo();
}

// col
.col-auto-xs {
    flex-basis: auto;
    max-width: inherit;
    flex-grow: inherit;
}

.col-auto-s {
    @include sm {
        flex-basis: auto;
        max-width: inherit;
        flex-grow: inherit;
    }
}

.col-auto-m {
    @include md {
        flex-basis: auto;
        max-width: inherit;
        flex-grow: inherit;
    }
}

.col-auto-l {
    @include lg {
        flex-basis: auto;
        max-width: inherit;
        flex-grow: inherit;
    }
}

[v-cloak] {
    display: none;
}

.scroll-prevent {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
}

// -----------------------------------------------
// common
// -----------------------------------------------
.header {
    background: $base_color;

    .container {
        @include sm {
            max-width: 100%;
        }

        @include lg {
            max-width: 1200px;
        }
    }
}

// header
.header {
    @include mt {
        z-index: 5;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }

    .header__wrap {
        @include mt {
            height: 14vw;
        }
    }


    .header__logos {

      width: 50%;

        @include mt {
            order: 2;
            width: 100%;
            position: absolute;
        }

        .header__logo {
            @include sm {
                padding: 1rem 0 .7rem 1rem;
            }

            @include mt {
                text-align: center;
            }

            img {
                @include sm {
                }

                @include mt {
                    width: 9rem;
                }
            }
        }
    }

    .header__logo-links {
        @include mt {
            display: none;
        }

        .header__logo-link {

          padding-left: 1rem;

            @include sm {
                padding-top: .4rem;
            }

            a {
                @include sm {
                    display: block;
                    line-height: 0;
                    font-weight: bold;
                    color: inherit;
                }
            }

            img {
                @include sm {
                    max-width: 27px;
                    width: 1.7rem;
                }
            }

            &:nth-child(1) {
                color: $color_1;

                @include sm {
                    padding-left: 1.7rem;
                }

                @include md {
                    padding-left: 1rem;
                }
            }

            &:nth-child(2) {
                color: $color_2;
            }
        }

        .header__logo-link-text {
            @include sm {
                display: inline-block;
                padding-left: .4rem;
            }
        }
    }

    .header__info {
        @include sm {
            margin-right: 3rem;
            padding: 0 1rem;
            font-size: .8rem;
        }

        @include tp {
            margin-left: auto;
            padding: 0;
            font-size: .8rem;
        }

        @include mt {
            display: none;
        }

        .header__info-item {
              padding-left: 1rem;
        }
    }

    .header__logins {
        @include mt {
            order: 3;
            transform: scale(0.65);
            transform-origin: right;
            position: fixed;
            right: 0;
        }

        .btn {
          &.btn--hazime {
              padding: .7rem;
          }
        }


        .header__login-items .header__login-item {
            @include mt {
                line-height: 0;
            }

            .btn {
                @include mt {
                    position: relative;
                    top: .1rem;
                    left: -.2rem;
                    padding: 0;
                    display: inline-block;
                    line-height: 0;
                    background: none;
                    border: none;
                    box-shadow: none;
                    text-align: center;
                    font-size: .8rem;
                }

                img {
                    @include mt {
                        max-width: 143px;
                        width: 8rem;
                    }
                }
            }
        }
    }

    .header__users {

        @include mt {
            padding-right: 1rem;
          }

        .header__circles .circle.circle--has-num {
            @include mt {
                transform: scale(1.2);
            }
        }

        .header__user-names {
            @include mt {
                display: none;
            }

            .header__user-btn {
                position: relative;
                display: block;
                width: 100%;
            }

            .header__user-name {
                line-height: 1.3;
                text-align: left;
                font-size: .8rem;
                font-weight: bold;
                color: $color_1;

                @include sm {
                    padding: 0 1.8rem 0 .9rem;
                }
            }

            img {
                position: absolute;
                top: 50%;
                right: .6rem;
                display: inline-block;
                max-width: 12px;
                transform: translateY(-50%);
            }
        }
    }

    .header__login-items {
        .header__login-item {
            @include mt {
                line-height: 0;

                .btn__text {
                    line-height: 31px;
                }
            }

            .btn {
                @include sm {
                    width: 100%;
                    max-width: 80px;
                    padding: .8rem;
                    text-align: center;
                    font-size: .8rem;
                }
            }

            & + .header__login-item {
                @include sm {
                    padding-left: .4rem;
                }
            }
        }
    }

    .header__btns {
        @include sm {
            display: none;
        }

        @include mt {
            order: 1;
            line-height: 0;
            transform: scale(0.65);
        }

        .header__btn,
        .header__btn span {
            display: inline-block;
            transition: all .4s;
            box-sizing: border-box;
        }

        .header__btn {
            position: relative;
            width: 2.4rem;
            height: 2rem;

            .header__btn-item {
                position: absolute;
                left: 0;
                max-width: 100%;
                width: 3rem;
                height: 4px;
                background-color: $color_5;

                &:nth-of-type(1) {
                    top: 0;
                }

                &:nth-of-type(2) {
                    top: .9rem;
                }

                &:nth-of-type(3) {
                    bottom: 0;
                }
            }
        }
    }

    .header__menu {
        @include sm {
            display: none;
        }

        @include mt {
            z-index: 1;
            position: absolute;
            top: 14vw;
            left: -100%;
            width: 100vw;
            height: calc(100vh - 16.4vw);
            padding: 0 1.5rem;
            background: rgba(60, 60, 71, .9);
            opacity: 0;
            transition: all .3s;
        }

        .header__login-items {
            @include mt {
                @include col-2(.75rem);

                padding: 1.5rem 0 0;
            }
        }

        .header__menu-items {
            @include mt {
                padding-top: 1.5rem;
            }

            .header__menu-item {
                & + .header__menu-item {
                    @include mt {
                        padding-top: .75rem;
                    }
                }

                .btn.btn--icon-only {
                    @include mt {
                        padding: .5rem;
                        color: $base_color;
                    }
                }
            }
        }
    }

    &.header--menu-open {
        .header__menu {
            @include mt {
                left: 0;
                opacity: 1;
                bottom: 0;
                overflow-y: auto;
                padding-bottom: 30.4vw;
                .header__menu-items {
                    height: max-content;
                }
            }
        }

        .header__btns .header__btn .header__btn-item:nth-of-type(1) {
            transform: translateY(.8rem) rotate(-315deg);
        }

        .header__btns .header__btn .header__btn-item:nth-of-type(2) {
            opacity: 0;
        }

        .header__btns .header__btn .header__btn-item:nth-of-type(3) {
            transform: translateY(-.9rem) rotate(315deg);
        }
    }

    .header__mypage-menu {
        background: $border_color;
        padding: 2px 0;

        .container {
            font-size: .8rem;
            text-align: right;
            color: $base_color;

            @include mt {
              text-align: center;
            }

        }

    }
}

// footer
.footer {
  background: $color_11;
  font-size: .9rem;

    .footer__wrap {
      padding: 1rem 0;

        @include mt {
            text-align: center;
        }
    }

    .footer__logo {
        @include sm {
            text-align: center;
        }

        @include mt {
            display: none;
        }

        img {
            max-width: 100px;
        }
    }

    .footer__copy {
        @include sm {
            text-align: center;
        }

        @include mt {
            color: $color_19;
            margin: 0 auto;
        }
    }

    .footer__nav {
        @include sm {
            margin-left: auto;
        }

        @include mt {
            display: none;
        }

        .footer__nav-items {

            @include sm {

            }

            .footer__nav-item {
                padding-left: 1rem;
            }
        }
    }

    .footer__pagetop {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        line-height: 0;

        @include sm {
            bottom: -.5rem;
        }

        .container {
            position: relative;
            max-width: 100%;
        }

        a {
            position: absolute;
            display: inline-block;
            opacity: 0;

            @include sm {
                top: -7rem;
                right: 15px;
            }

            @include mt {
                bottom: 15px;
                right: 1rem;
                top: initial;
            }
        }

        img {
            max-width: 53px;
        }
    }
}
