@charset "UTF-8";

.contents--entries {
    .content {
        .container {
            @include sm {
                max-width: 800px;
            }
        }
    }

    .entry__progress {
        @include sm {
            padding-bottom: 4rem;
        }

        @include mt {
            padding-bottom: 1.5rem;
        }

        .entry__progress-items {
        }

        .entry__progress-item {
            position: relative;
            text-align: center;
            background: $color_1_light;

            &:not(:first-child) {
                .entry__progress-item-wrap {
                    @include mt {
                        padding-left: 1.5rem;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1.5rem;
                    height: 100%;
                    background: url(../images/login_progress_arrow.png) center
                        center / 100% 100% no-repeat;

                    @include tab {
                        left: -1px;
                    }
                }
            }

            &.entry__progress-item--now {
                background-color: $color_20;
                color: $base_color;

                & + .entry__progress-item:after {
                    background-image: url(../images/login_progress_arrow_now.png);
                }
            }

            &.entry__progress-item--fin {
                background-color: $color_1;
                color: $base_color;

                & + .entry__progress-item:after {
                    background: url(../images/login_progress_arrow_fin.png)
                        center center / 105% 105% no-repeat;
                }
            }
        }

        .entry__progress-item-wrap {
            display: block;
            font-weight: bold;

            @include padding(.8rem .8rem .8rem 2rem, 1rem 0);

            @include mt {
                font-size: .8rem;
            }
        }
    }
}

// -----------------------------------------------
// login
// -----------------------------------------------
.contents--login {
    .login__reminder {
        padding: 1rem 0;
        text-align: right;

        @include sm {
            font-size: .8rem;
        }

        @include mt {
            padding: 1rem 0;
        }

        a {
            color: $color_1;
            text-decoration: underline;
        }
    }

    .login__sign-up {
        text-align: center;

        @include sm {
            padding: 1rem 0;
        }

        @include mt {
            padding-top: 1.5rem;
        }

        a {
            text-decoration: underline;
            font-weight: bold;
            color: $color_3;
        }
    }

    .submit-area {
        @include sm {
            padding-top: 2rem;
        }

        @include mt {
            padding-top: 2rem;
        }
    }
}

// -----------------------------------------------
// entry
// -----------------------------------------------
.contents--entry {
    .entry-pop-btn {
        text-decoration: underline;
        font-weight: bold;
        color: $color_1;
    }

    .entry__sign-up {
        text-align: center;

        @include sm {
            padding: 1rem 0;
        }

        @include mt {
            padding-top: 1.5rem;
        }

        a {
            text-decoration: underline;
            font-weight: bold;
            color: $color_1;
        }
    }

    .submit-area {
        @include sm {
            padding-top: 6rem;
        }

        @include mt {
            padding-top: 4rem;
        }
    }

    .complete__img {
        img {
            max-width: 129px;
        }
    }

    .checkbox {
        width: auto;
    }
}

// -----------------------------------------------
// signup
// -----------------------------------------------
.contents--signup {
    .signup-card__text {
        padding-top: 1rem;
    }

    .submit-area {
        padding-top: 2rem;
    }

    .signup__license {
        & + .signup__license {
            @include mt {
                padding-top: 1rem;
            }
        }
    }

    .signup__license-num {
        @include sm {
            text-align: center;
        }

        .input {
            @include sm {
                width: 80px;
            }

            @include mt {
                width: 60%;
            }
        }
    }
}

// -----------------------------------------------
// password
// -----------------------------------------------
.contents--password {
    .password__text {
        @include padding-bottom(4rem, 2rem);

        text-align: center;
    }

    .submit-area {
        @include sm {
            padding-top: 4rem;
        }

        @include mt {
            padding-top: 4rem;
        }
    }
}

.contents--reset {
    .entry-done__title {
        br {
            @include sm {
                display: none;
            }
        }
    }

    .entry-done__img {
        img {
            max-width: 68px;
        }
    }
}
