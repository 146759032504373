@charset "UTF-8";

.contents--list {
    .search-box {
        background: $color_11;
    }
}

// -----------------------------------------------
// property
// -----------------------------------------------
.contents--list-property {
    .search-box {
        .search-box__items {
            .search-box__item {
                border-bottom-color: $color_1;
            }
        }

        .label {
            color: $color_1;
        }

        .search-box__item-label, {
            background: $color_1_light;
        }

        .arrow-d-box .arrow-d-box__wrap,
        .select-search .select-arrow {
            background: $color_1;
        }

        .search-box__result .select-search .select-arrow {
            background: $base_color;
        }
    }

    .search-fixed-box .search-fixed-box__item:last-child .btn {
        background: $color_22;
    }

    .search-static-box {
        padding: 0.25rem;
        .search-static-box__item{
            padding: 0.25rem;
            .btn.btn--needs {
                background: $color_22;
            }
        }
    }

    .traffic-modal {
        background: $color_1_light;

        .modal-title {
          color: $color_1;
        }

        .title.title--sub {
            color: $color_1;
        }
    }
}

// -----------------------------------------------
// needs
// -----------------------------------------------
.contents--list-needs {
    .search-box {
        .search-box__items {
            .search-box__item {
                border-bottom-color: $color_2;
            }
        }

        .label {
            color: $color_2;
        }

        .search-box__item-label {
            background: $color_2_light;
        }

        .arrow-d-box .arrow-d-box__wrap,
        .select-search .select-arrow {
            background: $color_2;
        }

        .search-box__result .select-search .select-arrow {
            background: $base_color;
        }
    }

    .search-fixed-box .search-fixed-box__item:last-child .btn {
        background: $color_2;
    }


    .search-static-box {
        padding: 0.25rem;
        .search-static-box__item {
            padding: 0.25rem;
            .btn--needs {
                background: $color_2;
            }
        }
    }

    .traffic-modal {
        background: $color_2_light;

        .modal-title {
          color: $color_2;
        }

        .title.title--sub {
            color: $color_2;
        }

        .checkbox:checked+.btn {
          background: $color_2;
        }
    }
}
