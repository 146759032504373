@charset "UTF-8";

// -----------------------------------------------
// input
// -----------------------------------------------
.input {
    display: inline-block;
    padding: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    transition: all .3s;
    background: $base_color;
    border: solid 1px $base_color;

    &:-ms-input-placeholder {
        color: #aca490;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px $base_color inset;
    }

    &.input--three-quarter-sm {
        @include sm {
            max-width: 70%;
        }
    }

    &.input--half {
        max-width: 50%;
    }

    &.input--short {
        @include sm {
            max-width: 206px;
        }

        @include mt {
            max-width: 50%;
        }
    }

    &.input--num {
        @include sm {
            padding:.5rem;
        }
    }
}

.input-search-mask {
    position: relative;

    img {
        @include sm {
            display: none;
        }

        @include mt {
            max-width: 23px;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.input,
.select,
.checkbox {

    @include sm {
        font-size: 1rem;
    }

    @include mt {
        font-size: 1rem;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        opacity: .4;
        background: $color_16;
        cursor:not-allowed;

        + .select-arrow {
            opacity: .4;
        }

        &:not(:checked) + .btn {
            opacity: .4;
            background: $color_16;
            cursor:not-allowed;
        }

    }
}

.range {
    -webkit-appearance: none;
    margin: 0;
    width: 100%;

    &:focus {
        outline: none;
    }

    /* WebKit・Blink向け 溝のスタイル */
    &::-webkit-slider-runnable-track {
        @include shadow();

        width: 100%;
        height: 12px;
        cursor: pointer;
        animate: .2s;
        border-radius: 1.3px;
        border: 1px solid;
    }

    /* WebKit・Blink向け つまみのスタイル */
    &::-webkit-slider-thumb {
        @include shadow();

        border: none;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        cursor: pointer;
        -webkit-appearance: none;

        /* 以下は つまみの縦位置調整 */
        margin-top: -14px; /* (つまみの高さ - トラックの高さ) / 2 。つまみの高さは border を含む */
    }

    /* 何故か上の margin-top 指定が Edge に効いてしまうので、Edge向けに設定をリセット */
    @supports (-ms-ime-align: auto) {
        &::-webkit-slider-thumb {
            margin-top: 0 !important;
        }
    }

    /* Firefox向け 溝のスタイル */
    &::-moz-range-track {
        @include shadow();

        width: 100%;
        height: 12px;
        cursor: pointer;
        animate: .2s;
        border-radius: 1.3px;
        border: .2px solid;
    }

    /* Firefox向け つまみのスタイル */
    &::-moz-range-thumb {
        @include shadow();

        border: none;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        cursor: pointer;
    }

    /* Edge・IE向け 溝のスタイル */
    &::-ms-track {
        width: 100%;
        height: 12px;
        cursor: pointer;
        animate: .2s;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
    }

    /* Edge・IE向け 溝の色（つまみより左側） */
    &::-ms-fill-lower {
        @include shadow();

        border: .2px solid;
        border-radius: 2.6px;
    }

    /* Edge・IE向け 溝の色（つまみより右側） */
    &::-ms-fill-upper {
        @include shadow();

        border: .2px solid;
        border-radius: 2.6px;
    }

    /* Edge・IE向け つまみのスタイル */
    &::-ms-thumb {
        @include shadow();

        border: none;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        cursor: pointer;
    }

    /* Edge・IE向け ポップアップを非表示に */
    &::-ms-tooltip {
        display: none;
    }

    &.range--property {
        /* WebKit・Blink向け 溝のスタイル */
        &::-webkit-slider-runnable-track {
            background: $color_1_light;
            border-color: $color_1_light;
        }

        /* WebKit・Blink向け つまみのスタイル */
        &::-webkit-slider-thumb {
            background: $color_1;
        }

        /* WebKit・Blink向け focus時のスタイル */
        &:focus::-webkit-slider-runnable-track {
            background: $color_1_light;
        }

        /* Firefox向け 溝のスタイル */
        &::-moz-range-track {
            background: $color_1_light;
            border-color: $color_1_light;
        }

        /* Firefox向け つまみのスタイル */
        &::-moz-range-thumb {
            background: $color_1;
        }

        /* Edge・IE向け 溝の色（つまみより左側） */
        &::-ms-fill-lower {
            background: $color_1_light;
            border-color: $color_1_light;
        }

        /* Edge・IE向け 溝の色（つまみより右側） */
        &::-ms-fill-upper {
            background: $base_color;
            border-color: $color_1_light;
        }

        /* Edge・IE向け focus時の色（つまみより左側） */
        &:focus::-ms-fill-lower {
            background: $color_1_light;
        }

        /* Edge・IE向け focus時の色（つまみより右側） */
        &:focus::-ms-fill-upper {
            background: $base_color;
        }
    }

    &.range--needs {
        /* WebKit・Blink向け 溝のスタイル */
        &::-webkit-slider-runnable-track {
            background: $color_2_light;
            border-color: $color_2_light;
        }

        /* WebKit・Blink向け つまみのスタイル */
        &::-webkit-slider-thumb {
            background: $color_2;
        }

        /* WebKit・Blink向け focus時のスタイル */
        &:focus::-webkit-slider-runnable-track {
            background: $color_2_light;
        }

        /* Firefox向け 溝のスタイル */
        &::-moz-range-track {
            background: $color_2_light;
            border-color: $color_2_light;
        }

        /* Firefox向け つまみのスタイル */
        &::-moz-range-thumb {
            background: $color_2;
        }

        /* Edge・IE向け 溝の色（つまみより左側） */
        &::-ms-fill-lower {
            background: $color_2_light;
            border-color: $color_2_light;
        }

        /* Edge・IE向け 溝の色（つまみより右側） */
        &::-ms-fill-upper {
            background: $base_color;
            border-color: $color_2_light;
        }

        /* Edge・IE向け focus時の色（つまみより左側） */
        &:focus::-ms-fill-lower {
            background: $color_2_light;
        }

        /* Edge・IE向け focus時の色（つまみより右側） */
        &:focus::-ms-fill-upper {
            background: $base_color;
        }
    }
}

.arrow-d-box {
    line-height: 0;
}

.select {
    width: 100%;
    line-height: 1;
    padding: .8rem 3.3rem .8rem .8rem;
    background: $base_color;
    border-radius: 0;

    &.select--no-event {
        pointer-events: none;
    }

    &.select--entry {
        background: $base_color;
    }

    &.select--property-border {
        border: solid 1px $color_1_light;
    }

    &.select--needs-border {
        border: solid 1px $color_2_light;
    }

    &.select--no-click {
        background: $color_16;
        opacity: .4;
    }
}

.select-mask {
    position: relative;
    display: inline-block;
    width: 100%;

    .label {
        padding: 0 1rem 0 0;
    }

    .select-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        display: flex;

        @include sm {
            right: 0.5rem;
            width: 2rem;
            height: 2rem;
        }

        @include mt {
            right: 0.5rem;
            width: 2rem;
            height: 2rem;
        }
    }

    &.select-mask--order {
        width: auto;

        @include mt {
            margin:0 0 .5rem 0;
        }

        .select {
            @include padding(.5rem 4rem .5rem .5rem, .5rem 4rem .5rem .5rem);

            width: auto;
            border: solid 1px $font_color;
            border-radius: 0;
        }

        .select-arrows {
            @include padding(1rem, 1rem);

            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            text-align: center;
            background: $font_color;
            pointer-events: none;

            @include sm {
                width: 2.8rem;
                line-height: 0;
            }

            @include mt {
                width: 3.3rem;
                line-height: 1.4rem;
            }

            img {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;

                @include sm {
                    max-width: 12px;
                    width: 12px;
                }

                @include mt {
                    max-width: 28px;
                }
            }
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.select-search {
    .select-arrow {
        text-align: center;
        border-radius: 4px;
        line-height: 2.5rem;
        right: 0;
        display: inline-block;

        @include sm {
            width: 2.5rem;
            height: 2.5rem;
        }

        @include mt {
            width: 2.5rem;
            height: 2.5rem;
        }

        img {
            position: relative;
            max-width: 22px;

            @include sm {
                top: .4rem;
            }

            @include mt {
                top: .2rem;
            }
        }
    }
}

.select-placeholder {
    &.placeholder-selected {
        color: #ACA490;
    }

    option:nth-child(1) {
        color: #ACA490;
    }

    option:nth-child(n + 2) {
        color: $font_color;
    }
}

.checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & + .btn {
        border-radius: 4px;
        font-weight: bold;

        @include sm {
            width: auto;
            min-width: 4rem;
            background:  $base_color;
        }

        @include mt {
            color: $font_color;
        }
    }

    &:checked {
        & + .btn {
            color: $base_color;

            @include sm {
                background: $color_1;
            }

            @include mt {
                background: $font_color;
            }
        }

        & + .chckbox-wrap .chckbox-img {
            img {
                display: none;

                + img {
                    display: inline-block;
                    max-width: 44px;
                }
            }
        }
    }
}

// label
.label.label--basic:after,
.form-group .form-group .form-group__double-input:after {
    content: '';
    position: absolute;
    top: 55%;
    background: url(../images/icon/check_inactive.png) center center/100% auto
        no-repeat;
    transform: translateY(-50%);
    transition: all .3s;

    @include sm {
        width: 23px;
        height: 23px;
    }

    @include mt {
        width: 1rem;
        height: 1rem;
    }
}

.label {
    display: inline-block;

    &.label--basic {
        position: relative;
        font-weight: bold;

        @include sm {
            margin-top: .6rem;
            padding: 0 1rem 0 4.3rem;
        }

        @include mt {
            padding: 1rem 1rem 1rem 3rem;
        }

        &:after {
            @include sm {
                left: 1.3rem;
            }

            @include mt {
                left: 1rem;
            }
        }

        &.label--basic-no-check {
            @include padding(0, 1rem);

            @include sm {
                position: absolute;
                top: 50%;
                width: 100%;
                margin-top: 0;
                padding: 0;
                text-align: center;
                transform: translateY(-50%);
            }

            &:after {
                display: none;
            }
        }
    }

    &.label--checkbox {
        position: relative;


        @include sm {
            padding-top: 0;
            width: 100%;
        }

        @include mt {
            width: 100%;
        }

        .checkbox {
            clip: rect(0px, 0px, 0px, 0px);
            pointer-events: none;
        }

        .chckbox-img {
            pointer-events: none;
            line-height: 0;

            img {
              max-width: 44px;

                @include mt {
                    display: inline-block;
                }

                & + img {
                    display: none;
                }
            }
        }

        .checkbox-text {
            display: inline-block;
            width: auto;
            margin-left: 1rem;

            @include sm {
            }

            @include mt {
                padding-left: 1rem;
                margin-left: 0;
            }
        }

        .btn {
          width: 100%;
        }
    }
}

.label-mask {
    position: relative;

    &.label-mask--property {
        background: $color_1_light;

        .label.label--basic-no-check {
            color: $color_1;
        }
    }

    &.label-mask--needs {
        background: $color_2_light;

        .label.label--basic-no-check {
            color: $color_2;
        }
    }
}

// box
.form {
    &.form--mypage {
        .form-control {
            & + .form-control {
                @include padding-top(1rem, .5rem);
            }
        }

        .label {
            @include sm {
                padding-top: 1rem;
            }
        }

        .input {
            border-radius: 4px;

            @include padding(1rem, .5rem);

            @include sm {
                background: $color_11;
            }

            @include mt {
                background: $base_color;
                border: solid 1px $color_13;
            }
        }

        .form-group {
            background: none;
        }
    }

    &:not(.form--mypage) {
    }
}

.form-group {

    padding: .4rem .6rem .4rem 0;

    @include mt {
        margin-top: 1rem;
        padding: 0 .4rem .4rem .4rem;
    }

    background: $color_11;

    + .form-group {
        @include sm {
            margin-top: 1rem;
        }
    }

    &.form-group--checkbox-center {
        background: none;
        text-align: center;

        @include mt {
            margin-top: 1.5rem;
            text-align: left;
            width: 100%;
        }
    }

    .form-group.form-group--card {
        @include sm {
            margin-left: 1rem;
            padding: 1rem .6rem 1rem 0;
        }

        .label.label--basic {
            @include sm {
                padding-left: 0;
            }

            &:after {
                @include sm {
                    display: none;
                }
            }
        }

        + .form-group.form-group--card {
            @include sm {
                margin-top: 0;
            }
        }
    }
}

.form-group__double-input {
    position: relative;

    &:after {
        @include sm {
            right: 1.3rem;
        }

        @include mt {
            display: none;
        }
    }
}

.form-has-unit {
    .input,
    .select-mask {
        max-width: 60%;
    }

    .form-has-unit__unit {
        display: inline-block;
        padding-left: .5rem;
        font-weight: bold;
    }
}

.form-result {
    @include padding(.6rem 1.2rem .6rem 1.2rem, .5rem);
    background: $color_8;
}

.form-file {
    @include mt {
        padding: 1.5rem 0;
    }

    .form-file__dd {
        text-align: center;
        background: $base_color;
        border: dashed 3px $color_1;
        font-size: .8rem;

        @include padding(0rem, 1rem);

        img {
            max-width: 50px;

            @include margin(0 0 1rem, 0 1rem .6rem);
        }
    }



    .form-file__text {

        .form-file__attention {
            color: $color_1_dark;
            background: $color_1_light;
            padding: 1rem;
            font-weight: bold;
            font-size: .8rem;
        }
    }

    .form-file__notes {
        padding-top: 1rem;
        font-size: .8rem;
    }

    &.form-file--msg {
        .form-file__dd {
            background: $color_11;
            border: solid 1px $color_12;
        }
    }
}

.form-file-img {
    @include padding(0 1rem, 1.5rem 0 0);

    img {
        width: auto;
    }

    .btn img {
        @include mt {
            max-width: 20px;
        }
    }
}



.form-file-items {
    @include padding(0 0 0 1rem, 0);
    counter-increment: section;

    .form-file__title {
      background: $color_1_light;
      font-size: .8rem;
      font-weight: bold;
      padding: .5rem;
      text-align: center;
      border-top: solid 1px $color_19;
      border-left: solid 1px $color_19;
      border-right: solid 1px $color_19;
    }

    .form-file-item {

        border-top: solid 1px $color_19;
        border-left: solid 1px $color_19;
        border-right: solid 1px $color_19;
        font-size: .8rem;
        position: relative;

        &:last-child {
            border-bottom: solid 1px $color_19;
        }

        .form-file__nos {
            background: $color_1;
            color: $base_color;
            padding: .5rem;
            width: 5%;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            .form-file__no {
              top: 50%;
              left: 40%;
              position: absolute;
              transform: translateY(-50%);
              align-items: center;
            }

        }

        .form-file__name {
          padding: .5rem;
          margin-left: 5%;

        }
    }

    img {
        max-width: 30px;
    }

    .btn img {
        @include mt {
            max-width: 20px;
        }
    }
}

.form-basic {
    @include sm {
        border-bottom: solid 1px;
    }

    &.form-basic--property {
        border-bottom-color: $color_1;

        .input {
            border: solid 1px $color_1_light;
        }

        .input-box__infomation {
          width: 60%;
          background: $color_11;
          border-radius: 5px;
          padding: 0 .5rem;
          margin-bottom: .5rem;
          color: $color_1;
          font-size: .8rem;

          @include mt {
              width: 100%;
              padding: .5rem;
          }

          .post_no {
              padding-left: .5rem;
          }

          img {
              margin: 20%;
              width: 60%;
              height: 60%;

              @include mt {
                  margin: 20%;
                  width: 60%;
                  height: 60%;
              }
          }
        }
    }

    &.form-basic--needs {
        border-bottom-color: $color_2;

        .input {
            border: solid 1px $color_2_light;
        }

        .input-box__infomation {
          width: 60%;
          background: $color_11;
          border-radius: 5px;
          padding: 0 .5rem;
          margin-bottom: .5rem;
          color: $color_2;
          font-size: .8rem;

          @include mt {
              width: 100%;
              padding: .5rem;
          }

          .post_no {
            padding-left: .5rem;
          }

          img {
            margin: 20%;
            width: 60%;
            height: 60%;

            @include mt {
                margin: 20%;
                width: 60%;
                height: 60%;
            }
          }
        }
    }

    &.required .label.label--basic::before {
        content: '※';
        position: absolute;
        line-height: 0;
        font-weight: normal;
        font-size: .8rem;
        color: $color_3;

        @include sm {
            top: .6rem;
            right: .6rem;
        }

        @include mt {
            top: 50%;
            right: -1rem;
        }
    }

    & > .row {
        @include sm {
            height: 100%;
        }
    }
}

.input-box {
    @include sm {
        padding: .6rem 1rem;
    }

    @include mt {
        padding: 1rem 0;
    }

    &.input-box--sm-pc-padding-bottom {
        @include sm {
            padding: 0 0 1rem;
        }

        .help-block {
            @include sm {
                padding: .6rem 1rem;
            }
        }
    }

    .input-box__label {
        font-weight: bold;
        color: $color_1;

        @include mt {
            padding: 0 0 .75rem 0;
        }

        &.input-box__label--mb-padidng-top {
            @include mt {
                padding-top: 1rem;
            }
        }
    }

    .input-box__radio {
        @include mt {
            padding-bottom: .5rem;
        }
    }

    .input-box__items {
        &.input-box__items--structure {
            .checkbox + .btn {
                @include sm {
                    min-width: 9.3rem;
                }
            }
        }

        &.input-box__items--button {
            @include mt {
                @include col-2(.5rem);
            }

            .input-box__item {
                @include mt {
                    flex-basis: 50%;
                    max-width: 50%;
                }

                &:nth-child(n + 3) {

                }

                .label {
                    @include mt {
                        width: 100%;
                    }
                }
            }
        }

        .input-box__item {
            min-width: 110px;

            @include sm {
                padding: 1rem 0 0 1rem;
            }
        }
    }

    .input-box__continuous {
        + .input-box__continuous {
            @include mt {
                padding-top: 1rem;
            }
        }
    }

    .input-box__along-wrap + .input-box__along-wrap {
        @include sm {
            padding-top: .5rem;
        }

        @include mt {
            padding-top: 1rem;
        }
    }

    .input-box__along {
        @include sm {
            padding-right: 1rem;
        }

        + .input-box__along {
            @include mt {
                padding-top: 1rem;
            }
        }
    }

    .input-box__build-date {
        .form-has-unit .input,
        .form-has-unit .select-mask {
            max-width: 80%;
        }

        & + .input-box__build-date {
            @include mt {
                padding-top: 1rem;
            }
        }
    }
}

.range-wrap {
    @include sm {
        padding: 1rem;
    }

    @include mt {
        padding: 1rem 1rem 2rem;
    }
}

.required-box {
    position: relative;

    .required-only {
        position: absolute;
        top: 50%;
        left: 70%;
        z-index: 2;
        display: inline-block;
        font-weight: normal;
        font-size: 1rem;
        color: $color_3;

        @include sm {
            line-height: 0;
        }

        @include mt {
            line-height: 1;
            top: 40%;
            left: 80%;
        }

        & + * {
            @include sm {
                margin-left: auto;
                margin-right: 1rem;
                width: 100%;
            }
        }
    }
}


.has-error {
    transition: all .3s;
    background-color: $color_3_light;
    border-radius: 5px;

    .input,
    .select,
    .select.select--entry {
        background-color: $color_3_light;
        border: solid 1px $color_3;
        transition: all .3s;

        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px $color_3_light inset;
        }
    }

    &.form-basic.form-basic--property .input,
    &.form-basic.form-basic--needs .input,
    .select.select--property-border,
    .select.select--needs-border {
        border-color: $color_3;
    }

    .label.label--basic:after,
    &.form-group .form-group__double-input:after {
        background-image: url(../images/icon/close_error.png);
    }

    &.form-basic {
        @include sm {
            background: none;
        }

        .label-mask{
            background-color: $color_3_light;
        }

        .label.label--basic.label--basic-no-check {
            color: $color_3;
        }
    }

    &.form-basic.form-basic--property {
        .has-no-error .input{
            background-color: $base_color;
            border-color: $color_2_light;
        }
    }

    &.form-basic.form-basic--needs {
        .has-no-error .input{
            background-color: $base_color;
            border-color: $color_2_light;
        }
    }
}

.has-success {
    transition: all .3s;
    background-color: $color_1_light;
    border-radius: 5px;

    .input,
    .select,
    .select.select--entry {
        background-color: $color_8;
        border: solid 1px $color_8;
        color: $font_color;

        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px $color_8 inset;
        }
    }

    &.form-basic.form-basic--property .input,
    &.form-basic.form-basic--needs .input,
    .select.select--property-border,
    .select.select--needs-border {
        border-color: $color_1;
    }

    .label.label--basic:after,
    &.form-group .form-group__double-input:after {
        background-image: url(../images/icon/check_active.png);
    }
}

.has-disabled {
    transition: all .3s;

    .input,
    .select,
    .select.select--entry {
        background-color: $color_16;
        border: solid 1px $color_19;
        color: $color_19;

        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px $color_16 inset;
        }
    }

    &.form-basic.form-basic--property .input,
    &.form-basic.form-basic--needs .input {
        border-color: $color_19;
    }

    .label {
        color: $color_19;
    }

    .label.label--basic:after,
    &.form-group .form-group__double-input:after {
        opacity: .6;
    }
}

.help-block {
    display: block;
    padding-top: .4rem;
    font-size: .8rem;
    color: $color_3;
    transition: all .3s;
}

.form-horizontal-block {
    .label.label--checkbox {
        width: 100%;
    }

    .chckbox-img {
        @include mt {
            flex-basis: 10%;
            max-width: 10%;
        }
    }

    .checkbox-text {
        @include mt {
            flex-basis: 90%;
            max-width: 90%;
        }
    }

    & + .form-horizontal-block {
        padding-top: .5rem;
    }
}

.chack-has-input {
    @include sm {
        margin-left: 3.8rem;
    }

    @include mt {
        padding-top: .5rem;
    }
}

.submit-area {
    text-align: center;

    .btn.btn--basic {
        @include sm {
            width: 100%;
            max-width: 20rem;
        }


    }

    &.submit-area--independent {
        @include padding(1rem 0,0 0 1rem 0);
    }

    &.submit-area--buttons {
        .btn {
            @include sm {
                vertical-align: top;
                min-height: 3.2rem;
            }

            + .btn {
                @include sm {
                    margin-left: 1rem;
                }

                @include mt {
                    margin-top: 1.5rem;
                }
            }
        }
    }
}
