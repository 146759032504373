@charset "UTF-8";

// -----------------------------------------------
// home
// -----------------------------------------------
.contents.contents--home {
    .content {
        @include padding(2.3rem 0 4.1rem, 1.5rem 1.5rem 3.5rem);
    }

    .title.title--sub {
        @include mt {
            padding-top: 2rem;
        }
    }

    .home__campaign {
        background: rgba(60, 60, 71, .74);

        @include sm {
            padding: 1.2rem 0;
        }

        .home__campaign-texts {
            color: $base_color;

            img {
                max-width: 105px;

                @include mt {
                    width: 6rem;
                    padding-right: .5rem;
                }
            }
        }

        .home__campaign-text {
            @include mt {
                justify-content: center;
                padding: 0 1.5rem;
                font-size: .8rem;
            }
        }

        .home__campaign-btns {
            @include sm {
                margin-left: auto;
                text-align: right;
            }

            @include mt {
                padding: 1.5rem;
                text-align: center;
            }
        }

        .btn + .btn {
            @include sm {
                margin-left: 1rem;
            }

            @include mt {
                margin-top: 1rem;
            }
        }
    }

    .home__visual {
        position: relative;

        @include padding(2.5rem 0 0 0, 2.5rem  0 0 0);

        @include sm {
            background: url(../images/home_visual.jpg) center center / cover
                no-repeat;
        }

        @include mt {
            background: url(../images/home_visual_mb.jpg) center center / cover
                no-repeat;
        }

        .slider {
          margin-top: 40px;
          overflow: hidden;
            display: none;

            &.slick-initialized{
              display: block;
            }

          @include mt {
            margin-top: 30px;
          }

          .dot-class {
                text-align: center;
                background: $color_1;

                li{
                  display: inline-block;
                  margin: 0 5px;
                  text-indent: 0px;


                  button{
                    margin-top: 20px;
                    position: relative;
                    text-indent: -9999px;
                    height: 5px;
                    width: 30px;
                    background: #013684;
                    color: #013684;
                    border-radius: 5px;
                    font-size: 20px;

                  }
                  &.slick-active{
                    button{
                      background: $base_color;
                      color: $base_color;

                    }
                  }
                }

          }
        }

        .home__campaign {
            @include sm {
                position: absolute;
                top: 0;
                width: 100%;
            }

            @include mt {
                display: none;
            }
        }

        .home__bana {
            @include mt {
                padding: 2rem 1.5rem 1rem;
            }
        }

        .home__logo {
            @include padding-bottom(5.1rem, 2rem);

            text-align: center;

            img {
                max-width: 220px;
            }

            @include mt {
              display: none;
            }
        }

        .home__bana-img {
            @include padding-bottom(2.1rem, 1.1rem);

            text-align: center;

            img {
                width: 68%;
                @include mt {
                    width: 100%;
                }
            }
        }

        .home__bana-img__price {
            @include padding-bottom(1rem, 0rem);
            @include padding-top(0rem, 1rem);

            text-align: center;

            img {
                width: 34.5%;
                @include mt {
                    width: 60%;
                }
            }
        }

        .home__top {
            .home__top-items {
                @include sm {
                    @include col-2(1.5rem);
                }

                @include mt {
                    padding: 0 1.5rem;
                }
            }

            .home__top-item {
                &:nth-child(1) {
                    .home__top-contents {
                        background: $color_1;
                    }

                    .home__top-arrow {
                        background: rgba(14, 107, 175, .47);
                    }

                    .home__top-image {
                        background: url(../images/home_visual_item_1.jpg) left
                            top / cover no-repeat $color_1;
                    }
                }

                &:nth-child(2) {
                    .home__top-contents {
                        background: $color_2;
                    }

                    .home__top-arrow {
                        background: rgba(124, 179, 66, .47);
                    }

                    .home__top-image {
                        background: url(../images/home_visual_item_2.jpg) left
                            top / cover no-repeat $color_2;
                    }

                    @include mt {
                        padding-top: .5rem;
                    }
                }
            }

            .home__top-item-wrap {
                display: block;
            }

            .home__top-texts {
                color: $base_color;

                @include sm {
                    padding: .7rem 2.1rem;
                }

                @include mt {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .home__top-image {
                img {
                    opacity: 0;
                }
            }

            .home__top-title {
                font-size: 1.4rem;
                font-weight: bold;

                br {
                    @include sm {
                        display: none;
                    }
                }
            }

            .home__top-text {
                @include sm {
                    line-height: 1.3;
                }

                @include mt {
                    display: none;
                }
            }

            .home__top-arrow {
                @include sm {
                    padding: .5rem 0;
                    line-height: 0;
                    text-align: center;
                }

                @include mt {
                    display: none;
                }

                img {
                    max-width: 28px;
                    width: 1.8rem;
                }
            }
        }
    }


    .home__media {
        background: $color_11;

        // 共通リスト
        .home__media-items {

            @include sm {
                margin-right: -16px;
                display: flex;
                width: 100%;
                flex-wrap: wrap;
            }

            @include mt {
                margin-top: 5%;
                width: 100%;
            }

            + .title {
                @include sm {
                    padding-top: 4rem;
                }

                @include padding-top(4rem, 2rem);
            }

            &:not(.home__media-items--has-img) {
                @include sm {
                    @include col-5(.5rem);
                }

                @include mt {
                    @include col-2(.75rem);
                }
            }

            &.home__media-items--has-img {
                .home__media-item-wrap {

                    @include sm {

                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;
                    }

                    @include mt {
                        display: flex;
                    }
                }

                .home__media-item {

                    width: 100%;

                    @include sm {
                        float: left;
                        width: 33.333%;
                        padding-right: 16px;
                        display: flex;
                        flex-direction: column;
                        flex-shrink: 1;
                        flex-basis: auto;
                        margin-bottom: 16px;
                    }
                    + .home__media-item {
                        @include mt {
                            padding-top: 1rem;
                        }
                    }
                }
            }

            &.home__media-items--absolute {
                // position: absolute;
            }

            .btn {
                width: 100%;
                font-weight: bold;

                @include sm {
                    width: 100%;
                    padding: .8rem;
                }
            }

            .home__media-imgs {
                background: $base_color;
                position: relative;

                figure {
                    position: relative;
                    overflow: hidden;
                    width: 100%;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        max-width: initial; /* サイズ指定をリセット！ */
                        width: auto;
                        height: 120%;
                    }
                }


                @include sm {
                    min-height: 14rem;

                    figure {
                        height: 14rem;
                    }
                }

                @include mt {
                    width: 43%;
                    min-height: 4rem;

                    figure {
                        height: 100%;
                    }

                }
            }

            .home__media-item-info {

                @include padding(0  16px  16px  16px, 0 0 0 1%);
                background: $base_color;

                @include sm {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;
                }

                @include mt {
                    width: 70%;

                }

            }

            .home__media-item-properties {
                @include sm {
                    min-height: 4.6rem;
                }

                @include mt {
                    font-size: .8rem;
                    min-height: 100px;
                }
            }

            .home__media-item-titles {
                text-align: left;
                width: 100%;

                @include sm {
                    margin: 10px 0;
                    height: 80px;
                }

                margin:0 0 2% 0;
            }

            .home__media-item-title {
                width: 100%;
                padding: 0;

                @include sm {
                    font-size: 1.2rem;
                }

                @include mt {
                    font-size: 1rem;
                }
            }


            .home__media-date-item {

                .home__media-category-img {
                    text-align: center;

                    img {
                        width:20px;
                        height:20px;
                    }
                }

                .home__media-category-text {
                    font-size: .8rem;
                    color: $color_1;
                    font-weight: bold;
                }

                .home__media-date-img {
                    text-align: center;

                    img {
                        width:20px;
                        height:20px;
                    }
                }

                .home__media-date-text {

                    font-size: .8rem;

                    @include mt {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

        }


    }

    .home__matching {
        text-align: center;

        @include mt {
            padding: 1.5rem 1.5rem 1rem;
        }

        .home__matching_course {
            max-width: 1200px;
            margin: auto;
            background: url(../images/home_top_buy_bg_2.png) center top/100% no-repeat #fff;
            @include mt {
                background: none;
            }
        }

        .home_matching_desc {
           width: 70%;
           margin: 1rem auto 1rem;
           font-weight: bold;

           @include mt {
                width: 100%;
                font-size: 0.7rem;
           }
        }
    }

    .home__problem {
        margin: 1rem auto -8rem;

        @include mt {
            margin: 0 0 -3rem 0;
            padding: 0rem 1.5rem;
        }

        .home__problem-content {
            width: 100%;
            background-color: $color_9;
            border-radius: 1rem;
            padding: 2.5rem 1rem;
            position: relative;
            text-align: center;

            @include mt {
                padding: 1.5rem 1rem;
            }

            h2 {
                width: 100%;
                font-size: 2.3rem;
                font-weight: bold;
                @include mt {
                    font-size: 1.5rem;
                }
            }
            .home__problem-lookhol {
                width: 100%;
                font-size: 1.7rem;
                color: $color_1;
                font-weight: bold;

                @include mt {
                    font-size: 1.1rem;
                }
            }
        }

        .home__problem-content__footer {
            width: 97%;
            margin: auto;

            @include mt {
                width: 92%;
            }
        }

        .home__problem-items {
            width: 100%;
            margin: 1.5rem 0 2.2rem;

            @include mt {
                margin: 1rem 0 1rem;
            }
            .home__problem-item {
                @include mt {
                    margin: 0.5rem 0;
                }

                .home__problem-item__text {
                    padding: 1.8rem 1.8rem;
                    margin: auto 19%;
                    font-weight: bold;
                    background-color: $base_color;
                    border-radius: 0.8rem;
                    position: relative;
                    text-align: left;

                    @include sm {
                        font-size: 15px;
                    }

                    @include md {
                        font-size: 1.15rem;
                    }

                    @include mt {
                        padding: 1rem 1rem;
                        font-size: 0.6rem;
                    }

                    p {
                        font-size: inherit;
                    }

                    label {
                        display: block;
                        text-align: right;
                        margin-top: 1rem;
                        color: $color_1;
                        font-size: inherit;

                        @include mt {
                            margin-top: 0.2rem;
                        }
                    }
                }
            }
            .home__problem-item__image {
                width: 15.8%;
                margin: auto;
            }

            .home__problem_flow_left {
                .home__problem-item__text {
                    margin-right: 20%;
                    margin-left: 0;

                    @include mt {
                        margin-right: 0;
                    }

                    &:after {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        left: -1.8rem;
                        top: calc(50% - 1rem);
                        border-top: 1rem solid transparent;
                        border-bottom: 1rem solid transparent;
                        border-right: 1.8rem solid $base_color;

                        @include mt {
                            left: -1rem;
                        }
                    }
                }
            }
            .home__problem_flow_right {
                .home__problem-item__text {
                    margin-left: 15%;
                    margin-right: 0;

                    @include mt {
                        margin-left: 0;
                    }

                    &:after {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        right: -1.8rem;
                        top: calc(50% - 1rem);
                        border-top: 1rem solid transparent;
                        border-bottom: 1rem solid transparent;
                        border-left: 1.8rem solid $base_color;

                        @include mt {
                            right: -1rem;
                        }
                    }
                }
            }
        }


    }

    .home__good {
        background-color: $color_24;
        padding: 7rem 0 3rem;

        @include mt {
            padding: 3rem 0 3rem;
            text-align: center;
        }

        h4 {
            font-size: 2rem;
            color: $base_color;
            font-weight: bold;
            word-break: keep-all;

            @include mt {
                font-size: 1.5rem;
                margin-top: 1.5rem;
            }
        }
        p {
            margin-top: auto;
            margin-bottom: auto;
            margin-top: 1rem;
            color: $base_color;

            @include mt {
                font-size: inherit;
            }
        }

        h2 {
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 2.3rem;
            color: white;

            @include mt {
                padding: 0 1.5rem;
                font-size: 2rem;
            }
        }

        .home__good-action {
            @include mt {
                padding: 0 1.5rem;
            }
        }

        .home__good-items {
            margin: auto;
            .home__good-item {
                margin: 3.5rem 0;

                @include mt {
                    margin: 1rem 0;
                }

                .home__good-item_image_contain {
                    @include mt {
                        margin: 1rem 0;
                    }
                }

                dt, dd {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                dd {
                    @include mt {
                        padding: 0 1.5rem;
                    }
                }

                img {
                    max-width: 100%;
                    width: initial;

                    @include mt {
                        width: 100%;
                    }
                }
                .home__good-item_image_message {
                    margin-left: 0;

                    @include md {
                        margin-left: -10rem;
                    }
                }

                .home__good-item_image_message_late {
                    margin-left: -5rem;
                    margin-right: 5rem;

                    @include sm {
                        margin-left: 0;
                        margin-right: 0;
                    }

                    @include mt {
                        margin: 0;
                    }
                }
            }
            .home__good-item_image_left {
                flex-flow: row;

                @include mt {
                    flex-flow: column;
                }

                img {
                    float: left;
                }
            }
            .home__good-item_image_right {
                flex-flow: row-reverse;

                @include mt {
                    flex-flow: column;
                }

                dd {
                    padding-left: 8.3%;
                }

                img {
                    float: right;
                }
            }
        }
    }

    .home__news {
        background: $base_color;

        .home__news-items {
        }

        .home__news-item {
            + .home__news-item {
                @include padding-top(2rem, 1.5rem);
            }
        }

        .home__news-time {
            font-weight: bold;
        }

        .home__news-text {
            @include mt {
                padding-top: .75rem;
            }
        }
    }

    .home__about {
        @include sm {
            background: url(../images/home_about_bg.jpg) center center / cover
                no-repeat;
        }

        @include mt {
            background: url(../images/home_about_bg_mb.jpg) center center /
                cover no-repeat;
        }

        .title {
            color: $base_color;

            @include mt {
                text-align: center;
            }
        }

        .home__about-item {
            &:nth-child(1) {
                .home__about-title {
                    background: $color_1;
                }
            }

            &:nth-of-type(2) {
                .home__about-title {
                    background: $color_2;
                }
            }
        }

        .home__about-title {
            font-size: 1.4rem;
            font-weight: bold;
            text-align: center;
            color: $base_color;

            @include sm {
                padding: .4rem 0;
            }

            @include mt {
                padding: .3rem 0;
            }
        }

        .home__about-contents {
            background: $base_color;
        }

        .home__about-image {
            text-align: right;

            @include mt {
                padding: 1rem;
            }

            img {
                max-width: 99px;
            }
        }

        .home__about-text {
            @include sm {
                line-height: 1.3;
                padding: 2rem 1rem;
            }

            @include mt {
                padding: .8rem 1rem .8rem;
            }
        }

        .home__about-matching {
            @include sm {
                align-self: center;
            }

            .home__about-matching-img {
                @include sm {
                    width: 120%;
                    position: relative;
                    left: -10%;
                }

                @include mt {
                    text-align: center;
                }
            }

            img {
                max-width: 291px;
            }
        }

        .home__about-description {
            color: $base_color;

            @include sm {
                padding: 3.6rem 0;
            }

            @include mt {
                padding: 1.5rem 0;
            }
        }

        .home__campaign {
            @include padding(1.4rem 1.2rem, 1.5rem 0);
        }
    }

    .home__search {
        position: relative;
        background: $color_6;

        .title__arrows {
            @include sm {
                color: $color_1;
            }
        }

        .tns-horizontal.tns-subpixel > .tns-item {
            @include sm {
                font-size: 1rem !important;
            }
        }

        .tns-outer {
            @include sm {
                position: relative;
            }

            [data-controls='prev'],
            [data-controls='next'] {

              width: 40px;

                @include sm {
                    z-index: 1;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            [data-controls='prev'] {
                @include sm {
                    left: 0;
                }

                @include lg {
                    left: -3rem;
                }
            }

            [data-controls='next'] {
                @include sm {
                    right: 0;
                }

                @include lg {
                    right: -3rem;
                }
            }
        }

        .home__search-items {
            @include sm {
                display: flex;
                flex-wrap: wrap;
            }

            + .title {
                @include sm {
                    padding-top: 4rem;
                }

                @include padding-top(4rem, 2rem);
            }

            &:not(.home__search-items--has-img) {
                @include sm {
                    @include col-5(.5rem);
                }

                @include mt {
                    @include col-2(.75rem);
                }
            }

            &.home__search-items--has-img {
                .home__search-item-wrap {
                    background: $color_1;

                    @include sm {

                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;
                    }

                    @include mt {
                        display: flex;
                    }
                }

                .home__search-item {
                    @include sm {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;
                    }
                    + .home__search-item {
                        @include mt {
                            padding-top: 1rem;
                        }
                    }

                    &:nth-child(n + 6) {
                        @include mt {
                            display: none;
                        }
                    }

                    &>h4.home__search-item-title {
                        @include mt {
                            background-color: #fff;
                            padding: .5rem;
                            font-size: 1rem;
                            border-bottom: 1px solid #e1e1e1;
                        }
                    }
                }
            }

            &.home__search-items--absolute {
                // position: absolute;
            }

            &.home__search-items--property {
                .home__search-item {
                    .home__search-imgs {
                        background: $color_1;
                    }

                    .home__search-img-infos-wrap {
                        background: rgba(14, 107, 175, .5);
                    }

                    .home__search-item-yield {
                        background: $color_1_light;
                        color: $color_1;

                        & + .home__search-item-num {
                            color: $color_1;
                        }
                    }

                    .type {
                        background: $color_1;
                    }
                }
            }

            &.home__search-items--needs {
                .home__search-item {
                    .home__search-imgs {
                        background: $color_2;
                    }

                    .home__search-img-infos-wrap {
                        background: rgba(103, 150, 54, .5);
                    }

                    .home__search-item-yield {
                        background: $color_2_light;
                        color: $color_2;

                        & + .home__search-item-num {
                            color: $color_2;
                        }
                    }

                    .type {
                        background: $color_2;
                    }
                }
            }
        }

        .home__search-item {
            .btn {
                width: 100%;
                font-weight: bold;

                @include sm {
                    width: 100%;
                    padding: .8rem;
                }
            }

            .home__search-imgs {
                position: relative;

                figure {
                  position: relative;
                  overflow: hidden;
                  width: 100%;

                    img {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -webkit-transform: translate(-50%, -50%);
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                      max-width: initial; /* サイズ指定をリセット！ */
                      width: auto;
                      height: 110%;
                    }
                }


                @include sm {
                    min-height: 10.7rem;

                    figure {
                      height: 10.7rem;
                    }
                }

                @include mt {
                    width: 43%;
                    min-height: 4rem;

                    figure {
                      height: 100%;
                    }

                }
            }

            .home__search-img-infos {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .home__search-img-infos-wrap {
                height: 100%;

                @include sm {
                    padding: .5rem;
                }
            }

            .home__search-img-info {
                text-align: center;
                font-weight: bold;
                color: $base_color;

                @include mt {
                    font-size: 1.4rem;
                }
            }

            .home__search-types {
                width: 100%;

                @include sm {
                    height: 3.5rem;
                    margin-top: 1.6rem;
                }

                @include mt {
                    .type {
                      width: 3rem;
                      height: 3rem;
                    }
                }

                .type+.type {
                    @include mt {
                        margin-top: 0;
                        margin-left: .2rem;
                    }
                }
            }

            .type {
                color: $base_color;
                border-color: $base_color;
            }

            .home__search-item-info {
                @include sm {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;
                }

                @include mt {
                    width: 57%;
                }

                @include padding(.5rem, .75rem .75rem .2rem);

                background: $base_color;
            }

            .home__search-item-title {
                font-weight: bold;
            }

            .home__search-item-properties {
                @include sm {
                    min-height: 4.6rem;
                }

                @include mt {
                  font-size: .8rem;
                }
            }

            .home__search-item-nums {
                text-align: center;
            }

            .home__search-item-num {
                color: $color_3;

                @include sm {
                    font-size: 1.4rem;
                }

                @include mt {
                    font-size: 1.5rem;
                    letter-spacing: -2px;
                }
            }

            .home__search-item-unit {
                @include sm {
                    text-align: right;
                    font-size: .8rem;
                }

                @include mt {
                    font-size: .8rem;
                    padding-top: .2rem;
                    letter-spacing: -1px;
                }
            }

            .home__search-item-yield {
                @include sm {
                    margin-right: .25rem;
                    padding: .25rem;
                    font-size: .8rem;
                }

                @include mt {
                    margin-right: .25rem;
                    padding: .25rem;
                    max-width: 30%;
                }
            }

            .home__search-item-data {
                .exp+.exp {
                    @include mt {
                        &:before {
                            content: '/';
                        }
                    }
                }

                &.home__search-item-data--toolong .exp::before {
                    display: none;
                }
            }

            .home__search-address-items {
                @include padding-top(.5rem, .5rem);

                @include mt {
                    font-size: .8rem;
                }
            }

            .home__search-address-item-wrap {
                width: 100%;
            }

            .home__search-traffic-item-wrap {
                .home__search-address-text {
                    @include sm {
                        line-height: 1rem;
                        height: 2rem;
                        overflow: hidden;
                    }
                }
            }

            .home__search-address-item + .home__search-address-item {
                @include padding(.5rem 0, 0);
            }

            .home__search-address-img {
                text-align: center;

                img {
                    max-width: 20px;
                }
            }

            .home__search-address-text {
                @include sm {
                    font-size: .8rem;
                }

                @include mt {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &:nth-child(n + 6) {
                @include sm {
                    padding-top: .7rem;
                }
            }
        }

        + .home__search {
            background: $color_7;

            .title__arrows {
                @include sm {
                    color: $color_2;
                }

                @include mt {
                    background: $color_2;
                }
            }
        }
    }
}

_:-ms-lang(x), .tns-slider {
    display: block !important;
}

_:lang(x)::-ms-backdrop,
.contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item {
    display: inline-flex;
}

_:lang(x)::-ms-backdrop, .home__search-item-info {
    min-height: 100%;
}
