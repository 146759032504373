//-------------------------*/
// +MIXINS                 */
//-------------------------*/

// LAYOUT

// Min-width media queries
@mixin fx-bp($point) {
  @media screen and (min-width: $point / 16 + em) {
    @content;
  }
}

// Max-width media queries
@mixin fx-bp-max($point) {
  @media screen and (max-width: $point / 16 + em) {
    @content;
  }
}

// FORMATTING

// HD background images
@mixin fx-img-2x($image, $width, $height) {
  @media (-moz-min-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}
