@charset "UTF-8";

.contents--profile {
    .submit-area {
        @include sm {
            padding-top: 4rem;
        }

        @include mt {
            padding-top: 4rem;
        }
    }

    .signup__license {
        & + .signup__license {
            @include mt {
                padding-top: 1rem;
            }
        }
    }

    .signup__license-num {
        @include sm {
            text-align: center;
        }

        .input {
            @include sm {
                width: 80px;
            }

            @include mt {
                width: 60%;
            }
        }
    }

    .profile__submit_link {
        text-align: center;

        @include sm {
            padding: 1rem 0;
        }

        @include mt {
            padding-top: 1.5rem;
        }

        a {
            text-decoration: underline;
            font-weight: bold;
            color: $font_color;
        }
    }
}

// -----------------------------------------------
// contact
// -----------------------------------------------
.contents--profile-edit {
    .form-group-email {
        .label.label--basic {
            @include sm {
                margin-top: 0;
            }
        }

        .btn {
            @include sm {
                font-weight: bold;
            }

            @include mt {
                font-size: .8rem;
            }
        }
    }
}

.contents--unsubscribe {
    .title {
        text-align: center;

        @include sm {
            padding-bottom: 0;
        }
    }

    .unsubscribe-title-note {
        @include padding-bottom(3rem, 1.5rem);

        @include sm {
            max-width: 460px;
            margin: 0 auto;
            text-align: right;
        }
    }

    .submit-area {
        @include sm {
            padding-top: 4rem;
        }

        @include mt {
            padding-top: 4rem;
        }
    }
}
