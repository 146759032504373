@charset "UTF-8";

// -----------------------------------------------
// Modal
// -----------------------------------------------
.pem {
    &.pem--img-only {
        .pem__wrap .pem__body .pem__content {
            max-width: 180px;
            background: none;
            border: none;
            box-shadow: none;
        }
    }

    &.pem--guest-modal {
        .guest-modal {
            display: block;
        }
    }

    &.pem--terms-modal {
        .pem__wrap .pem__body .pem__content {
            @include mt {
                width: 100%;
                height: 100%;
            }

            .pem__close {
                display: inline-block;
                color: $font_color;

                @include sm {
                    top: 1.5rem;
                    right: 1.5rem;
                    font-size: 3rem;
                }

                @include mt {
                    top: 2.3rem;
                    font-size: 5rem;
                    right: 1rem;
                }
            }
        }

        .terms-modal {
            display: block;
        }
    }

    &.pem--myli-modal {
        .myli-modal {
            display: block;
        }

        .pem__wrap .pem__body .pem__content {
            background: none;
            border: none;
            box-shadow: none;
        }

        .pem__close {
            display: inline-block;
            width: 30px;
            height: 30px;
            background-color: #25252b;
            top: -14px !important;
            right: -8px !important;
            border-radius: 25px;

            @include mt {
                top: -15px!important;
                right: -10px!important;
            }

            &:before {
                content: '';
                position: absolute;
                background-image: url(../images/icon/close.png);
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: inline-block;
                background-size: cover;
                transform: scale(0.85);
            }

            > span {
                display: none;
            }
        }
    }
}

.basic-modal {
    @include padding(1rem, 1rem);

    background: $base_color;
    border: solid 1px $color_1;
    border-radius: 4px;
    animation: fade-in-bottom .5s;

    @include mt {
        height: 100%;
    }

    .modal-close-button {
        position: absolute;
        top: 10px;
        right: 14px;
        width: 26px;
        height: 26px;
        background-color: #25252b;
        border-radius: 25px;
        cursor: pointer;

        @include mt {
            top: 10px;
            right: 10px;
        }

        &:before {
            content: '';
            position: absolute;
            background-image: url(../images/icon/close.png);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: inline-block;
            background-size: cover;
            transform: scale(0.85);
        }
    }

    .modal-titles {
        @include padding-bottom(.5rem, .75rem);

        .modal-title-img {
            line-height: 0;

            img {
                max-width: 30px;
            }
        }

        .modal-title {
            font-size: 1rem;
            font-weight: bold;
            @include padding-left(.5rem, .75rem);
        }
    }

    &.basic-modal--property {
        background: $color_1_light;
        border: none;

        .modal-titles {
            color: $color_1;
        }
    }

    &.basic-modal--needs {
        background: $color_2_light;
        border: none;

        .modal-titles {
            color: $color_2;
        }
    }

    &.basic-modal--needs-border {
        border-color: $color_2;
    }

    &.basic-modal--yesno {
      .modal-titles {
          @include padding-bottom(.5rem, .75rem);

          .modal-title-img {
              line-height: 0;
              margin: 0 auto;
              animation: skew 2s infinite linear;

              img {
                  max-width: 180px;
              }


          }

          .modal-title {
              width: 100%;
              text-align: center;
              font-size: 1rem;
              font-weight: bold;
              @include padding-left(.5rem, .75rem);
          }
      }

      .submit-area {
          text-align: center;

          .btn.btn--basic {
              @include sm {
                  width: 100%;
                  max-width: 15rem;
              }
          }

          .btn--light {
            @include mt {
              display: none;
            }
          }
      }
    }
}

.myli-modal {
    display: none;

    img {
        @include sm {
            max-width: 769px;
        }

        @include mt {
            max-height: 75vh;
        }
    }
}

.terms-modal {
    display: none;
    border-radius: 10px;

    @include padding(1.5rem, 1.5rem);

    .terms-modal__logo {
        text-align: center;

        img {
            max-width: 162px;

            @include mt {
                width: 10.4rem;
            }
        }
    }

    .title.title--sub {
        @include mt {
            margin-bottom: 0;
            padding-top: .7rem;
            text-align: left;
        }
    }

    .terms-modal__texts {
        overflow-y: auto;
        text-align: left;

        h3{
          font-weight: bold;
          color: $color_1;
        }

        h4{
          font-weight: bold;
        }

        p{
          margin: 1rem 0;
        }

        @include sm {
            max-height: 50vh;
        }

        @include mt {
            max-height: 80vh;
            -webkit-overflow-scrolling: touch;
        }
    }
}

.guest-modal {
    @include padding(4rem 2rem, 1.5rem);

    display: none;

    .modal__texts {
        text-align: center;

        img {
            display: inline-block;
            max-width: 47px;
        }
    }

    .modal__text {
        padding: 1rem 0 2rem;
    }

    .modal__btns {
        @include sm {
            @include col-2(1rem);
        }

        .modal__btn + .modal__btn {
            @include mt {
                padding-top: 1.5rem;
            }
        }
    }
}

.condition-modal {
    border: none;
    padding: 3rem;
    max-width: 60%;
    position: relative;
    animation: fade-in-bottom .5s;

    .modal-close-button+.list-condition {
        @include mt {
            margin-top: 1.4rem;
        }
    }

    .list-condition {
        @include sm {
            max-height: 40vh;
            overflow-y: auto;
        }

        @include mt {
            max-height: 60%;
            padding-bottom: 1.5rem;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    .form-group.form-group--checkbox-center {
        @include padding(1.5rem 0, .75rem 0);
    }

    &.condition-modal--property {
        background: $color_1_light;

        .condition-modal-titles {
            color: $color_1;
        }

        .list-condition
        .list-condition__items
        .list-condition__item
        .list-condition__item-nums {
            background: $color_1;
        }
    }

    &.condition-modal--needs {
        background: $color_2_light;

        .condition-modal-titles {
            color: $color_2;
        }

        .list-condition
        .list-condition__items
        .list-condition__item
        .list-condition__item-nums {
            background: $color_2;
        }
    }

    &.condition-modal--save,
    &.condition-modal--load {
        @include mt {
            padding: 1rem;
            max-width: 100%;

            .list-condition,
            .form-group.form-group--checkbox-center {
                padding: 0;
            }
        }
    }

    &.condition-modal--save {
        @include mt {
            height: max-content;
            margin-top: -3rem;
        }
    }

    &.condition-modal--load {
        @include mt {
            .list-condition {
                max-height: 90%;
            }
        }
    }

}

// -----------------------------------------------
// loadingしかくいやつ
// -----------------------------------------------

.loading-modal {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    background: $color_1;
    text-align:center;
    font-weight: 700;
    animation: fade-in-bottom .5s;

    .loading-modal-img {
      text-align:center;
      animation: spin 4s infinite linear;

      &.push {
        animation: fade-in-bottom 2s infinite linear;
      }
    }

    .loading-modal-title {

    }

    &.needs {
      background: $color_2;
      color: $base_color;
    }

    &.red {
      background: $color_3;
      color: $base_color;
      .loading-modal-img {
          animation: hvr-pulse 2s infinite linear;
      }
    }

    &.property {
      background: $color_1;
      color: $base_color;
    }

    &.normal {
      background: $base_color;
      color: $font_color;
    }

    &.end {
      background: $color_11;
      color: $font_color;
        .loading-modal-img {
            animation: skew 2s infinite linear;
        }
    }

    .loading-modal__items {
        position: relative;
    }

    .loading-modal__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    img {
        width: auto;
        max-width: 140px;
    }
}


.loader-wrap {
    height: 300px;
    padding: 100px;
    display: block;
    text-align: center;

    .loader,
    .loader:after {
        border-radius: 50%;
        display: inline-block;
    }
    .loader {
      width: 80px;
      height: 80px;
        font-size: 10px;
        text-indent: -9999em;
        border-top: 5px solid rgba(0, 0, 0, 0.1);
        border-right: 5px solid rgba(0, 0, 0, 0.1);
        border-bottom: 5px solid rgba(0, 0, 0, 0.1);
        border-left: 5px solid $color_11;
        transform: translateZ(0);
        animation: load8 1.1s infinite linear;
    }
}


.traffic-modal {
    border: none;
    position: relative;

    .modal-title {
      font-size: 1rem;
      font-weight: bold;
    }

    .title.title--sub {
      padding: .5rem;
      font-size: 1rem;
      margin:1rem 0 0 0;

        @include mt {
            padding-top: 0;
        }
    }

    .traffic-modal__list {

        @include sm {
            max-height: 50vh;
            overflow-y: auto;
        }

        @include mt {
            max-height: 94%;
            padding-bottom: 1.5rem;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        @include mb {
            max-height: 90%;
        }
    }

    .label {
        width: 100%;

        .btn {
            text-align: left;
            width: 100%;
            border-radius: 0;
            font-weight: 100;
        }
    }

    .checkbox+.btn {

    }

    .submit-area {
      padding-top: 1rem;

    }

    .traffic-modal__items {
      background: $base_color;
      padding-bottom: .5rem;
    }

    .traffic-modal__items .traffic-modal__item {
        @include sm {
            padding: .5rem 0 0 .5rem;
            min-width: 33%;
        }

        @include mt {
            width: 100%;
        }

        + .traffic-modal__item {
            @include mt {
                padding-top: .75rem;
            }
        }
    }
}

// -----------------------------------------------
// vue-js-modal
// -----------------------------------------------
.v--modal-overlay {
    // &[data-modal='loading'] {
    //     background: transparent !important;
    // }

    .v--modal-box.v--modal {
        overflow: inherit;
    }

    .v--modal {
        background: none;
        box-shadow: none;

        @include mt {
            max-width: 100%;
            padding: 1rem;
        }



        &.v--modal-has-item {

            @include mt {
                left: 0 !important;
                width: 100% !important;
                transform: translateY(0%);
            }
        }

        &.v--modal-has-list {
            @include sm {
                //top: 0% !important;
                height: auto !important;
                max-height: 50vh;
                width: 900px;
                //transform: translateY(0%);
            }

            @include mt {
                top: 0% !important;
                left: 0 !important;
                height: 90vh !important;
                width: 100% !important;
                transform: translateY(0%);
            }

            @include mb {
                height: 90vh !important;
            }
        }
    }

    .loading-modal,
    .img-only-modal,
    .basic-modal {
        display: block;
    }

    .basic-modal {
        @include sm {
            width: 100%;
            max-width: 900px;
            margin: 0 auto;
        }
    }
}

// -----------------------------------------------
// ajs-js-modal
// -----------------------------------------------
.alertify-notifier {
    .ajs-message {
      font-size: .8rem !important;
      color: $color_3_dark !important;
      background: $base_color !important;
      border: solid 1px $color_3_dark !important;
    }
}

// -----------------------------------------------
// 一覧で1件も無いとき
// -----------------------------------------------
.noitem-comment {
  margin: 100px auto;
  text-align: center;
}

// -----------------------------------------------
// 削除投稿オーバーレイ
// -----------------------------------------------
.deleted--message {
    position: absolute;
    top:0;
    left: 0;
    z-index: 1;
    text-align: center;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: $base_color;
    width: 100%;
    height: 100%;
    background: url(../images/pem_bg.png) center top repeat;
}
