@charset "UTF-8";

// -----------------------------------------------
// Animation
// -----------------------------------------------
.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slideInUp_1_2 {
-webkit-animation: 1.2s 1 both slideInUp;
    -moz-animation: 1.2s 1 both slideInUp;
        animation: 1.2s 1 both slideInUp;
}

.fadeInLeft-0_6 {
-webkit-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.6s 1 both
    fadeInLeft;
-moz-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.6s 1 both fadeInLeft;
animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.6s 1 both fadeInLeft;
}

.fadeInLeft-0_8 {
-webkit-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.8s 1 both
    fadeInLeft;
-moz-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.8s 1 both fadeInLeft;
animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.8s 1 both fadeInLeft;
}

.fadeInLeft-1_0 {
-webkit-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 1s 1 both
    fadeInLeft;
-moz-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 1s 1 both fadeInLeft;
animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 1s 1 both fadeInLeft;
}

@keyframes fade-in-bottom {
from {
    opacity: 0;
    transform: translateY(10px);
}
to {
    opacity: 1;
    transform: translateY(0);
}
}

@keyframes fade-in-top {
from {
    opacity: 0;
    transform: translateY(-10px);
}
to {
    opacity: 1;
    transform: translateY(0);
}
}

@keyframes fade-in-left {

from {
    opacity: 0;
    transform: translateX(-10px);
}
to {
    opacity: 1;
    transform: translateY(0);
}
}

@keyframes fade-out-bottom {
from {
    opacity: 1;
    transform: translateY(0px);
}
to {
    opacity: 0;
    transform: translateY(10px);
}
}

@keyframes spin {

  0% {transform:rotateY(0deg);}
  10% {transform:rotateY(0deg);}
  15% {transform:rotateY(180deg);}
  20% {transform:rotateY(0deg);}
  100% {transform:rotateY(0deg);}
}


@keyframes fadeInLeft {
from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    -moz-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
}
to {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
}
}

@keyframes slideInUp {
from {
    -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    visibility: visible;
}
to {
    -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes skew {
        0% {transform: skew(0deg, 0deg);}
        5% {transform: skew(5deg, 4.2deg);}
        10% {transform: skew(-4deg, -3deg);}
        15% {transform: skew(3deg, 2.2deg);}
        20% {transform: skew(-2deg, -1.5deg);}
        25% {transform: skew(0.9deg, 0.9deg);}
        30% {transform: skew(-0.6deg, -0.6deg);}
        35% {transform: skew(0.3deg, 0.3deg);}
        40% {transform: skew(-0.2deg, -0.2deg);}
        45% {transform: skew(0.1deg, 0.1deg);}
        50% {transform: skew(0deg, 0deg);}
    }

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}


@keyframes fadein {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes fadeout {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes zoomin {
  0% {transform:scale(.9);}
  100% {transform:scale(1);}
}
@keyframes zoomout {
  0% {transform:scale(1);}
  100% {transform:scale(.9);}
}
@keyframes hide {
  0% {visibility: visible;}
  100% {visibility: hidden;}
}
