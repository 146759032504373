@charset "UTF-8";

// -----------------------------------------------
// 202002　アマゾンキャンペーン
// -----------------------------------------------
.campaign__amazon {

  display: none;

  @media screen and (min-width: 1400px) {
      display: block;
      position: relative;
      z-index: 10;
  }

  .left {
    position: fixed;
    left: 20px;
    top:150px;
    width: 180px;
  }

  .right{
    position: fixed;
    right: 20px;
    top:150px;
    width: 180px;
  }


  &.inner {

    .left {
      position: absolute;
      left: -220px;
      top:-100px;
      width: 180px;
    }

    .right{
      position: absolute;
      right: -220px;
      top:-100px;
      width: 180px;
    }

  }

}
