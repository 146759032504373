@charset "UTF-8";

.contents--static {
    .submit-area {
        @include sm {
            padding-top: 4rem;
        }

        @include mt {
            padding-top: 4rem;
        }
    }

}

.contents--complete {
    .complete__img {
        line-height: 0;
        text-align: center;

        @include padding(0 0 2rem, 1.5rem 0);

        img {
            max-width: 129px;
        }
    }

    .complete__title {
        text-align: center;
        font-weight: bold;
        color: $color_1;

        &.complete__title--needs {
          color: $color_2;
        }

        @include sm {
            font-size: 1.4rem;
        }

        @include mt {
            font-size: 1.4rem;
        }
    }

    .complete__text {
        text-align: center;

        @include padding(2rem 0, 1.5rem 0);
    }

    .complete__negative {
        text-align: center;
        font-size: 1rem;
        color: $color_3;
    }

    .submit-area {
        @include sm {
            padding-top: 4rem;
        }

        @include mt {
            padding-top: 4rem;
        }
    }
}

// -----------------------------------------------
// contact
// -----------------------------------------------
.contents--contact {
    .form-has-unit {
        .form-has-unit__unit {
            padding: 0 .5rem 0 0;
        }

        .input {
            @include mt {
                max-width: 70%;
            }
        }
    }
}


// -----------------------------------------------
// company
// -----------------------------------------------
.contents--company {
    .company-def {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 60px;

        @include mt {
            display: block;
            padding-bottom: 0;
        }

        .company-def__ttl,
        .company-def__data {
            padding: .75rem 1.5rem;

            &:nth-of-type(even) {
                background: $color_11;
            }
        }

        .company-def__ttl {
            width: 20%;

            @include mt {
                width: 100%;
                padding: 1rem 1rem .2rem;
                font-weight: bold;
            }

            &:first-of-type {
                @include mt {
                    padding-top: 0;
                }
            }
        }

        .company-def__data {
            width: 80%;

            @include mt {
                width: 100%;
                padding: .2rem 1rem 1rem;
            }
        }

        .company-def__to_contact {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding-top: 2rem;

            &:before {
                content: '';
                display: inline-block;
                background-image: url(../images/icon/mail_inactive.png);
                background-size: contain;
                background-repeat: no-repeat;
                margin-bottom: 2px;
                height: 18px;
                width: 20px;
            }

            a {
                color: #4988ea;
                text-decoration: underline;
            }
        }
    }
}


// -----------------------------------------------
// privacy
// -----------------------------------------------
.contents--privacy {
    .privacy--sec {
        padding-bottom: 60px;

        @include mt {
            padding-bottom: 10%;
        }

        & + .privacy--sec {
            @include mt {
                padding-bottom: 0;
            }
        }
    }

    .privacy-ttl {
        @include mt {
            font-size: 1.7rem;
        }
    }

    .privacy-def {
        .privacy-def__ttl {
            margin-top: 2rem;
            font-size: 1.2rem;
            font-weight: bold;
            color: $color_1;

            @include mt {
                margin-top: 1.5rem;
            }
        }

        .privacy-def__data {
            margin-top: 1rem;

            @include mt {
                margin-top: .5rem;
            }

            .privacy-def__data-ttl {
                margin-bottom: .5rem
            }

            .privacy-def__data-note {
                display: inline-block;
                margin-top: .5rem
            }

            a {
                color: $color_1;

                &:hover {
                    opacity: .6;
                }
            }
        }

        .privacy-lists {
            margin: .5rem 0 0;
        	padding-left: 2.25rem;
            list-style-type: upper-latin;

            @include mt {
                padding-left: 1.75rem;
            }

            .privacy-list {
                list-style-type: upper-latin;
                padding-left: .5rem;
            }

            &--disc {
                list-style-type: disc;

                .privacy-list {
                    list-style-type: disc;
                    padding-left: 0;
                }
            }
        }
    }
}


// -----------------------------------------------
// law
// -----------------------------------------------
.contents--law {
    .law-def {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 60px;

        @include mt {
            display: block;
            padding-bottom: 0;
        }

        .law-def__ttl,
        .law-def__data {
            padding: .75rem 1.5rem;

            &:nth-of-type(even) {
                background: $color_11;
            }
        }

        .law-def__ttl {
            width: 20%;

            @include mt {
                width: 100%;
                padding: 1rem 1rem .2rem;
                font-weight: bold;
            }

            &:first-of-type {
                @include mt {
                    padding-top: 0;
                }
            }
        }

        .law-def__data {
            width: 80%;

            @include mt {
                width: 100%;
                padding: .2rem 1rem 1rem;
            }

            a {
                color: $color_1;

                &:hover {
                    opacity: .6;
                }
            }
        }
    }
}



// -----------------------------------------------
// faq
// -----------------------------------------------
.contents--faq {
    .faq-def {

        .faq-def__ttl {
            width: 100%;
            color: $color_1;
            padding: 2rem 0 .5rem 0;
            font-weight: bold;

            @include mt {

            }

            &:first-of-type {
                @include mt {
                    padding-top: 0;
                }
            }
        }

        .faq-def__data {
            width: 100%;
            background: $color_11;
            padding: 1rem;

            @include mt {


            }
        }
    }
}
