@charset "UTF-8";

// -----------------------------------------------
// mixins
// -----------------------------------------------
// @media
@mixin mb-320 {
    @media screen and (max-width: 320px) and (orientation: portrait) {
        @content;
    }
}

@mixin mb {
    @media screen and (max-width: 414px) and (orientation: portrait) {
        @content;
    }
}

@mixin mt {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin tab {
    @media screen and (min-width: 415px) and (max-width: 768px) {
        @content;
    }
}

@mixin tp {
    @media screen and (min-width: 415px) {
        @content;
    }
}

@mixin sm {
    @media screen and (min-width: 769px) {
        @content;
    }
}

@mixin md {
    @media screen and (min-width: 960px) {
        @content;
    }
}

@mixin lg {
    @media screen and (min-width: 1200px) {
        @content;
    }
}

@mixin xlg {
    @media screen and (min-width: 1980px) {
        @content;
    }
}

// font
@mixin fontfamily-serif() {
    font-family: 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', '游明朝',
        'Yu Mincho', '游明朝体', 'YuMincho', 'ＭＳ Ｐ明朝', 'MS PMincho', serif;
}

@mixin fontfamily-sansserif() {
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
        'ヒラギノ角ゴ ProN W3', Meiryo, 'Noto Sans JP', 'Noto Sans Japanese',
        sans-serif;
}

@mixin fontfamily-shingo() {
    font-family: a-otf-ud-shin-go-pr6n, -apple-system, BlinkMacSystemFont,
        'Helvetica Neue', 'Segoe UI', 'ヒラギノ角ゴ ProN W3', Meiryo,
        'Noto Sans JP', 'Noto Sans Japanese', sans-serif;
}

@mixin fontfamily-meiryo() {
    font-family:  "メイリオ",Meiryo,"ヒラギノ角ゴ ProN W3","HiraKakuProN-W3","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","helvetica","arial",sans-serif;
}

@mixin fontfamily-mplus() {
  @import url('https://fonts.googleapis.com/css?family=M+PLUS+1p:400,700,800,900');
  font-family: 'M PLUS 1p', sans-serif;
}

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(
                #{$min-value} +
                #{strip-unit($max-value - $min-value)} *
                (100vw - #{$min-vw}) /
                #{strip-unit($max-vw - $min-vw)}
            );
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

// accessory
@mixin placeholder-color($color) {
    &:placeholder-shown {
        color: $color;
    }

    &::-webkit-input-placeholder {
        color: $color;
    }

    &:-ms-input-placeholder {
        color: $color;
    }
}

// box
@mixin col-table($verticalAlign) {
    display: table;

    > * {
        float: none;
        display: table-cell;
        vertical-align: $verticalAlign;
    }
}

@mixin equal-width-column($num) {
    margin-left: -$num;
    margin-right: -$num;

    > * {
        padding: 0 $num;
    }
}

@mixin col-2($num) {
    @include equal-width-column($num);

    > *:nth-child(2n + 1) {
        clear: both;
    }

    > *:nth-child(n + 3) {
        padding-top: ($num);
    }
}

@mixin col-3($num) {
    @include equal-width-column($num);

    > *:nth-child(3n + 1) {
        clear: both;
    }

    > *:nth-child(n + 4) {
        padding-top: ($num);
    }
}

@mixin col-4($num) {
    @include equal-width-column($num);

    > *:nth-child(4n + 1) {
        clear: both;
    }

    > *:nth-child(n + 5) {
        padding-top: ($num);
    }
}

@mixin col-5($num) {
    @include equal-width-column($num);

    > *:nth-child(5n + 1) {
        clear: both;
    }

    > *:nth-child(n + 6) {
        padding-top: ($num);
    }
}

@mixin col-6($num) {
    @include equal-width-column($num);

    > *:nth-child(6n + 1) {
        clear: both;
    }

    > *:nth-child(n + 7) {
        padding-top: ($num);
    }
}

@mixin col-7($num) {
    @include equal-width-column($num);

    > *:nth-child(7n + 1) {
        clear: both;
    }

    > *:nth-child(n + 8) {
        padding-top: ($num);
    }
}

@mixin col-8($num) {
    @include equal-width-column($num);

    > *:nth-child(8n + 1) {
        clear: both;
    }

    > *:nth-child(n + 9) {
        padding-top: ($num);
    }
}

@mixin col-9($num) {
    @include equal-width-column($num);

    > *:nth-child(9n + 1) {
        clear: both;
    }

    > *:nth-child(n + 10) {
        padding-top: ($num);
    }
}

// fixed layout
@mixin rectangle-4to3() {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 75% 0 0;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin rectangle-16to9() {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 56.25% 0 0;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// margin / padding
@mixin margin($p, $m) {
    @include sm {
        margin: $p;
    }

    @include mt {
        margin: $m;
    }
}

@mixin margin-top($p, $m) {
    @include sm {
        margin-top: $p;
    }

    @include mt {
        margin-top: $m;
    }
}

@mixin margin-left($p, $m) {
    @include sm {
        margin-left: $p;
    }

    @include mt {
        margin-left: $m;
    }
}

@mixin margin-right($p, $m) {
    @include sm {
        margin-right: $p;
    }

    @include mt {
        margin-right: $m;
    }
}

@mixin margin-bottom($p, $m) {
    @include sm {
        margin-bottom: $p;
    }

    @include mt {
        margin-bottom: $m;
    }
}

@mixin padding($p, $m) {
    @include sm {
        padding: $p;
    }

    @include mt {
        padding: $m;
    }
}

@mixin padding-side($p, $m) {
    @include sm {
        padding: 0 $p;
    }

    @include mt {
        padding: 0 $m;
    }
}

@mixin padding-vertical($p, $m) {
    @include sm {
        padding: $p 0;
    }

    @include mt {
        padding: $m 0;
    }
}

@mixin padding-top($p, $m) {
    @include sm {
        padding-top: $p;
    }

    @include mt {
        padding-top: $m;
    }
}

@mixin padding-left($p, $m) {
    @include sm {
        padding-left: $p;
    }

    @include mt {
        padding-left: $m;
    }
}

@mixin padding-right($p, $m) {
    @include sm {
        padding-right: $p;
    }

    @include mt {
        padding-right: $m;
    }
}

@mixin padding-bottom($p, $m) {
    @include sm {
        padding-bottom: $p;
    }

    @include mt {
        padding-bottom: $m;
    }
}

@mixin shadow() {
    @include sm {
        box-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    }

    @include mt {
        box-shadow: 1px 3px 1px rgba(0, 0, 0, .2);
    }
}
