/*-------------------------*/

/* +GRID                   */

/*-------------------------*/

// Container for each row
.row {
    display: flex;
    flex-wrap: wrap;

    + .row {
    }
}

// Reversed row. Use with .row
.row-reverse-xs {
    flex-direction: row-reverse;
}

@each $breakpoint, $value in $fx-grid-breakpoints {
    @include fx-bp($value) {
        .row-reverse-#{$breakpoint} {
            flex-direction: row-reverse;
        }
    }
}

// Global settings for columns
[class*='col'] {
    // margin-top: $fx-grid-gutter + px;
    // padding-left: ($fx-grid-gutter / 2) + px;
    // padding-right: ($fx-grid-gutter / 2) + px;
    flex-grow: 1;
}

// Different column sizes
@each $size in $fx-column-sizes {
    $roundsize: floor($size);

    .col-#{$roundsize}-xs {
        @if roundsize == 33 {
            flex-basis: 33.33333333%;
            max-width: 33.33333333%;
        }

 @else {
            flex-basis: $size * 1%;
            max-width: $size * 1%;
        }
    }
}

@each $breakpoint, $value in $fx-grid-breakpoints {
    @include fx-bp($value) {
        @each $size in $fx-column-sizes {
            $roundsize: floor($size);

            .col-#{$roundsize}-#{$breakpoint} {
                @if $roundsize == 33 {
                    flex-basis: 33.33333333%;
                    max-width: 33.33333333%;
                }

 @else if $roundsize == 16 {
                    flex-basis: 16.6666666667%;
                    max-width: 16.6666666667%;
                }

 @else if $roundsize == 37 {
                    flex-basis: 37.5%;
                    max-width: 37.5%;
                }

 @else if $roundsize == 66 {
                    flex-basis: 66.6666666%;
                    max-width: 66.6666666%;
                }

 @else {
                    flex-basis: $size * 1%;
                    max-width: $size * 1%;
                }
            }
        }
    }
}

// Align self to the start, center , end, baseline , stretch
@each $position, $value in $fx-self-position {
    .self-#{$position}-xs {
        align-self: $value;
    }
}

@each $breakpoint, $value in $fx-grid-breakpoints {
    @include fx-bp($value) {
        @each $position, $value in $fx-self-position {
            .self-#{$position}-#{$breakpoint} {
                align-self: $value;
            }
        }
    }
}

// justify content to the top, center, end, baseline, between, around, evenly, right, left
@each $position, $value in $fx-just-position {
    .just-#{$position}-xs {
        justify-content: $value;
    }
}

@each $breakpoint, $value in $fx-grid-breakpoints {
    @include fx-bp($value) {
        @each $position, $value in $fx-just-position {
            .just-#{$position}-#{$breakpoint} {
                justify-content: $value;
            }
        }
    }
}

// Align item to the start-start, center, end-end, baseline, stretch
@each $position, $value in $fx-items-position {
    .items-#{$position}-xs {
        align-items: $value;
    }
}

@each $breakpoint, $value in $fx-grid-breakpoints {
    @include fx-bp($value) {
        @each $position, $value in $fx-items-position {
            .items-#{$position}-#{$breakpoint} {
                align-items: $value;
            }
        }
    }
}
