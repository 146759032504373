@charset "UTF-8";

/*!
FOX CSS 3.0.0
by Ronan Levesque - CC BY 3.0 license

Includes Normalize by Nicolas Gallagher
More info => http://necolas.github.io/normalize.css/
*/
// VARIABLES & MIXINS

@import '../vendor/fox-css/sass/variables';
@import '../vendor/fox-css/sass/mixins';

// IMPORT CHOSEN FOX CSS MODULES

//@import '../vendor/fox-css/sass/resets';
//@import '../vendor/fox-css/sass/normalize';
// @import '../vendor/fox-css/sass/utils';
@import '../vendor/fox-css/sass/grid';
// @import '../vendor/fox-css/sass/grid-ie9';

@import '../../../vue-env/node_modules/tiny-slider/src/tiny-slider.scss';
@import '../vendor/pemodal/sass/pemodal.scss';
