@charset "UTF-8";

// area
.wrapper {
    width: 100%;
    height: 100%;

    @include mt {
        padding-top: 14vw;
    }
}

.container {
    width: 100%;
    height: 100%;

    @include sm {
        max-width: 970px;
        margin: 0 auto;
        padding: 0 1.5rem;
    }

    @include md {
        padding: 0;
    }

    @include mt {
        margin: 0 auto;
    }
}

.contents {
    @include sm {
        min-height: calc(100% - 10rem);
    }

    @include mb {
        min-height: calc(100vh - 10rem);
    }

    @include mt {
        padding-top: 19vw;
    }
}

.content {
    @include padding(1rem 0 2rem 0, 1rem);
}

.has-fixed-bar + .footer {
    @include sm {
        margin-bottom: 4.3rem;
    }

    @include mt {
        margin-bottom: 5.8rem;
    }
}

// box
.breadcrumb {
    @include mt {
        display: none;
    }

    .breadcrumb__items {
        @include padding(1.4rem 0, 1rem);

        @include sm {
            line-height: 1;
        }
    }

    .breadcrumb__item {
        @include sm {
            position: relative;
            display: inline-block;
        }

        @include mt {
            display: none;
        }

        &:not(:last-child) {
            @include sm {
                padding-right: 1.4rem;
            }

            &:after {
                content: '＞';
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}

.circle {
    position: relative;
    border-radius: 50%;

    &.circle--has-icon {
        line-height: 0;
        text-align: center;

        @include sm {
            width: 3.1rem;
            max-width: 50px;
            height: 3.1rem;
            max-height: 50px;
        }

        @include mt {
            width: 3.7rem;
            max-width: 80px;
            max-height: 80px;
            padding: 1rem;
        }

        @include tab {
            padding: 0;
        }

        img {
            max-width: 30px;

            + img {
                display: none;
            }
        }

        .circle__icon {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
        }
    }

    &.circle--has-name {
        width: 3.2rem;
        height: 3.2rem;
        line-height: 3.2rem;
        text-align: center;
        background: $font_color;

        @include sm {
            font-weight: bold;
        }

        @include mt {
        }

        .circle__name {
            color: $base_color;

            @include sm {
                font-size: 1.5rem;
            }

            @include mt {
                font-size: 1.5rem;
            }
        }
    }

    &.circle--has-num {
        position: absolute;
        display: inline-block;
        background: $color_4;
        font-size: .8rem;
        color: $base_color;

        @include sm {
            top: -.4rem;
            right: -.7rem;
            width: 24px;
            height: 24px;
            line-height: 24px;
        }

        @include mt {
            top: 0;
            right: -.6rem;
            width: 1.4rem;
            height: 1.4rem;
            line-height: 1.4rem;
        }

        &.circle--has-num-only {
            position: static;
            margin-left: 1rem;
        }
    }

    &.circle--s {
        @include sm {
            width: 2rem;
            height: 2rem;
            line-height: 1.8rem;
        }

        @include mt {
            width: 2.5rem;
            height: 2.5rem;
            line-height: 2.5rem;
        }

        @include tab {
            max-width: 60px;
            max-height: 60px;
            line-height: 60px;
        }

        .circle__name {
            display: inline-block;
            vertical-align: middle;
            font-size: 1rem;
            line-height: 0;

            @include tab {
                font-size: 1rem;
            }
        }
    }

    &.circle--shadow {
        @include shadow();
    }

    &.circle--unread {
        background: $color_3;
        color: $base_color;
    }

    &.circle--read {
        background: $color_18;
        color: $base_color;
    }

    &.circle--reply {
        background: $color_1;
        color: $base_color;
    }
}

.colmun-2-s {
    @include sm {
        @include col-2(.5rem);
    }
}

.colmun-3-s {
    @include sm {
        @include col-3(.5rem);
    }
}

.continuous-item + .continuous-item {
    @include mt {
        padding-top: 0.5rem;
    }
}

.more {
    text-align: center;
    @include padding-top(2rem, 2rem);
}

.tag {
    display: inline-block;
    text-align: center;
    font-size: .8rem;

    @include mt {
        position: relative;
        top: -.3rem;
        margin-right: .5rem;
        padding: .4rem .6rem;
    }

    &.tag--disabled {
        background: $color_18;
        color: $base_color;
    }

    &.tag--new {
        background: $color_3;
        color: $base_color;
    }
}

.type {
    @include padding(0rem, 0rem);

    display: inline-block;
    border: solid 1px;
    text-align: center;

    @include sm {
        width: 3rem;
        height: 3rem;
    }

    @include mt {
        width: 4rem;
        height: 4rem;
    }

    &.text {
        @include mt {
            height: auto;
            font-weight: 600;
        }
    }

    &.type + .type {
        @include margin-left(.5rem, .5rem);
    }

    .type-img {
        line-height: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .type-title {
      display: none;
      padding-top: 2px;
      line-height: 100%;

        @include sm {
            font-weight: bold;
            font-size: .6rem;
        }

        @include mt {
            font-size: .6rem;
        }
    }
}

.pager {
    text-align: center;

    @include padding(2rem 0, 1rem 0);

    .pager__item {
        display: inline-block;
        border: solid 1px $color_15;

        img {
            max-width: 1.4rem;
            width: 1.4rem;

            @include sm {
            }
        }

        & + .pager__item {
            border-left: none;
        }

        &.pager__item--activate {
            background: $font_color;
            border-color: $font_color;

            .pager__item-wrap {
                color: $base_color;
            }
        }
    }

    .pager__item-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        min-height: 2.5rem;
        min-width: 2.5rem;

        @include padding(.2rem .8rem, .5rem 1.2rem);
    }
}

// search
.search-result-num {
  display: none;

    font-weight: bold;
    @include padding(.5rem 0, .5rem);

    @include sm {
        font-size: 1.2rem;
    }

    @include mt {
        font-size: 1rem;
    }
}

.search-result-order {
    margin-left: auto;

    @include padding(1rem 0 0 0, 1.7rem 0 0 0);
}

.search-box {
    .help-block {
        display: none;
    }

    @include padding(1rem 0, 0);

    .container {
        position: relative;

        &:after {
            content: '';
            position: absolute;

            @include sm {
                bottom: -3.5rem;
                left: calc(50% - 3rem);
                border-right: 3rem solid transparent;
                border-top: 2.5rem solid $color_11;
                border-left: 3rem solid transparent;
            }

            @include mt {
                bottom: -2rem;
                left: calc(50% - 3rem);
                border-right: 3rem solid transparent;
                border-top: 2rem solid $color_11;
                border-left: 3rem solid transparent;
            }
        }
    }

    .search-box__items {
        .search-box__item {
            border-bottom: solid 1px;

            &.search-box__item--yield {
                .search-box__item-has-unit {
                    .input {
                        @include sm {
                            max-width: 80px;
                        }
                    }
                }
            }
        }

        .label {
            font-weight: bold;

            @include mt {
                font-size: .8rem;
            }
        }

        .input,
        .select {
            border: solid 1px $color_1_light;

            &:focus {
                background-color:darken($color_1_light, 10%);
            }
            &:hover {
                background-color:darken($color_1_light, 10%);
            }

            @include mt {
                border-radius: 0;
            }
        }

        .input {
            @include mt {
                background: $base_color;
            }
        }

        .search-box__item-wrap {
            height: 100%;
        }

        .search-box__item-label {
            position: relative;

            .label {
                position: absolute;
                top: 50%;
                display: inline-block;
                width: 100%;
                text-align: center;
                transform: translateY(-50%);
            }
        }

        .search-box__item-inputs {
            @include padding(.5rem, .5rem);
        }

        .search-box__item-has-unit {
            .input {
                @include sm {
                    max-width: 80%;
                    margin-right: 1rem;
                }

                @include mt {
                    max-width: 70%;
                    margin-right: .2rem;
                }
            }
        }

        .search-box__item-unit {
        }

        .search-box__item-from {
            text-align: center;
        }

        .search-box__result {
            .select-search {
                @include mt {
                    text-align: center;
                }

                .select-arrow {
                    @include mt {
                        display: inline-block;
                        background: $base_color;
                    }
                }
            }
        }

        .search-box__price {
            .search-box__item-has-unit .input {
                @include sm {
                    max-width: 70%;
                    margin: 0 .5rem 0 0;
                }
            }

            .search-box__price-ranges {
                @include mt {
                    padding-right: .75rem;
                }

                + .search-box__price-text {
                    @include mt {
                        padding-top: 1.5rem;
                    }
                }

                &.search-box__price-max {
                    @include mt {
                        padding-top: 1.5rem;
                    }
                }
            }

            .search-box__price-max {
                @include mt {
                    padding-top: .75rem;
                }
            }
        }

        .search-box__prefectures {
            @include sm {
                @include col-3(1rem);
            }

            @include mt {
                @include col-2(.75rem);
            }
        }

        .search-box__price {
            .label {
            }
        }

        .search-box__alongs {
            .search-box__along {
                @include sm {
                    padding-right: 1rem;
                }

                &:nth-of-type(2) {
                    @include mt {
                        padding: .75rem .325rem 0 0;
                    }
                }

                &:nth-of-type(3) {
                    @include mt {
                        padding: .75rem 0 0 .325rem;
                    }
                }

                &:nth-of-type(4) {
                    @include mt {
                        padding-top: .75rem;
                    }
                }
            }

            + .search-box__alongs {
                @include padding-top(.5rem, .2rem);
            }
        }

        .search-box__checkboxs {
            > * {
                @include padding(0 .5rem 0 0, .2rem);
            }

            .checkbox {
                &:not(:checked) + .btn {
                    background: $base_color;
                    color: $color_1;
                }

              &:not(:checked) + .btn--needs-border {
                  color: $color_2;
                }

                &:checked + .btn {
                    background: $color_1;
                }

                &:checked + .btn--needs-border {
                  color: $base_color;
                  background-color:$color_2;
                }
            }

            .btn {
                border-radius: 0;

                @include sm {
                    min-width: 5rem;
                }

                @include mt {
                    font-size: 1rem;
                }
            }
        }

        &.search-box__items--needs {
            .input,
            .select {
                border-color: $color_2_light;

                &:focus {
                    background-color:darken($color_2_light, 10%);
                }
                &:hover {
                    background-color:darken($color_2_light, 10%);
                }
            }
        }
    }

    .more-area {
      @include padding(0, 0 0 .5rem 0);
        text-align: right;
        width: 100%;

        .btn--has-icon-horizontal {
            min-width: 200px;

            .btn__icon {
                line-height: 0;

                img {
                    max-width: 20px;

                    + img {
                        display: none;
                    }
                }
            }

            .btn__text {
                padding-left: 1rem;
                font-size: .8rem;
            }
        }
    }

    .submit-area {
        @include padding(0, 0);
    }
}

.search-fixed-box {

    width: 100%;

    @include padding(1rem 0, .5rem);

    @include mt {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        background: rgba(60, 60, 71, .87);
        animation: fade-in-bottom 2s;
    }

    .search-fixed-box__items {
        @include sm {
            @include col-3(0);
        }

        @include mt {
            @include col-3(0);
        }
    }

    .search-fixed-box__item {

        font-weight: bold;
        text-align: center;

        &:not(:last-child) {
            @include sm {
                font-size: .8rem;
                width: 29%;
                margin-right: 1%;
            }
            @include mt {
              width: 30%;
            }
        }

        &:first-child {
            @include mt {
                width: 100%;
            }
        }

        &:last-child {
            @include sm {
              width: 40%;
              text-align: right;
            }

            @include mt {
                width: 100%;
            }
        }

        &.search-fixed-box__item--text {

            align-self: center;
            font-size: 1rem;
            color: $base_color;

            @include mt {
                width: 100%;
                margin-bottom: .5rem;
            }
        }

        .btn {
          max-width: 100%;
        }
    }
}

// property
.property-images {
    @include mt {
        order: 1;
    }

    .property-image__wrap {
        position: relative;
        height: 5rem;
        width: 7rem;
        background: $color_11;
    }

    .property-image  {
          position: relative;
          overflow: hidden;
          width: 100%;

          @include mt {
              position:  absolute;
          }

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                max-width: initial; /* サイズ指定をリセット！ */
                width: auto;
                height: 110%;
              }


          @include sm {
              height: 5rem;
          }

          @include mt {
              height: auto;
              width: 100%;
              padding-right: .75rem;
          }

          + .list-search__prop-items .list-search__prop-item {
              &:nth-child(2) {
                  border-top: none;
              }

              &:nth-child(3) {
                  border-top: solid 1px $color_11;
              }
          }
      }


    .property-image__info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;

        .property-image__bar {
            background: rgba(14, 107, 175, .83);

            img {
                max-width: 47px;
            }
        }

        .property-image__icon {
            line-height: 0;

            img {
                position: relative;
                top: -.7rem;
                display: inline-block;

                @include sm {
                    padding-right: .7rem;
                }

                @include mt {
                    left: -.7rem;
                    width: 2.5rem;
                }
            }
        }

        .property-image__text {
            padding-bottom: .7rem;
            color: $base_color;
        }

        .property-image__btn {
            position: absolute;
            top: -1rem;
            right: 1rem;
        }
    }

    .property-image__disabled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .property-image__disabled-wrap {
            height: 100%;
            background: rgba(101, 103, 102, .83);
            color: $base_color;
        }
    }
}

.property-infos {
    .property-info {
        @include mt {
            padding: 1rem 1rem 0 1rem;
        }
    }

    .property-info__text {
        padding-left: 1rem;

        &.property-info__text--yield {
            font-size: 1.4rem;
            font-weight: bold;
            color: $color_1;
        }
    }

    .property-info__address {
        @include sm {
            padding-left: 1rem;
        }
    }

    .property-info__address-btn {
        text-align: right;
    }

    &.property-infos--vertical {
        .property-info__text {
            padding: .5rem 0 0 1rem;
        }

        .property-info {
            & + .property-info {
                padding-top: 1rem;
            }

            &.property-info__text--map .property-info__text {
                @include padding(0, 0 0 0 1rem);
            }
        }
    }
}

.price-highlight {
    @include padding-bottom(1.5rem, 1.5rem);

    line-height: 1;
    color: $color_10;
    font-weight: bold;

    dd {
        font-size: 1.4rem;
    }

}

// list
.list {
    @include padding-bottom(2rem, 1rem);

    .list__item {
        & + .list__item {
            @include sm {
                padding-top: 1rem;
            }

            @include mt {
                margin-top: .5rem;
            }
        }
    }

    .list__item-number {
        text-align: center;
        background: $color_1;
        color: $base_color;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mt {
            padding: 1rem;
            flex-direction: row;
        }

        .list__item-number-wrap {
            height: 100%;
        }
    }

    .list__item-status {
        text-align: center;
        color: $base_color;

        .list__item-status-wrap {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.status--unread {
                background: $color_3;
                color: $base_color;
            }

            &.status--read {
                background: $color_18;
                color: $base_color;
            }

            &.status--reply {
                background: $color_1;
                color: $base_color;
            }
        }


    }

    .list__item-search {
        font-weight: bold;

        @include sm {
            padding: 1.5rem 1rem;
        }

        @include mt {
            padding-top: .5rem;
        }
    }

    &.list--basic {
        .list__item.list__item--disabled {
            .list__item-wrap {
                background: rgba(101, 103, 102, .5);
            }

            .property-images,
            .list__item-info,
            .list__item-title-pdf {
                @include mt {
                    display: none;
                }
            }

            .list__item-title {
                @include mt {
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }

            .list__item-icons {
                @include mt {
                    padding-top: 0;
                }
            }
        }

        .list__item-wrap {
            background: $color_11;

            @include mt {
                padding: 2.5rem 1.5rem;
            }

            &.list__item-wrap--search {
                @include mt {
                    padding: 0 0 .5rem 0;
                }
            }
        }

        .list__item-title {
            font-weight: bold;

            @include mt {
                font-size: 1.2rem;
                padding-bottom: 1.5rem;
            }
        }

        .list__item-main {
            @include mt {
                @include col-2(.75rem);
            }
        }

        .list__item-number {
            @include mt {
                padding: 1rem;
            }
        }

        .property-images {
            @include mt {
                padding: 0;
                order: inherit;
            }

            .property-image__icon {
                img {
                    @include sm {
                    }

                    @include mt {
                        left: -.4rem;
                        max-width: 47px;
                        width: 2rem;
                    }
                }
            }

            .property-image__text {
                @include mt {
                    font-weight: bold;
                    font-size: .8rem;
                }
            }

            &.property__main-image--blank {
                align-self: stretch;

                .property-image__wrap {
                    background: $color_1_light;

                    @include sm {
                        padding: 3rem 0;
                    }

                    @include mt {
                        padding: 0;
                        .property-image__text {
                            height: fit-content;
                            line-height: 1rem;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            padding: 0;
                        }
                    }
                }

                .property-image__info {
                    position: static;
                }

                .property-image__text {
                    color: $color_1;
                }

                .property-image__bar {
                    background: none;
                }

                .property-image__icon {
                    img {
                        @include sm {
                            top: -.1rem;
                        }

                        @include mt {
                            top: -.2rem;
                            left: 0;
                            max-width: 47px;
                            width: 2rem;
                        }
                    }
                }

            }
        }

        .list__item-info {
            @include padding(1rem .5rem .5rem, 0 0 0 1rem);

            .price-highlight {
                padding-bottom: 0;

                @include sm {
                    font-size: 1rem;
                }

                @include mt {
                    font-weight: bold;
                    font-size: 1.1rem;
                }
            }

            .title-box .title-box__text {
                background: $base_color;
                font-size: .8rem;
            }

            .property-infos {
                @include sm {
                    padding-top: .8rem;
                }

                .property-info__text {
                    font-size: 1rem;

                    @include mt {
                        font-size: .8rem;
                    }

                    &.property-info__text--yield {
                        font-size: 1rem;
                    }
                }
            }
        }

        .list__item-main > :nth-child(n + 3) {
            @include mt {
                padding: 2rem 0 0;
            }
        }

        .list__item-icons {
            @include sm {
                align-self: stretch;
            }

            .property__main-btns,
            .property__main-btns-wrap,
            .property__main-btn,
            .btn {
                @include sm {
                    height: 100%;
                }
            }

            .property__main-btns-wrap {
                @include mt {
                    @include col-2(.75rem);
                }
            }

            .property__main-btn {
                @include sm {
                    border-left: solid 1px $base_color;
                }

                .circle {
                    &.circle--has-icon {
                        @include mt {
                            width: auto;
                            height: auto;
                        }
                    }

                    &.circle--has-num {
                        @include mt {
                            top: -.4rem;
                            right: -1rem;
                        }
                    }
                }

                .btn {
                    display: block;
                    height: 100%;

                    @include sm {
                        padding: 0;
                        background: none;
                        border: none;
                        box-shadow: none;
                    }

                    @include mt {
                        width: 100%;
                    }

                    .row {
                        height: 100%;
                    }

                    &.btn--needs {
                        @include sm {
                            background: none;
                        }
                    }
                }
            }
        }
    }

    &.list--needs {

        .list__item-number {
            text-align: center;
            background: $color_2;
            color: $base_color;
        }

        .property-images.property__main-image--blank {
            .property-image__wrap {
                background: $color_2_light;
            }

            .property-image__text {
                color: $color_2;
            }
        }

        .property-infos .property-info__text.property-info__text--yield {
            color: $color_2;
        }
    }
}

.list-search {
    @include sm {
        padding-top: 2rem;
    }

    @include mt {
        padding-top: .5rem;
    }

    .list-search__item {
        & + .list-search__item {
            @include padding-top(2rem, 1rem);
        }
    }

    .list-search__item-wrap {
        @include shadow();

        @include sm {
            border-top: solid 1px $color_11;
            border-left: solid 1px $color_11;
        }

        @include mt {
            position: relative;
            border: solid 1px $color_11;
        }

        position: relative;

        a.list-search__title {
            position: static;
        }

        button, a {
            position: relative;
            z-index: 3;
        }
    }

    .list-search__item-mb {
        padding: .5rem;

        li {
          padding-bottom: .5rem;

          span {
            text-align: center;
            font-size: .6rem;
            background: $color_1_light;
            color: $color_1;
            border-radius: 5px;
            padding: 2px 5px;
            margin-right: 5px;
            min-width: 50px;
            display: inline-block;
          }

        }
    }

    .list-search__item-with__mb__link {
        @include mt {
            position: relative;

            .list-search__item-mb__link {
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                width: 100%;
                height: 100%;
                display: block;
                z-index: 100;
            }
        }
    }

    a.list-search__title {
        @include padding(1rem, .5rem);
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: bold;

        @include mt {
            font-size: 1rem;
        }

        &::after{
            @include mt {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 0;
                pointer-events: auto;
                content: "";
                background-color:rgba(0,0,0,0);
            }
          }

        &:hover {
            color: $base_color;
        }

        &:after {
            position: absolute;
            border: 4px solid;
            box-sizing: border-box;
            z-index: 2;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            top: 0;
            left: 0;
            content: '';
            transition: .3s;
        }

        &:hover:after {
            content: '';
        }

        label {
            text-decoration: none;
            @include mt {
                max-width: 75%;
                line-height: 1.6rem;
                vertical-align: initial;

                img {
                    height: inherit;
                    line-height: inherit;
                    vertical-align: initial;
                }
            }
        }
    }

    .list-search__title {
        @include padding(1rem, 1rem);
        border-bottom: solid 1px $color_11;

        @include sm {
            position: initial !important;
        }

        @include mt {
            line-height: 1.2;
        }

        a, label {
            text-decoration: underline;
        }

        .tag {
            @include margin-right(1rem, .75rem);
            @include padding(.5rem, .325rem .75rem);
            font-size: .8rem;
        }

        .list-search__title-arrow {
            text-align: right;
            align-self: center;

            img {
                max-width: 14px;
            }
        }

        &+.list-search__item-line {
            @include mt {
                z-index: 0;
            }
        }
    }

    .list-search__image {
        position: relative;
        overflow: hidden;
        width: 100%;
        background: $color_11;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              max-width: initial; /* サイズ指定をリセット！ */
              width: auto;
              height: 110%;
            }


        @include sm {
            height: 13.2rem;
        }

        @include mt {
            height: auto;
            width: 100%;
            min-height: 80px;
        }

        + .list-search__prop-items .list-search__prop-item {
            &:nth-child(2) {
                border-top: none;
            }

            &:nth-child(3) {
                border-top: solid 1px $color_11;
            }
        }
    }

    .list-search__item-line {
        position: relative;

        @include sm {
            border-bottom: solid 1px $color_11;
        }

        @include mt {
            padding: 0;
            font-size: .8rem;
            border-bottom: solid 1px $color_11;
        }
    }

    .list-search__prop-items {
        .list-search__prop-items-wrap {
            @include sm {
                height: 100%;
            }
        }

        .list-search__prop-item {
            &:nth-child(n + 4) {
                @include sm {
                    border-top: solid 1px $color_11;
                }
            }
        }

        .list-search__prop-item-wrap {
            @include sm {
                height: 100%;
            }
        }

        .list-search__prop-titles {
            text-align: center;
            font-size: .9rem;

            @include padding(.5rem 0, .5rem 0);

            @include sm {
                position: relative;
                max-width: 113px;
                min-height: 3rem;
                font-weight: bold;
            }

            @include mt {
                background: $base_color;
            }

            .list-search__prop-title {
                @include sm {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    transform: translateY(-50%);
                }
            }
        }

        .list-search__prop-text {
            @include padding(.5rem 1rem,.5rem);

            @include sm {
                align-self: center;
            }

            @include mt {
                font-size: 1.2rem;
                font-weight: bold;
            }

            &.list-search__prop-text--num {
                color: $color_3;
                font-weight: bold;

                @include sm {
                    font-size: 1.2rem;
                }

                @include mt {
                    font-size: 1rem;
                }
            }
        }

        .list-search__prop-map {
            @include sm {
                padding-left: 1rem;
            }
        }

        .list-search__prop-along {
            @include sm {
                padding-top: .5rem;
                font-size: .8rem;
            }
        }
    }

    .list-search__date {
        font-size: .8rem;
        color: $color_19;
    }

    .list-search__btns {
        @include padding(.5rem 1rem, 0rem);

        /* search__title::afterでタップ領域を広げた時に、内側にくるボタンをz-indexで上にもってくる */
        @include mt {
            position: relative;
        }

        .list-search__type {
            @include sm {
                padding-left: 1rem;
            }

            @include mt {
                width: 100%;
                padding: .75rem 0;
                text-align: center;
            }

            .type + .type {
                @include margin-left(.5rem, .75rem);
            }
        }

        .list-search__btn-items {
            @include sm {
                margin-left: auto;
            }

            @include mt {
                width: 100%;
            }

            .list-search__btn-item-wrap {
                @include sm {
                    @include col-2(.5rem);
                }

                @include mt {
                    @include col-2(0rem);
                }
            }

            .list-search__btn-status-wrap {

                @include mt {
                    @include col-4(0rem);
                }
            }

            .list-search__btn-item {
                @include mt {
                    flex-basis: 50%;
                    max-width: 50%;
                    margin-left: auto;
                }
            }

            .btn {
                @include sm {
                    min-width: 13.5rem;
                }

                @include mt {
                    width: 100%;
                }
            }
        }

        .circle {
            &.circle--has-icon {
                max-height: 31px;
            }

            &.circle--has-num {
                @include sm {
                    top: -.5rem;
                }

                @include mt {
                    top: -.7rem;
                }
            }
        }
    }

    .list-search__btn-mb {
        width: 100%;

        @include padding(.5rem 1rem, 0);

        @include mt {
            position: relative;
        }

        .type {
          width: 2.8rem;
          height: 2.8rem;
          margin: .5rem;
        }

        .type + .type {
            @include margin-left(.5rem, 0rem);
        }

        ul {
          justify-content: right;
          justify-content: end;
          padding: .5rem;
        }

        .btn {
          padding: .2rem;
          box-shadow: 0px 0px 0px rgba(0,0,0,.2);
          border: solid 1px $color_11;
        }
    }

    &.list-search--property {
        .list-search__title,
        .list-search__prop-titles {
            background: $color_1_light;
        }

        .list-search__prop-titles,
        .type,
        .list-search__prop-mb
        .list-search__prop-text.list-search__prop-text--yield {
            color: $color_1;
        }

        a.list-search__title {
            &:hover {
                background: $color_1;
            }

            &:after {
                border-color: transparentize( $color_1, 1);
            }

            &:hover:after {
                border-color: transparentize( $color_1, 0);
            }
        }

        .type {
            border-color: $color_1;
        }

        .list-search__btn-item {
            z-index: initial !important;
        }
    }

    &.list-search--needs {
        .list-search__title,
        .list-search__prop-titles {
            background: $color_2_light;
        }

        .list-search__prop-items {
            @include mt {
                border-bottom: solid 1px $color_11;

                .list-search__prop-mb {
                    background: $base_color;
                }
            }
        }

        .list-search__prop-titles,
        .type,
        .list-search__prop-mb
        .list-search__prop-text.list-search__prop-text--yield {
            color: $color_2;
        }

        a.list-search__title {
            &:hover {
                background: $color_2;
            }

            &:after {
                border-color: transparentize( $color_2, 1);
            }

            &:hover:after {
                border-color: transparentize( $color_2, 0);
            }
        }

        .type {
            border-color: $color_2;
        }

        .list-search__item-mb {
            li {
              span {
                background: $color_2_light;
                color: $color_2;
              }
            }
        }
    }
}

.list-condition {
    .list-condition__item-wrap {
        &:hover {
            cursor: pointer;
            opacity: .8;
        }
    }

    .list-condition__item {
        .list-condition__item-nums {
            position: relative;
        }

        .list-condition__item-num {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            color: $base_color;
            text-align: center;
            transform: translateY(-50%);
        }

        .list-condition__item-texts {
            @include padding(.5rem, .5rem);

            background: $base_color;
            color: $font_color;
            text-align: left;
        }

        &.list-condition__item {
            @include padding-top(.5rem, .75rem);
        }

        &.condition-modal__item {
            .list-condition__item-texts {
                line-height: 1.5;
            }

        }
    }
}

.dummy {
  display: inline-block ;
  max-width: 80%;

  img {
    height: 24px;
  }
}
