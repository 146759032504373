@charset "UTF-8";

// -----------------------------------------------
// variable
// -----------------------------------------------
// color
$base_color: #fff;
$font_color: #3c3c47;
$color_1: #0e6baf;
$color_1_dark: #0d619e;
$color_1_light: #d5e6f2;
$color_2: #679636;
$color_2_dark: #5e8931;
$color_2_light: #e5f0d9;
$color_3: #a40000;
$color_3_dark: #980000;
$color_3_light: #fde2e2;
$color_4: #e60012;
$color_5: #012264;
$color_6: #ecf3f9;
$color_7: #f5f9f0;
$color_8: #dceaf4;
$color_9: #d5e6f2;
$color_10: #a40000;
$color_11: #f5f5f5;
$color_12: #e7e7e7;
$color_13: #888;
$color_14: #35455d;
$color_15: #dadada;
$color_16: #e5e5e5;
$color_17: #fcfcfc;
$color_18: #656766;
$color_19: #b2b2b2;
$color_20: #093389;
$color_21: #3b3b46;
$color_22: #228dc7;
$color_23: #CF0E72;
$color_23_dark: #CF0E4C;
$color_24: #374979;
$border_color: #000;
$link_color: #000;
$visit_color: #000;

// url
$imgPath: '/assets/images/min/'; //"http://";
