@charset "UTF-8";

// PEM
.pem {
    background: url(../images/pem_bg.png) center top repeat;

    .pem__close {
        display: none;
    }

    .pem__wrap .pem__body .pem__content {
        animation: fade-in-bottom .5s;
        background: $base_color;
        border: solid 1px $color_1;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, .2);

        @include sm {
            max-width: 740px;
        }

        @include mt {
            width: 90%;
        }
    }
}
