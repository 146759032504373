@charset "UTF-8";
/*!
FOX CSS 3.0.0
by Ronan Levesque - CC BY 3.0 license

Includes Normalize by Nicolas Gallagher
More info => http://necolas.github.io/normalize.css/
*/
/*-------------------------*/
/* +GRID                   */
/*-------------------------*/
.row {
  display: flex;
  flex-wrap: wrap;
}

.row-reverse-xs {
  flex-direction: row-reverse;
}

@media screen and (min-width: 48.0625em) {
  .row-reverse-s {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 60em) {
  .row-reverse-m {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 75em) {
  .row-reverse-l {
    flex-direction: row-reverse;
  }
}

[class*='col'] {
  flex-grow: 1;
}

.col-1-xs {
  flex-basis: 1%;
  max-width: 1%;
}

.col-2-xs {
  flex-basis: 2%;
  max-width: 2%;
}

.col-3-xs {
  flex-basis: 3%;
  max-width: 3%;
}

.col-4-xs {
  flex-basis: 4%;
  max-width: 4%;
}

.col-5-xs {
  flex-basis: 5%;
  max-width: 5%;
}

.col-8-xs {
  flex-basis: 8%;
  max-width: 8%;
}

.col-10-xs {
  flex-basis: 10%;
  max-width: 10%;
}

.col-12-xs {
  flex-basis: 12%;
  max-width: 12%;
}

.col-15-xs {
  flex-basis: 15%;
  max-width: 15%;
}

.col-16-xs {
  flex-basis: 16%;
  max-width: 16%;
}

.col-20-xs {
  flex-basis: 20%;
  max-width: 20%;
}

.col-25-xs {
  flex-basis: 25%;
  max-width: 25%;
}

.col-30-xs {
  flex-basis: 30%;
  max-width: 30%;
}

.col-33-xs {
  flex-basis: 33%;
  max-width: 33%;
}

.col-35-xs {
  flex-basis: 35%;
  max-width: 35%;
}

.col-37-xs {
  flex-basis: 37%;
  max-width: 37%;
}

.col-40-xs {
  flex-basis: 40%;
  max-width: 40%;
}

.col-45-xs {
  flex-basis: 45%;
  max-width: 45%;
}

.col-50-xs {
  flex-basis: 50%;
  max-width: 50%;
}

.col-55-xs {
  flex-basis: 55%;
  max-width: 55%;
}

.col-60-xs {
  flex-basis: 60%;
  max-width: 60%;
}

.col-65-xs {
  flex-basis: 65%;
  max-width: 65%;
}

.col-66-xs {
  flex-basis: 66%;
  max-width: 66%;
}

.col-70-xs {
  flex-basis: 70%;
  max-width: 70%;
}

.col-75-xs {
  flex-basis: 75%;
  max-width: 75%;
}

.col-80-xs {
  flex-basis: 80%;
  max-width: 80%;
}

.col-85-xs {
  flex-basis: 85%;
  max-width: 85%;
}

.col-87-xs {
  flex-basis: 87%;
  max-width: 87%;
}

.col-90-xs {
  flex-basis: 90%;
  max-width: 90%;
}

.col-91-xs {
  flex-basis: 91%;
  max-width: 91%;
}

.col-92-xs {
  flex-basis: 92%;
  max-width: 92%;
}

.col-93-xs {
  flex-basis: 93%;
  max-width: 93%;
}

.col-94-xs {
  flex-basis: 94%;
  max-width: 94%;
}

.col-95-xs {
  flex-basis: 95%;
  max-width: 95%;
}

.col-96-xs {
  flex-basis: 96%;
  max-width: 96%;
}

.col-97-xs {
  flex-basis: 97%;
  max-width: 97%;
}

.col-98-xs {
  flex-basis: 98%;
  max-width: 98%;
}

.col-99-xs {
  flex-basis: 99%;
  max-width: 99%;
}

.col-100-xs {
  flex-basis: 100%;
  max-width: 100%;
}

@media screen and (min-width: 48.0625em) {
  .col-1-s {
    flex-basis: 1%;
    max-width: 1%;
  }
  .col-2-s {
    flex-basis: 2%;
    max-width: 2%;
  }
  .col-3-s {
    flex-basis: 3%;
    max-width: 3%;
  }
  .col-4-s {
    flex-basis: 4%;
    max-width: 4%;
  }
  .col-5-s {
    flex-basis: 5%;
    max-width: 5%;
  }
  .col-8-s {
    flex-basis: 8%;
    max-width: 8%;
  }
  .col-10-s {
    flex-basis: 10%;
    max-width: 10%;
  }
  .col-12-s {
    flex-basis: 12%;
    max-width: 12%;
  }
  .col-15-s {
    flex-basis: 15%;
    max-width: 15%;
  }
  .col-16-s {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-20-s {
    flex-basis: 20%;
    max-width: 20%;
  }
  .col-25-s {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-30-s {
    flex-basis: 30%;
    max-width: 30%;
  }
  .col-33-s {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-35-s {
    flex-basis: 35%;
    max-width: 35%;
  }
  .col-37-s {
    flex-basis: 37.5%;
    max-width: 37.5%;
  }
  .col-40-s {
    flex-basis: 40%;
    max-width: 40%;
  }
  .col-45-s {
    flex-basis: 45%;
    max-width: 45%;
  }
  .col-50-s {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-55-s {
    flex-basis: 55%;
    max-width: 55%;
  }
  .col-60-s {
    flex-basis: 60%;
    max-width: 60%;
  }
  .col-65-s {
    flex-basis: 65%;
    max-width: 65%;
  }
  .col-66-s {
    flex-basis: 66.6666666%;
    max-width: 66.6666666%;
  }
  .col-70-s {
    flex-basis: 70%;
    max-width: 70%;
  }
  .col-75-s {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-80-s {
    flex-basis: 80%;
    max-width: 80%;
  }
  .col-85-s {
    flex-basis: 85%;
    max-width: 85%;
  }
  .col-87-s {
    flex-basis: 87%;
    max-width: 87%;
  }
  .col-90-s {
    flex-basis: 90%;
    max-width: 90%;
  }
  .col-91-s {
    flex-basis: 91%;
    max-width: 91%;
  }
  .col-92-s {
    flex-basis: 92%;
    max-width: 92%;
  }
  .col-93-s {
    flex-basis: 93%;
    max-width: 93%;
  }
  .col-94-s {
    flex-basis: 94%;
    max-width: 94%;
  }
  .col-95-s {
    flex-basis: 95%;
    max-width: 95%;
  }
  .col-96-s {
    flex-basis: 96%;
    max-width: 96%;
  }
  .col-97-s {
    flex-basis: 97%;
    max-width: 97%;
  }
  .col-98-s {
    flex-basis: 98%;
    max-width: 98%;
  }
  .col-99-s {
    flex-basis: 99%;
    max-width: 99%;
  }
  .col-100-s {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 60em) {
  .col-1-m {
    flex-basis: 1%;
    max-width: 1%;
  }
  .col-2-m {
    flex-basis: 2%;
    max-width: 2%;
  }
  .col-3-m {
    flex-basis: 3%;
    max-width: 3%;
  }
  .col-4-m {
    flex-basis: 4%;
    max-width: 4%;
  }
  .col-5-m {
    flex-basis: 5%;
    max-width: 5%;
  }
  .col-8-m {
    flex-basis: 8%;
    max-width: 8%;
  }
  .col-10-m {
    flex-basis: 10%;
    max-width: 10%;
  }
  .col-12-m {
    flex-basis: 12%;
    max-width: 12%;
  }
  .col-15-m {
    flex-basis: 15%;
    max-width: 15%;
  }
  .col-16-m {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-20-m {
    flex-basis: 20%;
    max-width: 20%;
  }
  .col-25-m {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-30-m {
    flex-basis: 30%;
    max-width: 30%;
  }
  .col-33-m {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-35-m {
    flex-basis: 35%;
    max-width: 35%;
  }
  .col-37-m {
    flex-basis: 37.5%;
    max-width: 37.5%;
  }
  .col-40-m {
    flex-basis: 40%;
    max-width: 40%;
  }
  .col-45-m {
    flex-basis: 45%;
    max-width: 45%;
  }
  .col-50-m {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-55-m {
    flex-basis: 55%;
    max-width: 55%;
  }
  .col-60-m {
    flex-basis: 60%;
    max-width: 60%;
  }
  .col-65-m {
    flex-basis: 65%;
    max-width: 65%;
  }
  .col-66-m {
    flex-basis: 66.6666666%;
    max-width: 66.6666666%;
  }
  .col-70-m {
    flex-basis: 70%;
    max-width: 70%;
  }
  .col-75-m {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-80-m {
    flex-basis: 80%;
    max-width: 80%;
  }
  .col-85-m {
    flex-basis: 85%;
    max-width: 85%;
  }
  .col-87-m {
    flex-basis: 87%;
    max-width: 87%;
  }
  .col-90-m {
    flex-basis: 90%;
    max-width: 90%;
  }
  .col-91-m {
    flex-basis: 91%;
    max-width: 91%;
  }
  .col-92-m {
    flex-basis: 92%;
    max-width: 92%;
  }
  .col-93-m {
    flex-basis: 93%;
    max-width: 93%;
  }
  .col-94-m {
    flex-basis: 94%;
    max-width: 94%;
  }
  .col-95-m {
    flex-basis: 95%;
    max-width: 95%;
  }
  .col-96-m {
    flex-basis: 96%;
    max-width: 96%;
  }
  .col-97-m {
    flex-basis: 97%;
    max-width: 97%;
  }
  .col-98-m {
    flex-basis: 98%;
    max-width: 98%;
  }
  .col-99-m {
    flex-basis: 99%;
    max-width: 99%;
  }
  .col-100-m {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 75em) {
  .col-1-l {
    flex-basis: 1%;
    max-width: 1%;
  }
  .col-2-l {
    flex-basis: 2%;
    max-width: 2%;
  }
  .col-3-l {
    flex-basis: 3%;
    max-width: 3%;
  }
  .col-4-l {
    flex-basis: 4%;
    max-width: 4%;
  }
  .col-5-l {
    flex-basis: 5%;
    max-width: 5%;
  }
  .col-8-l {
    flex-basis: 8%;
    max-width: 8%;
  }
  .col-10-l {
    flex-basis: 10%;
    max-width: 10%;
  }
  .col-12-l {
    flex-basis: 12%;
    max-width: 12%;
  }
  .col-15-l {
    flex-basis: 15%;
    max-width: 15%;
  }
  .col-16-l {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-20-l {
    flex-basis: 20%;
    max-width: 20%;
  }
  .col-25-l {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-30-l {
    flex-basis: 30%;
    max-width: 30%;
  }
  .col-33-l {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-35-l {
    flex-basis: 35%;
    max-width: 35%;
  }
  .col-37-l {
    flex-basis: 37.5%;
    max-width: 37.5%;
  }
  .col-40-l {
    flex-basis: 40%;
    max-width: 40%;
  }
  .col-45-l {
    flex-basis: 45%;
    max-width: 45%;
  }
  .col-50-l {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-55-l {
    flex-basis: 55%;
    max-width: 55%;
  }
  .col-60-l {
    flex-basis: 60%;
    max-width: 60%;
  }
  .col-65-l {
    flex-basis: 65%;
    max-width: 65%;
  }
  .col-66-l {
    flex-basis: 66.6666666%;
    max-width: 66.6666666%;
  }
  .col-70-l {
    flex-basis: 70%;
    max-width: 70%;
  }
  .col-75-l {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-80-l {
    flex-basis: 80%;
    max-width: 80%;
  }
  .col-85-l {
    flex-basis: 85%;
    max-width: 85%;
  }
  .col-87-l {
    flex-basis: 87%;
    max-width: 87%;
  }
  .col-90-l {
    flex-basis: 90%;
    max-width: 90%;
  }
  .col-91-l {
    flex-basis: 91%;
    max-width: 91%;
  }
  .col-92-l {
    flex-basis: 92%;
    max-width: 92%;
  }
  .col-93-l {
    flex-basis: 93%;
    max-width: 93%;
  }
  .col-94-l {
    flex-basis: 94%;
    max-width: 94%;
  }
  .col-95-l {
    flex-basis: 95%;
    max-width: 95%;
  }
  .col-96-l {
    flex-basis: 96%;
    max-width: 96%;
  }
  .col-97-l {
    flex-basis: 97%;
    max-width: 97%;
  }
  .col-98-l {
    flex-basis: 98%;
    max-width: 98%;
  }
  .col-99-l {
    flex-basis: 99%;
    max-width: 99%;
  }
  .col-100-l {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.self-start-xs {
  align-self: flex-start;
}

.self-center-xs {
  align-self: center;
}

.self-end-xs {
  align-self: flex-end;
}

.self-baseline-xs {
  align-self: baseline;
}

.self-stretch-xs {
  align-self: stretch;
}

@media screen and (min-width: 48.0625em) {
  .self-start-s {
    align-self: flex-start;
  }
  .self-center-s {
    align-self: center;
  }
  .self-end-s {
    align-self: flex-end;
  }
  .self-baseline-s {
    align-self: baseline;
  }
  .self-stretch-s {
    align-self: stretch;
  }
}

@media screen and (min-width: 60em) {
  .self-start-m {
    align-self: flex-start;
  }
  .self-center-m {
    align-self: center;
  }
  .self-end-m {
    align-self: flex-end;
  }
  .self-baseline-m {
    align-self: baseline;
  }
  .self-stretch-m {
    align-self: stretch;
  }
}

@media screen and (min-width: 75em) {
  .self-start-l {
    align-self: flex-start;
  }
  .self-center-l {
    align-self: center;
  }
  .self-end-l {
    align-self: flex-end;
  }
  .self-baseline-l {
    align-self: baseline;
  }
  .self-stretch-l {
    align-self: stretch;
  }
}

.just-start-xs {
  justify-content: flex-start;
}

.just-center-xs {
  justify-content: center;
}

.just-end-xs {
  justify-content: flex-end;
}

.just-baseline-xs {
  justify-content: baseline;
}

.just-between-xs {
  justify-content: space-between;
}

.just-around-xs {
  justify-content: space-around;
}

.just-evenly-xs {
  justify-content: space-evenly;
}

.just-right-xs {
  justify-content: right;
}

.just-left-xs {
  justify-content: left;
}

.just-stretch-xs {
  justify-content: stretch;
}

@media screen and (min-width: 48.0625em) {
  .just-start-s {
    justify-content: flex-start;
  }
  .just-center-s {
    justify-content: center;
  }
  .just-end-s {
    justify-content: flex-end;
  }
  .just-baseline-s {
    justify-content: baseline;
  }
  .just-between-s {
    justify-content: space-between;
  }
  .just-around-s {
    justify-content: space-around;
  }
  .just-evenly-s {
    justify-content: space-evenly;
  }
  .just-right-s {
    justify-content: right;
  }
  .just-left-s {
    justify-content: left;
  }
  .just-stretch-s {
    justify-content: stretch;
  }
}

@media screen and (min-width: 60em) {
  .just-start-m {
    justify-content: flex-start;
  }
  .just-center-m {
    justify-content: center;
  }
  .just-end-m {
    justify-content: flex-end;
  }
  .just-baseline-m {
    justify-content: baseline;
  }
  .just-between-m {
    justify-content: space-between;
  }
  .just-around-m {
    justify-content: space-around;
  }
  .just-evenly-m {
    justify-content: space-evenly;
  }
  .just-right-m {
    justify-content: right;
  }
  .just-left-m {
    justify-content: left;
  }
  .just-stretch-m {
    justify-content: stretch;
  }
}

@media screen and (min-width: 75em) {
  .just-start-l {
    justify-content: flex-start;
  }
  .just-center-l {
    justify-content: center;
  }
  .just-end-l {
    justify-content: flex-end;
  }
  .just-baseline-l {
    justify-content: baseline;
  }
  .just-between-l {
    justify-content: space-between;
  }
  .just-around-l {
    justify-content: space-around;
  }
  .just-evenly-l {
    justify-content: space-evenly;
  }
  .just-right-l {
    justify-content: right;
  }
  .just-left-l {
    justify-content: left;
  }
  .just-stretch-l {
    justify-content: stretch;
  }
}

.items-start-xs {
  align-items: flex-start;
}

.items-center-xs {
  align-items: center;
}

.items-end-xs {
  align-items: flex-end;
}

.items-baseline-xs {
  align-items: baseline;
}

.items-stretch-xs {
  align-items: stretch;
}

@media screen and (min-width: 48.0625em) {
  .items-start-s {
    align-items: flex-start;
  }
  .items-center-s {
    align-items: center;
  }
  .items-end-s {
    align-items: flex-end;
  }
  .items-baseline-s {
    align-items: baseline;
  }
  .items-stretch-s {
    align-items: stretch;
  }
}

@media screen and (min-width: 60em) {
  .items-start-m {
    align-items: flex-start;
  }
  .items-center-m {
    align-items: center;
  }
  .items-end-m {
    align-items: flex-end;
  }
  .items-baseline-m {
    align-items: baseline;
  }
  .items-stretch-m {
    align-items: stretch;
  }
}

@media screen and (min-width: 75em) {
  .items-start-l {
    align-items: flex-start;
  }
  .items-center-l {
    align-items: center;
  }
  .items-end-l {
    align-items: flex-end;
  }
  .items-baseline-l {
    align-items: baseline;
  }
  .items-stretch-l {
    align-items: stretch;
  }
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.42857%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

/*-----------------------------------------------
pem
-----------------------------------------------*/
.pem {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity .3s;
}

.pem .pem__wrap {
  display: table;
  width: 100%;
  height: 100%;
}

.pem .pem__wrap .pem__body {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.pem .pem__wrap .pem__body .pem__content {
  position: relative;
  width: 50%;
  margin: 0 auto;
}

.pem .pem__wrap .pem__body .pem__content .pem__close {
  z-index: 1;
  position: absolute;
  top: -2rem;
  right: 0;
  font-size: 3rem;
  line-height: 0;
  color: #fff;
}

.pem .pem__wrap .pem__body .pem__content .pem__close:hover {
  cursor: pointer;
}

.pem.pem--activate {
  display: block;
  opacity: 1;
}

.pem.pem--open {
  overflow-y: hidden;
}

.pemLoading {
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .3s;
}

.pemLoading.pemLoading--activate {
  opacity: 1;
}

.pemLoading .pemLoading__wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.pemLoading .pemLoading__wrapper .pemLoading__body {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.pemLoading .pemLoading__wrapper .pemLoading__body img {
  max-width: 44px;
}

* {
  margin: 0;
  padding: 0;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-size: inherit;
  font-family: "メイリオ",Meiryo,"ヒラギノ角ゴ ProN W3","HiraKakuProN-W3","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","helvetica","arial",sans-serif;
}

img {
  border: none;
}

li {
  list-style: none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

code,
kbd,
pre,
samp,
tt {
  font-family: "メイリオ",Meiryo,"ヒラギノ角ゴ ProN W3","HiraKakuProN-W3","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","helvetica","arial",sans-serif;
  line-height: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
fieldset,
form,
input,
label,
legend,
select,
textarea {
  border: 0;
  box-shadow: 0 0 0 0;
  background: none;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  vertical-align: baseline;
  -webkit-appearance: none;
}

input:active,
input:focus,
textarea:active,
textarea:focus,
button:active,
button:focus,
a:active,
a:focus {
  outline: 0;
  font-style: inherit;
  font-weight: inherit;
}

q::before,
q::after {
  content: '';
}

article,
aside,
dialog,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
  word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6,
section,
nav,
article,
aside,
hgroup,
header,
footer,
address,
address,
caption,
cite,
code,
dfn,
em,
th,
strong,
var,
small,
time {
  font-style: normal;
  font-weight: normal;
}

input:-webkit-autofill,
textarea:-webkit-autofill {
  box-shadow: 0 0 0 1000px transparent inset;
}

/*!
    Add ress
    https://github.com/filipelinhares/ress
*/
audio:not([controls]) {
  display: none;
  height: 0;
}

hr {
  overflow: visible;
}

[hidden],
template {
  display: none;
}

abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

input {
  border-radius: 0;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

optgroup {
  font-weight: bold;
}

button {
  overflow: visible;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  border: 0;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

progress {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

/**!
 * Noto Sans CJK JP: https://www.google.com/get/noto/#sans-jpan
 * Noto Sans JP: https://fonts.google.com/earlyaccess#Noto+Sans+JP
 */
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 100;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format("opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format("opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 900;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format("opentype");
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slideInUp_1_2 {
  -webkit-animation: 1.2s 1 both slideInUp;
  -moz-animation: 1.2s 1 both slideInUp;
  animation: 1.2s 1 both slideInUp;
}

.fadeInLeft-0_6 {
  -webkit-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.6s 1 both fadeInLeft;
  -moz-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.6s 1 both fadeInLeft;
  animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.6s 1 both fadeInLeft;
}

.fadeInLeft-0_8 {
  -webkit-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.8s 1 both fadeInLeft;
  -moz-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.8s 1 both fadeInLeft;
  animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 0.8s 1 both fadeInLeft;
}

.fadeInLeft-1_0 {
  -webkit-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 1s 1 both fadeInLeft;
  -moz-animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 1s 1 both fadeInLeft;
  animation: 0.5s cubic-bezier(0.05, 0.74, 0.39, 1) 1s 1 both fadeInLeft;
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-top {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-bottom {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  10% {
    transform: rotateY(0deg);
  }
  15% {
    transform: rotateY(180deg);
  }
  20% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    -moz-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes skew {
  0% {
    transform: skew(0deg, 0deg);
  }
  5% {
    transform: skew(5deg, 4.2deg);
  }
  10% {
    transform: skew(-4deg, -3deg);
  }
  15% {
    transform: skew(3deg, 2.2deg);
  }
  20% {
    transform: skew(-2deg, -1.5deg);
  }
  25% {
    transform: skew(0.9deg, 0.9deg);
  }
  30% {
    transform: skew(-0.6deg, -0.6deg);
  }
  35% {
    transform: skew(0.3deg, 0.3deg);
  }
  40% {
    transform: skew(-0.2deg, -0.2deg);
  }
  45% {
    transform: skew(0.1deg, 0.1deg);
  }
  50% {
    transform: skew(0deg, 0deg);
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoomin {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomout {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes hide {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-top: 14vw;
  }
}

.container {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 769px) {
  .container {
    max-width: 970px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }
}

@media screen and (min-width: 960px) {
  .container {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0 auto;
  }
}

@media screen and (min-width: 769px) {
  .contents {
    min-height: calc(100% - 10rem);
  }
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  .contents {
    min-height: calc(100vh - 10rem);
  }
}

@media screen and (max-width: 768px) {
  .contents {
    padding-top: 19vw;
  }
}

@media screen and (min-width: 769px) {
  .content {
    padding: 1rem 0 2rem 0;
  }
}

@media screen and (max-width: 768px) {
  .content {
    padding: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .has-fixed-bar + .footer {
    margin-bottom: 4.3rem;
  }
}

@media screen and (max-width: 768px) {
  .has-fixed-bar + .footer {
    margin-bottom: 5.8rem;
  }
}

@media screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .breadcrumb .breadcrumb__items {
    padding: 1.4rem 0;
  }
}

@media screen and (max-width: 768px) {
  .breadcrumb .breadcrumb__items {
    padding: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .breadcrumb .breadcrumb__items {
    line-height: 1;
  }
}

@media screen and (min-width: 769px) {
  .breadcrumb .breadcrumb__item {
    position: relative;
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {
  .breadcrumb .breadcrumb__item {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .breadcrumb .breadcrumb__item:not(:last-child) {
    padding-right: 1.4rem;
  }
}

.breadcrumb .breadcrumb__item:not(:last-child):after {
  content: '＞';
  position: absolute;
  top: 0;
  right: 0;
}

.circle {
  position: relative;
  border-radius: 50%;
}

.circle.circle--has-icon {
  line-height: 0;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .circle.circle--has-icon {
    width: 3.1rem;
    max-width: 50px;
    height: 3.1rem;
    max-height: 50px;
  }
}

@media screen and (max-width: 768px) {
  .circle.circle--has-icon {
    width: 3.7rem;
    max-width: 80px;
    max-height: 80px;
    padding: 1rem;
  }
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .circle.circle--has-icon {
    padding: 0;
  }
}

.circle.circle--has-icon img {
  max-width: 30px;
}

.circle.circle--has-icon img + img {
  display: none;
}

.circle.circle--has-icon .circle__icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.circle.circle--has-name {
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  text-align: center;
  background: #3c3c47;
}

@media screen and (min-width: 769px) {
  .circle.circle--has-name {
    font-weight: bold;
  }
}

.circle.circle--has-name .circle__name {
  color: #fff;
}

@media screen and (min-width: 769px) {
  .circle.circle--has-name .circle__name {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .circle.circle--has-name .circle__name {
    font-size: 1.5rem;
  }
}

.circle.circle--has-num {
  position: absolute;
  display: inline-block;
  background: #e60012;
  font-size: .8rem;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .circle.circle--has-num {
    top: -.4rem;
    right: -.7rem;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .circle.circle--has-num {
    top: 0;
    right: -.6rem;
    width: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
  }
}

.circle.circle--has-num.circle--has-num-only {
  position: static;
  margin-left: 1rem;
}

@media screen and (min-width: 769px) {
  .circle.circle--s {
    width: 2rem;
    height: 2rem;
    line-height: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .circle.circle--s {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .circle.circle--s {
    max-width: 60px;
    max-height: 60px;
    line-height: 60px;
  }
}

.circle.circle--s .circle__name {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 0;
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .circle.circle--s .circle__name {
    font-size: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .circle.circle--shadow {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .circle.circle--shadow {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.circle.circle--unread {
  background: #a40000;
  color: #fff;
}

.circle.circle--read {
  background: #656766;
  color: #fff;
}

.circle.circle--reply {
  background: #0e6baf;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .colmun-2-s {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .colmun-2-s > * {
    padding: 0 0.5rem;
  }
  .colmun-2-s > *:nth-child(2n + 1) {
    clear: both;
  }
  .colmun-2-s > *:nth-child(n + 3) {
    padding-top: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .colmun-3-s {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .colmun-3-s > * {
    padding: 0 0.5rem;
  }
  .colmun-3-s > *:nth-child(3n + 1) {
    clear: both;
  }
  .colmun-3-s > *:nth-child(n + 4) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .continuous-item + .continuous-item {
    padding-top: 0.5rem;
  }
}

.more {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .more {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .more {
    padding-top: 2rem;
  }
}

.tag {
  display: inline-block;
  text-align: center;
  font-size: .8rem;
}

@media screen and (max-width: 768px) {
  .tag {
    position: relative;
    top: -.3rem;
    margin-right: .5rem;
    padding: .4rem .6rem;
  }
}

.tag.tag--disabled {
  background: #656766;
  color: #fff;
}

.tag.tag--new {
  background: #a40000;
  color: #fff;
}

.type {
  display: inline-block;
  border: solid 1px;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .type {
    padding: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .type {
    padding: 0rem;
  }
}

@media screen and (min-width: 769px) {
  .type {
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .type {
    width: 4rem;
    height: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .type.text {
    height: auto;
    font-weight: 600;
  }
}

@media screen and (min-width: 769px) {
  .type.type + .type {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .type.type + .type {
    margin-left: 0.5rem;
  }
}

.type .type-img {
  line-height: 0;
}

.type .type-img img {
  width: 100%;
  height: 100%;
}

.type .type-title {
  display: none;
  padding-top: 2px;
  line-height: 100%;
}

@media screen and (min-width: 769px) {
  .type .type-title {
    font-weight: bold;
    font-size: .6rem;
  }
}

@media screen and (max-width: 768px) {
  .type .type-title {
    font-size: .6rem;
  }
}

.pager {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .pager {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 768px) {
  .pager {
    padding: 1rem 0;
  }
}

.pager .pager__item {
  display: inline-block;
  border: solid 1px #dadada;
}

.pager .pager__item img {
  max-width: 1.4rem;
  width: 1.4rem;
}

.pager .pager__item + .pager__item {
  border-left: none;
}

.pager .pager__item.pager__item--activate {
  background: #3c3c47;
  border-color: #3c3c47;
}

.pager .pager__item.pager__item--activate .pager__item-wrap {
  color: #fff;
}

.pager .pager__item-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
}

@media screen and (min-width: 769px) {
  .pager .pager__item-wrap {
    padding: 0.2rem 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .pager .pager__item-wrap {
    padding: 0.5rem 1.2rem;
  }
}

.search-result-num {
  display: none;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .search-result-num {
    padding: 0.5rem 0;
  }
}

@media screen and (max-width: 768px) {
  .search-result-num {
    padding: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .search-result-num {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .search-result-num {
    font-size: 1rem;
  }
}

.search-result-order {
  margin-left: auto;
}

@media screen and (min-width: 769px) {
  .search-result-order {
    padding: 1rem 0 0 0;
  }
}

@media screen and (max-width: 768px) {
  .search-result-order {
    padding: 1.7rem 0 0 0;
  }
}

.search-box .help-block {
  display: none;
}

@media screen and (min-width: 769px) {
  .search-box {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .search-box {
    padding: 0;
  }
}

.search-box .container {
  position: relative;
}

.search-box .container:after {
  content: '';
  position: absolute;
}

@media screen and (min-width: 769px) {
  .search-box .container:after {
    bottom: -3.5rem;
    left: calc(50% - 3rem);
    border-right: 3rem solid transparent;
    border-top: 2.5rem solid #f5f5f5;
    border-left: 3rem solid transparent;
  }
}

@media screen and (max-width: 768px) {
  .search-box .container:after {
    bottom: -2rem;
    left: calc(50% - 3rem);
    border-right: 3rem solid transparent;
    border-top: 2rem solid #f5f5f5;
    border-left: 3rem solid transparent;
  }
}

.search-box .search-box__items .search-box__item {
  border-bottom: solid 1px;
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__item.search-box__item--yield .search-box__item-has-unit .input {
    max-width: 80px;
  }
}

.search-box .search-box__items .label {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .label {
    font-size: .8rem;
  }
}

.search-box .search-box__items .input,
.search-box .search-box__items .select {
  border: solid 1px #d5e6f2;
}

.search-box .search-box__items .input:focus,
.search-box .search-box__items .select:focus {
  background-color: #aecfe6;
}

.search-box .search-box__items .input:hover,
.search-box .search-box__items .select:hover {
  background-color: #aecfe6;
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .input,
  .search-box .search-box__items .select {
    border-radius: 0;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .input {
    background: #fff;
  }
}

.search-box .search-box__items .search-box__item-wrap {
  height: 100%;
}

.search-box .search-box__items .search-box__item-label {
  position: relative;
}

.search-box .search-box__items .search-box__item-label .label {
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__item-inputs {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__item-inputs {
    padding: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__item-has-unit .input {
    max-width: 80%;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__item-has-unit .input {
    max-width: 70%;
    margin-right: .2rem;
  }
}

.search-box .search-box__items .search-box__item-from {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__result .select-search {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__result .select-search .select-arrow {
    display: inline-block;
    background: #fff;
  }
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__price .search-box__item-has-unit .input {
    max-width: 70%;
    margin: 0 .5rem 0 0;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__price .search-box__price-ranges {
    padding-right: .75rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__price .search-box__price-ranges + .search-box__price-text {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__price .search-box__price-ranges.search-box__price-max {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__price .search-box__price-max {
    padding-top: .75rem;
  }
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__prefectures {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .search-box .search-box__items .search-box__prefectures > * {
    padding: 0 1rem;
  }
  .search-box .search-box__items .search-box__prefectures > *:nth-child(3n + 1) {
    clear: both;
  }
  .search-box .search-box__items .search-box__prefectures > *:nth-child(n + 4) {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__prefectures {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .search-box .search-box__items .search-box__prefectures > * {
    padding: 0 0.75rem;
  }
  .search-box .search-box__items .search-box__prefectures > *:nth-child(2n + 1) {
    clear: both;
  }
  .search-box .search-box__items .search-box__prefectures > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__alongs .search-box__along {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__alongs .search-box__along:nth-of-type(2) {
    padding: .75rem .325rem 0 0;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__alongs .search-box__along:nth-of-type(3) {
    padding: .75rem 0 0 .325rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__alongs .search-box__along:nth-of-type(4) {
    padding-top: .75rem;
  }
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__alongs + .search-box__alongs {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__alongs + .search-box__alongs {
    padding-top: 0.2rem;
  }
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__checkboxs > * {
    padding: 0 0.5rem 0 0;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__checkboxs > * {
    padding: 0.2rem;
  }
}

.search-box .search-box__items .search-box__checkboxs .checkbox:not(:checked) + .btn {
  background: #fff;
  color: #0e6baf;
}

.search-box .search-box__items .search-box__checkboxs .checkbox:not(:checked) + .btn--needs-border {
  color: #679636;
}

.search-box .search-box__items .search-box__checkboxs .checkbox:checked + .btn {
  background: #0e6baf;
}

.search-box .search-box__items .search-box__checkboxs .checkbox:checked + .btn--needs-border {
  color: #fff;
  background-color: #679636;
}

.search-box .search-box__items .search-box__checkboxs .btn {
  border-radius: 0;
}

@media screen and (min-width: 769px) {
  .search-box .search-box__items .search-box__checkboxs .btn {
    min-width: 5rem;
  }
}

@media screen and (max-width: 768px) {
  .search-box .search-box__items .search-box__checkboxs .btn {
    font-size: 1rem;
  }
}

.search-box .search-box__items.search-box__items--needs .input,
.search-box .search-box__items.search-box__items--needs .select {
  border-color: #e5f0d9;
}

.search-box .search-box__items.search-box__items--needs .input:focus,
.search-box .search-box__items.search-box__items--needs .select:focus {
  background-color: #cce2b4;
}

.search-box .search-box__items.search-box__items--needs .input:hover,
.search-box .search-box__items.search-box__items--needs .select:hover {
  background-color: #cce2b4;
}

.search-box .more-area {
  text-align: right;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .search-box .more-area {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .search-box .more-area {
    padding: 0 0 0.5rem 0;
  }
}

.search-box .more-area .btn--has-icon-horizontal {
  min-width: 200px;
}

.search-box .more-area .btn--has-icon-horizontal .btn__icon {
  line-height: 0;
}

.search-box .more-area .btn--has-icon-horizontal .btn__icon img {
  max-width: 20px;
}

.search-box .more-area .btn--has-icon-horizontal .btn__icon img + img {
  display: none;
}

.search-box .more-area .btn--has-icon-horizontal .btn__text {
  padding-left: 1rem;
  font-size: .8rem;
}

@media screen and (min-width: 769px) {
  .search-box .submit-area {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .search-box .submit-area {
    padding: 0;
  }
}

.search-fixed-box {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .search-fixed-box {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .search-fixed-box {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .search-fixed-box {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    background: rgba(60, 60, 71, 0.87);
    animation: fade-in-bottom 2s;
  }
}

@media screen and (min-width: 769px) {
  .search-fixed-box .search-fixed-box__items {
    margin-left: 0;
    margin-right: 0;
  }
  .search-fixed-box .search-fixed-box__items > * {
    padding: 0 0;
  }
  .search-fixed-box .search-fixed-box__items > *:nth-child(3n + 1) {
    clear: both;
  }
  .search-fixed-box .search-fixed-box__items > *:nth-child(n + 4) {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .search-fixed-box .search-fixed-box__items {
    margin-left: 0;
    margin-right: 0;
  }
  .search-fixed-box .search-fixed-box__items > * {
    padding: 0 0;
  }
  .search-fixed-box .search-fixed-box__items > *:nth-child(3n + 1) {
    clear: both;
  }
  .search-fixed-box .search-fixed-box__items > *:nth-child(n + 4) {
    padding-top: 0;
  }
}

.search-fixed-box .search-fixed-box__item {
  font-weight: bold;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .search-fixed-box .search-fixed-box__item:not(:last-child) {
    font-size: .8rem;
    width: 29%;
    margin-right: 1%;
  }
}

@media screen and (max-width: 768px) {
  .search-fixed-box .search-fixed-box__item:not(:last-child) {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .search-fixed-box .search-fixed-box__item:first-child {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .search-fixed-box .search-fixed-box__item:last-child {
    width: 40%;
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .search-fixed-box .search-fixed-box__item:last-child {
    width: 100%;
  }
}

.search-fixed-box .search-fixed-box__item.search-fixed-box__item--text {
  align-self: center;
  font-size: 1rem;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .search-fixed-box .search-fixed-box__item.search-fixed-box__item--text {
    width: 100%;
    margin-bottom: .5rem;
  }
}

.search-fixed-box .search-fixed-box__item .btn {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .property-images {
    order: 1;
  }
}

.property-images .property-image__wrap {
  position: relative;
  height: 5rem;
  width: 7rem;
  background: #f5f5f5;
}

.property-images .property-image {
  position: relative;
  overflow: hidden;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .property-images .property-image {
    position: absolute;
  }
}

.property-images .property-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 110%;
}

@media screen and (min-width: 769px) {
  .property-images .property-image {
    height: 5rem;
  }
}

@media screen and (max-width: 768px) {
  .property-images .property-image {
    height: auto;
    width: 100%;
    padding-right: .75rem;
  }
}

.property-images .property-image + .list-search__prop-items .list-search__prop-item:nth-child(2) {
  border-top: none;
}

.property-images .property-image + .list-search__prop-items .list-search__prop-item:nth-child(3) {
  border-top: solid 1px #f5f5f5;
}

.property-images .property-image__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.property-images .property-image__info .property-image__bar {
  background: rgba(14, 107, 175, 0.83);
}

.property-images .property-image__info .property-image__bar img {
  max-width: 47px;
}

.property-images .property-image__info .property-image__icon {
  line-height: 0;
}

.property-images .property-image__info .property-image__icon img {
  position: relative;
  top: -.7rem;
  display: inline-block;
}

@media screen and (min-width: 769px) {
  .property-images .property-image__info .property-image__icon img {
    padding-right: .7rem;
  }
}

@media screen and (max-width: 768px) {
  .property-images .property-image__info .property-image__icon img {
    left: -.7rem;
    width: 2.5rem;
  }
}

.property-images .property-image__info .property-image__text {
  padding-bottom: .7rem;
  color: #fff;
}

.property-images .property-image__info .property-image__btn {
  position: absolute;
  top: -1rem;
  right: 1rem;
}

.property-images .property-image__disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.property-images .property-image__disabled .property-image__disabled-wrap {
  height: 100%;
  background: rgba(101, 103, 102, 0.83);
  color: #fff;
}

@media screen and (max-width: 768px) {
  .property-infos .property-info {
    padding: 1rem 1rem 0 1rem;
  }
}

.property-infos .property-info__text {
  padding-left: 1rem;
}

.property-infos .property-info__text.property-info__text--yield {
  font-size: 1.4rem;
  font-weight: bold;
  color: #0e6baf;
}

@media screen and (min-width: 769px) {
  .property-infos .property-info__address {
    padding-left: 1rem;
  }
}

.property-infos .property-info__address-btn {
  text-align: right;
}

.property-infos.property-infos--vertical .property-info__text {
  padding: .5rem 0 0 1rem;
}

.property-infos.property-infos--vertical .property-info + .property-info {
  padding-top: 1rem;
}

@media screen and (min-width: 769px) {
  .property-infos.property-infos--vertical .property-info.property-info__text--map .property-info__text {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .property-infos.property-infos--vertical .property-info.property-info__text--map .property-info__text {
    padding: 0 0 0 1rem;
  }
}

.price-highlight {
  line-height: 1;
  color: #a40000;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .price-highlight {
    padding-bottom: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .price-highlight {
    padding-bottom: 1.5rem;
  }
}

.price-highlight dd {
  font-size: 1.4rem;
}

@media screen and (min-width: 769px) {
  .list {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .list {
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .list .list__item + .list__item {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list .list__item + .list__item {
    margin-top: .5rem;
  }
}

.list .list__item-number {
  text-align: center;
  background: #0e6baf;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .list .list__item-number {
    padding: 1rem;
    flex-direction: row;
  }
}

.list .list__item-number .list__item-number-wrap {
  height: 100%;
}

.list .list__item-status {
  text-align: center;
  color: #fff;
}

.list .list__item-status .list__item-status-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list .list__item-status .list__item-status-wrap.status--unread {
  background: #a40000;
  color: #fff;
}

.list .list__item-status .list__item-status-wrap.status--read {
  background: #656766;
  color: #fff;
}

.list .list__item-status .list__item-status-wrap.status--reply {
  background: #0e6baf;
  color: #fff;
}

.list .list__item-search {
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .list .list__item-search {
    padding: 1.5rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list .list__item-search {
    padding-top: .5rem;
  }
}

.list.list--basic .list__item.list__item--disabled .list__item-wrap {
  background: rgba(101, 103, 102, 0.5);
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item.list__item--disabled .property-images,
  .list.list--basic .list__item.list__item--disabled .list__item-info,
  .list.list--basic .list__item.list__item--disabled .list__item-title-pdf {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item.list__item--disabled .list__item-title {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item.list__item--disabled .list__item-icons {
    padding-top: 0;
  }
}

.list.list--basic .list__item-wrap {
  background: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-wrap {
    padding: 2.5rem 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-wrap.list__item-wrap--search {
    padding: 0 0 .5rem 0;
  }
}

.list.list--basic .list__item-title {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-title {
    font-size: 1.2rem;
    padding-bottom: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-main {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .list.list--basic .list__item-main > * {
    padding: 0 0.75rem;
  }
  .list.list--basic .list__item-main > *:nth-child(2n + 1) {
    clear: both;
  }
  .list.list--basic .list__item-main > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-number {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .property-images {
    padding: 0;
    order: inherit;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .property-images .property-image__icon img {
    left: -.4rem;
    max-width: 47px;
    width: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .property-images .property-image__text {
    font-weight: bold;
    font-size: .8rem;
  }
}

.list.list--basic .property-images.property__main-image--blank {
  align-self: stretch;
}

.list.list--basic .property-images.property__main-image--blank .property-image__wrap {
  background: #d5e6f2;
}

@media screen and (min-width: 769px) {
  .list.list--basic .property-images.property__main-image--blank .property-image__wrap {
    padding: 3rem 0;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .property-images.property__main-image--blank .property-image__wrap {
    padding: 0;
  }
  .list.list--basic .property-images.property__main-image--blank .property-image__wrap .property-image__text {
    height: fit-content;
    line-height: 1rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
  }
}

.list.list--basic .property-images.property__main-image--blank .property-image__info {
  position: static;
}

.list.list--basic .property-images.property__main-image--blank .property-image__text {
  color: #0e6baf;
}

.list.list--basic .property-images.property__main-image--blank .property-image__bar {
  background: none;
}

@media screen and (min-width: 769px) {
  .list.list--basic .property-images.property__main-image--blank .property-image__icon img {
    top: -.1rem;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .property-images.property__main-image--blank .property-image__icon img {
    top: -.2rem;
    left: 0;
    max-width: 47px;
    width: 2rem;
  }
}

@media screen and (min-width: 769px) {
  .list.list--basic .list__item-info {
    padding: 1rem 0.5rem 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-info {
    padding: 0 0 0 1rem;
  }
}

.list.list--basic .list__item-info .price-highlight {
  padding-bottom: 0;
}

@media screen and (min-width: 769px) {
  .list.list--basic .list__item-info .price-highlight {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-info .price-highlight {
    font-weight: bold;
    font-size: 1.1rem;
  }
}

.list.list--basic .list__item-info .title-box .title-box__text {
  background: #fff;
  font-size: .8rem;
}

@media screen and (min-width: 769px) {
  .list.list--basic .list__item-info .property-infos {
    padding-top: .8rem;
  }
}

.list.list--basic .list__item-info .property-infos .property-info__text {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-info .property-infos .property-info__text {
    font-size: .8rem;
  }
}

.list.list--basic .list__item-info .property-infos .property-info__text.property-info__text--yield {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-main > :nth-child(n + 3) {
    padding: 2rem 0 0;
  }
}

@media screen and (min-width: 769px) {
  .list.list--basic .list__item-icons {
    align-self: stretch;
  }
}

@media screen and (min-width: 769px) {
  .list.list--basic .list__item-icons .property__main-btns,
  .list.list--basic .list__item-icons .property__main-btns-wrap,
  .list.list--basic .list__item-icons .property__main-btn,
  .list.list--basic .list__item-icons .btn {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-icons .property__main-btns-wrap {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .list.list--basic .list__item-icons .property__main-btns-wrap > * {
    padding: 0 0.75rem;
  }
  .list.list--basic .list__item-icons .property__main-btns-wrap > *:nth-child(2n + 1) {
    clear: both;
  }
  .list.list--basic .list__item-icons .property__main-btns-wrap > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .list.list--basic .list__item-icons .property__main-btn {
    border-left: solid 1px #fff;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-icons .property__main-btn .circle.circle--has-icon {
    width: auto;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-icons .property__main-btn .circle.circle--has-num {
    top: -.4rem;
    right: -1rem;
  }
}

.list.list--basic .list__item-icons .property__main-btn .btn {
  display: block;
  height: 100%;
}

@media screen and (min-width: 769px) {
  .list.list--basic .list__item-icons .property__main-btn .btn {
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 768px) {
  .list.list--basic .list__item-icons .property__main-btn .btn {
    width: 100%;
  }
}

.list.list--basic .list__item-icons .property__main-btn .btn .row {
  height: 100%;
}

@media screen and (min-width: 769px) {
  .list.list--basic .list__item-icons .property__main-btn .btn.btn--needs {
    background: none;
  }
}

.list.list--needs .list__item-number {
  text-align: center;
  background: #679636;
  color: #fff;
}

.list.list--needs .property-images.property__main-image--blank .property-image__wrap {
  background: #e5f0d9;
}

.list.list--needs .property-images.property__main-image--blank .property-image__text {
  color: #679636;
}

.list.list--needs .property-infos .property-info__text.property-info__text--yield {
  color: #679636;
}

@media screen and (min-width: 769px) {
  .list-search {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search {
    padding-top: .5rem;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__item + .list-search__item {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__item + .list-search__item {
    padding-top: 1rem;
  }
}

.list-search .list-search__item-wrap {
  position: relative;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__item-wrap {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__item-wrap {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__item-wrap {
    border-top: solid 1px #f5f5f5;
    border-left: solid 1px #f5f5f5;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__item-wrap {
    position: relative;
    border: solid 1px #f5f5f5;
  }
}

.list-search .list-search__item-wrap a.list-search__title {
  position: static;
}

.list-search .list-search__item-wrap button, .list-search .list-search__item-wrap a {
  position: relative;
  z-index: 3;
}

.list-search .list-search__item-mb {
  padding: .5rem;
}

.list-search .list-search__item-mb li {
  padding-bottom: .5rem;
}

.list-search .list-search__item-mb li span {
  text-align: center;
  font-size: .6rem;
  background: #d5e6f2;
  color: #0e6baf;
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 5px;
  min-width: 50px;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .list-search .list-search__item-with__mb__link {
    position: relative;
  }
  .list-search .list-search__item-with__mb__link .list-search__item-mb__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 100;
  }
}

.list-search a.list-search__title {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .list-search a.list-search__title {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search a.list-search__title {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search a.list-search__title {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search a.list-search__title::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
}

.list-search a.list-search__title:hover {
  color: #fff;
}

.list-search a.list-search__title:after {
  position: absolute;
  border: 4px solid;
  box-sizing: border-box;
  z-index: 2;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: 0;
  left: 0;
  content: '';
  transition: .3s;
}

.list-search a.list-search__title:hover:after {
  content: '';
}

.list-search a.list-search__title label {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .list-search a.list-search__title label {
    max-width: 75%;
    line-height: 1.6rem;
    vertical-align: initial;
  }
  .list-search a.list-search__title label img {
    height: inherit;
    line-height: inherit;
    vertical-align: initial;
  }
}

.list-search .list-search__title {
  border-bottom: solid 1px #f5f5f5;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__title {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__title {
    padding: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__title {
    position: initial !important;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__title {
    line-height: 1.2;
  }
}

.list-search .list-search__title a, .list-search .list-search__title label {
  text-decoration: underline;
}

.list-search .list-search__title .tag {
  font-size: .8rem;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__title .tag {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__title .tag {
    margin-right: 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__title .tag {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__title .tag {
    padding: 0.325rem 0.75rem;
  }
}

.list-search .list-search__title .list-search__title-arrow {
  text-align: right;
  align-self: center;
}

.list-search .list-search__title .list-search__title-arrow img {
  max-width: 14px;
}

@media screen and (max-width: 768px) {
  .list-search .list-search__title + .list-search__item-line {
    z-index: 0;
  }
}

.list-search .list-search__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #f5f5f5;
}

.list-search .list-search__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 110%;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__image {
    height: 13.2rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__image {
    height: auto;
    width: 100%;
    min-height: 80px;
  }
}

.list-search .list-search__image + .list-search__prop-items .list-search__prop-item:nth-child(2) {
  border-top: none;
}

.list-search .list-search__image + .list-search__prop-items .list-search__prop-item:nth-child(3) {
  border-top: solid 1px #f5f5f5;
}

.list-search .list-search__item-line {
  position: relative;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__item-line {
    border-bottom: solid 1px #f5f5f5;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__item-line {
    padding: 0;
    font-size: .8rem;
    border-bottom: solid 1px #f5f5f5;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-items-wrap {
    height: 100%;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-item:nth-child(n + 4) {
    border-top: solid 1px #f5f5f5;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-item-wrap {
    height: 100%;
  }
}

.list-search .list-search__prop-items .list-search__prop-titles {
  text-align: center;
  font-size: .9rem;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-titles {
    padding: 0.5rem 0;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__prop-items .list-search__prop-titles {
    padding: 0.5rem 0;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-titles {
    position: relative;
    max-width: 113px;
    min-height: 3rem;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__prop-items .list-search__prop-titles {
    background: #fff;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-titles .list-search__prop-title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-text {
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__prop-items .list-search__prop-text {
    padding: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-text {
    align-self: center;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__prop-items .list-search__prop-text {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.list-search .list-search__prop-items .list-search__prop-text.list-search__prop-text--num {
  color: #a40000;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-text.list-search__prop-text--num {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__prop-items .list-search__prop-text.list-search__prop-text--num {
    font-size: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-map {
    padding-left: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__prop-items .list-search__prop-along {
    padding-top: .5rem;
    font-size: .8rem;
  }
}

.list-search .list-search__date {
  font-size: .8rem;
  color: #b2b2b2;
}

.list-search .list-search__btns {
  /* search__title::afterでタップ領域を広げた時に、内側にくるボタンをz-indexで上にもってくる */
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btns {
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns {
    padding: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns {
    position: relative;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btns .list-search__type {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns .list-search__type {
    width: 100%;
    padding: .75rem 0;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btns .list-search__type .type + .type {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns .list-search__type .type + .type {
    margin-left: 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btns .list-search__btn-items {
    margin-left: auto;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns .list-search__btn-items {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item-wrap {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item-wrap > * {
    padding: 0 0.5rem;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item-wrap > *:nth-child(2n + 1) {
    clear: both;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item-wrap > *:nth-child(n + 3) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item-wrap {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item-wrap > * {
    padding: 0 0rem;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item-wrap > *:nth-child(2n + 1) {
    clear: both;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item-wrap > *:nth-child(n + 3) {
    padding-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-status-wrap {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-status-wrap > * {
    padding: 0 0rem;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-status-wrap > *:nth-child(4n + 1) {
    clear: both;
  }
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-status-wrap > *:nth-child(n + 5) {
    padding-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns .list-search__btn-items .list-search__btn-item {
    flex-basis: 50%;
    max-width: 50%;
    margin-left: auto;
  }
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btns .list-search__btn-items .btn {
    min-width: 13.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns .list-search__btn-items .btn {
    width: 100%;
  }
}

.list-search .list-search__btns .circle.circle--has-icon {
  max-height: 31px;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btns .circle.circle--has-num {
    top: -.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btns .circle.circle--has-num {
    top: -.7rem;
  }
}

.list-search .list-search__btn-mb {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btn-mb {
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btn-mb {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btn-mb {
    position: relative;
  }
}

.list-search .list-search__btn-mb .type {
  width: 2.8rem;
  height: 2.8rem;
  margin: .5rem;
}

@media screen and (min-width: 769px) {
  .list-search .list-search__btn-mb .type + .type {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-search .list-search__btn-mb .type + .type {
    margin-left: 0rem;
  }
}

.list-search .list-search__btn-mb ul {
  justify-content: right;
  justify-content: end;
  padding: .5rem;
}

.list-search .list-search__btn-mb .btn {
  padding: .2rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  border: solid 1px #f5f5f5;
}

.list-search.list-search--property .list-search__title,
.list-search.list-search--property .list-search__prop-titles {
  background: #d5e6f2;
}

.list-search.list-search--property .list-search__prop-titles,
.list-search.list-search--property .type,
.list-search.list-search--property .list-search__prop-mb
.list-search__prop-text.list-search__prop-text--yield {
  color: #0e6baf;
}

.list-search.list-search--property a.list-search__title:hover {
  background: #0e6baf;
}

.list-search.list-search--property a.list-search__title:after {
  border-color: rgba(14, 107, 175, 0);
}

.list-search.list-search--property a.list-search__title:hover:after {
  border-color: #0e6baf;
}

.list-search.list-search--property .type {
  border-color: #0e6baf;
}

.list-search.list-search--property .list-search__btn-item {
  z-index: initial !important;
}

.list-search.list-search--needs .list-search__title,
.list-search.list-search--needs .list-search__prop-titles {
  background: #e5f0d9;
}

@media screen and (max-width: 768px) {
  .list-search.list-search--needs .list-search__prop-items {
    border-bottom: solid 1px #f5f5f5;
  }
  .list-search.list-search--needs .list-search__prop-items .list-search__prop-mb {
    background: #fff;
  }
}

.list-search.list-search--needs .list-search__prop-titles,
.list-search.list-search--needs .type,
.list-search.list-search--needs .list-search__prop-mb
.list-search__prop-text.list-search__prop-text--yield {
  color: #679636;
}

.list-search.list-search--needs a.list-search__title:hover {
  background: #679636;
}

.list-search.list-search--needs a.list-search__title:after {
  border-color: rgba(103, 150, 54, 0);
}

.list-search.list-search--needs a.list-search__title:hover:after {
  border-color: #679636;
}

.list-search.list-search--needs .type {
  border-color: #679636;
}

.list-search.list-search--needs .list-search__item-mb li span {
  background: #e5f0d9;
  color: #679636;
}

.list-condition .list-condition__item-wrap:hover {
  cursor: pointer;
  opacity: .8;
}

.list-condition .list-condition__item .list-condition__item-nums {
  position: relative;
}

.list-condition .list-condition__item .list-condition__item-num {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  transform: translateY(-50%);
}

.list-condition .list-condition__item .list-condition__item-texts {
  background: #fff;
  color: #3c3c47;
  text-align: left;
}

@media screen and (min-width: 769px) {
  .list-condition .list-condition__item .list-condition__item-texts {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-condition .list-condition__item .list-condition__item-texts {
    padding: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .list-condition .list-condition__item.list-condition__item {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-condition .list-condition__item.list-condition__item {
    padding-top: 0.75rem;
  }
}

.list-condition .list-condition__item.condition-modal__item .list-condition__item-texts {
  line-height: 1.5;
}

.dummy {
  display: inline-block;
  max-width: 80%;
}

.dummy img {
  height: 24px;
}

.btn {
  display: inline-block;
  background: #fff;
  border: solid 1px #fff;
  text-align: center;
  min-height: 2.6rem;
}

@media screen and (min-width: 769px) {
  .btn {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .btn {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.btn:hover {
  background-color: #e6e6e6;
}

@media screen and (max-width: 768px) {
  .btn {
    padding: .5rem;
    font-size: 1rem;
    min-width: 80px;
  }
}

@media screen and (min-width: 769px) {
  .btn.btn--basic {
    width: 10rem;
    padding: .5rem;
  }
}

.btn.btn--block {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .btn.btn--mb-horizontal {
    display: block;
    width: 100%;
    padding: .5rem 0;
  }
}

.btn.btn--primary {
  background: #0e6baf;
  border: solid 1px #0d619e;
  color: #fff;
}

.btn.btn--primary:hover {
  background-color: #0a4e80;
}

.btn.btn--hazime {
  background: #CF0E72;
  border: solid 1px #CF0E4C;
  color: #fff;
}

.btn.btn--hazime:hover {
  background-color: #9f0b58;
}

.btn.btn--hazime span {
  border: solid 1px #fff;
  padding: .3rem .6rem;
  margin-left: .7rem;
}

.btn.btn--needs {
  background: #679636;
  border: solid 1px #5e8931;
  color: #fff;
}

.btn.btn--needs:hover {
  background-color: #4d7129;
}

.btn.btn--negative {
  background: #0e6baf;
  border: solid 1px #0d619e;
  color: #fff;
}

.btn.btn--negative:hover {
  background-color: #0a4e80;
}

.btn.btn--gray {
  background: #f5f5f5;
  border: solid 1px #f5f5f5;
  box-shadow: none;
}

.btn.btn--gray:hover {
  background-color: gainsboro;
}

.btn.btn--dark {
  background: #3c3c47;
  border: solid 1px #3b3b46;
  color: #fff;
}

.btn.btn--dark:hover {
  background-color: #25252b;
}

.btn.btn--primary-color {
  color: #0e6baf;
}

.btn.btn--primary-color:hover {
  color: #fff;
  background-color: #0d619e;
}

.btn.btn--needs-color {
  color: #679636;
}

.btn.btn--needs-color:hover {
  color: #fff;
  background-color: #5e8931;
}

.btn.btn--primary-negative {
  color: #a40000;
}

.btn.btn--primary-border {
  border: solid 1px #0e6baf;
}

.btn.btn--needs-border {
  border: solid 1px #679636;
}

.btn.btn--basic-border {
  border: solid 1px #dceaf4;
}

.btn.btn--negative-border {
  border: solid 1px #a40000;
}

.btn.btn--light-border {
  border-top: solid 1px #dceaf4;
  border-left: solid 1px #dceaf4;
}

.btn.btn--disabled {
  background: #f5f5f5;
  box-shadow: none;
  color: #b2b2b2;
}

.btn.btn--has-icon-horizontal {
  width: 100%;
  line-height: 0px;
}

.btn.btn--has-icon-horizontal .btn__icon img {
  max-width: 30px;
}

.btn.btn--has-icon-horizontal .btn__icon img + img {
  display: none;
}

.btn.btn--has-icon-horizontal .btn__text {
  padding-left: .5rem;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .btn.btn--has-icon-vertical .btn__wrap {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .btn.btn--has-icon-vertical .btn__wrap {
    font-size: 1.1rem;
  }
}

.btn.btn--has-icon-vertical .btn__icon {
  line-height: 0;
}

.btn.btn--has-icon-vertical .btn__icon img {
  max-width: 66px;
}

@media screen and (min-width: 769px) {
  .btn.btn--has-icon-vertical .btn__address {
    padding: 1.1rem 0 0;
  }
}

.btn.btn--has-icon-vertical .btn__detail {
  line-height: 1.3;
}

@media screen and (min-width: 769px) {
  .btn.btn--has-icon-vertical .btn__detail {
    padding-top: .8rem;
  }
}

@media screen and (min-width: 769px) {
  .btn.btn--has-icon-menu {
    padding: 1.2rem 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .btn.btn--has-icon-menu {
    padding: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .btn.btn--has-icon-menu {
    width: auto;
    min-height: 10rem;
  }
}

@media screen and (min-width: 960px) {
  .btn.btn--has-icon-menu {
    min-height: inherit;
  }
}

@media screen and (max-width: 768px) {
  .btn.btn--has-icon-menu {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 769px) {
  .btn.btn--has-icon-menu .btn__icon {
    min-height: 3rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .btn.btn--has-icon-menu .btn__icon {
    margin: 1rem 0;
  }
}

.btn.btn--has-icon-menu .btn__icon img {
  max-width: 44px;
}

.btn.btn--has-icon-menu .btn__detail {
  font-weight: normal;
  font-size: .8rem;
}

.btn.btn--icon-only {
  line-height: 0;
  padding: 0;
  background: none;
  box-shadow: none;
}

.btn.btn--icon-only img {
  display: inline-block;
}

.btn.btn--icon-only img + img {
  display: none;
}

.btn.btn--checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .btn.btn--checkbox {
    width: 100%;
  }
}

.btn.btn--map {
  width: auto;
  color: #a40000;
  min-height: 1rem;
}

@media screen and (min-width: 769px) {
  .btn.btn--map {
    padding: .5rem;
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .btn.btn--map {
    padding: .5rem;
    font-size: .6rem;
    min-width: auto;
  }
}

@media screen and (min-width: 769px) {
  .btn.btn--has-num .btn__text {
    padding-left: 2rem;
  }
}

.btn.btn--has-num .btn__num {
  margin-left: auto;
}

@media screen and (min-width: 769px) {
  .btn.btn--more {
    width: 37%;
    padding: .7rem;
  }
}

.btn.btn--activate.circle.circle--has-icon img,
.btn.btn--activate.btn--has-icon-horizontal .btn__icon img, .btn.btn--activate.btn--icon-only img {
  display: none;
}

.btn.btn--activate.circle.circle--has-icon img + img,
.btn.btn--activate.btn--has-icon-horizontal .btn__icon img + img, .btn.btn--activate.btn--icon-only img + img {
  display: inline-block;
}

.btn.btn--activate.btn--favo {
  color: #a40000;
}

.btn.btn--use-area {
  line-height: 1.2;
  color: #0e6baf;
  width: 100px;
  height: 100px;
  background: #000;
  display: block;
}

@media screen and (min-width: 769px) {
  .btn.btn--use-area {
    height: 6rem;
  }
}

@media screen and (max-width: 768px) {
  .btn.btn--use-area {
    height: 7rem;
  }
}

.btn.btn--use-area .btn--use-area__title {
  display: block;
  line-height: 1.5;
  background: #d5e6f2;
  border-radius: 4px;
  color: #0e6baf;
}

@media screen and (min-width: 769px) {
  .btn.btn--use-area .btn--use-area__title {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .btn.btn--use-area .btn--use-area__title {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 769px) {
  .btn.btn--use-area .btn--use-area__title {
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .btn.btn--use-area .btn--use-area__title {
    padding: 0 0.75rem;
  }
}

.btn.btn--use-area .btn--use-area__title:hover {
  background-color: #aecfe6;
}

.btn.btn--use-area.btn--use-area--no-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .btn.btn--use-area.btn--use-area--no-title {
    justify-content: center;
  }
}

.btn.btn--use-area.btn--use-area--2nd .btn--use-area__title {
  color: #679636;
  background: #e5f0d9;
}

.btn-wrap {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input {
  display: inline-block;
  padding: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  transition: all .3s;
  background: #fff;
  border: solid 1px #fff;
}

.input:-ms-input-placeholder {
  color: #aca490;
}

.input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

@media screen and (min-width: 769px) {
  .input.input--three-quarter-sm {
    max-width: 70%;
  }
}

.input.input--half {
  max-width: 50%;
}

@media screen and (min-width: 769px) {
  .input.input--short {
    max-width: 206px;
  }
}

@media screen and (max-width: 768px) {
  .input.input--short {
    max-width: 50%;
  }
}

@media screen and (min-width: 769px) {
  .input.input--num {
    padding: .5rem;
  }
}

.input-search-mask {
  position: relative;
}

@media screen and (min-width: 769px) {
  .input-search-mask img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .input-search-mask img {
    max-width: 23px;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 769px) {
  .input,
  .select,
  .checkbox {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .input,
  .select,
  .checkbox {
    font-size: 1rem;
  }
}

.input:focus,
.select:focus,
.checkbox:focus {
  outline: none;
}

.input:hover,
.select:hover,
.checkbox:hover {
  cursor: pointer;
}

.input:disabled,
.select:disabled,
.checkbox:disabled {
  opacity: .4;
  background: #e5e5e5;
  cursor: not-allowed;
}

.input:disabled + .select-arrow,
.select:disabled + .select-arrow,
.checkbox:disabled + .select-arrow {
  opacity: .4;
}

.input:disabled:not(:checked) + .btn,
.select:disabled:not(:checked) + .btn,
.checkbox:disabled:not(:checked) + .btn {
  opacity: .4;
  background: #e5e5e5;
  cursor: not-allowed;
}

.range {
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  /* WebKit・Blink向け 溝のスタイル */
  /* WebKit・Blink向け つまみのスタイル */
  /* 何故か上の margin-top 指定が Edge に効いてしまうので、Edge向けに設定をリセット */
  /* Firefox向け 溝のスタイル */
  /* Firefox向け つまみのスタイル */
  /* Edge・IE向け 溝のスタイル */
  /* Edge・IE向け 溝の色（つまみより左側） */
  /* Edge・IE向け 溝の色（つまみより右側） */
  /* Edge・IE向け つまみのスタイル */
  /* Edge・IE向け ポップアップを非表示に */
}

.range:focus {
  outline: none;
}

.range::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: .2s;
  border-radius: 1.3px;
  border: 1px solid;
}

@media screen and (min-width: 769px) {
  .range::-webkit-slider-runnable-track {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .range::-webkit-slider-runnable-track {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.range::-webkit-slider-thumb {
  border: none;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
  /* 以下は つまみの縦位置調整 */
  margin-top: -14px;
  /* (つまみの高さ - トラックの高さ) / 2 。つまみの高さは border を含む */
}

@media screen and (min-width: 769px) {
  .range::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .range::-webkit-slider-thumb {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

@supports (-ms-ime-align: auto) {
  .range::-webkit-slider-thumb {
    margin-top: 0 !important;
  }
}

.range::-moz-range-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: .2s;
  border-radius: 1.3px;
  border: .2px solid;
}

@media screen and (min-width: 769px) {
  .range::-moz-range-track {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .range::-moz-range-track {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.range::-moz-range-thumb {
  border: none;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .range::-moz-range-thumb {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .range::-moz-range-thumb {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.range::-ms-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: .2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

.range::-ms-fill-lower {
  border: .2px solid;
  border-radius: 2.6px;
}

@media screen and (min-width: 769px) {
  .range::-ms-fill-lower {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .range::-ms-fill-lower {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.range::-ms-fill-upper {
  border: .2px solid;
  border-radius: 2.6px;
}

@media screen and (min-width: 769px) {
  .range::-ms-fill-upper {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .range::-ms-fill-upper {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.range::-ms-thumb {
  border: none;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .range::-ms-thumb {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .range::-ms-thumb {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.range::-ms-tooltip {
  display: none;
}

.range.range--property {
  /* WebKit・Blink向け 溝のスタイル */
  /* WebKit・Blink向け つまみのスタイル */
  /* WebKit・Blink向け focus時のスタイル */
  /* Firefox向け 溝のスタイル */
  /* Firefox向け つまみのスタイル */
  /* Edge・IE向け 溝の色（つまみより左側） */
  /* Edge・IE向け 溝の色（つまみより右側） */
  /* Edge・IE向け focus時の色（つまみより左側） */
  /* Edge・IE向け focus時の色（つまみより右側） */
}

.range.range--property::-webkit-slider-runnable-track {
  background: #d5e6f2;
  border-color: #d5e6f2;
}

.range.range--property::-webkit-slider-thumb {
  background: #0e6baf;
}

.range.range--property:focus::-webkit-slider-runnable-track {
  background: #d5e6f2;
}

.range.range--property::-moz-range-track {
  background: #d5e6f2;
  border-color: #d5e6f2;
}

.range.range--property::-moz-range-thumb {
  background: #0e6baf;
}

.range.range--property::-ms-fill-lower {
  background: #d5e6f2;
  border-color: #d5e6f2;
}

.range.range--property::-ms-fill-upper {
  background: #fff;
  border-color: #d5e6f2;
}

.range.range--property:focus::-ms-fill-lower {
  background: #d5e6f2;
}

.range.range--property:focus::-ms-fill-upper {
  background: #fff;
}

.range.range--needs {
  /* WebKit・Blink向け 溝のスタイル */
  /* WebKit・Blink向け つまみのスタイル */
  /* WebKit・Blink向け focus時のスタイル */
  /* Firefox向け 溝のスタイル */
  /* Firefox向け つまみのスタイル */
  /* Edge・IE向け 溝の色（つまみより左側） */
  /* Edge・IE向け 溝の色（つまみより右側） */
  /* Edge・IE向け focus時の色（つまみより左側） */
  /* Edge・IE向け focus時の色（つまみより右側） */
}

.range.range--needs::-webkit-slider-runnable-track {
  background: #e5f0d9;
  border-color: #e5f0d9;
}

.range.range--needs::-webkit-slider-thumb {
  background: #679636;
}

.range.range--needs:focus::-webkit-slider-runnable-track {
  background: #e5f0d9;
}

.range.range--needs::-moz-range-track {
  background: #e5f0d9;
  border-color: #e5f0d9;
}

.range.range--needs::-moz-range-thumb {
  background: #679636;
}

.range.range--needs::-ms-fill-lower {
  background: #e5f0d9;
  border-color: #e5f0d9;
}

.range.range--needs::-ms-fill-upper {
  background: #fff;
  border-color: #e5f0d9;
}

.range.range--needs:focus::-ms-fill-lower {
  background: #e5f0d9;
}

.range.range--needs:focus::-ms-fill-upper {
  background: #fff;
}

.arrow-d-box {
  line-height: 0;
}

.select {
  width: 100%;
  line-height: 1;
  padding: .8rem 3.3rem .8rem .8rem;
  background: #fff;
  border-radius: 0;
}

.select.select--no-event {
  pointer-events: none;
}

.select.select--entry {
  background: #fff;
}

.select.select--property-border {
  border: solid 1px #d5e6f2;
}

.select.select--needs-border {
  border: solid 1px #e5f0d9;
}

.select.select--no-click {
  background: #e5e5e5;
  opacity: .4;
}

.select-mask {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-mask .label {
  padding: 0 1rem 0 0;
}

.select-mask .select-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  display: flex;
}

@media screen and (min-width: 769px) {
  .select-mask .select-arrow {
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .select-mask .select-arrow {
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}

.select-mask.select-mask--order {
  width: auto;
}

@media screen and (max-width: 768px) {
  .select-mask.select-mask--order {
    margin: 0 0 .5rem 0;
  }
}

.select-mask.select-mask--order .select {
  width: auto;
  border: solid 1px #3c3c47;
  border-radius: 0;
}

@media screen and (min-width: 769px) {
  .select-mask.select-mask--order .select {
    padding: 0.5rem 4rem 0.5rem 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .select-mask.select-mask--order .select {
    padding: 0.5rem 4rem 0.5rem 0.5rem;
  }
}

.select-mask.select-mask--order .select-arrows {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  text-align: center;
  background: #3c3c47;
  pointer-events: none;
}

@media screen and (min-width: 769px) {
  .select-mask.select-mask--order .select-arrows {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .select-mask.select-mask--order .select-arrows {
    padding: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .select-mask.select-mask--order .select-arrows {
    width: 2.8rem;
    line-height: 0;
  }
}

@media screen and (max-width: 768px) {
  .select-mask.select-mask--order .select-arrows {
    width: 3.3rem;
    line-height: 1.4rem;
  }
}

.select-mask.select-mask--order .select-arrows img {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

@media screen and (min-width: 769px) {
  .select-mask.select-mask--order .select-arrows img {
    max-width: 12px;
    width: 12px;
  }
}

@media screen and (max-width: 768px) {
  .select-mask.select-mask--order .select-arrows img {
    max-width: 28px;
  }
}

.select-mask:hover {
  cursor: pointer;
}

.select-search .select-arrow {
  text-align: center;
  border-radius: 4px;
  line-height: 2.5rem;
  right: 0;
  display: inline-block;
}

@media screen and (min-width: 769px) {
  .select-search .select-arrow {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .select-search .select-arrow {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.select-search .select-arrow img {
  position: relative;
  max-width: 22px;
}

@media screen and (min-width: 769px) {
  .select-search .select-arrow img {
    top: .4rem;
  }
}

@media screen and (max-width: 768px) {
  .select-search .select-arrow img {
    top: .2rem;
  }
}

.select-placeholder.placeholder-selected {
  color: #ACA490;
}

.select-placeholder option:nth-child(1) {
  color: #ACA490;
}

.select-placeholder option:nth-child(n + 2) {
  color: #3c3c47;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.checkbox + .btn {
  border-radius: 4px;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .checkbox + .btn {
    width: auto;
    min-width: 4rem;
    background: #fff;
  }
}

@media screen and (max-width: 768px) {
  .checkbox + .btn {
    color: #3c3c47;
  }
}

.checkbox:checked + .btn {
  color: #fff;
}

@media screen and (min-width: 769px) {
  .checkbox:checked + .btn {
    background: #0e6baf;
  }
}

@media screen and (max-width: 768px) {
  .checkbox:checked + .btn {
    background: #3c3c47;
  }
}

.checkbox:checked + .chckbox-wrap .chckbox-img img {
  display: none;
}

.checkbox:checked + .chckbox-wrap .chckbox-img img + img {
  display: inline-block;
  max-width: 44px;
}

.label.label--basic:after,
.form-group .form-group .form-group__double-input:after {
  content: '';
  position: absolute;
  top: 55%;
  background: url(../images/icon/check_inactive.png) center center/100% auto no-repeat;
  transform: translateY(-50%);
  transition: all .3s;
}

@media screen and (min-width: 769px) {
  .label.label--basic:after,
  .form-group .form-group .form-group__double-input:after {
    width: 23px;
    height: 23px;
  }
}

@media screen and (max-width: 768px) {
  .label.label--basic:after,
  .form-group .form-group .form-group__double-input:after {
    width: 1rem;
    height: 1rem;
  }
}

.label {
  display: inline-block;
}

.label.label--basic {
  position: relative;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .label.label--basic {
    margin-top: .6rem;
    padding: 0 1rem 0 4.3rem;
  }
}

@media screen and (max-width: 768px) {
  .label.label--basic {
    padding: 1rem 1rem 1rem 3rem;
  }
}

@media screen and (min-width: 769px) {
  .label.label--basic:after {
    left: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .label.label--basic:after {
    left: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .label.label--basic.label--basic-no-check {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .label.label--basic.label--basic-no-check {
    padding: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .label.label--basic.label--basic-no-check {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: 0;
    padding: 0;
    text-align: center;
    transform: translateY(-50%);
  }
}

.label.label--basic.label--basic-no-check:after {
  display: none;
}

.label.label--checkbox {
  position: relative;
}

@media screen and (min-width: 769px) {
  .label.label--checkbox {
    padding-top: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .label.label--checkbox {
    width: 100%;
  }
}

.label.label--checkbox .checkbox {
  clip: rect(0px, 0px, 0px, 0px);
  pointer-events: none;
}

.label.label--checkbox .chckbox-img {
  pointer-events: none;
  line-height: 0;
}

.label.label--checkbox .chckbox-img img {
  max-width: 44px;
}

@media screen and (max-width: 768px) {
  .label.label--checkbox .chckbox-img img {
    display: inline-block;
  }
}

.label.label--checkbox .chckbox-img img + img {
  display: none;
}

.label.label--checkbox .checkbox-text {
  display: inline-block;
  width: auto;
  margin-left: 1rem;
}

@media screen and (max-width: 768px) {
  .label.label--checkbox .checkbox-text {
    padding-left: 1rem;
    margin-left: 0;
  }
}

.label.label--checkbox .btn {
  width: 100%;
}

.label-mask {
  position: relative;
}

.label-mask.label-mask--property {
  background: #d5e6f2;
}

.label-mask.label-mask--property .label.label--basic-no-check {
  color: #0e6baf;
}

.label-mask.label-mask--needs {
  background: #e5f0d9;
}

.label-mask.label-mask--needs .label.label--basic-no-check {
  color: #679636;
}

@media screen and (min-width: 769px) {
  .form.form--mypage .form-control + .form-control {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .form.form--mypage .form-control + .form-control {
    padding-top: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .form.form--mypage .label {
    padding-top: 1rem;
  }
}

.form.form--mypage .input {
  border-radius: 4px;
}

@media screen and (min-width: 769px) {
  .form.form--mypage .input {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .form.form--mypage .input {
    padding: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .form.form--mypage .input {
    background: #f5f5f5;
  }
}

@media screen and (max-width: 768px) {
  .form.form--mypage .input {
    background: #fff;
    border: solid 1px #888;
  }
}

.form.form--mypage .form-group {
  background: none;
}

.form-group {
  padding: .4rem .6rem .4rem 0;
  background: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .form-group {
    margin-top: 1rem;
    padding: 0 .4rem .4rem .4rem;
  }
}

@media screen and (min-width: 769px) {
  .form-group + .form-group {
    margin-top: 1rem;
  }
}

.form-group.form-group--checkbox-center {
  background: none;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .form-group.form-group--checkbox-center {
    margin-top: 1.5rem;
    text-align: left;
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .form-group .form-group.form-group--card {
    margin-left: 1rem;
    padding: 1rem .6rem 1rem 0;
  }
}

@media screen and (min-width: 769px) {
  .form-group .form-group.form-group--card .label.label--basic {
    padding-left: 0;
  }
}

@media screen and (min-width: 769px) {
  .form-group .form-group.form-group--card .label.label--basic:after {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .form-group .form-group.form-group--card + .form-group.form-group--card {
    margin-top: 0;
  }
}

.form-group__double-input {
  position: relative;
}

@media screen and (min-width: 769px) {
  .form-group__double-input:after {
    right: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .form-group__double-input:after {
    display: none;
  }
}

.form-has-unit .input,
.form-has-unit .select-mask {
  max-width: 60%;
}

.form-has-unit .form-has-unit__unit {
  display: inline-block;
  padding-left: .5rem;
  font-weight: bold;
}

.form-result {
  background: #dceaf4;
}

@media screen and (min-width: 769px) {
  .form-result {
    padding: 0.6rem 1.2rem 0.6rem 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .form-result {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .form-file {
    padding: 1.5rem 0;
  }
}

.form-file .form-file__dd {
  text-align: center;
  background: #fff;
  border: dashed 3px #0e6baf;
  font-size: .8rem;
}

@media screen and (min-width: 769px) {
  .form-file .form-file__dd {
    padding: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .form-file .form-file__dd {
    padding: 1rem;
  }
}

.form-file .form-file__dd img {
  max-width: 50px;
}

@media screen and (min-width: 769px) {
  .form-file .form-file__dd img {
    margin: 0 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .form-file .form-file__dd img {
    margin: 0 1rem 0.6rem;
  }
}

.form-file .form-file__text .form-file__attention {
  color: #0d619e;
  background: #d5e6f2;
  padding: 1rem;
  font-weight: bold;
  font-size: .8rem;
}

.form-file .form-file__notes {
  padding-top: 1rem;
  font-size: .8rem;
}

.form-file.form-file--msg .form-file__dd {
  background: #f5f5f5;
  border: solid 1px #e7e7e7;
}

@media screen and (min-width: 769px) {
  .form-file-img {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .form-file-img {
    padding: 1.5rem 0 0;
  }
}

.form-file-img img {
  width: auto;
}

@media screen and (max-width: 768px) {
  .form-file-img .btn img {
    max-width: 20px;
  }
}

.form-file-items {
  counter-increment: section;
}

@media screen and (min-width: 769px) {
  .form-file-items {
    padding: 0 0 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .form-file-items {
    padding: 0;
  }
}

.form-file-items .form-file__title {
  background: #d5e6f2;
  font-size: .8rem;
  font-weight: bold;
  padding: .5rem;
  text-align: center;
  border-top: solid 1px #b2b2b2;
  border-left: solid 1px #b2b2b2;
  border-right: solid 1px #b2b2b2;
}

.form-file-items .form-file-item {
  border-top: solid 1px #b2b2b2;
  border-left: solid 1px #b2b2b2;
  border-right: solid 1px #b2b2b2;
  font-size: .8rem;
  position: relative;
}

.form-file-items .form-file-item:last-child {
  border-bottom: solid 1px #b2b2b2;
}

.form-file-items .form-file-item .form-file__nos {
  background: #0e6baf;
  color: #fff;
  padding: .5rem;
  width: 5%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.form-file-items .form-file-item .form-file__nos .form-file__no {
  top: 50%;
  left: 40%;
  position: absolute;
  transform: translateY(-50%);
  align-items: center;
}

.form-file-items .form-file-item .form-file__name {
  padding: .5rem;
  margin-left: 5%;
}

.form-file-items img {
  max-width: 30px;
}

@media screen and (max-width: 768px) {
  .form-file-items .btn img {
    max-width: 20px;
  }
}

@media screen and (min-width: 769px) {
  .form-basic {
    border-bottom: solid 1px;
  }
}

.form-basic.form-basic--property {
  border-bottom-color: #0e6baf;
}

.form-basic.form-basic--property .input {
  border: solid 1px #d5e6f2;
}

.form-basic.form-basic--property .input-box__infomation {
  width: 60%;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 0 .5rem;
  margin-bottom: .5rem;
  color: #0e6baf;
  font-size: .8rem;
}

@media screen and (max-width: 768px) {
  .form-basic.form-basic--property .input-box__infomation {
    width: 100%;
    padding: .5rem;
  }
}

.form-basic.form-basic--property .input-box__infomation .post_no {
  padding-left: .5rem;
}

.form-basic.form-basic--property .input-box__infomation img {
  margin: 20%;
  width: 60%;
  height: 60%;
}

@media screen and (max-width: 768px) {
  .form-basic.form-basic--property .input-box__infomation img {
    margin: 20%;
    width: 60%;
    height: 60%;
  }
}

.form-basic.form-basic--needs {
  border-bottom-color: #679636;
}

.form-basic.form-basic--needs .input {
  border: solid 1px #e5f0d9;
}

.form-basic.form-basic--needs .input-box__infomation {
  width: 60%;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 0 .5rem;
  margin-bottom: .5rem;
  color: #679636;
  font-size: .8rem;
}

@media screen and (max-width: 768px) {
  .form-basic.form-basic--needs .input-box__infomation {
    width: 100%;
    padding: .5rem;
  }
}

.form-basic.form-basic--needs .input-box__infomation .post_no {
  padding-left: .5rem;
}

.form-basic.form-basic--needs .input-box__infomation img {
  margin: 20%;
  width: 60%;
  height: 60%;
}

@media screen and (max-width: 768px) {
  .form-basic.form-basic--needs .input-box__infomation img {
    margin: 20%;
    width: 60%;
    height: 60%;
  }
}

.form-basic.required .label.label--basic::before {
  content: '※';
  position: absolute;
  line-height: 0;
  font-weight: normal;
  font-size: .8rem;
  color: #a40000;
}

@media screen and (min-width: 769px) {
  .form-basic.required .label.label--basic::before {
    top: .6rem;
    right: .6rem;
  }
}

@media screen and (max-width: 768px) {
  .form-basic.required .label.label--basic::before {
    top: 50%;
    right: -1rem;
  }
}

@media screen and (min-width: 769px) {
  .form-basic > .row {
    height: 100%;
  }
}

@media screen and (min-width: 769px) {
  .input-box {
    padding: .6rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .input-box {
    padding: 1rem 0;
  }
}

@media screen and (min-width: 769px) {
  .input-box.input-box--sm-pc-padding-bottom {
    padding: 0 0 1rem;
  }
}

@media screen and (min-width: 769px) {
  .input-box.input-box--sm-pc-padding-bottom .help-block {
    padding: .6rem 1rem;
  }
}

.input-box .input-box__label {
  font-weight: bold;
  color: #0e6baf;
}

@media screen and (max-width: 768px) {
  .input-box .input-box__label {
    padding: 0 0 .75rem 0;
  }
}

@media screen and (max-width: 768px) {
  .input-box .input-box__label.input-box__label--mb-padidng-top {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .input-box .input-box__radio {
    padding-bottom: .5rem;
  }
}

@media screen and (min-width: 769px) {
  .input-box .input-box__items.input-box__items--structure .checkbox + .btn {
    min-width: 9.3rem;
  }
}

@media screen and (max-width: 768px) {
  .input-box .input-box__items.input-box__items--button {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .input-box .input-box__items.input-box__items--button > * {
    padding: 0 0.5rem;
  }
  .input-box .input-box__items.input-box__items--button > *:nth-child(2n + 1) {
    clear: both;
  }
  .input-box .input-box__items.input-box__items--button > *:nth-child(n + 3) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .input-box .input-box__items.input-box__items--button .input-box__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .input-box .input-box__items.input-box__items--button .input-box__item .label {
    width: 100%;
  }
}

.input-box .input-box__items .input-box__item {
  min-width: 110px;
}

@media screen and (min-width: 769px) {
  .input-box .input-box__items .input-box__item {
    padding: 1rem 0 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .input-box .input-box__continuous + .input-box__continuous {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .input-box .input-box__along-wrap + .input-box__along-wrap {
    padding-top: .5rem;
  }
}

@media screen and (max-width: 768px) {
  .input-box .input-box__along-wrap + .input-box__along-wrap {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .input-box .input-box__along {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .input-box .input-box__along + .input-box__along {
    padding-top: 1rem;
  }
}

.input-box .input-box__build-date .form-has-unit .input,
.input-box .input-box__build-date .form-has-unit .select-mask {
  max-width: 80%;
}

@media screen and (max-width: 768px) {
  .input-box .input-box__build-date + .input-box__build-date {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .range-wrap {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .range-wrap {
    padding: 1rem 1rem 2rem;
  }
}

.required-box {
  position: relative;
}

.required-box .required-only {
  position: absolute;
  top: 50%;
  left: 70%;
  z-index: 2;
  display: inline-block;
  font-weight: normal;
  font-size: 1rem;
  color: #a40000;
}

@media screen and (min-width: 769px) {
  .required-box .required-only {
    line-height: 0;
  }
}

@media screen and (max-width: 768px) {
  .required-box .required-only {
    line-height: 1;
    top: 40%;
    left: 80%;
  }
}

@media screen and (min-width: 769px) {
  .required-box .required-only + * {
    margin-left: auto;
    margin-right: 1rem;
    width: 100%;
  }
}

.has-error {
  transition: all .3s;
  background-color: #fde2e2;
  border-radius: 5px;
}

.has-error .input,
.has-error .select,
.has-error .select.select--entry {
  background-color: #fde2e2;
  border: solid 1px #a40000;
  transition: all .3s;
}

.has-error .input:-webkit-autofill,
.has-error .select:-webkit-autofill,
.has-error .select.select--entry:-webkit-autofill {
  box-shadow: 0 0 0 1000px #fde2e2 inset;
}

.has-error.form-basic.form-basic--property .input,
.has-error.form-basic.form-basic--needs .input,
.has-error .select.select--property-border,
.has-error .select.select--needs-border {
  border-color: #a40000;
}

.has-error .label.label--basic:after,
.has-error.form-group .form-group__double-input:after {
  background-image: url(../images/icon/close_error.png);
}

@media screen and (min-width: 769px) {
  .has-error.form-basic {
    background: none;
  }
}

.has-error.form-basic .label-mask {
  background-color: #fde2e2;
}

.has-error.form-basic .label.label--basic.label--basic-no-check {
  color: #a40000;
}

.has-error.form-basic.form-basic--property .has-no-error .input {
  background-color: #fff;
  border-color: #e5f0d9;
}

.has-error.form-basic.form-basic--needs .has-no-error .input {
  background-color: #fff;
  border-color: #e5f0d9;
}

.has-success {
  transition: all .3s;
  background-color: #d5e6f2;
  border-radius: 5px;
}

.has-success .input,
.has-success .select,
.has-success .select.select--entry {
  background-color: #dceaf4;
  border: solid 1px #dceaf4;
  color: #3c3c47;
}

.has-success .input:-webkit-autofill,
.has-success .select:-webkit-autofill,
.has-success .select.select--entry:-webkit-autofill {
  box-shadow: 0 0 0 1000px #dceaf4 inset;
}

.has-success.form-basic.form-basic--property .input,
.has-success.form-basic.form-basic--needs .input,
.has-success .select.select--property-border,
.has-success .select.select--needs-border {
  border-color: #0e6baf;
}

.has-success .label.label--basic:after,
.has-success.form-group .form-group__double-input:after {
  background-image: url(../images/icon/check_active.png);
}

.has-disabled {
  transition: all .3s;
}

.has-disabled .input,
.has-disabled .select,
.has-disabled .select.select--entry {
  background-color: #e5e5e5;
  border: solid 1px #b2b2b2;
  color: #b2b2b2;
}

.has-disabled .input:-webkit-autofill,
.has-disabled .select:-webkit-autofill,
.has-disabled .select.select--entry:-webkit-autofill {
  box-shadow: 0 0 0 1000px #e5e5e5 inset;
}

.has-disabled.form-basic.form-basic--property .input,
.has-disabled.form-basic.form-basic--needs .input {
  border-color: #b2b2b2;
}

.has-disabled .label {
  color: #b2b2b2;
}

.has-disabled .label.label--basic:after,
.has-disabled.form-group .form-group__double-input:after {
  opacity: .6;
}

.help-block {
  display: block;
  padding-top: .4rem;
  font-size: .8rem;
  color: #a40000;
  transition: all .3s;
}

.form-horizontal-block .label.label--checkbox {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .form-horizontal-block .chckbox-img {
    flex-basis: 10%;
    max-width: 10%;
  }
}

@media screen and (max-width: 768px) {
  .form-horizontal-block .checkbox-text {
    flex-basis: 90%;
    max-width: 90%;
  }
}

.form-horizontal-block + .form-horizontal-block {
  padding-top: .5rem;
}

@media screen and (min-width: 769px) {
  .chack-has-input {
    margin-left: 3.8rem;
  }
}

@media screen and (max-width: 768px) {
  .chack-has-input {
    padding-top: .5rem;
  }
}

.submit-area {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .submit-area .btn.btn--basic {
    width: 100%;
    max-width: 20rem;
  }
}

@media screen and (min-width: 769px) {
  .submit-area.submit-area--independent {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .submit-area.submit-area--independent {
    padding: 0 0 1rem 0;
  }
}

@media screen and (min-width: 769px) {
  .submit-area.submit-area--buttons .btn {
    vertical-align: top;
    min-height: 3.2rem;
  }
}

@media screen and (min-width: 769px) {
  .submit-area.submit-area--buttons .btn + .btn {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .submit-area.submit-area--buttons .btn + .btn {
    margin-top: 1.5rem;
  }
}

.pem {
  background: url(../images/pem_bg.png) center top repeat;
}

.pem .pem__close {
  display: none;
}

.pem .pem__wrap .pem__body .pem__content {
  animation: fade-in-bottom .5s;
  background: #fff;
  border: solid 1px #0e6baf;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 769px) {
  .pem .pem__wrap .pem__body .pem__content {
    max-width: 740px;
  }
}

@media screen and (max-width: 768px) {
  .pem .pem__wrap .pem__body .pem__content {
    width: 90%;
  }
}

.pem.pem--img-only .pem__wrap .pem__body .pem__content {
  max-width: 180px;
  background: none;
  border: none;
  box-shadow: none;
}

.pem.pem--guest-modal .guest-modal {
  display: block;
}

@media screen and (max-width: 768px) {
  .pem.pem--terms-modal .pem__wrap .pem__body .pem__content {
    width: 100%;
    height: 100%;
  }
}

.pem.pem--terms-modal .pem__wrap .pem__body .pem__content .pem__close {
  display: inline-block;
  color: #3c3c47;
}

@media screen and (min-width: 769px) {
  .pem.pem--terms-modal .pem__wrap .pem__body .pem__content .pem__close {
    top: 1.5rem;
    right: 1.5rem;
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .pem.pem--terms-modal .pem__wrap .pem__body .pem__content .pem__close {
    top: 2.3rem;
    font-size: 5rem;
    right: 1rem;
  }
}

.pem.pem--terms-modal .terms-modal {
  display: block;
}

.pem.pem--myli-modal .myli-modal {
  display: block;
}

.pem.pem--myli-modal .pem__wrap .pem__body .pem__content {
  background: none;
  border: none;
  box-shadow: none;
}

.pem.pem--myli-modal .pem__close {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #25252b;
  top: -14px !important;
  right: -8px !important;
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .pem.pem--myli-modal .pem__close {
    top: -15px !important;
    right: -10px !important;
  }
}

.pem.pem--myli-modal .pem__close:before {
  content: '';
  position: absolute;
  background-image: url(../images/icon/close.png);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: inline-block;
  background-size: cover;
  transform: scale(0.85);
}

.pem.pem--myli-modal .pem__close > span {
  display: none;
}

.basic-modal {
  background: #fff;
  border: solid 1px #0e6baf;
  border-radius: 4px;
  animation: fade-in-bottom .5s;
}

@media screen and (min-width: 769px) {
  .basic-modal {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .basic-modal {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .basic-modal {
    height: 100%;
  }
}

.basic-modal .modal-close-button {
  position: absolute;
  top: 10px;
  right: 14px;
  width: 26px;
  height: 26px;
  background-color: #25252b;
  border-radius: 25px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .basic-modal .modal-close-button {
    top: 10px;
    right: 10px;
  }
}

.basic-modal .modal-close-button:before {
  content: '';
  position: absolute;
  background-image: url(../images/icon/close.png);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: inline-block;
  background-size: cover;
  transform: scale(0.85);
}

@media screen and (min-width: 769px) {
  .basic-modal .modal-titles {
    padding-bottom: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .basic-modal .modal-titles {
    padding-bottom: 0.75rem;
  }
}

.basic-modal .modal-titles .modal-title-img {
  line-height: 0;
}

.basic-modal .modal-titles .modal-title-img img {
  max-width: 30px;
}

.basic-modal .modal-titles .modal-title {
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .basic-modal .modal-titles .modal-title {
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .basic-modal .modal-titles .modal-title {
    padding-left: 0.75rem;
  }
}

.basic-modal.basic-modal--property {
  background: #d5e6f2;
  border: none;
}

.basic-modal.basic-modal--property .modal-titles {
  color: #0e6baf;
}

.basic-modal.basic-modal--needs {
  background: #e5f0d9;
  border: none;
}

.basic-modal.basic-modal--needs .modal-titles {
  color: #679636;
}

.basic-modal.basic-modal--needs-border {
  border-color: #679636;
}

@media screen and (min-width: 769px) {
  .basic-modal.basic-modal--yesno .modal-titles {
    padding-bottom: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .basic-modal.basic-modal--yesno .modal-titles {
    padding-bottom: 0.75rem;
  }
}

.basic-modal.basic-modal--yesno .modal-titles .modal-title-img {
  line-height: 0;
  margin: 0 auto;
  animation: skew 2s infinite linear;
}

.basic-modal.basic-modal--yesno .modal-titles .modal-title-img img {
  max-width: 180px;
}

.basic-modal.basic-modal--yesno .modal-titles .modal-title {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .basic-modal.basic-modal--yesno .modal-titles .modal-title {
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .basic-modal.basic-modal--yesno .modal-titles .modal-title {
    padding-left: 0.75rem;
  }
}

.basic-modal.basic-modal--yesno .submit-area {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .basic-modal.basic-modal--yesno .submit-area .btn.btn--basic {
    width: 100%;
    max-width: 15rem;
  }
}

@media screen and (max-width: 768px) {
  .basic-modal.basic-modal--yesno .submit-area .btn--light {
    display: none;
  }
}

.myli-modal {
  display: none;
}

@media screen and (min-width: 769px) {
  .myli-modal img {
    max-width: 769px;
  }
}

@media screen and (max-width: 768px) {
  .myli-modal img {
    max-height: 75vh;
  }
}

.terms-modal {
  display: none;
  border-radius: 10px;
}

@media screen and (min-width: 769px) {
  .terms-modal {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .terms-modal {
    padding: 1.5rem;
  }
}

.terms-modal .terms-modal__logo {
  text-align: center;
}

.terms-modal .terms-modal__logo img {
  max-width: 162px;
}

@media screen and (max-width: 768px) {
  .terms-modal .terms-modal__logo img {
    width: 10.4rem;
  }
}

@media screen and (max-width: 768px) {
  .terms-modal .title.title--sub {
    margin-bottom: 0;
    padding-top: .7rem;
    text-align: left;
  }
}

.terms-modal .terms-modal__texts {
  overflow-y: auto;
  text-align: left;
}

.terms-modal .terms-modal__texts h3 {
  font-weight: bold;
  color: #0e6baf;
}

.terms-modal .terms-modal__texts h4 {
  font-weight: bold;
}

.terms-modal .terms-modal__texts p {
  margin: 1rem 0;
}

@media screen and (min-width: 769px) {
  .terms-modal .terms-modal__texts {
    max-height: 50vh;
  }
}

@media screen and (max-width: 768px) {
  .terms-modal .terms-modal__texts {
    max-height: 80vh;
    -webkit-overflow-scrolling: touch;
  }
}

.guest-modal {
  display: none;
}

@media screen and (min-width: 769px) {
  .guest-modal {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .guest-modal {
    padding: 1.5rem;
  }
}

.guest-modal .modal__texts {
  text-align: center;
}

.guest-modal .modal__texts img {
  display: inline-block;
  max-width: 47px;
}

.guest-modal .modal__text {
  padding: 1rem 0 2rem;
}

@media screen and (min-width: 769px) {
  .guest-modal .modal__btns {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .guest-modal .modal__btns > * {
    padding: 0 1rem;
  }
  .guest-modal .modal__btns > *:nth-child(2n + 1) {
    clear: both;
  }
  .guest-modal .modal__btns > *:nth-child(n + 3) {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .guest-modal .modal__btns .modal__btn + .modal__btn {
    padding-top: 1.5rem;
  }
}

.condition-modal {
  border: none;
  padding: 3rem;
  max-width: 60%;
  position: relative;
  animation: fade-in-bottom .5s;
}

@media screen and (max-width: 768px) {
  .condition-modal .modal-close-button + .list-condition {
    margin-top: 1.4rem;
  }
}

@media screen and (min-width: 769px) {
  .condition-modal .list-condition {
    max-height: 40vh;
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .condition-modal .list-condition {
    max-height: 60%;
    padding-bottom: 1.5rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (min-width: 769px) {
  .condition-modal .form-group.form-group--checkbox-center {
    padding: 1.5rem 0;
  }
}

@media screen and (max-width: 768px) {
  .condition-modal .form-group.form-group--checkbox-center {
    padding: 0.75rem 0;
  }
}

.condition-modal.condition-modal--property {
  background: #d5e6f2;
}

.condition-modal.condition-modal--property .condition-modal-titles {
  color: #0e6baf;
}

.condition-modal.condition-modal--property .list-condition
.list-condition__items
.list-condition__item
.list-condition__item-nums {
  background: #0e6baf;
}

.condition-modal.condition-modal--needs {
  background: #e5f0d9;
}

.condition-modal.condition-modal--needs .condition-modal-titles {
  color: #679636;
}

.condition-modal.condition-modal--needs .list-condition
.list-condition__items
.list-condition__item
.list-condition__item-nums {
  background: #679636;
}

@media screen and (max-width: 768px) {
  .condition-modal.condition-modal--save, .condition-modal.condition-modal--load {
    padding: 1rem;
    max-width: 100%;
  }
  .condition-modal.condition-modal--save .list-condition,
  .condition-modal.condition-modal--save .form-group.form-group--checkbox-center, .condition-modal.condition-modal--load .list-condition,
  .condition-modal.condition-modal--load .form-group.form-group--checkbox-center {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .condition-modal.condition-modal--save {
    height: max-content;
    margin-top: -3rem;
  }
}

@media screen and (max-width: 768px) {
  .condition-modal.condition-modal--load .list-condition {
    max-height: 90%;
  }
}

.loading-modal {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 180px;
  height: 180px;
  border-radius: 10px;
  background: #0e6baf;
  text-align: center;
  font-weight: 700;
  animation: fade-in-bottom .5s;
}

.loading-modal .loading-modal-img {
  text-align: center;
  animation: spin 4s infinite linear;
}

.loading-modal .loading-modal-img.push {
  animation: fade-in-bottom 2s infinite linear;
}

.loading-modal.needs {
  background: #679636;
  color: #fff;
}

.loading-modal.red {
  background: #a40000;
  color: #fff;
}

.loading-modal.red .loading-modal-img {
  animation: hvr-pulse 2s infinite linear;
}

.loading-modal.property {
  background: #0e6baf;
  color: #fff;
}

.loading-modal.normal {
  background: #fff;
  color: #3c3c47;
}

.loading-modal.end {
  background: #f5f5f5;
  color: #3c3c47;
}

.loading-modal.end .loading-modal-img {
  animation: skew 2s infinite linear;
}

.loading-modal .loading-modal__items {
  position: relative;
}

.loading-modal .loading-modal__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading-modal img {
  width: auto;
  max-width: 140px;
}

.loader-wrap {
  height: 300px;
  padding: 100px;
  display: block;
  text-align: center;
}

.loader-wrap .loader,
.loader-wrap .loader:after {
  border-radius: 50%;
  display: inline-block;
}

.loader-wrap .loader {
  width: 80px;
  height: 80px;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  border-left: 5px solid #f5f5f5;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

.traffic-modal {
  border: none;
  position: relative;
}

.traffic-modal .modal-title {
  font-size: 1rem;
  font-weight: bold;
}

.traffic-modal .title.title--sub {
  padding: .5rem;
  font-size: 1rem;
  margin: 1rem 0 0 0;
}

@media screen and (max-width: 768px) {
  .traffic-modal .title.title--sub {
    padding-top: 0;
  }
}

@media screen and (min-width: 769px) {
  .traffic-modal .traffic-modal__list {
    max-height: 50vh;
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .traffic-modal .traffic-modal__list {
    max-height: 94%;
    padding-bottom: 1.5rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  .traffic-modal .traffic-modal__list {
    max-height: 90%;
  }
}

.traffic-modal .label {
  width: 100%;
}

.traffic-modal .label .btn {
  text-align: left;
  width: 100%;
  border-radius: 0;
  font-weight: 100;
}

.traffic-modal .submit-area {
  padding-top: 1rem;
}

.traffic-modal .traffic-modal__items {
  background: #fff;
  padding-bottom: .5rem;
}

@media screen and (min-width: 769px) {
  .traffic-modal .traffic-modal__items .traffic-modal__item {
    padding: .5rem 0 0 .5rem;
    min-width: 33%;
  }
}

@media screen and (max-width: 768px) {
  .traffic-modal .traffic-modal__items .traffic-modal__item {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .traffic-modal .traffic-modal__items .traffic-modal__item + .traffic-modal__item {
    padding-top: .75rem;
  }
}

.v--modal-overlay .v--modal-box.v--modal {
  overflow: inherit;
}

.v--modal-overlay .v--modal {
  background: none;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .v--modal-overlay .v--modal {
    max-width: 100%;
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .v--modal-overlay .v--modal.v--modal-has-item {
    left: 0 !important;
    width: 100% !important;
    transform: translateY(0%);
  }
}

@media screen and (min-width: 769px) {
  .v--modal-overlay .v--modal.v--modal-has-list {
    height: auto !important;
    max-height: 50vh;
    width: 900px;
  }
}

@media screen and (max-width: 768px) {
  .v--modal-overlay .v--modal.v--modal-has-list {
    top: 0% !important;
    left: 0 !important;
    height: 90vh !important;
    width: 100% !important;
    transform: translateY(0%);
  }
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  .v--modal-overlay .v--modal.v--modal-has-list {
    height: 90vh !important;
  }
}

.v--modal-overlay .loading-modal,
.v--modal-overlay .img-only-modal,
.v--modal-overlay .basic-modal {
  display: block;
}

@media screen and (min-width: 769px) {
  .v--modal-overlay .basic-modal {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
}

.alertify-notifier .ajs-message {
  font-size: .8rem !important;
  color: #980000 !important;
  background: #fff !important;
  border: solid 1px #980000 !important;
}

.noitem-comment {
  margin: 100px auto;
  text-align: center;
}

.deleted--message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  text-align: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 100%;
  background: url(../images/pem_bg.png) center top repeat;
}

.table {
  width: 100%;
}

.table .tr:nth-child(2n) {
  background: #f5f5f5;
}

.table .th,
.table .td {
  padding: .6rem 1rem;
}

@media screen and (min-width: 769px) {
  .table.table--wide .th {
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .table.table--wide .th {
    width: 40%;
  }
}

@media screen and (min-width: 769px) {
  .table.table--wide .td {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .table.table--wide .td {
    width: 60%;
  }
}

.table.table--half .th {
  width: 35%;
}

.table.table--half .td {
  width: 65%;
}

.title {
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-bottom: .6rem;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-bottom: .7rem;
    font-size: 1.6rem;
  }
}

.title:first-of-type {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .title:first-of-type {
    margin-top: 1rem;
  }
}

.title.title--primary {
  border-bottom: solid 1px #0e6baf;
}

.title.title--needs {
  border-bottom: solid 1px #679636;
}

.title.title--sub {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

@media screen and (min-width: 769px) {
  .title.title--sub {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .title.title--sub {
    padding-top: 1rem;
    text-align: center;
    font-size: 1.4rem;
  }
}

.title.title--sub-border {
  padding-bottom: .5rem;
}

@media screen and (max-width: 768px) {
  .title.title--sub-border {
    padding-bottom: 1rem;
  }
}

.titles {
  position: relative;
}

@media screen and (max-width: 768px) {
  .titles .title {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .titles .title__arrows {
    position: absolute;
    top: 50%;
    right: 0;
    display: inline-block;
    text-decoration: underline;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 768px) {
  .titles .title__arrows {
    display: block;
    padding: 1rem;
    text-align: center;
    font-size: 1.2rem;
    color: #fff;
    background: #0e6baf;
  }
}

.titles .title__arrows .row {
  display: block;
}

.titles .title__arrows img {
  max-width: 6px;
}

@media screen and (min-width: 769px) {
  .titles .title__arrow {
    margin: 0 .5rem 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .titles .title__arrow {
    display: none;
  }
}

.title-lower .title-lower__text {
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .title-lower .title-lower__text {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .title-lower .title-lower__text {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .title-lower .title-lower__text {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .title-lower .title-lower__text.title-lower__text-has-breadcrumb {
    text-align: left;
    font-size: 1.2rem;
  }
}

.title-lower.title-lower--property {
  background: #0e6baf;
  background: linear-gradient(83deg, #0e6baf 0%, #3f426f 100%);
}

.title-lower.title-lower--needs {
  background: #7cb342;
  background: linear-gradient(83deg, #7cb342 0%, #0a7011 100%);
}

.title-lower.title-lower--mypage {
  background: url(../images/title_mypage.jpg) center center/cover no-repeat;
}

.title-list {
  font-weight: bold;
  font-size: 1.3rem;
}

@media screen and (min-width: 769px) {
  .title-list {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .title-list {
    padding-bottom: 1.5rem;
  }
}

.title-box {
  text-align: center;
}

.title-box .title-box__text {
  display: block;
  padding: .5rem 0;
  background: #d5e6f2;
}

.title-icons {
  margin-left: auto;
}

@media screen and (min-width: 769px) {
  .title-icons {
    padding: 1.4rem 0;
  }
}

@media screen and (max-width: 768px) {
  .title-icons {
    padding: 0.5rem 1rem 1rem;
  }
}

.title-icons .title-icon__move {
  max-width: 141px;
}

@media screen and (min-width: 769px) {
  .title-icons .title-icon + .title-icon {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .title-icons .title-icon + .title-icon {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .title-tab {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .title-tab .title-tab__items {
    align-items: center;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .title-tab .title-tab__items .title-tab__item {
    padding: 0;
    font-size: 1rem;
  }
}

.title-tab .title-tab__items .title-tab__item .btn {
  border: none;
  font-weight: bold;
}

.title-tab .title-tab__items .title-tab__item .btn.btn--basic {
  background: none;
  color: #fff;
  box-shadow: none;
}

@media screen and (min-width: 769px) {
  .title-tab .title-tab__items .title-tab__item .btn.btn--basic {
    width: 13rem;
  }
}

@media screen and (max-width: 768px) {
  .title-tab .title-tab__items .title-tab__item .btn.btn--basic {
    width: 100%;
    padding: 1rem 0;
    background: #35455d;
    font-size: 1rem;
    font-weight: bold;
  }
}

.title-tab .title-tab__items .title-tab__item .btn.btn--basic:hover, .title-tab .title-tab__items .title-tab__item .btn.btn--basic.btn--activate {
  background: #fff;
  color: #3c3c47;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .title-tab .title-tab__items .title-tab__item .btn.btn--basic .circle.circle--has-num {
    display: none;
  }
}

.title-mypage .title-mypage__wrap {
  font-size: 1.4rem;
  background: #3c3c47;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .title-mypage .title-mypage__wrap {
    padding: 0.5rem 6.2% 0.5rem 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .title-mypage .title-mypage__wrap {
    padding: 1rem 1.5rem;
  }
}

@media screen and (min-width: 769px) {
  .title-mypage .title-mypage__wrap {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .title-mypage .title-mypage__text {
    font-weight: bold;
  }
}

@media screen and (min-width: 769px) {
  .title-mypage .title-mypage__text-note {
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .title-mypage .title-mypage__text-note {
    padding: 1rem 1rem 0;
  }
}

@media screen and (min-width: 769px) {
  .title-mypage .title-mypage__icon {
    line-height: 0;
    margin-left: auto;
  }
}

.title-mypage .title-mypage__icon img {
  max-width: 30px;
}

.campaign__amazon {
  display: none;
}

@media screen and (min-width: 1400px) {
  .campaign__amazon {
    display: block;
    position: relative;
    z-index: 10;
  }
}

.campaign__amazon .left {
  position: fixed;
  left: 20px;
  top: 150px;
  width: 180px;
}

.campaign__amazon .right {
  position: fixed;
  right: 20px;
  top: 150px;
  width: 180px;
}

.campaign__amazon.inner .left {
  position: absolute;
  left: -220px;
  top: -100px;
  width: 180px;
}

.campaign__amazon.inner .right {
  position: absolute;
  right: -220px;
  top: -100px;
  width: 180px;
}

html {
  height: 100%;
  min-height: 100%;
}

@media screen and (min-width: 769px) {
  html {
    font-size: 8px;
  }
}

@media screen and (min-width: 769px) and (min-width: 769px) {
  html {
    font-size: calc( 8px + 6 * (100vw - 769px) / 191);
  }
}

@media screen and (min-width: 769px) and (min-width: 960px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  html {
    font-size: calc( 12px + 14 * (100vw - 320px) / 448);
  }
}

@media screen and (max-width: 768px) and (min-width: 768px) {
  html {
    font-size: 26px;
  }
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1.5;
  background: #f5f5f5;
  color: #3c3c47;
  font-family: "メイリオ",Meiryo,"ヒラギノ角ゴ ProN W3","HiraKakuProN-W3","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","helvetica","arial",sans-serif;
}

main {
  background: #fff;
}

a {
  color: #000;
  text-decoration: none;
}

a,
button,
.btn {
  transition: all .3s;
  position: relative;
  top: 0;
}

a:hover,
button:hover,
.btn:hover {
  cursor: pointer;
}

a:active,
button:active,
.btn:active {
  top: 1px;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: text-bottom;
}

figure,
picture {
  line-height: 0;
}

picture {
  display: block;
}

figcaption {
  list-style: 1.5;
}

.dsn {
  display: none !important;
}

.clear {
  clear: both;
}

.cf {
  display: block;
}

.cf::after {
  clear: both;
  visibility: hidden;
  display: block;
  height: 0;
  content: '';
}

@media screen and (min-width: 769px) {
  .mb-on {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .mb-off {
    display: none;
  }
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.tal {
  text-align: left;
}

.shingo {
  font-family: a-otf-ud-shin-go-pr6n, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'ヒラギノ角ゴ ProN W3', Meiryo, 'Noto Sans JP', 'Noto Sans Japanese', sans-serif;
}

.col-auto-xs {
  flex-basis: auto;
  max-width: inherit;
  flex-grow: inherit;
}

@media screen and (min-width: 769px) {
  .col-auto-s {
    flex-basis: auto;
    max-width: inherit;
    flex-grow: inherit;
  }
}

@media screen and (min-width: 960px) {
  .col-auto-m {
    flex-basis: auto;
    max-width: inherit;
    flex-grow: inherit;
  }
}

@media screen and (min-width: 1200px) {
  .col-auto-l {
    flex-basis: auto;
    max-width: inherit;
    flex-grow: inherit;
  }
}

[v-cloak] {
  display: none;
}

.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.header {
  background: #fff;
}

@media screen and (min-width: 769px) {
  .header .container {
    max-width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .header .container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .header .header__wrap {
    height: 14vw;
  }
}

.header .header__logos {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .header .header__logos {
    order: 2;
    width: 100%;
    position: absolute;
  }
}

@media screen and (min-width: 769px) {
  .header .header__logos .header__logo {
    padding: 1rem 0 .7rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__logos .header__logo {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .header .header__logos .header__logo img {
    width: 9rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__logo-links {
    display: none;
  }
}

.header .header__logo-links .header__logo-link {
  padding-left: 1rem;
}

@media screen and (min-width: 769px) {
  .header .header__logo-links .header__logo-link {
    padding-top: .4rem;
  }
}

@media screen and (min-width: 769px) {
  .header .header__logo-links .header__logo-link a {
    display: block;
    line-height: 0;
    font-weight: bold;
    color: inherit;
  }
}

@media screen and (min-width: 769px) {
  .header .header__logo-links .header__logo-link img {
    max-width: 27px;
    width: 1.7rem;
  }
}

.header .header__logo-links .header__logo-link:nth-child(1) {
  color: #0e6baf;
}

@media screen and (min-width: 769px) {
  .header .header__logo-links .header__logo-link:nth-child(1) {
    padding-left: 1.7rem;
  }
}

@media screen and (min-width: 960px) {
  .header .header__logo-links .header__logo-link:nth-child(1) {
    padding-left: 1rem;
  }
}

.header .header__logo-links .header__logo-link:nth-child(2) {
  color: #679636;
}

@media screen and (min-width: 769px) {
  .header .header__logo-links .header__logo-link-text {
    display: inline-block;
    padding-left: .4rem;
  }
}

@media screen and (min-width: 769px) {
  .header .header__info {
    margin-right: 3rem;
    padding: 0 1rem;
    font-size: .8rem;
  }
}

@media screen and (min-width: 415px) {
  .header .header__info {
    margin-left: auto;
    padding: 0;
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__info {
    display: none;
  }
}

.header .header__info .header__info-item {
  padding-left: 1rem;
}

@media screen and (max-width: 768px) {
  .header .header__logins {
    order: 3;
    transform: scale(0.65);
    transform-origin: right;
    position: fixed;
    right: 0;
  }
}

.header .header__logins .btn.btn--hazime {
  padding: .7rem;
}

@media screen and (max-width: 768px) {
  .header .header__logins .header__login-items .header__login-item {
    line-height: 0;
  }
}

@media screen and (max-width: 768px) {
  .header .header__logins .header__login-items .header__login-item .btn {
    position: relative;
    top: .1rem;
    left: -.2rem;
    padding: 0;
    display: inline-block;
    line-height: 0;
    background: none;
    border: none;
    box-shadow: none;
    text-align: center;
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__logins .header__login-items .header__login-item .btn img {
    max-width: 143px;
    width: 8rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__users {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__users .header__circles .circle.circle--has-num {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 768px) {
  .header .header__users .header__user-names {
    display: none;
  }
}

.header .header__users .header__user-names .header__user-btn {
  position: relative;
  display: block;
  width: 100%;
}

.header .header__users .header__user-names .header__user-name {
  line-height: 1.3;
  text-align: left;
  font-size: .8rem;
  font-weight: bold;
  color: #0e6baf;
}

@media screen and (min-width: 769px) {
  .header .header__users .header__user-names .header__user-name {
    padding: 0 1.8rem 0 .9rem;
  }
}

.header .header__users .header__user-names img {
  position: absolute;
  top: 50%;
  right: .6rem;
  display: inline-block;
  max-width: 12px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .header .header__login-items .header__login-item {
    line-height: 0;
  }
  .header .header__login-items .header__login-item .btn__text {
    line-height: 31px;
  }
}

@media screen and (min-width: 769px) {
  .header .header__login-items .header__login-item .btn {
    width: 100%;
    max-width: 80px;
    padding: .8rem;
    text-align: center;
    font-size: .8rem;
  }
}

@media screen and (min-width: 769px) {
  .header .header__login-items .header__login-item + .header__login-item {
    padding-left: .4rem;
  }
}

@media screen and (min-width: 769px) {
  .header .header__btns {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header .header__btns {
    order: 1;
    line-height: 0;
    transform: scale(0.65);
  }
}

.header .header__btns .header__btn,
.header .header__btns .header__btn span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}

.header .header__btns .header__btn {
  position: relative;
  width: 2.4rem;
  height: 2rem;
}

.header .header__btns .header__btn .header__btn-item {
  position: absolute;
  left: 0;
  max-width: 100%;
  width: 3rem;
  height: 4px;
  background-color: #012264;
}

.header .header__btns .header__btn .header__btn-item:nth-of-type(1) {
  top: 0;
}

.header .header__btns .header__btn .header__btn-item:nth-of-type(2) {
  top: .9rem;
}

.header .header__btns .header__btn .header__btn-item:nth-of-type(3) {
  bottom: 0;
}

@media screen and (min-width: 769px) {
  .header .header__menu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header .header__menu {
    z-index: 1;
    position: absolute;
    top: 14vw;
    left: -100%;
    width: 100vw;
    height: calc(100vh - 16.4vw);
    padding: 0 1.5rem;
    background: rgba(60, 60, 71, 0.9);
    opacity: 0;
    transition: all .3s;
  }
}

@media screen and (max-width: 768px) {
  .header .header__menu .header__login-items {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding: 1.5rem 0 0;
  }
  .header .header__menu .header__login-items > * {
    padding: 0 0.75rem;
  }
  .header .header__menu .header__login-items > *:nth-child(2n + 1) {
    clear: both;
  }
  .header .header__menu .header__login-items > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__menu .header__menu-items {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__menu .header__menu-items .header__menu-item + .header__menu-item {
    padding-top: .75rem;
  }
}

@media screen and (max-width: 768px) {
  .header .header__menu .header__menu-items .header__menu-item .btn.btn--icon-only {
    padding: .5rem;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .header.header--menu-open .header__menu {
    left: 0;
    opacity: 1;
    bottom: 0;
    overflow-y: auto;
    padding-bottom: 30.4vw;
  }
  .header.header--menu-open .header__menu .header__menu-items {
    height: max-content;
  }
}

.header.header--menu-open .header__btns .header__btn .header__btn-item:nth-of-type(1) {
  transform: translateY(0.8rem) rotate(-315deg);
}

.header.header--menu-open .header__btns .header__btn .header__btn-item:nth-of-type(2) {
  opacity: 0;
}

.header.header--menu-open .header__btns .header__btn .header__btn-item:nth-of-type(3) {
  transform: translateY(-0.9rem) rotate(315deg);
}

.header .header__mypage-menu {
  background: #000;
  padding: 2px 0;
}

.header .header__mypage-menu .container {
  font-size: .8rem;
  text-align: right;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .header .header__mypage-menu .container {
    text-align: center;
  }
}

.footer {
  background: #f5f5f5;
  font-size: .9rem;
}

.footer .footer__wrap {
  padding: 1rem 0;
}

@media screen and (max-width: 768px) {
  .footer .footer__wrap {
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .footer .footer__logo {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer__logo {
    display: none;
  }
}

.footer .footer__logo img {
  max-width: 100px;
}

@media screen and (min-width: 769px) {
  .footer .footer__copy {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer__copy {
    color: #b2b2b2;
    margin: 0 auto;
  }
}

@media screen and (min-width: 769px) {
  .footer .footer__nav {
    margin-left: auto;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer__nav {
    display: none;
  }
}

.footer .footer__nav .footer__nav-items .footer__nav-item {
  padding-left: 1rem;
}

.footer .footer__pagetop {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  line-height: 0;
}

@media screen and (min-width: 769px) {
  .footer .footer__pagetop {
    bottom: -.5rem;
  }
}

.footer .footer__pagetop .container {
  position: relative;
  max-width: 100%;
}

.footer .footer__pagetop a {
  position: absolute;
  display: inline-block;
  opacity: 0;
}

@media screen and (min-width: 769px) {
  .footer .footer__pagetop a {
    top: -7rem;
    right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer__pagetop a {
    bottom: 15px;
    right: 1rem;
    top: initial;
  }
}

.footer .footer__pagetop img {
  max-width: 53px;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .content {
    padding: 2.3rem 0 4.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .content {
    padding: 1.5rem 1.5rem 3.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .title.title--sub {
    padding-top: 2rem;
  }
}

.contents.contents--home .home__campaign {
  background: rgba(60, 60, 71, 0.74);
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__campaign {
    padding: 1.2rem 0;
  }
}

.contents.contents--home .home__campaign .home__campaign-texts {
  color: #fff;
}

.contents.contents--home .home__campaign .home__campaign-texts img {
  max-width: 105px;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__campaign .home__campaign-texts img {
    width: 6rem;
    padding-right: .5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__campaign .home__campaign-text {
    justify-content: center;
    padding: 0 1.5rem;
    font-size: .8rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__campaign .home__campaign-btns {
    margin-left: auto;
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__campaign .home__campaign-btns {
    padding: 1.5rem;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__campaign .btn + .btn {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__campaign .btn + .btn {
    margin-top: 1rem;
  }
}

.contents.contents--home .home__visual {
  position: relative;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual {
    padding: 2.5rem 0 0 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual {
    padding: 2.5rem 0 0 0;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual {
    background: url(../images/home_visual.jpg) center center/cover no-repeat;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual {
    background: url(../images/home_visual_mb.jpg) center center/cover no-repeat;
  }
}

.contents.contents--home .home__visual .slider {
  margin-top: 40px;
  overflow: hidden;
  display: none;
}

.contents.contents--home .home__visual .slider.slick-initialized {
  display: block;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .slider {
    margin-top: 30px;
  }
}

.contents.contents--home .home__visual .slider .dot-class {
  text-align: center;
  background: #0e6baf;
}

.contents.contents--home .home__visual .slider .dot-class li {
  display: inline-block;
  margin: 0 5px;
  text-indent: 0px;
}

.contents.contents--home .home__visual .slider .dot-class li button {
  margin-top: 20px;
  position: relative;
  text-indent: -9999px;
  height: 5px;
  width: 30px;
  background: #013684;
  color: #013684;
  border-radius: 5px;
  font-size: 20px;
}

.contents.contents--home .home__visual .slider .dot-class li.slick-active button {
  background: #fff;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__campaign {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__campaign {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__bana {
    padding: 2rem 1.5rem 1rem;
  }
}

.contents.contents--home .home__visual .home__logo {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__logo {
    padding-bottom: 5.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__logo {
    padding-bottom: 2rem;
  }
}

.contents.contents--home .home__visual .home__logo img {
  max-width: 220px;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__logo {
    display: none;
  }
}

.contents.contents--home .home__visual .home__bana-img {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__bana-img {
    padding-bottom: 2.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__bana-img {
    padding-bottom: 1.1rem;
  }
}

.contents.contents--home .home__visual .home__bana-img img {
  width: 68%;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__bana-img img {
    width: 100%;
  }
}

.contents.contents--home .home__visual .home__bana-img__price {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__bana-img__price {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__bana-img__price {
    padding-bottom: 0rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__bana-img__price {
    padding-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__bana-img__price {
    padding-top: 1rem;
  }
}

.contents.contents--home .home__visual .home__bana-img__price img {
  width: 34.5%;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__bana-img__price img {
    width: 60%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__top .home__top-items {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .contents.contents--home .home__visual .home__top .home__top-items > * {
    padding: 0 1.5rem;
  }
  .contents.contents--home .home__visual .home__top .home__top-items > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--home .home__visual .home__top .home__top-items > *:nth-child(n + 3) {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__top .home__top-items {
    padding: 0 1.5rem;
  }
}

.contents.contents--home .home__visual .home__top .home__top-item:nth-child(1) .home__top-contents {
  background: #0e6baf;
}

.contents.contents--home .home__visual .home__top .home__top-item:nth-child(1) .home__top-arrow {
  background: rgba(14, 107, 175, 0.47);
}

.contents.contents--home .home__visual .home__top .home__top-item:nth-child(1) .home__top-image {
  background: url(../images/home_visual_item_1.jpg) left top/cover no-repeat #0e6baf;
}

.contents.contents--home .home__visual .home__top .home__top-item:nth-child(2) .home__top-contents {
  background: #679636;
}

.contents.contents--home .home__visual .home__top .home__top-item:nth-child(2) .home__top-arrow {
  background: rgba(124, 179, 66, 0.47);
}

.contents.contents--home .home__visual .home__top .home__top-item:nth-child(2) .home__top-image {
  background: url(../images/home_visual_item_2.jpg) left top/cover no-repeat #679636;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__top .home__top-item:nth-child(2) {
    padding-top: .5rem;
  }
}

.contents.contents--home .home__visual .home__top .home__top-item-wrap {
  display: block;
}

.contents.contents--home .home__visual .home__top .home__top-texts {
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__top .home__top-texts {
    padding: .7rem 2.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__top .home__top-texts {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contents.contents--home .home__visual .home__top .home__top-image img {
  opacity: 0;
}

.contents.contents--home .home__visual .home__top .home__top-title {
  font-size: 1.4rem;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__top .home__top-title br {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__top .home__top-text {
    line-height: 1.3;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__top .home__top-text {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__visual .home__top .home__top-arrow {
    padding: .5rem 0;
    line-height: 0;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__visual .home__top .home__top-arrow {
    display: none;
  }
}

.contents.contents--home .home__visual .home__top .home__top-arrow img {
  max-width: 28px;
  width: 1.8rem;
}

.contents.contents--home .home__media {
  background: #f5f5f5;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items {
    margin-right: -16px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items {
    margin-top: 5%;
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items + .title {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items + .title {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items + .title {
    padding-top: 2rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items:not(.home__media-items--has-img) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents.contents--home .home__media .home__media-items:not(.home__media-items--has-img) > * {
    padding: 0 0.5rem;
  }
  .contents.contents--home .home__media .home__media-items:not(.home__media-items--has-img) > *:nth-child(5n + 1) {
    clear: both;
  }
  .contents.contents--home .home__media .home__media-items:not(.home__media-items--has-img) > *:nth-child(n + 6) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items:not(.home__media-items--has-img) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .contents.contents--home .home__media .home__media-items:not(.home__media-items--has-img) > * {
    padding: 0 0.75rem;
  }
  .contents.contents--home .home__media .home__media-items:not(.home__media-items--has-img) > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--home .home__media .home__media-items:not(.home__media-items--has-img) > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items.home__media-items--has-img .home__media-item-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items.home__media-items--has-img .home__media-item-wrap {
    display: flex;
  }
}

.contents.contents--home .home__media .home__media-items.home__media-items--has-img .home__media-item {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items.home__media-items--has-img .home__media-item {
    float: left;
    width: 33.333%;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-basis: auto;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items.home__media-items--has-img .home__media-item + .home__media-item {
    padding-top: 1rem;
  }
}

.contents.contents--home .home__media .home__media-items .btn {
  width: 100%;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items .btn {
    width: 100%;
    padding: .8rem;
  }
}

.contents.contents--home .home__media .home__media-items .home__media-imgs {
  background: #fff;
  position: relative;
}

.contents.contents--home .home__media .home__media-items .home__media-imgs figure {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.contents.contents--home .home__media .home__media-items .home__media-imgs figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 120%;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items .home__media-imgs {
    min-height: 14rem;
  }
  .contents.contents--home .home__media .home__media-items .home__media-imgs figure {
    height: 14rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items .home__media-imgs {
    width: 43%;
    min-height: 4rem;
  }
  .contents.contents--home .home__media .home__media-items .home__media-imgs figure {
    height: 100%;
  }
}

.contents.contents--home .home__media .home__media-items .home__media-item-info {
  background: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-info {
    padding: 0 16px 16px 16px;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-info {
    padding: 0 0 0 1%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-info {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-info {
    width: 70%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-properties {
    min-height: 4.6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-properties {
    font-size: .8rem;
    min-height: 100px;
  }
}

.contents.contents--home .home__media .home__media-items .home__media-item-titles {
  text-align: left;
  width: 100%;
  margin: 0 0 2% 0;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-titles {
    margin: 10px 0;
    height: 80px;
  }
}

.contents.contents--home .home__media .home__media-items .home__media-item-title {
  width: 100%;
  padding: 0;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items .home__media-item-title {
    font-size: 1rem;
  }
}

.contents.contents--home .home__media .home__media-items .home__media-date-item .home__media-category-img {
  text-align: center;
}

.contents.contents--home .home__media .home__media-items .home__media-date-item .home__media-category-img img {
  width: 20px;
  height: 20px;
}

.contents.contents--home .home__media .home__media-items .home__media-date-item .home__media-category-text {
  font-size: .8rem;
  color: #0e6baf;
  font-weight: bold;
}

.contents.contents--home .home__media .home__media-items .home__media-date-item .home__media-date-img {
  text-align: center;
}

.contents.contents--home .home__media .home__media-items .home__media-date-item .home__media-date-img img {
  width: 20px;
  height: 20px;
}

.contents.contents--home .home__media .home__media-items .home__media-date-item .home__media-date-text {
  font-size: .8rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__media .home__media-items .home__media-date-item .home__media-date-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.contents.contents--home .home__matching {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__matching {
    padding: 1.5rem 1.5rem 1rem;
  }
}

.contents.contents--home .home__matching .home__matching_course {
  max-width: 1200px;
  margin: auto;
  background: url(../images/home_top_buy_bg_2.png) center top/100% no-repeat #fff;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__matching .home__matching_course {
    background: none;
  }
}

.contents.contents--home .home__matching .home_matching_desc {
  width: 70%;
  margin: 1rem auto 1rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__matching .home_matching_desc {
    width: 100%;
    font-size: 0.7rem;
  }
}

.contents.contents--home .home__problem {
  margin: 1rem auto -8rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem {
    margin: 0 0 -3rem 0;
    padding: 0rem 1.5rem;
  }
}

.contents.contents--home .home__problem .home__problem-content {
  width: 100%;
  background-color: #d5e6f2;
  border-radius: 1rem;
  padding: 2.5rem 1rem;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-content {
    padding: 1.5rem 1rem;
  }
}

.contents.contents--home .home__problem .home__problem-content h2 {
  width: 100%;
  font-size: 2.3rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-content h2 {
    font-size: 1.5rem;
  }
}

.contents.contents--home .home__problem .home__problem-content .home__problem-lookhol {
  width: 100%;
  font-size: 1.7rem;
  color: #0e6baf;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-content .home__problem-lookhol {
    font-size: 1.1rem;
  }
}

.contents.contents--home .home__problem .home__problem-content__footer {
  width: 97%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-content__footer {
    width: 92%;
  }
}

.contents.contents--home .home__problem .home__problem-items {
  width: 100%;
  margin: 1.5rem 0 2.2rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-items {
    margin: 1rem 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem-item {
    margin: 0.5rem 0;
  }
}

.contents.contents--home .home__problem .home__problem-items .home__problem-item .home__problem-item__text {
  padding: 1.8rem 1.8rem;
  margin: auto 19%;
  font-weight: bold;
  background-color: #fff;
  border-radius: 0.8rem;
  position: relative;
  text-align: left;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem-item .home__problem-item__text {
    font-size: 15px;
  }
}

@media screen and (min-width: 960px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem-item .home__problem-item__text {
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem-item .home__problem-item__text {
    padding: 1rem 1rem;
    font-size: 0.6rem;
  }
}

.contents.contents--home .home__problem .home__problem-items .home__problem-item .home__problem-item__text p {
  font-size: inherit;
}

.contents.contents--home .home__problem .home__problem-items .home__problem-item .home__problem-item__text label {
  display: block;
  text-align: right;
  margin-top: 1rem;
  color: #0e6baf;
  font-size: inherit;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem-item .home__problem-item__text label {
    margin-top: 0.2rem;
  }
}

.contents.contents--home .home__problem .home__problem-items .home__problem-item__image {
  width: 15.8%;
  margin: auto;
}

.contents.contents--home .home__problem .home__problem-items .home__problem_flow_left .home__problem-item__text {
  margin-right: 20%;
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem_flow_left .home__problem-item__text {
    margin-right: 0;
  }
}

.contents.contents--home .home__problem .home__problem-items .home__problem_flow_left .home__problem-item__text:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -1.8rem;
  top: calc(50% - 1rem);
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-right: 1.8rem solid #fff;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem_flow_left .home__problem-item__text:after {
    left: -1rem;
  }
}

.contents.contents--home .home__problem .home__problem-items .home__problem_flow_right .home__problem-item__text {
  margin-left: 15%;
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem_flow_right .home__problem-item__text {
    margin-left: 0;
  }
}

.contents.contents--home .home__problem .home__problem-items .home__problem_flow_right .home__problem-item__text:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: -1.8rem;
  top: calc(50% - 1rem);
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-left: 1.8rem solid #fff;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__problem .home__problem-items .home__problem_flow_right .home__problem-item__text:after {
    right: -1rem;
  }
}

.contents.contents--home .home__good {
  background-color: #374979;
  padding: 7rem 0 3rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good {
    padding: 3rem 0 3rem;
    text-align: center;
  }
}

.contents.contents--home .home__good h4 {
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  word-break: keep-all;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good h4 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }
}

.contents.contents--home .home__good p {
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 1rem;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good p {
    font-size: inherit;
  }
}

.contents.contents--home .home__good h2 {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 2.3rem;
  color: white;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good h2 {
    padding: 0 1.5rem;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good .home__good-action {
    padding: 0 1.5rem;
  }
}

.contents.contents--home .home__good .home__good-items {
  margin: auto;
}

.contents.contents--home .home__good .home__good-items .home__good-item {
  margin: 3.5rem 0;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good .home__good-items .home__good-item {
    margin: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good .home__good-items .home__good-item .home__good-item_image_contain {
    margin: 1rem 0;
  }
}

.contents.contents--home .home__good .home__good-items .home__good-item dt, .contents.contents--home .home__good .home__good-items .home__good-item dd {
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good .home__good-items .home__good-item dd {
    padding: 0 1.5rem;
  }
}

.contents.contents--home .home__good .home__good-items .home__good-item img {
  max-width: 100%;
  width: initial;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good .home__good-items .home__good-item img {
    width: 100%;
  }
}

.contents.contents--home .home__good .home__good-items .home__good-item .home__good-item_image_message {
  margin-left: 0;
}

@media screen and (min-width: 960px) {
  .contents.contents--home .home__good .home__good-items .home__good-item .home__good-item_image_message {
    margin-left: -10rem;
  }
}

.contents.contents--home .home__good .home__good-items .home__good-item .home__good-item_image_message_late {
  margin-left: -5rem;
  margin-right: 5rem;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__good .home__good-items .home__good-item .home__good-item_image_message_late {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good .home__good-items .home__good-item .home__good-item_image_message_late {
    margin: 0;
  }
}

.contents.contents--home .home__good .home__good-items .home__good-item_image_left {
  flex-flow: row;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good .home__good-items .home__good-item_image_left {
    flex-flow: column;
  }
}

.contents.contents--home .home__good .home__good-items .home__good-item_image_left img {
  float: left;
}

.contents.contents--home .home__good .home__good-items .home__good-item_image_right {
  flex-flow: row-reverse;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__good .home__good-items .home__good-item_image_right {
    flex-flow: column;
  }
}

.contents.contents--home .home__good .home__good-items .home__good-item_image_right dd {
  padding-left: 8.3%;
}

.contents.contents--home .home__good .home__good-items .home__good-item_image_right img {
  float: right;
}

.contents.contents--home .home__news {
  background: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__news .home__news-item + .home__news-item {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__news .home__news-item + .home__news-item {
    padding-top: 1.5rem;
  }
}

.contents.contents--home .home__news .home__news-time {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__news .home__news-text {
    padding-top: .75rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__about {
    background: url(../images/home_about_bg.jpg) center center/cover no-repeat;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__about {
    background: url(../images/home_about_bg_mb.jpg) center center/cover no-repeat;
  }
}

.contents.contents--home .home__about .title {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__about .title {
    text-align: center;
  }
}

.contents.contents--home .home__about .home__about-item:nth-child(1) .home__about-title {
  background: #0e6baf;
}

.contents.contents--home .home__about .home__about-item:nth-of-type(2) .home__about-title {
  background: #679636;
}

.contents.contents--home .home__about .home__about-title {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__about .home__about-title {
    padding: .4rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__about .home__about-title {
    padding: .3rem 0;
  }
}

.contents.contents--home .home__about .home__about-contents {
  background: #fff;
}

.contents.contents--home .home__about .home__about-image {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__about .home__about-image {
    padding: 1rem;
  }
}

.contents.contents--home .home__about .home__about-image img {
  max-width: 99px;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__about .home__about-text {
    line-height: 1.3;
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__about .home__about-text {
    padding: .8rem 1rem .8rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__about .home__about-matching {
    align-self: center;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__about .home__about-matching .home__about-matching-img {
    width: 120%;
    position: relative;
    left: -10%;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__about .home__about-matching .home__about-matching-img {
    text-align: center;
  }
}

.contents.contents--home .home__about .home__about-matching img {
  max-width: 291px;
}

.contents.contents--home .home__about .home__about-description {
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__about .home__about-description {
    padding: 3.6rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__about .home__about-description {
    padding: 1.5rem 0;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__about .home__campaign {
    padding: 1.4rem 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__about .home__campaign {
    padding: 1.5rem 0;
  }
}

.contents.contents--home .home__search {
  position: relative;
  background: #ecf3f9;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .title__arrows {
    color: #0e6baf;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .tns-horizontal.tns-subpixel > .tns-item {
    font-size: 1rem !important;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .tns-outer {
    position: relative;
  }
}

.contents.contents--home .home__search .tns-outer [data-controls='prev'],
.contents.contents--home .home__search .tns-outer [data-controls='next'] {
  width: 40px;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .tns-outer [data-controls='prev'],
  .contents.contents--home .home__search .tns-outer [data-controls='next'] {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .tns-outer [data-controls='prev'] {
    left: 0;
  }
}

@media screen and (min-width: 1200px) {
  .contents.contents--home .home__search .tns-outer [data-controls='prev'] {
    left: -3rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .tns-outer [data-controls='next'] {
    right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .contents.contents--home .home__search .tns-outer [data-controls='next'] {
    right: -3rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-items {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-items + .title {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-items + .title {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-items + .title {
    padding-top: 2rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-items:not(.home__search-items--has-img) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents.contents--home .home__search .home__search-items:not(.home__search-items--has-img) > * {
    padding: 0 0.5rem;
  }
  .contents.contents--home .home__search .home__search-items:not(.home__search-items--has-img) > *:nth-child(5n + 1) {
    clear: both;
  }
  .contents.contents--home .home__search .home__search-items:not(.home__search-items--has-img) > *:nth-child(n + 6) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-items:not(.home__search-items--has-img) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .contents.contents--home .home__search .home__search-items:not(.home__search-items--has-img) > * {
    padding: 0 0.75rem;
  }
  .contents.contents--home .home__search .home__search-items:not(.home__search-items--has-img) > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--home .home__search .home__search-items:not(.home__search-items--has-img) > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

.contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item-wrap {
  background: #0e6baf;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item-wrap {
    display: flex;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item + .home__search-item {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item:nth-child(n + 6) {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item > h4.home__search-item-title {
    background-color: #fff;
    padding: .5rem;
    font-size: 1rem;
    border-bottom: 1px solid #e1e1e1;
  }
}

.contents.contents--home .home__search .home__search-items.home__search-items--property .home__search-item .home__search-imgs {
  background: #0e6baf;
}

.contents.contents--home .home__search .home__search-items.home__search-items--property .home__search-item .home__search-img-infos-wrap {
  background: rgba(14, 107, 175, 0.5);
}

.contents.contents--home .home__search .home__search-items.home__search-items--property .home__search-item .home__search-item-yield {
  background: #d5e6f2;
  color: #0e6baf;
}

.contents.contents--home .home__search .home__search-items.home__search-items--property .home__search-item .home__search-item-yield + .home__search-item-num {
  color: #0e6baf;
}

.contents.contents--home .home__search .home__search-items.home__search-items--property .home__search-item .type {
  background: #0e6baf;
}

.contents.contents--home .home__search .home__search-items.home__search-items--needs .home__search-item .home__search-imgs {
  background: #679636;
}

.contents.contents--home .home__search .home__search-items.home__search-items--needs .home__search-item .home__search-img-infos-wrap {
  background: rgba(103, 150, 54, 0.5);
}

.contents.contents--home .home__search .home__search-items.home__search-items--needs .home__search-item .home__search-item-yield {
  background: #e5f0d9;
  color: #679636;
}

.contents.contents--home .home__search .home__search-items.home__search-items--needs .home__search-item .home__search-item-yield + .home__search-item-num {
  color: #679636;
}

.contents.contents--home .home__search .home__search-items.home__search-items--needs .home__search-item .type {
  background: #679636;
}

.contents.contents--home .home__search .home__search-item .btn {
  width: 100%;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .btn {
    width: 100%;
    padding: .8rem;
  }
}

.contents.contents--home .home__search .home__search-item .home__search-imgs {
  position: relative;
}

.contents.contents--home .home__search .home__search-item .home__search-imgs figure {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.contents.contents--home .home__search .home__search-item .home__search-imgs figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 110%;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-imgs {
    min-height: 10.7rem;
  }
  .contents.contents--home .home__search .home__search-item .home__search-imgs figure {
    height: 10.7rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-imgs {
    width: 43%;
    min-height: 4rem;
  }
  .contents.contents--home .home__search .home__search-item .home__search-imgs figure {
    height: 100%;
  }
}

.contents.contents--home .home__search .home__search-item .home__search-img-infos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contents.contents--home .home__search .home__search-item .home__search-img-infos-wrap {
  height: 100%;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-img-infos-wrap {
    padding: .5rem;
  }
}

.contents.contents--home .home__search .home__search-item .home__search-img-info {
  text-align: center;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-img-info {
    font-size: 1.4rem;
  }
}

.contents.contents--home .home__search .home__search-item .home__search-types {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-types {
    height: 3.5rem;
    margin-top: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-types .type {
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-types .type + .type {
    margin-top: 0;
    margin-left: .2rem;
  }
}

.contents.contents--home .home__search .home__search-item .type {
  color: #fff;
  border-color: #fff;
}

.contents.contents--home .home__search .home__search-item .home__search-item-info {
  background: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-info {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-info {
    width: 57%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-info {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-info {
    padding: 0.75rem 0.75rem 0.2rem;
  }
}

.contents.contents--home .home__search .home__search-item .home__search-item-title {
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-properties {
    min-height: 4.6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-properties {
    font-size: .8rem;
  }
}

.contents.contents--home .home__search .home__search-item .home__search-item-nums {
  text-align: center;
}

.contents.contents--home .home__search .home__search-item .home__search-item-num {
  color: #a40000;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-num {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-num {
    font-size: 1.5rem;
    letter-spacing: -2px;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-unit {
    text-align: right;
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-unit {
    font-size: .8rem;
    padding-top: .2rem;
    letter-spacing: -1px;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-yield {
    margin-right: .25rem;
    padding: .25rem;
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-yield {
    margin-right: .25rem;
    padding: .25rem;
    max-width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-item-data .exp + .exp:before {
    content: '/';
  }
}

.contents.contents--home .home__search .home__search-item .home__search-item-data.home__search-item-data--toolong .exp::before {
  display: none;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-address-items {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-address-items {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-address-items {
    font-size: .8rem;
  }
}

.contents.contents--home .home__search .home__search-item .home__search-address-item-wrap {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-traffic-item-wrap .home__search-address-text {
    line-height: 1rem;
    height: 2rem;
    overflow: hidden;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-address-item + .home__search-address-item {
    padding: 0.5rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-address-item + .home__search-address-item {
    padding: 0;
  }
}

.contents.contents--home .home__search .home__search-item .home__search-address-img {
  text-align: center;
}

.contents.contents--home .home__search .home__search-item .home__search-address-img img {
  max-width: 20px;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item .home__search-address-text {
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search .home__search-item .home__search-address-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search .home__search-item:nth-child(n + 6) {
    padding-top: .7rem;
  }
}

.contents.contents--home .home__search + .home__search {
  background: #f5f9f0;
}

@media screen and (min-width: 769px) {
  .contents.contents--home .home__search + .home__search .title__arrows {
    color: #679636;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--home .home__search + .home__search .title__arrows {
    background: #679636;
  }
}

_:-ms-lang(x), .tns-slider {
  display: block !important;
}

_:lang(x)::-ms-backdrop,
.contents.contents--home .home__search .home__search-items.home__search-items--has-img .home__search-item {
  display: inline-flex;
}

_:lang(x)::-ms-backdrop, .home__search-item-info {
  min-height: 100%;
}

.form-group--checkbox-center .label--checkbox .chckbox-wrap {
  justify-content: center;
}

.form-group--checkbox-center .label--checkbox .chckbox-wrap .checkbox-text {
  width: auto;
}

.form-group--checkbox-center .help-block {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .mb-on {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .mb-off {
    display: none;
  }
}

.wrapper {
  padding: 0;
}

@media screen and (min-width: 769px) {
  .pre__header {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .pre__header {
    padding: 0.5rem;
  }
}

.pre__header .header__logo {
  text-align: center;
}

.pre__header .header__logo img {
  max-width: 160px;
}

@media screen and (min-width: 769px) {
  .contents.contents--pre .content {
    padding: 2.3rem 0 4.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .content {
    padding: 1.5rem 1.5rem 3.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .title.title--sub {
    padding-top: 2rem;
  }
}

.contents.contents--pre .title {
  font-weight: bold;
  text-align: center;
}

.contents.contents--pre .title img {
  width: auto;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .title {
    margin: 0;
    padding: 1rem 0;
  }
}

.contents.contents--pre .pre__visual {
  position: relative;
  background: #25a9e7 url(../images/pre/main_bg.png) bottom center no-repeat;
  background-size: cover;
  height: 600px;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents.contents--pre .pre__visual {
    padding: 8.7rem 0 5.2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__visual {
    padding: 3.7rem 0 0.5rem;
  }
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .contents.contents--pre .pre__visual {
    height: 450px;
    padding-top: 2rem;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  .contents.contents--pre .pre__visual {
    height: 300px;
  }
}

.contents.contents--pre .pre__visual .pre__logo {
  position: absolute;
  top: 60px;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.contents.contents--pre .pre__visual .pre__logo img {
  max-width: 750px;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__visual .pre__logo {
    top: 1.4rem;
  }
  .contents.contents--pre .pre__visual .pre__logo img {
    max-width: 80%;
  }
}

.contents.contents--pre .pre__visual .pre__fukidasi {
  z-index: 2;
  position: absolute;
  bottom: -200px;
  width: 700px;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .contents.contents--pre .pre__visual .pre__fukidasi {
    bottom: -180px;
    width: 70%;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  .contents.contents--pre .pre__visual .pre__fukidasi {
    bottom: -80px;
    width: 80%;
  }
}

.contents.contents--pre .pre__visual .pre__fukidasi img {
  width: 130px;
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .contents.contents--pre .pre__visual .pre__fukidasi img {
    width: 100px;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  .contents.contents--pre .pre__visual .pre__fukidasi img {
    width: 80px;
  }
}

.contents.contents--pre .pre__visual .pre__fukidasi .fukidasi1 {
  position: absolute;
  top: -3rem;
  left: -3rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__visual .pre__fukidasi .fukidasi1 {
    left: -1rem;
  }
}

.contents.contents--pre .pre__visual .pre__fukidasi .fukidasi2 {
  position: absolute;
  top: 0rem;
  right: -2rem;
}

.contents.contents--pre .pre__visual .pre__fukidasi .fukidasi3 {
  position: absolute;
  top: 17rem;
  right: -3rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__visual .pre__fukidasi .fukidasi3 {
    top: 7rem;
    right: -1rem;
  }
}

.contents.contents--pre .pre__visual .pre__main {
  position: absolute;
  bottom: -150px;
  z-index: 1;
  margin: 0 auto;
  position: relative;
  width: 800px;
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .contents.contents--pre .pre__visual .pre__main {
    bottom: -150px;
    width: 80%;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  .contents.contents--pre .pre__visual .pre__main {
    bottom: -80px;
    width: 90%;
  }
}

.contents.contents--pre .pre__visual .pre__main img {
  max-width: 80%;
}

.contents.contents--pre .pre__banner {
  margin: 0 auto;
  text-align: center;
  width: 700px;
}

@media screen and (min-width: 769px) {
  .contents.contents--pre .pre__banner {
    padding: 8rem 0 4.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__banner {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__banner {
    width: 100%;
  }
}

.contents.contents--pre .pre__banner a {
  display: block;
  margin-top: 3rem;
}

.contents.contents--pre .pre__banner a img {
  width: 400px;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__banner a img {
    width: 100%;
  }
}

.contents.contents--pre .pre__banner a img:hover {
  animation: hoverShake 0.15s linear 3;
  opacity: 0.8;
}

@media screen and (min-width: 769px) {
  .contents.contents--pre .pre__about {
    padding: 4.1rem 0 4.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__about {
    padding: 1.5rem;
  }
}

.contents.contents--pre .pre__about p {
  text-align: center;
  line-height: 2rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__about p {
    margin: 2rem 0;
    line-height: 1.7rem;
  }
}

.contents.contents--pre .pre__trouble {
  background: #d5e6f2;
  width: 100%;
  position: relative;
}

@media screen and (min-width: 769px) {
  .contents.contents--pre .pre__trouble {
    padding: 4.1rem 0 4.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__trouble {
    padding: 1.5rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__trouble .title {
    width: 80%;
    margin: auto;
  }
}

.contents.contents--pre .pre__trouble:before {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  height: 0;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 59px 350px 0 350px;
  border-color: #d5e6f2 transparent transparent transparent;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__trouble:before {
    bottom: -30px;
    border-width: 30px 80px 0 80px;
  }
}

.contents.contents--pre .pre__trouble .container .pre__comment {
  position: relative;
  text-align: center;
}

.contents.contents--pre .pre__trouble .container .pre__comment img {
  width: 650px;
  margin: 20px;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__trouble .container .pre__comment img {
    width: 90%;
    margin: 10px 0;
  }
}

.contents.contents--pre .pre__trouble .container .pre__comment .human1 {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 120px;
}

.contents.contents--pre .pre__trouble .container .pre__comment .human2 {
  position: absolute;
  top: 8rem;
  right: 0rem;
  width: 120px;
}

.contents.contents--pre .pre__point {
  background: #25a9e7 url(../images/pre/main_bg.png) bottom center no-repeat;
  background-size: cover;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

@media screen and (min-width: 769px) {
  .contents.contents--pre .pre__point {
    padding: 0 0 5.2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__point {
    padding: 1.5rem;
  }
}

.contents.contents--pre .pre__point:before {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 59px 350px 0 350px;
  border-color: #228dc7 transparent transparent transparent;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__point:before {
    bottom: -30px;
    border-width: 30px 80px 0 80px;
  }
}

.contents.contents--pre .pre__point .row {
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__point .row {
    margin-bottom: 2rem;
  }
}

.contents.contents--pre .pre__point .main {
  z-index: 1;
  position: relative;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__point .main {
    margin-bottom: 1rem;
  }
}

.contents.contents--pre .pre__point .main .fukidasi1 {
  z-index: 4;
  position: absolute;
  top: -40px;
  left: 280px;
  width: 120px;
  animation: purupuru 3s ease-in-out 1s infinite normal;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__point .main .fukidasi1 {
    left: inherit;
    right: 20px;
  }
}

.contents.contents--pre .pre__point .items-center-xs {
  margin-left: -1rem;
  margin-right: -1rem;
}

.contents.contents--pre .pre__point .items-center-xs > * {
  padding: 0 1rem;
}

.contents.contents--pre .pre__point .items-center-xs > *:nth-child(3n + 1) {
  clear: both;
}

.contents.contents--pre .pre__point .items-center-xs > *:nth-child(n + 4) {
  padding-top: 1rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--pre .pre__point .items-center-xs .col-100-xs {
    padding: 1rem;
  }
}

@keyframes hoverShake {
  0% {
    transform: skew(0deg, 0deg);
  }
  25% {
    transform: skew(1deg, 1deg);
  }
  75% {
    transform: skew(-1deg, -1deg);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}

@keyframes purupuru {
  0% {
    transform: skew(0deg, 0deg);
  }
  5% {
    transform: skew(1deg, 1deg);
  }
  10% {
    transform: skew(-1deg, -1deg);
  }
  15% {
    transform: skew(0deg, 0deg);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}

@media screen and (max-width: 768px) {
  .pre__nav ul {
    padding-top: 20px;
  }
  .pre__nav li {
    border-top: solid 1px #ecf3f9;
    width: 100%;
    padding: 5px 0;
    text-align: center;
  }
}

.footer__pagetop {
  z-index: 5;
}

@media screen and (min-width: 769px) {
  .contents--entries .content .container {
    max-width: 800px;
  }
}

@media screen and (min-width: 769px) {
  .contents--entries .entry__progress {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--entries .entry__progress {
    padding-bottom: 1.5rem;
  }
}

.contents--entries .entry__progress .entry__progress-item {
  position: relative;
  text-align: center;
  background: #d5e6f2;
}

@media screen and (max-width: 768px) {
  .contents--entries .entry__progress .entry__progress-item:not(:first-child) .entry__progress-item-wrap {
    padding-left: 1.5rem;
  }
}

.contents--entries .entry__progress .entry__progress-item:not(:first-child):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 100%;
  background: url(../images/login_progress_arrow.png) center center/100% 100% no-repeat;
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .contents--entries .entry__progress .entry__progress-item:not(:first-child):after {
    left: -1px;
  }
}

.contents--entries .entry__progress .entry__progress-item.entry__progress-item--now {
  background-color: #093389;
  color: #fff;
}

.contents--entries .entry__progress .entry__progress-item.entry__progress-item--now + .entry__progress-item:after {
  background-image: url(../images/login_progress_arrow_now.png);
}

.contents--entries .entry__progress .entry__progress-item.entry__progress-item--fin {
  background-color: #0e6baf;
  color: #fff;
}

.contents--entries .entry__progress .entry__progress-item.entry__progress-item--fin + .entry__progress-item:after {
  background: url(../images/login_progress_arrow_fin.png) center center/105% 105% no-repeat;
}

.contents--entries .entry__progress .entry__progress-item-wrap {
  display: block;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents--entries .entry__progress .entry__progress-item-wrap {
    padding: 0.8rem 0.8rem 0.8rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--entries .entry__progress .entry__progress-item-wrap {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--entries .entry__progress .entry__progress-item-wrap {
    font-size: .8rem;
  }
}

.contents--login .login__reminder {
  padding: 1rem 0;
  text-align: right;
}

@media screen and (min-width: 769px) {
  .contents--login .login__reminder {
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--login .login__reminder {
    padding: 1rem 0;
  }
}

.contents--login .login__reminder a {
  color: #0e6baf;
  text-decoration: underline;
}

.contents--login .login__sign-up {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents--login .login__sign-up {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--login .login__sign-up {
    padding-top: 1.5rem;
  }
}

.contents--login .login__sign-up a {
  text-decoration: underline;
  font-weight: bold;
  color: #a40000;
}

@media screen and (min-width: 769px) {
  .contents--login .submit-area {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--login .submit-area {
    padding-top: 2rem;
  }
}

.contents--entry .entry-pop-btn {
  text-decoration: underline;
  font-weight: bold;
  color: #0e6baf;
}

.contents--entry .entry__sign-up {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents--entry .entry__sign-up {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--entry .entry__sign-up {
    padding-top: 1.5rem;
  }
}

.contents--entry .entry__sign-up a {
  text-decoration: underline;
  font-weight: bold;
  color: #0e6baf;
}

@media screen and (min-width: 769px) {
  .contents--entry .submit-area {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--entry .submit-area {
    padding-top: 4rem;
  }
}

.contents--entry .complete__img img {
  max-width: 129px;
}

.contents--entry .checkbox {
  width: auto;
}

.contents--signup .signup-card__text {
  padding-top: 1rem;
}

.contents--signup .submit-area {
  padding-top: 2rem;
}

@media screen and (max-width: 768px) {
  .contents--signup .signup__license + .signup__license {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--signup .signup__license-num {
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .contents--signup .signup__license-num .input {
    width: 80px;
  }
}

@media screen and (max-width: 768px) {
  .contents--signup .signup__license-num .input {
    width: 60%;
  }
}

.contents--password .password__text {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents--password .password__text {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--password .password__text {
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--password .submit-area {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--password .submit-area {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--reset .entry-done__title br {
    display: none;
  }
}

.contents--reset .entry-done__img img {
  max-width: 68px;
}

.contents--list .search-box {
  background: #f5f5f5;
}

.contents--list-property .search-box .search-box__items .search-box__item {
  border-bottom-color: #0e6baf;
}

.contents--list-property .search-box .label {
  color: #0e6baf;
}

.contents--list-property .search-box .search-box__item-label {
  background: #d5e6f2;
}

.contents--list-property .search-box .arrow-d-box .arrow-d-box__wrap,
.contents--list-property .search-box .select-search .select-arrow {
  background: #0e6baf;
}

.contents--list-property .search-box .search-box__result .select-search .select-arrow {
  background: #fff;
}

.contents--list-property .search-fixed-box .search-fixed-box__item:last-child .btn {
  background: #228dc7;
}

.contents--list-property .search-static-box {
  padding: 0.25rem;
}

.contents--list-property .search-static-box .search-static-box__item {
  padding: 0.25rem;
}

.contents--list-property .search-static-box .search-static-box__item .btn.btn--needs {
  background: #228dc7;
}

.contents--list-property .traffic-modal {
  background: #d5e6f2;
}

.contents--list-property .traffic-modal .modal-title {
  color: #0e6baf;
}

.contents--list-property .traffic-modal .title.title--sub {
  color: #0e6baf;
}

.contents--list-needs .search-box .search-box__items .search-box__item {
  border-bottom-color: #679636;
}

.contents--list-needs .search-box .label {
  color: #679636;
}

.contents--list-needs .search-box .search-box__item-label {
  background: #e5f0d9;
}

.contents--list-needs .search-box .arrow-d-box .arrow-d-box__wrap,
.contents--list-needs .search-box .select-search .select-arrow {
  background: #679636;
}

.contents--list-needs .search-box .search-box__result .select-search .select-arrow {
  background: #fff;
}

.contents--list-needs .search-fixed-box .search-fixed-box__item:last-child .btn {
  background: #679636;
}

.contents--list-needs .search-static-box {
  padding: 0.25rem;
}

.contents--list-needs .search-static-box .search-static-box__item {
  padding: 0.25rem;
}

.contents--list-needs .search-static-box .search-static-box__item .btn--needs {
  background: #679636;
}

.contents--list-needs .traffic-modal {
  background: #e5f0d9;
}

.contents--list-needs .traffic-modal .modal-title {
  color: #679636;
}

.contents--list-needs .traffic-modal .title.title--sub {
  color: #679636;
}

.contents--list-needs .traffic-modal .checkbox:checked + .btn {
  background: #679636;
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .title.title--sub {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .myt-menu.content {
    padding: 0;
  }
}

.contents--mypage-top .myt-menu .btn {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .myt-menu .btn {
    font-size: 1rem;
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-top .myt-menu .myt-menu__item-mask {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .contents--mypage-top .myt-menu .myt-menu__item-mask > * {
    padding: 0 0.75rem;
  }
  .contents--mypage-top .myt-menu .myt-menu__item-mask > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents--mypage-top .myt-menu .myt-menu__item-mask > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-top .myt-menu .myt-menu__item-mask + .myt-menu__item-mask {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .myt-menu .myt-menu__item-mask + .myt-menu__item-mask {
    padding-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .myt-menu .myt-menu__item-wrap + .myt-menu__item-wrap {
    padding-top: 0rem;
  }
}

.contents--mypage-top .myt-menu .myt-menu__item-col {
  padding: 1.5rem;
  background: #f5f5f5;
}

.contents--mypage-top .myt-menu .myt-menu__item-col .title--sub {
  margin-top: 0;
}

@media screen and (min-width: 769px) {
  .contents--mypage-top .myt-menu .myt-menu__item-col {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .myt-menu .myt-menu__item-col {
    padding: 1rem;
  }
  .contents--mypage-top .myt-menu .myt-menu__item-col h2 {
    margin: 0;
  }
}

.contents--mypage-top .myt-menu .myt-menu__items.myt-menu__items--vertical .myt-menu__item .btn.btn--hazime span {
  padding: 0;
}

@media screen and (min-width: 769px) {
  .contents--mypage-top .myt-menu .myt-menu__items.myt-menu__items--vertical .myt-menu__item + .myt-menu__item {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .myt-menu .myt-menu__items.myt-menu__items--vertical .myt-menu__item + .myt-menu__item {
    padding-top: 0.5rem;
  }
}

.contents--mypage-top .myt-menu .myt-menu__items a {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) > * {
    padding: 0 0.5rem;
  }
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) > *:nth-child(7n + 1) {
    clear: both;
  }
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) > *:nth-child(n + 8) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) > * {
    padding: 0 0.5rem;
  }
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) > *:nth-child(3n + 1) {
    clear: both;
  }
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) > *:nth-child(n + 4) {
    padding-top: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-top .myt-menu .myt-menu__items:not(.myt-menu__items--vertical) .myt-menu__item {
    flex-basis: 20%;
    max-width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-top .myt-menu .myt-menu__item-bottom .myt-menu__item {
    padding: 5px;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-menu .title-tab__item .btn .btn__wrap {
    min-height: 2rem;
  }
}

.contents--mypage-menu .title-tab__item .btn:active,
.contents--mypage-menu .title-tab__item .btn:hover {
  top: 0;
}

@media screen and (max-width: 768px) {
  .contents--mypage-list .title-tab__items {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .contents--mypage-list .title-tab__items > * {
    padding: 0 0rem;
  }
  .contents--mypage-list .title-tab__items > *:nth-child(4n + 1) {
    clear: both;
  }
  .contents--mypage-list .title-tab__items > *:nth-child(n + 5) {
    padding-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-list .title-tab__item {
    flex-basis: 50%;
    max-width: 25%;
    font-size: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-list .title-lower .title-lower__text {
    font-size: 1.3rem;
    padding: 1.5rem 0 1rem 1rem;
  }
}

.contents--mypage-list .content {
  padding: .5rem;
}

.contents--mypage-list .pager {
  padding-top: 0;
}

.contents--mypage-list .list-search__items {
  padding-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .contents--mypage-list .list-search,
  .contents--mypage-list .list__item {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.contents--mypage-list .myli__add {
  margin-bottom: 1rem;
}

.contents--mypage-list .myli__add .btn {
  padding: .5rem;
}

@media screen and (max-width: 768px) {
  .contents--mypage-list .myli__add {
    padding: .5rem;
  }
}

.contents--mypage-list .myli__add.mb-off {
  margin-bottom: 2rem;
}

@media screen and (min-width: 769px) {
  .contents--mypage-sell .select-mask {
    margin: 1rem 0 1rem 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-buy .title-tab .title-tab__items .title-tab__item:last-child .btn.btn--basic {
    width: 16rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-buy .list {
    padding-top: 1rem;
  }
}

.contents--mypage-buy-menu .title-tab .title-tab__items .title-tab__item {
  max-width: 50%;
}

.contents--mypage-sell-menu .title-tab .title-tab__items .title-tab__item {
  max-width: 50%;
}

@media screen and (min-width: 769px) {
  .contents--mypage-buy-needs .list .list__item .list__item-main {
    min-height: 7rem;
  }
}

.contents--mypage-buy-search .title-box .title-box__text {
  background: #fcfcfc;
}

@media screen and (max-width: 768px) {
  .contents--mypage-buy-search .property-info__text-area {
    padding-top: .2rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-buy-search .price-highlight {
    padding: .4rem 0 0;
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-buy-search .list.list--basic .list__item-main {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-buy-search .list.list--basic .list__item:not(.list__item--disabled)
.list__item-main
.list__item-icons {
    padding: .75rem .75rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .title-mypage .title-mypage__wrap {
    background: none;
    color: #3c3c47;
  }
}

.contents--mypage-message-list .title-icons {
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .title-icons {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .title-lower .title-lower__text {
    font-size: 1.3rem;
    padding: 1rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .title-tab__items {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .contents--mypage-message-list .title-tab__items > * {
    padding: 0 0rem;
  }
  .contents--mypage-message-list .title-tab__items > *:nth-child(3n + 1) {
    clear: both;
  }
  .contents--mypage-message-list .title-tab__items > *:nth-child(n + 4) {
    padding-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .title-tab__item {
    flex-basis: 50%;
    max-width: 33.3333%;
  }
}

.contents--mypage-message-list .search-box .help-block {
  display: none;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .search-box {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .search-box {
    padding: 0;
  }
}

.contents--mypage-message-list .search-box .container {
  position: relative;
}

.contents--mypage-message-list .search-box .container:after {
  content: '';
  position: absolute;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .search-box .container:after {
    bottom: -3.5rem;
    left: calc(50% - 3rem);
    border-right: 3rem solid transparent;
    border-top: 2.5rem solid #fff;
    border-left: 3rem solid transparent;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .search-box .container:after {
    bottom: -2rem;
    left: calc(50% - 3rem);
    border-right: 3rem solid transparent;
    border-top: 2rem solid #fff;
    border-left: 3rem solid transparent;
  }
}

.contents--mypage-message-list .myml-searches {
  position: relative;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-searches {
    background: #f5f5f5;
  }
}

.contents--mypage-message-list .myml-searches:after {
  content: '';
  position: absolute;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .myml-searches:after {
    bottom: -1rem;
    left: calc(50% - 1.5rem);
    border-right: 1.5rem solid transparent;
    border-top: 1rem solid #fff;
    border-left: 1.5rem solid transparent;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-searches:after {
    bottom: -2rem;
    left: calc(50% - 3rem);
    border-right: 3rem solid transparent;
    border-top: 2rem solid #f5f5f5;
    border-left: 3rem solid transparent;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .myml-search {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search {
    padding: 1rem 1.5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .myml-search .myml-search__inputs {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item .myml-search__check-item-wrap {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item .myml-search__check-item-wrap > * {
    padding: 0 0.75rem;
  }
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item .myml-search__check-item-wrap > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item .myml-search__check-item-wrap > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item .myml-search__check-label {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item .myml-search__check-label + .myml-search__check-label {
  padding-left: .5rem;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item .label {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item .btn.btn--icon-only {
    padding-left: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item + .myml-search__check-item {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .myml-search__check-items .myml-search__check-item + .myml-search__check-item {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .myml-search .myml-search__items {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents--mypage-message-list .myml-search .myml-search__items > * {
    padding: 0 0.5rem;
  }
  .contents--mypage-message-list .myml-search .myml-search__items > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents--mypage-message-list .myml-search .myml-search__items > *:nth-child(n + 3) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .myml-search__items {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding-bottom: 1.5rem;
  }
  .contents--mypage-message-list .myml-search .myml-search__items > * {
    padding: 0 0.75rem;
  }
  .contents--mypage-message-list .myml-search .myml-search__items > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents--mypage-message-list .myml-search .myml-search__items > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

.contents--mypage-message-list .myml-search .myml-search__items .myml-search__item {
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .myml-search__items .myml-search__item .btn {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .myml-search .input-search-mask {
    padding-top: .8rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .input-search-mask {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .myml-search .input-search-mask .input {
    max-width: 630px;
    background: #f5f5f5;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .input-search-mask .input {
    background: #fff;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .submit-area.submit-area--independent {
    padding: 2rem 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-search .submit-area.submit-area--independent .btn {
    background: #3c3c47;
    color: #fff;
  }
}

.contents--mypage-message-list .myml-content {
  background: #f5f5f5;
}

.contents--mypage-message-list .myml-content .container {
  padding-top: 1rem;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-content .myml__icons {
    padding: 0 1.5rem;
  }
}

.contents--mypage-message-list .myml-content .myml__icon-items .myml__icon-item__text {
  padding-left: 1rem;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .myml-content .list .list__item .property-images .property-image__wrap .property-image {
    height: 100%;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list {
    padding-top: 1rem;
    min-height: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list {
    padding-top: 0rem;
  }
}

.contents--mypage-message-list .list .list__item .list__item-wrap {
  position: relative;
  background: #fff;
  transition: all .3s;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item .list__item-wrap {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item .list__item-wrap {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item .list__item-wrap {
    padding: 0;
  }
}

.contents--mypage-message-list .list .list__item .list__item-wrap:hover {
  background-color: #fefefe;
}

.contents--mypage-message-list .list .list__item a {
  border: solid 1px #d5e6f2;
}

.contents--mypage-message-list .list .list__item a:hover {
  border: solid 1px #0e6baf;
  box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
}

.contents--mypage-message-list .list .list__item.list--needs a {
  border: solid 1px #e5f0d9;
}

.contents--mypage-message-list .list .list__item.list--needs a:hover {
  border: solid 1px #679636;
}

.contents--mypage-message-list .list .list__item.list--needs .list__item-wrap:hover {
  background-color: #fefefe;
}

.contents--mypage-message-list .list .list__item.list--needs .list__item-mail-status {
  background: #e5f0d9;
}

.contents--mypage-message-list .list .list__item.list--needs .property-images.property__main-image--blank
.property-image__wrap {
  background: #e5f0d9;
}

.contents--mypage-message-list .list .list__item.list--needs .property-images.property__main-image--blank
.property-image__wrap .property-image__text {
  color: #679636;
}

.contents--mypage-message-list .list .list__item.list--needs .list__item-time {
  font-size: .8rem;
}

.contents--mypage-message-list .list .list__item.list--needs .list__item-time .day {
  background: #679636;
}

.contents--mypage-message-list .list .list__item.list--needs .list__item-from-name .from {
  background: #679636;
}

.contents--mypage-message-list .list .list__item .price-highlight {
  font-weight: normal;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-mail-status {
    padding: 0 0.4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-mail-status {
    padding: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-mail-status {
    height: 100%;
    background: #d5e6f2;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-mail-status {
    text-align: center;
    justify-content: center;
    background: #d5e6f2;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-mail-status img {
    max-width: 30px;
    max-height: 30px;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-read-status {
    width: 100%;
  }
}

.contents--mypage-message-list .list .list__item-read-icons {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-read-icons {
    align-self: center;
    flex-basis: 15%;
    max-width: 15%;
  }
}

.contents--mypage-message-list .list .list__item-read-icons .list__item-read-icon-wrap {
  height: 100%;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-read-icons .list__item-read-icon-wrap {
    position: relative;
    background: #f5f5f5;
    font-size: .8rem;
  }
}

.contents--mypage-message-list .list .list__item-read-icons .list__item-read-icon img {
  max-width: 26px;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-time {
    align-self: center;
    margin-left: auto;
    padding: .5rem;
    font-size: .8rem;
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-main {
    margin: 0;
    padding: 0 .5rem .5rem;
  }
}

.contents--mypage-message-list .list .list__item-main > * {
  padding: 0;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-subjects {
    padding: 1rem 1rem .6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-subjects {
    padding: 0 0 .5rem 0;
  }
}

.contents--mypage-message-list .list .list__item-subjects .list__item-time {
  font-size: .8rem;
}

.contents--mypage-message-list .list .list__item-subjects .list__item-time .day {
  text-align: center;
  font-size: .6rem;
  background: #0e6baf;
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 5px;
  min-width: 50px;
  display: inline-block;
}

.contents--mypage-message-list .list .list__item-subjects .list__item-subject {
  line-height: 1.2;
  font-size: 1.2rem;
  font-weight: normal;
}

.contents--mypage-message-list .list .list__item-subjects .list__item-from-name {
  font-size: .8rem;
  padding: .2rem 0 .5rem 0;
  margin-bottom: .5rem;
  border-bottom: solid 1px #f5f5f5;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-subjects .list__item-from-name {
    padding: .5rem 0;
  }
}

.contents--mypage-message-list .list .list__item-subjects .list__item-from-name .from {
  text-align: center;
  font-size: .6rem;
  background: #0e6baf;
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 5px;
  min-width: 50px;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-subjects .list__item-from-name .from {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-info {
    padding: .5rem 0;
    background: #f5f5f5;
  }
}

.contents--mypage-message-list .list .list__item-info .property-images .property-image__info .property-image__icon img {
  display: none;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-info .property-images .property-image__info .property-image__icon img {
    top: -.3rem;
    width: 1.3rem;
    padding-right: .2rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-info .property-images .property-image__info .property-image__text {
    padding: .2rem 0;
    font-size: .8rem;
  }
}

.contents--mypage-message-list .list .list__item-info .list__item-title {
  font-weight: normal;
  font-size: .8rem;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-info .list__item-title {
    line-height: 1.2;
    padding-bottom: .5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-info .list__item-title {
    line-height: 1.2;
    padding-bottom: .5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-info .list__item-title .tag {
    display: none;
  }
}

.contents--mypage-message-list .list .list__item-info .list__item-property {
  padding-left: 1rem;
}

.contents--mypage-message-list .list .list__item-info .list__item-address {
  font-size: .8rem;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list .list__item-info .list__item-address {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-list .list .list__item-info .list__item-address {
    padding-top: 0.75rem;
  }
}

.contents--mypage-message-list .list .list__item-arrow {
  text-align: center;
}

.contents--mypage-message-list .list .list__item-arrow .row {
  height: 100%;
}

.contents--mypage-message-list .list .list__item-arrow img {
  max-width: 20px;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-list .list.list--basic .property-images.property__main-image--blank
.property-image__wrap {
    padding: 2rem 0;
  }
}

.contents--mypage-message-detail .breadcrumb,
.contents--mypage-message-detail .msgd__header {
  background: #d5e6f2;
}

.contents--mypage-message-detail .select-arrow {
  background: #0e6baf;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .input::placeholder {
    color: transparent;
  }
}

.contents--mypage-message-detail .has_triangle {
  position: relative;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .has_triangle {
    margin-top: 3rem;
  }
}

.contents--mypage-message-detail .has_triangle:after {
  content: '';
  position: absolute;
  left: calc(50% - 3rem);
  border-right: 3rem solid transparent;
  border-bottom: 2rem solid #fff;
  border-left: 3rem solid transparent;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .has_triangle:after {
    top: calc(100% - 2rem);
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .has_triangle:after {
    top: calc(100% - 1.6rem);
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__header {
    padding: 0 0 3.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__header {
    padding: 0;
  }
}

.contents--mypage-message-detail .msgd__header .list-search {
  padding-top: 0;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__header .list-search__items {
    padding: 1rem;
  }
}

.contents--mypage-message-detail .msgd__header .list-search__items .list-search__item {
  background: #fff;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__header-info {
    padding-left: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__header-info .title-list {
    padding: 2rem 1.5rem 1.5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__header-info .property-images .property-image__info .property-image__icon img {
    max-width: 80%;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__header-info .price-highlight {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__header-info .price-highlight {
    padding: 0 1.5rem;
  }
}

.contents--mypage-message-detail .msgd__header-info .title-box .title-box__text {
  background: #fff;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__header-info .property-infos.property-infos--vertical {
    padding: 1.5rem 1.5rem 3rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__header-info .property-infos.property-infos--vertical .property-info + .property-info {
    padding-top: .5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__body {
    padding: 2rem 0 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__body {
    padding: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__body.content {
    padding: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__body.content .container {
    padding: .5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__body .msgd__names {
    padding-bottom: .5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__body .msgd__names .circle.circle--has-name {
    width: 3.8rem;
    height: 3.8rem;
    line-height: 3.8rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__body .msgd__names .circle.circle--has-name {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__body .msgd__forms {
    padding: 1.2rem 0;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__body .msgd__forms .form {
    padding-right: 2.5rem;
  }
}

.contents--mypage-message-detail .msgd__body .msgd__forms .form-basic {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__body .msgd__forms .form-basic .form-file {
    padding: 0;
  }
}

.contents--mypage-message-detail .msgd__body .msgd__forms .form-basic .form-file-items {
  padding: 0;
}

.contents--mypage-message-detail .msgd__log {
  background: #f5f5f5;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__log {
    margin-top: 1.8rem;
    padding-top: 3rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-wrap {
    padding: 0 3.5rem 2rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-wrap {
    padding: 0 1rem 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-wrap .title.title--sub {
    padding: 1rem 0 0 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box:not(.msgd__log-box--reply) .msgd__log-msg {
    padding-top: 0rem;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-box:not(.msgd__log-box--reply) .msgd__log-msg:after {
  content: '';
  position: absolute;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box:not(.msgd__log-box--reply) .msgd__log-msg:after {
    top: 50%;
    left: 0rem;
    border-top: 1rem solid transparent;
    border-right: 1rem solid #fff;
    border-bottom: 1rem solid transparent;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box:not(.msgd__log-box--reply) .msgd__log-msg:after {
    left: -0.5rem;
    top: 0.5rem;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 1rem solid #fff;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-box:not(.msgd__log-box--reply) .msgd__log-msg-wrap {
  background: #fff;
  border-radius: 12px;
}

.contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply {
  padding-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply {
    padding-top: 0rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply .msgd__log-msg {
    margin-left: 1rem;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap {
  position: relative;
  background: #0e6baf;
  border-radius: 12px;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap {
    padding-top: 0rem;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap:after {
  content: '';
  position: absolute;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap:after {
    top: 50%;
    right: -1rem;
    border-top: 1rem solid transparent;
    border-left: 1rem solid #0e6baf;
    border-bottom: 1rem solid transparent;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap:after {
    content: '';
    position: absolute;
    right: -0.5rem;
    top: 0.5rem;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid #0e6baf;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-box {
    align-items: start;
    margin-top: 1rem;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-msg {
  position: relative;
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-msg {
    padding-left: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-msg-wrap {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-msg-wrap {
    padding: .5rem;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-dear {
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-dear {
    padding-left: 1rem;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-subject {
  padding: 1rem 2rem;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-subject {
    padding: 0.5rem 0rem;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-subject h4 {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-subject .msgd__name + .msgd__log-subject-text {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-subject .msgd__log-subject-text {
    font-weight: bold;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-body {
  padding: 0 2rem;
  line-height: 140%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-body {
    padding: 0;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__log-btn .submit-area {
  text-align: right;
  padding: 1rem;
}

.contents--mypage-message-detail .msgd__log .msgd__log-btn .submit-area .download__btn {
  display: inline;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log .msgd__log-btn .submit-area {
    padding: 0;
  }
  .contents--mypage-message-detail .msgd__log .msgd__log-btn .submit-area .btn {
    margin: 0.5rem 0;
  }
}

.contents--mypage-message-detail .msgd__log .msgd__date {
  padding: .5rem 0 0;
  font-size: .8rem;
  text-align: right;
}

.contents--mypage-message-detail .msgd__log:after {
  top: -2rem;
  border-bottom-color: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail .msgd__log:after {
    top: -1rem;
  }
}

.contents--mypage-message-detail.contents--mypage-message-needs .breadcrumb,
.contents--mypage-message-detail.contents--mypage-message-needs .msgd__header,
.contents--mypage-message-detail.contents--mypage-message-needs .msgd__log
.msgd__log-box:not(.msgd__log-box--reply)
.form-file__title {
  background: #e5f0d9;
}

.contents--mypage-message-detail.contents--mypage-message-needs .property-infos .property-info__text.property-info__text--yield {
  color: #679636;
}

.contents--mypage-message-detail.contents--mypage-message-needs .msgd__header .property-images .property-image__info {
  position: static;
}

.contents--mypage-message-detail.contents--mypage-message-needs .msgd__header .property-images .property-image__info .property-image__icon img {
  position: static;
}

.contents--mypage-message-detail.contents--mypage-message-needs .msgd__header .property-images .property-image__info .property-image__bar {
  background: rgba(124, 179, 66, 0.2);
}

@media screen and (min-width: 769px) {
  .contents--mypage-message-detail.contents--mypage-message-needs .msgd__header .property-images .property-image__info .property-image__bar {
    height: 10rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail.contents--mypage-message-needs .msgd__header .property-images .property-image__info .property-image__bar {
    height: 20rem;
  }
}

.contents--mypage-message-detail.contents--mypage-message-needs .msgd__header .property-images .property-image__info .property-image__text {
  color: #679636;
  padding-bottom: 0;
}

@media screen and (max-width: 768px) {
  .contents--mypage-message-detail.contents--mypage-message-needs .msgd__header .property-images .property-image__info .property-image__text {
    padding-left: 1rem;
  }
}

.contents--mypage-message-detail.contents--mypage-message-needs .form-file__attention {
  color: #5e8931;
  background: #e5f0d9;
}

.contents--mypage-message-detail.contents--mypage-message-needs .form-file__nos,
.contents--mypage-message-detail.contents--mypage-message-needs .select-arrow {
  background: #679636;
}

.contents--mypage-message-detail.contents--mypage-message-needs .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap {
  background: #679636;
}

.contents--mypage-message-detail.contents--mypage-message-needs .msgd__log-box.msgd__log-box--reply .msgd__log-msg-wrap:after {
  border-left: 1rem solid #679636;
}

@media screen and (max-width: 768px) {
  .contents--mypage-buy-dl .list__item-title {
    padding-right: .5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .content {
    padding: 0;
  }
  .contents--mypage-edit .content .submit-area {
    padding: 0 1rem;
  }
}

.contents--mypage-edit .select-search .select {
  border-radius: 0;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .input.input--short {
    max-width: 150px;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .form-has-unit .input {
    max-width: 80%;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .form-has-unit .input.input--short {
    max-width: 150px;
  }
}

.contents--mypage-edit .form-has-unit .form-has-unit__unit {
  font-weight: normal;
}

.contents--mypage-edit .checkbox:not(:checked) + .btn {
  background: #fff;
  border: solid 1px;
}

.contents--mypage-edit .checkbox:checked + .btn {
  border: solid 1px transparent;
}

.contents--mypage-edit .checkbox + .btn {
  border-radius: 0;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .range-wrap {
    padding-left: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .title.title--sub {
    padding-top: 1rem;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .title-has-anchor {
    margin-top: -6rem;
    padding-top: 6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .title-has-anchor {
    margin-top: calc(-1 * (16.4vw + 16vw));
    padding-top: calc(16.4vw + 16vw);
  }
}

.contents--mypage-edit .myep {
  position: relative;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .myep {
    padding-top: 4.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep {
    padding-top: 4.2rem;
  }
}

.contents--mypage-edit .myep-menu {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep-menu {
    left: 0;
  }
}

.contents--mypage-edit .myep-menu .myep-menu__item {
  position: relative;
}

.contents--mypage-edit .myep-menu .myep-menu__item a {
  position: relative;
  display: block;
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .myep-menu .myep-menu__item a {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep-menu .myep-menu__item a {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep-menu .myep-menu__item a {
    font-size: .8rem;
  }
}

.contents--mypage-edit .myep-menu .myep-menu__item.error_state {
  background: #fde2e2;
  color: #a40000;
}

.contents--mypage-edit .myep-menu .myep-menu__item.error_state span.ng {
  display: block;
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep-menu .myep-menu__item.error_state span.ng {
    display: none;
  }
}

.contents--mypage-edit .myep-menu .myep-menu__item.no_error_state span.ok {
  display: block;
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep-menu .myep-menu__item.no_error_state span.ok {
    display: none;
  }
}

.contents--mypage-edit .myep-menu .myep-menu__item span {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  font-weight: bold;
  text-align: center;
  display: none;
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep-menu .myep-menu__item span {
    font-size: 0.7rem;
    line-height: 1.5rem;
  }
}

.contents--mypage-edit .myep-menu .myep-menu__item.myep-menu__item--activate a {
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .myep-menu .myep-menu__item.myep-menu__item--activate a:after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: calc(50% - .5rem);
    border-right: .5rem solid transparent;
    border-top: 1rem solid;
    border-left: .5rem solid transparent;
  }
}

.contents--mypage-edit .myep-menu.myep-menu--fixed {
  position: fixed;
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep-menu.myep-menu--fixed {
    top: 14vw;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .myep__content {
    border-top: solid 1px;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep__content .input-box {
    padding: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .myep__content + .title.title--sub {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep__content + .title.title--sub {
    text-align: center;
    margin-top: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .myep__content.myep__content--attachment .input-box {
    padding: 1rem 0 1rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .myep__content.myep__content--attachment .input-box {
    padding: 0.5rem;
  }
}

.contents--mypage-edit .traffic-modal {
  border: none;
}

.contents--mypage-edit .traffic-modal .modal-title {
  font-size: 1rem;
  font-weight: bold;
}

.contents--mypage-edit .traffic-modal .title.title--sub {
  padding: .5rem;
  font-size: 1rem;
  margin: 1rem 0 0 0;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .traffic-modal .title.title--sub {
    padding-top: 0;
  }
}

.contents--mypage-edit .traffic-modal .btn {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit .submit-area {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit .submit-area {
    padding: 1.5rem 0;
  }
}

.contents--mypage-edit.contents--mypage-edit--property .select-search .select-arrow {
  background: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .checkbox:not(:checked) + .btn {
  color: #0e6baf;
  border-color: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .checkbox:checked + .btn {
  background: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .myep-menu .container {
  background: #d5e6f2;
}

.contents--mypage-edit.contents--mypage-edit--property .myep-menu .myep-menu__item a {
  color: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .myep-menu .myep-menu__item.no_error_state {
  color: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .myep-menu .myep-menu__item.myep-menu__item--activate a {
  background: #228dc7;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit.contents--mypage-edit--property .myep-menu .myep-menu__item.myep-menu__item--activate a:after {
    border-top-color: #228dc7;
  }
}

.contents--mypage-edit.contents--mypage-edit--property .myep-menu .myep-menu__item.myep-menu__item--disabled {
  display: none;
}

.contents--mypage-edit.contents--mypage-edit--property .myep-menu .myep-menu__item.error_state {
  background: #fde2e2;
  color: #a40000;
}

.contents--mypage-edit.contents--mypage-edit--property .myep-menu .myep-menu__item.error_state.myep-menu__item--activate a {
  background: #a40000;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit.contents--mypage-edit--property .myep-menu .myep-menu__item.error_state.myep-menu__item--activate a:after {
    border-top-color: #a40000;
  }
}

.contents--mypage-edit.contents--mypage-edit--property .myep__content {
  border-color: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .input-box .input-box__label {
  color: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .traffic-modal {
  background: #d5e6f2;
}

.contents--mypage-edit.contents--mypage-edit--property .traffic-modal .modal-title {
  color: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .traffic-modal .title.title--sub {
  color: #0e6baf;
}

.contents--mypage-edit.contents--mypage-edit--property .traffic-modal .checkbox:not(:checked) + .btn {
  color: #0e6baf;
  border-color: #fff;
}

@media screen and (max-width: 768px) {
  .contents--mypage-edit.contents--mypage-edit--needs input, .contents--mypage-edit.contents--mypage-edit--needs select, .contents--mypage-edit.contents--mypage-edit--needs textarea {
    font-size: 16px;
  }
}

.contents--mypage-edit.contents--mypage-edit--needs .select-search .select-arrow {
  background: #679636;
}

.contents--mypage-edit.contents--mypage-edit--needs .checkbox:not(:checked) + .btn {
  color: #679636;
  border-color: #679636;
}

.contents--mypage-edit.contents--mypage-edit--needs .checkbox:checked + .btn {
  background: #679636;
}

.contents--mypage-edit.contents--mypage-edit--needs .myep-menu .container {
  background: #e5f0d9;
}

.contents--mypage-edit.contents--mypage-edit--needs .myep-menu .myep-menu__item a {
  color: #679636;
}

.contents--mypage-edit.contents--mypage-edit--needs .myep-menu .myep-menu__item.no_error_state {
  color: #5e8931;
}

.contents--mypage-edit.contents--mypage-edit--needs .myep-menu .myep-menu__item.myep-menu__item--activate a {
  background: #679636;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit.contents--mypage-edit--needs .myep-menu .myep-menu__item.myep-menu__item--activate a:after {
    border-top-color: #679636;
  }
}

.contents--mypage-edit.contents--mypage-edit--needs .myep-menu .myep-menu__item.myep-menu__item--disabled {
  display: none;
}

.contents--mypage-edit.contents--mypage-edit--needs .myep-menu .myep-menu__item.error_state {
  background: #fde2e2;
  color: #a40000;
}

.contents--mypage-edit.contents--mypage-edit--needs .myep-menu .myep-menu__item.error_state.myep-menu__item--activate a {
  background: #a40000;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents--mypage-edit.contents--mypage-edit--needs .myep-menu .myep-menu__item.error_state.myep-menu__item--activate a:after {
    border-top-color: #a40000;
  }
}

.contents--mypage-edit.contents--mypage-edit--needs .myep__content {
  border-color: #679636;
}

.contents--mypage-edit.contents--mypage-edit--needs .input-box .input-box__label {
  color: #679636;
}

.contents--mypage-edit.contents--mypage-edit--needs .traffic-modal {
  background: #e5f0d9;
}

.contents--mypage-edit.contents--mypage-edit--needs .traffic-modal .modal-title {
  color: #679636;
}

.contents--mypage-edit.contents--mypage-edit--needs .traffic-modal .title.title--sub {
  color: #679636;
}

.contents--mypage-edit.contents--mypage-edit--needs .traffic-modal .checkbox:not(:checked) + .btn {
  color: #679636;
  border-color: #fff;
}

.contents--mypage-edit.contents--mypage-edit--needs .traffic-modal .checkbox:checked + .btn {
  background: #679636;
}

@media screen and (min-width: 769px) {
  .contents--mypage-invite .content .container {
    max-width: 800px;
  }
}

.contents--mypage-invite .content .container .invite-list_title {
  font-weight: bold;
  line-height: 120%;
  flex-basis: 20%;
}

.contents--mypage-invite .content .container .invite-list_date {
  font-size: .8rem;
  color: #b2b2b2;
}

@media screen and (max-width: 768px) {
  .contents--mypage-invite .content .container .invite-list_date {
    width: 100%;
    border-top: solid 1px #b2b2b2;
    margin: .5rem;
    padding: .5rem;
  }
}

.contents--mypage-invite .content .container .invite-list_status {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .contents--mypage-invite .content .container .invite-list_status {
    padding: 0 1rem .5rem 1rem;
  }
}

.contents--mypage-invite .content .container .invite-list__item {
  padding-top: 0;
}

.contents--mypage-invite .content .container .invite-list__item ul {
  border: solid 1px #fff;
}

@media screen and (max-width: 768px) {
  .contents--mypage-invite .content .container .invite-list__item {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-invite .contents--mypage-invite-list {
    padding: .5rem;
  }
}

.contents--mypage-invite .contents--mypage-invite-list a {
  color: #a40000;
}

@media screen and (min-width: 769px) {
  .contents--mypage-invite .invite__progress {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-invite .invite__progress {
    padding-bottom: 1.5rem;
  }
}

.contents--mypage-invite .invite__progress .invite__progress-item {
  position: relative;
  text-align: center;
  background: #d5e6f2;
}

@media screen and (max-width: 768px) {
  .contents--mypage-invite .invite__progress .invite__progress-item:not(:first-child) .invite__progress-item-wrap {
    padding-left: 1.5rem;
  }
}

.contents--mypage-invite .invite__progress .invite__progress-item:not(:first-child):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 100%;
  background: url(../images/login_progress_arrow.png) center center/100% 100% no-repeat;
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .contents--mypage-invite .invite__progress .invite__progress-item:not(:first-child):after {
    left: -1px;
  }
}

.contents--mypage-invite .invite__progress .invite__progress-item-wrap {
  display: block;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents--mypage-invite .invite__progress .invite__progress-item-wrap {
    padding: 0.8rem 0.8rem 0.8rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-invite .invite__progress .invite__progress-item-wrap {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--mypage-invite .invite__progress .invite__progress-item-wrap {
    font-size: .8rem;
  }
}

_:lang(x)::-ms-backdrop, .contents--mypage-top .myt-menu .btn {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents--profile .submit-area {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--profile .submit-area {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--profile .signup__license + .signup__license {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--profile .signup__license-num {
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .contents--profile .signup__license-num .input {
    width: 80px;
  }
}

@media screen and (max-width: 768px) {
  .contents--profile .signup__license-num .input {
    width: 60%;
  }
}

.contents--profile .profile__submit_link {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents--profile .profile__submit_link {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--profile .profile__submit_link {
    padding-top: 1.5rem;
  }
}

.contents--profile .profile__submit_link a {
  text-decoration: underline;
  font-weight: bold;
  color: #3c3c47;
}

@media screen and (min-width: 769px) {
  .contents--profile-edit .form-group-email .label.label--basic {
    margin-top: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents--profile-edit .form-group-email .btn {
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .contents--profile-edit .form-group-email .btn {
    font-size: .8rem;
  }
}

.contents--unsubscribe .title {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents--unsubscribe .title {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents--unsubscribe .unsubscribe-title-note {
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--unsubscribe .unsubscribe-title-note {
    padding-bottom: 1.5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents--unsubscribe .unsubscribe-title-note {
    max-width: 460px;
    margin: 0 auto;
    text-align: right;
  }
}

@media screen and (min-width: 769px) {
  .contents--unsubscribe .submit-area {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--unsubscribe .submit-area {
    padding-top: 4rem;
  }
}

.contents.contents--detail .property__main {
  padding: 0;
}

.contents.contents--detail .property__main .property__main-titles {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-titles {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-titles .tag {
    padding: 0.75rem 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-titles .tag {
    padding: 0.5rem 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-titles .tag {
    font-size: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-titles .property__main-title {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-titles .property__main-title .tag {
    display: none;
  }
}

.contents.contents--detail .property__main .property__main-title {
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-title {
    line-height: 1.2;
    padding-bottom: 1.5rem;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-title {
    order: 2;
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    background: #fff;
  }
  .contents.contents--detail .property__main .property__main-title img {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-title br {
    display: none;
  }
}

.contents.contents--detail .property__main .property__main-prices {
  background: #d5e6f2;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-prices {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-prices {
    padding: 1rem;
  }
}

.contents.contents--detail .property__main .property__main-prices .property__main-types .type {
  background: #fff;
  width: 4rem;
  height: 4rem;
}

.contents.contents--detail .property__main .property__main-prices .title-box .title-box__text {
  background: rgba(255, 255, 255, 0.74);
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-prices .property__main-menus__item + .property__main-menus__item {
    padding-top: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-prices + .property-infos {
    padding: 1rem 0 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-prices + .property-infos {
    padding: 1rem;
  }
}

.contents.contents--detail .property__main .property__main-menus__item + .property__main-menus__item {
  padding-top: 1rem;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-menus {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-menus {
    order: 3;
  }
}

.contents.contents--detail .property__main .property__main-menus .property__main-menus__item-text {
  padding-left: 1rem;
}

.contents.contents--detail .property__main .property__main-menus .property__main-menus__item-text.property__main-menus__item-text--yield {
  font-size: 1.4rem;
  font-weight: bold;
  color: #0e6baf;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-menus .property__main-btns {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding-bottom: 1.5rem;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns > * {
    padding: 0 0.5rem;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns > *:nth-child(n + 3) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-menus .property__main-btns {
    padding: .5rem;
    margin: 0;
    background: #fff;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-menus .property__main-btns-wrap {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns-wrap > * {
    padding: 0 0.5rem;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns-wrap > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns-wrap > *:nth-child(n + 3) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-menus .property__main-btns-wrap {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns-wrap > * {
    padding: 0 0.25rem;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns-wrap > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--detail .property__main .property__main-menus .property__main-btns-wrap > *:nth-child(n + 3) {
    padding-top: 0.25rem;
  }
}

.contents.contents--detail .property__main .property__main-name {
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-name {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-name + .property-infos {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-name + .property-infos {
    padding: 1rem 1.5rem;
  }
}

.contents.contents--detail .property__main .property__main-needs {
  position: relative;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-needs .property__main-prices {
    padding: 3rem 1rem 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-needs .property__main-prices {
    padding: 3rem 1.5rem;
  }
}

.contents.contents--detail .property__main .property__main-needs .property__main-image__bar {
  padding-bottom: 1rem;
}

.contents.contents--detail .property__main .property__main-needs .property__main-image__text {
  padding-left: 1rem;
  font-size: 1.4rem;
}

.contents.contents--detail .property__main .property__main-needs .property__main-menus__item-text {
  padding-left: 1rem;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-needs .property__main-menus__item-text {
    width: calc(100% - 7.2rem);
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-needs .property__main-menus__item-text {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.contents.contents--detail .property__main .property__main-needs .property__main-menus__item-yield {
  font-size: 1.4rem;
  font-weight: bold;
  color: #679636;
}

.contents.contents--detail .property__main .property__main-needs .property__main-menus__item-more {
  padding-left: 1rem;
}

.contents.contents--detail .property__main .property__main-needs .property__main-image__btn {
  position: absolute;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__main .property__main-needs .property__main-image__btn {
    bottom: 1rem;
    right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__main .property__main-needs .property__main-image__btn {
    right: 1rem;
    bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__tables {
    padding-top: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__tables {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__tables .title.title--sub {
    padding-top: 0;
    text-align: left;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__tables .property__tables-contents {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .contents.contents--detail .property__tables .property__tables-contents > * {
    padding: 0 1rem;
  }
  .contents.contents--detail .property__tables .property__tables-contents > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--detail .property__tables .property__tables-contents > *:nth-child(n + 3) {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__tables .property__tables-contents .property__tables-content + .property__tables-content {
    padding-top: 2rem;
  }
}

.contents.contents--detail .property__tables .property__tables-remarks {
  line-height: 2;
  padding-top: 1rem;
}

.contents.contents--detail .property__file {
  background: #0e6baf;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__file {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__file {
    padding: 1.5rem 1.5rem;
  }
}

.contents.contents--detail .property__file .property__file-texts {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__file .property__file-texts {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__file .property__file-texts .row > * {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.contents.contents--detail .property__file .property__file-img img {
  max-width: 35px;
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__file .property__file-img img {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__file .property__file-text {
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__file .property__file-text {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__file .property__file-btns {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .contents.contents--detail .property__file .property__file-btns > * {
    padding: 0 1rem;
  }
  .contents.contents--detail .property__file .property__file-btns > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--detail .property__file .property__file-btns > *:nth-child(n + 3) {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__file .property__file-btn {
    padding: 0 0 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__file .property__file-btn {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__file .property__file-size {
    padding: 1rem 0 1rem;
    text-align: center;
  }
}

.contents.contents--detail .property__file .property__file-size.property__file-size-guest {
  font-size: 1rem;
}

.contents.contents--detail .property__file .property__file-guest-text {
  padding: 1.5rem 0;
  font-size: 1rem;
}

.contents.contents--detail .property__inquiry {
  background: #d5e6f2;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__inquiry {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__inquiry {
    padding: 1rem;
  }
}

.contents.contents--detail .property__inquiry .property__inquiry-title {
  color: #0e6baf;
  font-weight: bold;
}

.contents.contents--detail .property__inquiry .property__inquiry-title .property__inquiry-title_no {
  background: #0e6baf;
  border-radius: 5px;
  padding: 2px 10px;
  color: #fff;
  font-size: .8rem;
  margin-left: 1rem;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__inquiry .property__inquiry-wrap {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents.contents--detail .property__inquiry .property__inquiry-wrap > * {
    padding: 0 0.5rem;
  }
  .contents.contents--detail .property__inquiry .property__inquiry-wrap > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--detail .property__inquiry .property__inquiry-wrap > *:nth-child(n + 3) {
    padding-top: 0.5rem;
  }
}

.contents.contents--detail .property__inquiry .property__inquiry-name {
  line-height: 1.3;
  padding: 0 0 .5rem 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.contents.contents--detail .property__inquiry .property__inquiry-location-title {
  padding: .5rem 0;
  background-color: #aecfe6;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__inquiry .property__inquiry-location-title {
    width: 7.2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__inquiry .property__inquiry-location-title {
    flex-basis: 20%;
    max-width: 20%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__inquiry .property__inquiry-location-texts {
    flex-basis: calc(100% - 7.2rem);
    max-width: calc(100% - 7.2rem);
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__inquiry .property__inquiry-location-texts {
    flex-basis: 80%;
    max-width: 80%;
  }
}

.contents.contents--detail .property__inquiry .property__inquiry-location-text {
  padding-left: 1rem;
}

.contents.contents--detail .property__inquiry .property__inquiry-location-btn {
  padding-left: 1rem;
  text-align: right;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__inquiry .property__inquiry-btn {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__inquiry .property__inquiry-btn {
    padding-top: 1.5rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__inquiry .property__inquiry-btn {
    max-width: 314px;
    margin-left: auto;
  }
}

.contents.contents--detail .property__status {
  background: #3c3c47;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background: rgba(60, 60, 71, 0.87);
  animation: fade-in-bottom 2s;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__status .property__status-item + .property__status-item {
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__status .property__status-item + .property__status-item {
    padding-left: 0.75rem;
  }
}

.contents.contents--detail .property__status .property__status-img {
  line-height: 0;
}

.contents.contents--detail .property__status .property__status-img img {
  max-width: 30px;
}

.contents.contents--detail .property__status .property__status-text {
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property__status .property__status-text {
    padding: 0 2rem 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property__status .property__status-text {
    padding: 0 1rem 0 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail.contents--property .property__main .property__main-types {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail.contents--property .property__main .price-highlight {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail.contents--property .property__main .property-infos {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail.contents--property .property__main .property__main-price {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail.contents--property .property__main .property__main-price {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
  }
}

.contents.contents--detail.contents--property .property__main .type {
  border-color: #0e6baf;
  color: #0e6baf;
}

.contents.contents--detail.contents--needs .property__main .property__main-types {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contents.contents--detail.contents--needs .property__main .property__main-types {
    width: 100%;
    padding-top: 1rem;
  }
}

.contents.contents--detail.contents--needs .property__main .type {
  border-color: #679636;
  color: #679636;
}

.contents.contents--detail.contents--needs .property__main .type + .type {
  margin-left: .5rem;
}

@media screen and (min-width: 769px) {
  .contents.contents--detail.contents--needs .property__main .price-highlight {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--detail.contents--needs .property__main .price-highlight {
    padding-top: 1.5rem;
  }
}

.contents.contents--detail.contents--needs .title-box .title-box__text {
  background: #e5f0d9;
}

.contents.contents--detail.contents--needs .property__main-prices,
.contents.contents--detail.contents--needs .property__info,
.contents.contents--detail.contents--needs .property__inquiry {
  background: #e5f0d9;
}

.contents.contents--detail.contents--needs .property__inquiry-location-title {
  background-color: #cce2b4;
}

.contents.contents--detail.contents--needs .property-infos .property-info__text.property-info__text--yield,
.contents.contents--detail.contents--needs .property__inquiry-title {
  color: #679636;
}

.contents.contents--detail.contents--needs .property-infos .property-info__text.property-info__text--yield .property__inquiry-title_no,
.contents.contents--detail.contents--needs .property__inquiry-title .property__inquiry-title_no {
  background: #679636;
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property-images {
    order: 1;
  }
}

.contents.contents--detail .property-images .property-image__wrap {
  position: relative;
  height: 20rem;
  width: 100%;
  background: #f5f5f5;
}

.contents.contents--detail .property-images .property-image {
  position: relative;
  overflow: hidden;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property-images .property-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding-right: 0rem;
    padding-left: 0rem;
  }
}

.contents.contents--detail .property-images .property-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .contents.contents--detail .property-images .property-image img {
    height: 20rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--detail .property-images .property-image {
    height: 20rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .content {
    padding: 2.3rem 0 4.1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .content {
    padding: 0;
  }
}

.contents.contents--media a:hover {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .title.title--sub {
    padding-top: 2rem;
  }
}

.contents.contents--media .media__pickup {
  display: block;
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .tns-horizontal.tns-subpixel > .tns-item {
    font-size: 1rem !important;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items {
    margin-right: -28px;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items + .title {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items + .title {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items + .title {
    padding-top: 2rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items:not(.media__pickup-items--has-img) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents.contents--media .media__pickup .media__pickup-items:not(.media__pickup-items--has-img) > * {
    padding: 0 0.5rem;
  }
  .contents.contents--media .media__pickup .media__pickup-items:not(.media__pickup-items--has-img) > *:nth-child(5n + 1) {
    clear: both;
  }
  .contents.contents--media .media__pickup .media__pickup-items:not(.media__pickup-items--has-img) > *:nth-child(n + 6) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items:not(.media__pickup-items--has-img) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .contents.contents--media .media__pickup .media__pickup-items:not(.media__pickup-items--has-img) > * {
    padding: 0 0.75rem;
  }
  .contents.contents--media .media__pickup .media__pickup-items:not(.media__pickup-items--has-img) > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--media .media__pickup .media__pickup-items:not(.media__pickup-items--has-img) > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items.media__pickup-items--has-img .media__pickup-item-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items.media__pickup-items--has-img .media__pickup-item-wrap {
    display: flex;
  }
}

.contents.contents--media .media__pickup .media__pickup-items.media__pickup-items--has-img .media__pickup-item {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items.media__pickup-items--has-img .media__pickup-item {
    float: left;
    width: 33%;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items.media__pickup-items--has-img .media__pickup-item + .media__pickup-item {
    padding-top: 1rem;
  }
}

.contents.contents--media .media__pickup .media__pickup-items .btn {
  width: 100%;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items .btn {
    width: 100%;
    padding: .8rem;
  }
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-imgs {
  background: #f5f5f5;
  position: relative;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-imgs figure {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-imgs figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 120%;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-imgs {
    min-height: 14rem;
  }
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-imgs figure {
    height: 14rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-imgs {
    width: 43%;
    min-height: 4rem;
  }
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-imgs figure {
    height: 100%;
  }
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-category {
  margin: 0 0 10px 0;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-category {
    margin: 0 0 2% 0;
    border: solid 1px #0e6baf;
    padding: 2%;
  }
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-category .media__pickup-category-img {
  text-align: center;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-category .media__pickup-category-img img {
  width: 20px;
  height: 20px;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-category .media__pickup-category-text a {
  font-size: 1rem;
  color: #0e6baf;
  font-weight: bold;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-info {
  background: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-info {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-info {
    width: 70%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-info {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-info {
    padding: 0 0 0 1%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-properties {
    min-height: 4.6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-properties {
    font-size: .8rem;
  }
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-titles {
  text-align: left;
  width: 100%;
  border-bottom: solid 1px #0e6baf;
  margin: 0 0 2% 0;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-titles {
    margin: 10px 0;
    height: 80px;
  }
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-title {
  width: 100%;
  padding: 0;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-item-title {
    font-size: 1rem;
  }
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-profile-item {
  width: 100%;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-profile-item .media__pickup-profile-img {
  text-align: center;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-profile-item .media__pickup-profile-img img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-profile-item .media__pickup-profile-text {
  font-size: .8rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-profile-item .media__pickup-profile-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-date-item {
  width: 100%;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-date-item .media__pickup-date-img {
  text-align: center;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-date-item .media__pickup-date-img img {
  width: 20px;
  height: 20px;
}

.contents.contents--media .media__pickup .media__pickup-items .media__pickup-date-item .media__pickup-date-text {
  font-size: .8rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__pickup .media__pickup-items .media__pickup-date-item .media__pickup-date-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.contents.contents--media .media__list {
  background: #f5f5f5;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list .media__list-left {
    padding-right: 36px;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items {
    margin-right: -16px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items + .title {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items + .title {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items + .title {
    padding-top: 2rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items:not(.media__list-items--has-img) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .contents.contents--media .media__list-items:not(.media__list-items--has-img) > * {
    padding: 0 0.5rem;
  }
  .contents.contents--media .media__list-items:not(.media__list-items--has-img) > *:nth-child(5n + 1) {
    clear: both;
  }
  .contents.contents--media .media__list-items:not(.media__list-items--has-img) > *:nth-child(n + 6) {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items:not(.media__list-items--has-img) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .contents.contents--media .media__list-items:not(.media__list-items--has-img) > * {
    padding: 0 0.75rem;
  }
  .contents.contents--media .media__list-items:not(.media__list-items--has-img) > *:nth-child(2n + 1) {
    clear: both;
  }
  .contents.contents--media .media__list-items:not(.media__list-items--has-img) > *:nth-child(n + 3) {
    padding-top: 0.75rem;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items.media__list-items--has-img .media__list-item-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items.media__list-items--has-img .media__list-item-wrap {
    display: flex;
  }
}

.contents.contents--media .media__list-items.media__list-items--has-img .media__list-item {
  width: 100%;
  margin-bottom: 16px;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items.media__list-items--has-img .media__list-item {
    float: left;
    width: 50%;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items.media__list-items--has-img .media__list-item + .media__list-item {
    padding-top: 1rem;
  }
}

.contents.contents--media .media__list-items .btn {
  width: 100%;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items .btn {
    width: 100%;
    padding: .8rem;
  }
}

.contents.contents--media .media__list-items .media__list-imgs {
  background: #fff;
  position: relative;
}

.contents.contents--media .media__list-items .media__list-imgs figure {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.contents.contents--media .media__list-items .media__list-imgs figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 120%;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items .media__list-imgs {
    min-height: 14rem;
  }
  .contents.contents--media .media__list-items .media__list-imgs figure {
    height: 14rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items .media__list-imgs {
    width: 43%;
    min-height: 4rem;
  }
  .contents.contents--media .media__list-items .media__list-imgs figure {
    height: 100%;
  }
}

.contents.contents--media .media__list-items .media__list-item-info {
  background: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items .media__list-item-info {
    padding: 0 16px 16px 16px;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items .media__list-item-info {
    padding: 0 0 0 1%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items .media__list-item-info {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items .media__list-item-info {
    width: 70%;
  }
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items .media__list-item-properties {
    min-height: 4.6rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items .media__list-item-properties {
    font-size: .8rem;
    min-height: 100px;
  }
}

.contents.contents--media .media__list-items .media__list-item-titles {
  text-align: left;
  width: 100%;
  margin: 0 0 2% 0;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items .media__list-item-titles {
    margin: 10px 0;
    height: 80px;
  }
}

.contents.contents--media .media__list-items .media__list-item-title {
  width: 100%;
  padding: 0;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__list-items .media__list-item-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items .media__list-item-title {
    font-size: 1rem;
  }
}

.contents.contents--media .media__list-items .media__list-date-item .media__list-category-img {
  text-align: center;
}

.contents.contents--media .media__list-items .media__list-date-item .media__list-category-img img {
  width: 20px;
  height: 20px;
}

.contents.contents--media .media__list-items .media__list-date-item .media__list-category-text {
  font-size: .8rem;
  color: #0e6baf;
  font-weight: bold;
}

.contents.contents--media .media__list-items .media__list-date-item .media__list-date-img {
  text-align: center;
}

.contents.contents--media .media__list-items .media__list-date-item .media__list-date-img img {
  width: 20px;
  height: 20px;
}

.contents.contents--media .media__list-items .media__list-date-item .media__list-date-text {
  font-size: .8rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__list-items .media__list-date-item .media__list-date-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.contents.contents--media .media__detail {
  background: #f5f5f5;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__detail .media__detail-left {
    padding-right: 36px;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__detail-left {
    margin-bottom: 2.5%;
    background: #fff;
    padding: 2.5%;
  }
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item {
  background: #fff;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__detail .media__detail-left .media__detail-item {
    padding: 16px;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__detail-left .media__detail-item {
    padding: 0;
  }
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-item-title {
  text-align: left;
  width: 100%;
  padding: 16px 0;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-item-title {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-item-title {
    font-size: 1rem;
  }
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-date-item .media__detail-category-img {
  text-align: left;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-date-item .media__detail-category-img img {
  width: 20px;
  height: 20px;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-date-item .media__detail-category-text {
  font-size: .8rem;
  color: #0e6baf;
  font-weight: bold;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-date-item .media__detail-date-img {
  text-align: center;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-date-item .media__detail-date-img img {
  width: 20px;
  height: 20px;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-date-item .media__detail-date-text {
  text-align: right;
  font-size: .8rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__detail-left .media__detail-item .media__detail-item-info .media__detail-date-item .media__detail-date-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.contents.contents--media .media__detail .media__detail-left .media__detail-entry {
  position: relative;
  padding: 20px 0;
  min-height: 300px;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-entry p {
  margin-bottom: 16px;
  line-height: 170%;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-entry img {
  margin-bottom: 16px;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-entry .media__detail-secret {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  margin: 0 auto 5% auto;
  text-align: center;
  background: url(../images/dummy/dumy_media_secret.png) center top repeat-x;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-entry .media__detail-secret .media__detail-secretbox {
  border: solid 1px #0e6baf;
  padding: 5%;
  margin: 10% 5% 5% 5%;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__detail-left .media__detail-entry .media__detail-secret .media__detail-secretbox {
    margin: 20% 5% 5% 5%;
  }
}

.contents.contents--media .media__detail .media__detail-left .media__detail-entry .media__detail-secret img {
  width: 30px;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item-btn {
  background: #000;
  width: 100%;
}

.contents.contents--media .media__detail .media__detail-left .media__detail-item-btn a {
  padding: 15px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__detail-right {
    margin-bottom: 2.5%;
    padding: 2.5%;
  }
}

.contents.contents--media .media__detail .social {
  margin: 10px auto;
  height: auto;
}

.contents.contents--media .media__detail .social a {
  padding: 13px 15px 12px 15px;
  font-family: Arial;
  font-size: 8px auto;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 33.3333%;
}

.contents.contents--media .media__detail .social .facebook {
  background-color: #2e4a88;
  box-shadow: 0 4px 0 #1B3D82;
  text-shadow: 0 -1px -1px #1B3D82;
}

.contents.contents--media .media__detail .social .facebook:hover {
  background-color: #354F84;
}

.contents.contents--media .media__detail .social .facebook:active {
  top: 2px;
  box-shadow: 0 2px 0 #1B3D82;
}

.contents.contents--media .media__detail .social .twitter {
  background-color: #008DDE;
  box-shadow: 0 4px 0 #0078BD;
}

.contents.contents--media .media__detail .social .twitter:active {
  top: 2px;
  box-shadow: 0 2px 0 #0078BD;
}

.contents.contents--media .media__detail .social .twitter:hover {
  background-color: #1397D8;
}

.contents.contents--media .media__detail .social .line {
  background-color: #00C300;
  box-shadow: 0 4px 0 #00B300;
}

.contents.contents--media .media__detail .social .line:active {
  top: 2px;
  box-shadow: 0 2px 0 #00B300;
}

.contents.contents--media .media__detail .social .line:hover {
  background-color: #00e300;
}

.contents.contents--media .media__detail .media__detail-profile {
  border-top: solid 1px #0e6baf;
  padding: 16px 0;
  margin-top: 16px;
}

.contents.contents--media .media__detail .media__detail-profile .media__detail-profile-item .media__detail-profile-img {
  text-align: center;
}

.contents.contents--media .media__detail .media__detail-profile .media__detail-profile-item .media__detail-profile-img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.contents.contents--media .media__detail .media__detail-profile .media__detail-profile-item .media__detail-profile-text {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__detail-profile .media__detail-profile-item .media__detail-profile-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__list {
    padding: 2.5%;
    margin: 2.5% 0;
  }
}

.contents.contents--media .media__detail .media__list h3 {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin: 36px 0 16px 0;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__list h3 {
    margin: 2.5% 0;
  }
}

.contents.contents--media .media__detail .media__list .media__list-item {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__detail .media__list .media__list-item {
    float: left;
    width: 33.333%;
    margin-bottom: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__list .media__list-item + .media__list-item {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__detail .media__list .media__list-item {
    margin: 0;
    padding: 0;
  }
}

.contents.contents--media .media__detail .media__list .media__list-item .media__list-item-title {
  font-size: 14px;
}

.contents.contents--media .media__ranking {
  padding: 20px 0;
  background: #f5f5f5;
}

.contents.contents--media .media__ranking h3 {
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.contents.contents--media .media__ranking .tab {
  background: #fff;
  margin-bottom: 10px;
}

.contents.contents--media .media__ranking .tab li {
  padding: 10px;
  text-align: center;
}

.contents.contents--media .media__ranking .tab li.active, .contents.contents--media .media__ranking .tab li:hover {
  background: #0e6baf;
  color: #fff;
}

.contents.contents--media .media__ranking .media__ranking-items:not(.media__ranking-items--has-img) {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.contents.contents--media .media__ranking .media__ranking-items:not(.media__ranking-items--has-img) > * {
  padding: 0 0.75rem;
}

.contents.contents--media .media__ranking .media__ranking-items:not(.media__ranking-items--has-img) > *:nth-child(2n + 1) {
  clear: both;
}

.contents.contents--media .media__ranking .media__ranking-items:not(.media__ranking-items--has-img) > *:nth-child(n + 3) {
  padding-top: 0.75rem;
}

.contents.contents--media .media__ranking .media__ranking-items.media__ranking-items--has-img .media__ranking-item-wrap {
  display: flex;
}

.contents.contents--media .media__ranking .media__ranking-items.media__ranking-items--has-img .media__ranking-item {
  width: 100%;
}

.contents.contents--media .media__ranking .media__ranking-items.media__ranking-items--has-img .media__ranking-item + .media__ranking-item {
  padding-top: 5px;
}

.contents.contents--media .media__ranking .media__ranking-items .media__ranking-no {
  position: relative;
  width: 10%;
  min-height: 4rem;
  background: #fff;
}

.contents.contents--media .media__ranking .media__ranking-items .media__ranking-no span {
  position: absolute;
  top: 45%;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__ranking .media__ranking-items .media__ranking-no span {
    top: 37%;
  }
}

.contents.contents--media .media__ranking .media__ranking-items .media__ranking-imgs {
  position: relative;
  width: 33%;
  min-height: 4rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__ranking .media__ranking-items .media__ranking-imgs {
    width: 24%;
  }
}

.contents.contents--media .media__ranking .media__ranking-items .media__ranking-imgs figure {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 4rem;
  background: #0e6baf;
}

.contents.contents--media .media__ranking .media__ranking-items .media__ranking-imgs figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 120%;
}

.contents.contents--media .media__ranking .media__ranking-items .media__ranking-item-info {
  background: #fff;
  width: 57%;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__ranking .media__ranking-items .media__ranking-item-info {
    padding: 2%;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__ranking .media__ranking-items .media__ranking-item-info {
    padding: 2.5%;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__ranking .media__ranking-items .media__ranking-item-info {
    width: 70%;
  }
}

.contents.contents--media .media__ranking .media__ranking-items .media__ranking-item-properties {
  min-height: 80px;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__ranking .media__ranking-items .media__ranking-item-properties {
    font-size: .8rem;
  }
}

.contents.contents--media .media__ranking .media__ranking-items .media__ranking-item-title {
  text-align: left;
  width: 100%;
  font-size: 13px;
  margin: 0 0 2% 0;
}

.contents.contents--media .media__push {
  padding: 20px 0;
  background: #f5f5f5;
}

.contents.contents--media .media__push h3 {
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.contents.contents--media .media__push .tab {
  background: #fff;
  margin-bottom: 10px;
}

.contents.contents--media .media__push .tab li {
  padding: 10px;
  text-align: center;
}

.contents.contents--media .media__push .tab li.active, .contents.contents--media .media__push .tab li:hover {
  background: #0e6baf;
  color: #fff;
}

.contents.contents--media .media__push .media__push-items:not(.media__push-items--has-img) {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.contents.contents--media .media__push .media__push-items:not(.media__push-items--has-img) > * {
  padding: 0 0.75rem;
}

.contents.contents--media .media__push .media__push-items:not(.media__push-items--has-img) > *:nth-child(2n + 1) {
  clear: both;
}

.contents.contents--media .media__push .media__push-items:not(.media__push-items--has-img) > *:nth-child(n + 3) {
  padding-top: 0.75rem;
}

.contents.contents--media .media__push .media__push-items.media__push-items--has-img .media__push-item-wrap {
  display: flex;
}

.contents.contents--media .media__push .media__push-items.media__push-items--has-img .media__push-item {
  width: 100%;
}

.contents.contents--media .media__push .media__push-items.media__push-items--has-img .media__push-item + .media__push-item {
  padding-top: 5px;
}

.contents.contents--media .media__push .media__push-items .media__push-imgs {
  position: relative;
  width: 33%;
  min-height: 4rem;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__push .media__push-items .media__push-imgs {
    width: 24%;
  }
}

.contents.contents--media .media__push .media__push-items .media__push-imgs figure {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 4rem;
}

.contents.contents--media .media__push .media__push-items .media__push-imgs figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: initial;
  /* サイズ指定をリセット！ */
  width: auto;
  height: 120%;
}

.contents.contents--media .media__push .media__push-items .media__push-item-info {
  background: #fff;
  width: 67%;
}

@media screen and (min-width: 769px) {
  .contents.contents--media .media__push .media__push-items .media__push-item-info {
    padding: 2%;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__push .media__push-items .media__push-item-info {
    padding: 2.5%;
  }
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__push .media__push-items .media__push-item-info {
    width: 76%;
  }
}

.contents.contents--media .media__push .media__push-items .media__push-item-properties {
  min-height: 80px;
}

@media screen and (max-width: 768px) {
  .contents.contents--media .media__push .media__push-items .media__push-item-properties {
    font-size: .8rem;
  }
}

.contents.contents--media .media__push .media__push-items .media__push-item-title {
  text-align: left;
  width: 100%;
  font-size: 13px;
  margin: 0 0 2% 0;
}

@media screen and (min-width: 769px) {
  .contents--static .submit-area {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--static .submit-area {
    padding-top: 4rem;
  }
}

.contents--complete .complete__img {
  line-height: 0;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents--complete .complete__img {
    padding: 0 0 2rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--complete .complete__img {
    padding: 1.5rem 0;
  }
}

.contents--complete .complete__img img {
  max-width: 129px;
}

.contents--complete .complete__title {
  text-align: center;
  font-weight: bold;
  color: #0e6baf;
}

.contents--complete .complete__title.complete__title--needs {
  color: #679636;
}

@media screen and (min-width: 769px) {
  .contents--complete .complete__title {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--complete .complete__title {
    font-size: 1.4rem;
  }
}

.contents--complete .complete__text {
  text-align: center;
}

@media screen and (min-width: 769px) {
  .contents--complete .complete__text {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--complete .complete__text {
    padding: 1.5rem 0;
  }
}

.contents--complete .complete__negative {
  text-align: center;
  font-size: 1rem;
  color: #a40000;
}

@media screen and (min-width: 769px) {
  .contents--complete .submit-area {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .contents--complete .submit-area {
    padding-top: 4rem;
  }
}

.contents--contact .form-has-unit .form-has-unit__unit {
  padding: 0 .5rem 0 0;
}

@media screen and (max-width: 768px) {
  .contents--contact .form-has-unit .input {
    max-width: 70%;
  }
}

.contents--company .company-def {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .contents--company .company-def {
    display: block;
    padding-bottom: 0;
  }
}

.contents--company .company-def .company-def__ttl,
.contents--company .company-def .company-def__data {
  padding: .75rem 1.5rem;
}

.contents--company .company-def .company-def__ttl:nth-of-type(even),
.contents--company .company-def .company-def__data:nth-of-type(even) {
  background: #f5f5f5;
}

.contents--company .company-def .company-def__ttl {
  width: 20%;
}

@media screen and (max-width: 768px) {
  .contents--company .company-def .company-def__ttl {
    width: 100%;
    padding: 1rem 1rem .2rem;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .contents--company .company-def .company-def__ttl:first-of-type {
    padding-top: 0;
  }
}

.contents--company .company-def .company-def__data {
  width: 80%;
}

@media screen and (max-width: 768px) {
  .contents--company .company-def .company-def__data {
    width: 100%;
    padding: .2rem 1rem 1rem;
  }
}

.contents--company .company-def .company-def__to_contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;
}

.contents--company .company-def .company-def__to_contact:before {
  content: '';
  display: inline-block;
  background-image: url(../images/icon/mail_inactive.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 2px;
  height: 18px;
  width: 20px;
}

.contents--company .company-def .company-def__to_contact a {
  color: #4988ea;
  text-decoration: underline;
}

.contents--privacy .privacy--sec {
  padding-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .contents--privacy .privacy--sec {
    padding-bottom: 10%;
  }
}

@media screen and (max-width: 768px) {
  .contents--privacy .privacy--sec + .privacy--sec {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents--privacy .privacy-ttl {
    font-size: 1.7rem;
  }
}

.contents--privacy .privacy-def .privacy-def__ttl {
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #0e6baf;
}

@media screen and (max-width: 768px) {
  .contents--privacy .privacy-def .privacy-def__ttl {
    margin-top: 1.5rem;
  }
}

.contents--privacy .privacy-def .privacy-def__data {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .contents--privacy .privacy-def .privacy-def__data {
    margin-top: .5rem;
  }
}

.contents--privacy .privacy-def .privacy-def__data .privacy-def__data-ttl {
  margin-bottom: .5rem;
}

.contents--privacy .privacy-def .privacy-def__data .privacy-def__data-note {
  display: inline-block;
  margin-top: .5rem;
}

.contents--privacy .privacy-def .privacy-def__data a {
  color: #0e6baf;
}

.contents--privacy .privacy-def .privacy-def__data a:hover {
  opacity: .6;
}

.contents--privacy .privacy-def .privacy-lists {
  margin: .5rem 0 0;
  padding-left: 2.25rem;
  list-style-type: upper-latin;
}

@media screen and (max-width: 768px) {
  .contents--privacy .privacy-def .privacy-lists {
    padding-left: 1.75rem;
  }
}

.contents--privacy .privacy-def .privacy-lists .privacy-list {
  list-style-type: upper-latin;
  padding-left: .5rem;
}

.contents--privacy .privacy-def .privacy-lists--disc {
  list-style-type: disc;
}

.contents--privacy .privacy-def .privacy-lists--disc .privacy-list {
  list-style-type: disc;
  padding-left: 0;
}

.contents--law .law-def {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .contents--law .law-def {
    display: block;
    padding-bottom: 0;
  }
}

.contents--law .law-def .law-def__ttl,
.contents--law .law-def .law-def__data {
  padding: .75rem 1.5rem;
}

.contents--law .law-def .law-def__ttl:nth-of-type(even),
.contents--law .law-def .law-def__data:nth-of-type(even) {
  background: #f5f5f5;
}

.contents--law .law-def .law-def__ttl {
  width: 20%;
}

@media screen and (max-width: 768px) {
  .contents--law .law-def .law-def__ttl {
    width: 100%;
    padding: 1rem 1rem .2rem;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .contents--law .law-def .law-def__ttl:first-of-type {
    padding-top: 0;
  }
}

.contents--law .law-def .law-def__data {
  width: 80%;
}

@media screen and (max-width: 768px) {
  .contents--law .law-def .law-def__data {
    width: 100%;
    padding: .2rem 1rem 1rem;
  }
}

.contents--law .law-def .law-def__data a {
  color: #0e6baf;
}

.contents--law .law-def .law-def__data a:hover {
  opacity: .6;
}

.contents--faq .faq-def .faq-def__ttl {
  width: 100%;
  color: #0e6baf;
  padding: 2rem 0 .5rem 0;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contents--faq .faq-def .faq-def__ttl:first-of-type {
    padding-top: 0;
  }
}

.contents--faq .faq-def .faq-def__data {
  width: 100%;
  background: #f5f5f5;
  padding: 1rem;
}
