@charset "UTF-8";

// -----------------------------------------------
// Table
// -----------------------------------------------
.table {
    width: 100%;

    .tr {
        &:nth-child(2n) {
            background: $color_11;
        }
    }

    .th,
    .td {
        padding: .6rem 1rem;
    }

    &.table--wide {
        .th {
            @include sm {
                width: 20%;
            }

            @include mt {
                width: 40%;
            }
        }

        .td {
            @include sm {
                width: 80%;
            }

            @include mt {
                width: 60%;
            }
        }
    }

    &.table--half {
        .th {
            width: 35%;
        }

        .td {
            width: 65%;
        }
    }
}
