@charset "UTF-8";

// -----------------------------------------------
// reset
// -----------------------------------------------
* {
    margin: 0;
    padding: 0;
    text-size-adjust: 100%;
    box-sizing: border-box;
    font-size: inherit;
    @include fontfamily-meiryo();
}

img {
    border: none;
}

li {
    list-style: none;
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
}

code,
kbd,
pre,
samp,
tt {
    @include fontfamily-meiryo();
    line-height: 100%;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button,
fieldset,
form,
input,
label,
legend,
select,
textarea {
    border: 0;
    box-shadow: 0 0 0 0;
    background: none;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    vertical-align: baseline;
    -webkit-appearance: none;
}

input:active,
input:focus,
textarea:active,
textarea:focus,
button:active,
button:focus,
a:active,
a:focus {
    outline: 0;
    font-style: inherit;
    font-weight: inherit;
}

q::before,
q::after {
    content: '';
}

article,
aside,
dialog,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
    display: block;
    word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6,
section,
nav,
article,
aside,
hgroup,
header,
footer,
address,
address,
caption,
cite,
code,
dfn,
em,
th,
strong,
var,
small,
time {
    font-style: normal;
    font-weight: normal;
    // font-size: inherit;
}

// chorome
input:-webkit-autofill,
textarea:-webkit-autofill {
    box-shadow: 0 0 0 1000px transparent inset;
}

/*!
    Add ress
    https://github.com/filipelinhares/ress
*/
audio:not([controls]) {
    display: none;
    height: 0;
}

hr {
    overflow: visible;
}

[hidden],
template {
    display: none;
}

abbr[title] {
    border-bottom: 1px dotted;
    text-decoration: none;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
    outline-width: 0;
}

b,
strong {
    font-weight: bolder;
}

dfn {
    font-style: italic;
}

sub,
sup {
    // font-size: inherit;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

input {
    border-radius: 0;
}

[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

textarea {
    overflow: auto;
    resize: vertical;
}

optgroup {
    font-weight: bold;
}

button {
    overflow: visible;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button,
select {
    text-transform: none;
}

button,
input,
select,
textarea {
    background-color: transparent;
    border-style: none;
    color: inherit;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

select::-ms-expand {
    display: none;
}

select::-ms-value {
    color: currentColor;
}

legend {
    border: 0;
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: .54;
}

progress {
    vertical-align: baseline;
}

svg:not(:root) {
    overflow: hidden;
}

audio,
canvas,
progress,
video {
    display: inline-block;
}
